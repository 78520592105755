import { AdvertisingWidgetDto } from 'types/Types';
import styles from './MobileAdvertisingWidget.module.scss';
import MobileGameTypeWidget from '../MobileGameTypeWidget/MobileGameTypeWidget';
import { ReactComponent as Arrow } from '../../../assets/upper_right_arrow.svg';
import { useState } from 'react';
import MobileNotGameTypeWidget from '../MobileNotGameTypeWidget/MobileNotGameTypeWidget';
import ShowOn from 'components/core/adaptivity/ShowOn';
import Colors from 'constants/colors';

type MobileAdvertisingWidgetType = {
  advertising: AdvertisingWidgetDto;
};

function MobileAdvertisingWidget({ advertising }: MobileAdvertisingWidgetType) {
  const [isFull, setIsFull] = useState<boolean>(true);

  function getWidget() {
    if (advertising.attributes.game?.data) {
      return <MobileGameTypeWidget advertising={advertising} />;
    }

    return <MobileNotGameTypeWidget advertising={advertising} />;
  }
  return isFull ? (
    <div className={styles.bottomBlock}>
      <a
        href={advertising.attributes.link}
        target='_blank'
        className={styles.linkContainer}
      >
        <div className={styles.insideBlock}>{getWidget()}</div>
      </a>
      <div
        className={styles.fullVersionGameArrowBtn}
        onClick={() => {
          setIsFull(false);
        }}
      >
        <ShowOn largeDesktop smallDesktop largeTablet>
          <Arrow
            className={
              advertising.attributes.game?.data
                ? styles.fullVersionGameArrow
                : styles.fullVersionArrow
            }
            color={Colors.PRIMARY_RED}
          />
        </ShowOn>
        <ShowOn smallTablet largeMobile smallMobile>
          <Arrow
            className={styles.fullVersionArrow}
            color={Colors.PRIMARY_RED}
          />
        </ShowOn>
      </div>
    </div>
  ) : (
    <div
      className={
        advertising.attributes.game?.data
          ? styles.smallVersionGameArrowBtn
          : styles.smallVersionArrowBtn
      }
      onClick={() => {
        setIsFull(true);
      }}
    >
      <Arrow
        className={
          advertising.attributes.game?.data
            ? styles.smallVersionGameArrow
            : styles.smallVersionArrow
        }
        color={Colors.WHITE}
      />
    </div>
  );
}

export default MobileAdvertisingWidget;
