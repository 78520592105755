import { ReactElement } from 'react';
import { ReactComponent as Shop } from '../../../assets/footer_shop.svg';
import { ReactComponent as Ticket } from '../../../assets/footer_ticket.svg';
import { ReactComponent as Academy } from '../../../assets/footer_academy.svg';
import styles from './Buttons.module.scss';
import Url from 'constants/urls';
import { useTranslation } from 'react-i18next';

const Buttons = (): ReactElement => {
  const { t } = useTranslation();
  return (
    <div className={styles.wrapper}>
      <div className={styles.shopRow}>
        <a
          href={Url.loko.SHOP}
          className={styles.shopColumn}
          target='_blank'
          rel='noreferrer'
        >
          <Shop />
          <span className={styles.text}>{t('footer.buttons.shop')}</span>
        </a>
        <a
          href={Url.loko.TICKETS}
          className={styles.ticketsColumn}
          target='_blank'
          rel='noreferrer'
        >
          <Ticket />
          <span className={styles.text}>{t('footer.buttons.tickets')}</span>
        </a>
      </div>
      <div className={styles.academyRow}>
        <a
          href={Url.loko.ACADEMY}
          className={styles.academy}
          target='_blank'
          rel='noreferrer'
        >
          <Academy />
          <span className={styles.text}>{t('footer.buttons.academy')}</span>
        </a>
      </div>
    </div>
  );
};

export default Buttons;
