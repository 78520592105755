import { ReactElement, useState } from 'react';

interface TGIconTransparentSmallProps {
  fillColor: string;
  alterColor?: string;
}

export default function TGIconTransparentSmall({
  fillColor,
  alterColor,
}: TGIconTransparentSmallProps): ReactElement {
  const [color, setColor] = useState(fillColor);

  return (
    <svg
      width='16'
      height='16'
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      onMouseEnter={() => setColor(alterColor ? alterColor : fillColor)}
      onMouseLeave={() => setColor(fillColor)}
    >
      <g id='icons'>
        <path
          id='Vector'
          fillRule='evenodd'
          clipRule='evenodd'
          d='M1.85924 7.03732L10.1993 3.60346C11.0193 3.2447 13.8149 2.10318 13.8149 2.10318C13.8149 2.10318 15.1008 1.59998 14.9937 2.81605C14.9564 3.31925 14.6722 5.07112 14.3833 6.96744L13.4887 12.5865C13.4887 12.5865 13.4188 13.4112 12.8085 13.551C12.1981 13.6907 11.201 13.0524 11.0193 12.908C10.8376 12.7635 8.33559 11.1887 7.4084 10.4013C7.31385 10.3377 7.23694 10.2511 7.18483 10.1498C7.13271 10.0484 7.10709 9.93549 7.11035 9.82156C7.1136 9.70763 7.14563 9.59637 7.20345 9.49815C7.26126 9.39993 7.34299 9.31793 7.44102 9.25979C8.73163 8.07634 10.2692 6.60867 11.201 5.68148C11.6297 5.24817 12.0583 4.24644 10.2692 5.4625L5.22322 8.86375C4.96574 8.98053 4.68732 9.04403 4.40467 9.05044C4.12202 9.05684 3.841 9.00602 3.5785 8.90102C2.50222 8.57953 1.24888 8.15088 1.24888 8.15088C1.24888 8.15088 0.391577 7.61041 1.85924 7.03732Z'
          fill={color}
        />
      </g>
    </svg>
  );
}
