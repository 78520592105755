import { Fragment, ReactElement, useEffect, useRef, useState } from 'react';
import Slider from 'react-slick';
import ImageGallery from 'react-image-gallery';
import ReactImageGallery from 'react-image-gallery';
import ArrowLeftLine from '../../../assets/icons/ArrowLeftLine';
import ArrowRightLine from '../../../assets/icons/ArrowRightLIne';
import { MediaSlideProps } from '../../../types/Types';
import styles from './MediaSlider.module.scss';

const sliderSettings = {
  dots: false,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: true,
  prevArrow: <ArrowLeftLine fill='white' />,
  nextArrow: <ArrowRightLine fill='white' />,
};

type MediaSliderProps = {
  media: MediaSlideProps[];
  width?: string;
  height?: string;
};

function MediaSlider({
  media,
  width = '100%',
  height = '500px',
}: MediaSliderProps): ReactElement {
  const galleryRef = useRef<ReactImageGallery | null>(null);
  const [startImageIndex, setStartImageIndex] = useState<number>(0);
  const [isOpenAlbum, setIsOpenAlbum] = useState<boolean>(false);

  const getStartImageIndex = (currentItem: MediaSlideProps) => {
    let startIndex = 0;
    media.forEach((item) => {
      if (item.url === currentItem.url) {
        setStartImageIndex(startIndex);
        return;
      }

      if (!item.isVideo) {
        startIndex++;
      }
    });
  };

  useEffect(() => {
    if (isOpenAlbum && galleryRef?.current) {
      galleryRef.current?.fullScreen();
    }
  }, [isOpenAlbum, galleryRef]);

  return (
    <>
      <Slider className={styles.slider} {...sliderSettings}>
        {media.map((item: MediaSlideProps, index) => (
          <Fragment key={index}>
            {!item.isVideo ? (
              <img
                src={item.url}
                alt='slider-img'
                style={{ width, height }}
                onClick={() => {
                  getStartImageIndex(item);
                  setIsOpenAlbum(true);
                }}
              />
            ) : item.isYoutube ? (
              <iframe
                src={item.url}
                title={item.title}
                allowFullScreen
                style={{ width, height }}
              />
            ) : (
              <video controls style={{ width, height }}>
                <source src={item.url} type='video/mp4' />
                Your browser does not support the video tag.
              </video>
            )}
          </Fragment>
        ))}
      </Slider>
      {isOpenAlbum && (
        <ImageGallery
          ref={galleryRef}
          items={
            media
              ?.filter((item) => !item.isVideo)
              ?.map((item) => {
                return {
                  original: item.url,
                  thumbnail: item.url,
                };
              }) ?? []
          }
          infinite={false}
          showPlayButton={false}
          startIndex={startImageIndex || 0}
          onScreenChange={(value) => setIsOpenAlbum(value)}
        />
      )}
    </>
  );
}

export default MediaSlider;
