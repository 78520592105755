import { ReactElement } from "react";
import styles from "./LokoTableHeadCell.module.scss";
import classNames from "classnames";

interface LokoTableHeadCellProps {
  children: ReactElement;
  cellHeight: number;
  colSpan: number;
  rowSpan: number;
  isDark: boolean;
}

export default function LokoTableHeadCell({
  children,
  cellHeight,
  colSpan,
  rowSpan,
  isDark,
}: LokoTableHeadCellProps): ReactElement {
  return (
    <th rowSpan={rowSpan} colSpan={colSpan} className={styles.th}>
      <div
        className={classNames(
          styles.thBackground,
          isDark ? styles.thBackgroundDark : styles.thBackgroundLight
        )}
        style={{ height: `${cellHeight}px` }}
      >
        {children}
      </div>
    </th>
  );
}
