import { useEffect, useMemo, useRef, useState } from 'react';
import Slider from 'react-slick';
import { getGamesInSeason } from '../../api/api';
import { getCountDaysInMonth, getMonthName } from '../../helpers/helpers';
import { mergeGameWithLogoAndDateScheduleBlock } from '../../mapping/mapping';
import { GameWithLogo, ScheduleDay } from '../../types/Types';
import { ReactComponent as ArrowLeft } from '../../assets/left_arrow.svg';
import { ReactComponent as ArrowRight } from '../../assets/right_arrow.svg';
import { ReactComponent as ArrowUpper } from '../../assets/arrow_upper_white2.svg';
import styles from './ScheduleHPBlock.module.scss';
import classNames from 'classnames';
import Day from './Day/Day';
import { useStore } from '../../store/store';
import { observer } from 'mobx-react-lite';
import ShowOn from 'components/core/adaptivity/ShowOn';
import {
  BreakpointsForCalendar,
  calendarSlidesToScroll,
  calendarSlidesToShow,
} from 'constants/constants';
import Spinner from 'components/Spinner/Spinner';
import ErrorWidget from 'components/common/ErrorWidget/ErrorWidget';
import { useTranslation } from 'react-i18next';
import Select from 'components/common/Select/Select';
import Colors from 'constants/colors';

const ScheduleHPBlock = observer(() => {
  const { t } = useTranslation();
  const [schedule, setSchedule] = useState<Array<ScheduleDay>>([]);
  const [monthOptions, setMonthOptions] =
    useState<Array<{ key: string; value: string }>>();
  const currentMonth = new Date().getMonth();
  const currentYear = new Date().getFullYear();
  const [games, setGames] = useState<Array<GameWithLogo>>([]);
  const { currentSeasonStore, lokomotivTeamsStore } = useStore();
  const sliderRef = useRef<any>(null);
  const [countDays, setCountDays] = useState<Map<string, number>>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);
  const [isSeasonError, setIsSeasonError] = useState<boolean>(false);
  const [isDisabledRight, setIsDisabledRight] = useState<boolean>(false);
  const [isDisabledLeft, setIsDisabledLeft] = useState<boolean>(false);
  const [selectDate, setSelectDate] = useState<string>();

  const showSlides = new Map()
    .set(BreakpointsForCalendar.tv, calendarSlidesToShow.tv)
    .set(BreakpointsForCalendar.largeDesktop, calendarSlidesToShow.largeDesktop)
    .set(BreakpointsForCalendar.desktop, calendarSlidesToShow.desktop)
    .set(BreakpointsForCalendar.tablet, calendarSlidesToShow.tablet)
    .set(BreakpointsForCalendar.mobile, calendarSlidesToShow.mobile);

  const slidesToScroll = new Map()
    .set(BreakpointsForCalendar.tv, calendarSlidesToScroll.tv)
    .set(
      BreakpointsForCalendar.largeDesktop,
      calendarSlidesToScroll.largeDesktop
    )
    .set(BreakpointsForCalendar.desktop, calendarSlidesToScroll.desktop)
    .set(BreakpointsForCalendar.tablet, calendarSlidesToScroll.tablet)
    .set(BreakpointsForCalendar.mobile, calendarSlidesToScroll.mobile);

  const responsiveSetting = [
    {
      breakpoint: BreakpointsForCalendar.tv,
      settings: {
        slidesToShow: calendarSlidesToShow.tv,
      },
    },
    {
      breakpoint: BreakpointsForCalendar.largeDesktop,
      settings: {
        slidesToShow: calendarSlidesToShow.largeDesktop,
      },
    },
    {
      breakpoint: BreakpointsForCalendar.desktop,
      settings: {
        slidesToShow: calendarSlidesToShow.desktop,
      },
    },
    {
      breakpoint: BreakpointsForCalendar.tablet,
      settings: {
        slidesToShow: calendarSlidesToShow.tablet,
      },
    },
    {
      breakpoint: BreakpointsForCalendar.mobile,
      settings: {
        slidesToShow: calendarSlidesToShow.mobile,
      },
    },
  ];

  useEffect(() => {
    const getData = async () => {
      try {
        setIsLoading(true);
        if (
          currentSeasonStore.currentSeason?.men &&
          lokomotivTeamsStore.lokomotivTeams?.men
        ) {
          const respSeason = await getGamesInSeason(
            `${currentSeasonStore.currentSeason?.men.slice(0, 7)}`,
            undefined,
            lokomotivTeamsStore.lokomotivTeams?.men?.data?.attributes
              ?.id_league_web,
            lokomotivTeamsStore.lokomotivTeamsIds
          );
          const respPrepSeason = await getGamesInSeason(
            `${currentSeasonStore.currentSeason?.men.slice(0, 7)}prip`,
            undefined,
            lokomotivTeamsStore.lokomotivTeams?.men?.data?.attributes
              ?.id_league_web,
            lokomotivTeamsStore.lokomotivTeamsIds
          );
          const respPlayOffSeason = await getGamesInSeason(
            `${currentSeasonStore.currentSeason?.men.slice(0, 7)}-2`,
            undefined,
            lokomotivTeamsStore.lokomotivTeams?.men?.data?.attributes
              ?.id_league_web,
            lokomotivTeamsStore.lokomotivTeamsIds
          );
          setGames([
            ...respSeason?.data,
            ...respPrepSeason?.data,
            ...respPlayOffSeason?.data,
          ]);

          setIsSeasonError(false);
        } else {
          setIsLoading(false);
          setIsSeasonError(true);
        }
      } catch (error) {
        setIsError(true);
      } finally {
        setIsLoading(false);
      }
    };
    getData();
  }, [
    currentSeasonStore.currentSeason,
    lokomotivTeamsStore.lokomotivTeams,
    lokomotivTeamsStore.lokomotivTeamsIds,
  ]);

  useEffect(() => {
    try {
      if (currentSeasonStore.currentSeason?.men) {
        const calendar = getScheduleWithoutGames();
        setSchedule(mergeGameWithLogoAndDateScheduleBlock(calendar, games));
        setMonthOptions(getMonthFilterOptions());
      }
    } catch (error) {
      setIsError(true);
    }
  }, [games]);

  useEffect(() => {
    setSelectDate(
      JSON.stringify({
        month: currentMonth,
        year: currentYear,
      })
    );
    setCountDays(getCountDays());
  }, [schedule]);

  const getTodayScheduleIndex = useMemo(() => {
    const index = schedule?.findIndex((item, i) => {
      const date = new Date(item.day);
      const month = date.getMonth();
      const year = date.getFullYear();
      const day = date.getDate();
      if (
        month === currentMonth &&
        year === currentYear &&
        day === new Date().getDate()
      ) {
        return i;
      }
    });

    if (index) {
      return index;
    } else {
      return 0;
    }
  }, [schedule, currentMonth, currentYear]);

  useEffect(() => {
    if (!sliderRef?.current) {
      return;
    }

    if (getTodayScheduleIndex >= 0) {
      sliderRef?.current?.slickGoTo(getTodayScheduleIndex - 1, false);
    }
  }, [sliderRef?.current, getTodayScheduleIndex]);

  function getScheduleWithoutGames(): Array<Date> {
    const scheduleArray: Date[] = [];
    const monthOptions = getMonthFilterOptions();

    monthOptions.forEach((month) => {
      const key = JSON.parse(month.key);
      const countDaysInMonth = getCountDaysInMonth(
        Number(key.month),
        Number(key.year)
      );

      for (let i = 1; i <= countDaysInMonth; i++) {
        scheduleArray.push(new Date(Number(key.year), Number(key.month), i));
      }
    });
    return scheduleArray;
  }

  const sortMonthOptions = (options: Array<{ key: string; value: string }>) =>
    options.sort((a, b) => {
      const aKey = JSON.parse(a.key);
      const bKey = JSON.parse(b.key);
      return aKey.year === bKey.year
        ? aKey.month - bKey.month
        : aKey.year - bKey.year;
    });

  function getMonthFilterOptions(): Array<{ key: string; value: string }> {
    const monthOptions = new Map();
    let isCurrentMonthExists = false;

    games?.forEach((game) => {
      const date = new Date(game.attributes.date);
      const month = date.getMonth();
      const year = date.getFullYear();

      if (month === currentMonth && year === currentYear) {
        isCurrentMonthExists = true;
      }

      if (!monthOptions.has(JSON.stringify({ month, year })))
        monthOptions.set(
          JSON.stringify({ month, year }),
          `${getMonthName(month)} ${year}`
        );
    });

    if (!isCurrentMonthExists) {
      monthOptions.set(
        JSON.stringify({ month: currentMonth, year: currentYear }),
        `${getMonthName(currentMonth)} ${currentYear}`
      );
    }

    return sortMonthOptions(
      Array.from(monthOptions, ([key, value]) => ({ key, value }))
    );
  }

  function getCountDays() {
    const countDaysOptions = new Map();

    schedule?.forEach((item, i) => {
      const date = new Date(item.day);
      const month = date.getMonth();
      const year = date.getFullYear();
      if (!countDaysOptions.has(JSON.stringify({ month, year })))
        countDaysOptions.set(JSON.stringify({ month, year }), i);
    });
    return countDaysOptions;
  }

  function changeFilter(value: string) {
    setSelectDate(value);
    sliderRef?.current?.slickGoTo(countDays?.get(value), false);
  }

  function changeFilterAfterScroll(currentSlide: number) {
    countDays?.forEach((value, key) => {
      if (
        value <=
          currentSlide +
            Math.floor(
              showSlides.get(sliderRef.current.state.breakpoint) / 2
            ) ||
        value <= currentSlide
      ) {
        setSelectDate(key);
      }
    });
    disableArrowNext(currentSlide);
    disableArrowPrevious(currentSlide);
  }

  function slideNext() {
    sliderRef?.current?.slickGoTo(
      sliderRef.current.innerSlider.state.targetSlide +
        slidesToScroll.get(sliderRef.current.state.breakpoint),
      false
    );
  }

  function slidePrevious() {
    sliderRef?.current?.slickGoTo(
      sliderRef.current.innerSlider.state.targetSlide -
        slidesToScroll.get(sliderRef.current.state.breakpoint),
      false
    );
  }

  function disableArrowPrevious(currentSlide: number) {
    if (currentSlide === 0) {
      setIsDisabledLeft(true);
    } else {
      setIsDisabledLeft(false);
    }
  }

  function disableArrowNext(currentSlide: number) {
    if (currentSlide === 0) {
      setIsDisabledLeft(false);
    }
    if (
      currentSlide >=
      Number(schedule.length) -
        showSlides.get(sliderRef.current.state.breakpoint)
    ) {
      setIsDisabledRight(true);
    } else {
      setIsDisabledRight(false);
    }
  }

  function handlerClickPrevious() {
    slidePrevious();
    countDays?.forEach((value, key) => {
      if (
        value <=
        sliderRef.current.innerSlider.state.targetSlide -
          slidesToScroll.get(sliderRef.current.state.breakpoint) +
          Math.floor(showSlides.get(sliderRef.current.state.breakpoint) / 2)
      ) {
        setSelectDate(key);
      }
    });
  }

  function handlerClickNext() {
    slideNext();
    countDays?.forEach((value, key) => {
      if (
        value <=
        sliderRef.current.innerSlider.state.targetSlide +
          slidesToScroll.get(sliderRef.current.state.breakpoint) +
          Math.floor(showSlides.get(sliderRef.current.state.breakpoint) / 2)
      ) {
        setSelectDate(key);
      }
    });
  }

  function getMonthSelectOptions(
    monthOptions: Array<{ key: string; value: string }>
  ) {
    return monthOptions.map((option) => {
      const value = JSON.stringify({
        month: JSON.parse(option.key).month,
        year: JSON.parse(option.key).year,
      });
      return {
        value: value,
        displayValue: option.value,
      };
    });
  }

  function renderSelect() {
    return (
      monthOptions && (
        <Select
          className={styles.select}
          placeholder=''
          value={selectDate}
          arrowColor={Colors.WHITE}
          values={getMonthSelectOptions(monthOptions)}
          onChange={(option: any) => changeFilter(option)}
        />
      )
    );
  }

  const render = () => (
    <>
      <ShowOn largeDesktop smallDesktop largeTablet smallTablet>
        <div className={styles.calendarHeader}>
          <span className={styles.calendarHeaderText}>
            {t('homepage.scheduleWidget.header')}
          </span>
          {renderSelect()}
          <a href={`/schedule/men`} className={styles.link}>
            <div className={styles.headerBtn}>
              <span className={styles.headerBtnText}>
                {t('homepage.scheduleWidget.link')}
              </span>
              <ArrowUpper className={styles.arrowIcon} />
            </div>
          </a>
        </div>
      </ShowOn>

      <ShowOn largeMobile smallMobile>
        <div className={styles.calendarHeaderMobile}>
          <span className={styles.calendarHeaderText}>
            {t('homepage.scheduleWidget.header')}
          </span>
          <div>
            <a href={`/schedule/men`} className={styles.linkMobile}>
              <div className={styles.headerBtnMobile}>
                <span className={styles.headerBtnText}>
                  {t('homepage.scheduleWidget.link')}
                </span>
                <ArrowUpper />
              </div>
            </a>
          </div>
        </div>
        {renderSelect()}
      </ShowOn>

      <div className={styles.calendarSlick}>
        {!isDisabledLeft && (
          <button
            className={styles.buttonLeft}
            onClick={() => {
              handlerClickPrevious();
            }}
          >
            <ArrowLeft />
          </button>
        )}
        {!isDisabledRight && (
          <button
            className={styles.buttonRight}
            onClick={() => {
              handlerClickNext();
            }}
          >
            <ArrowRight />
          </button>
        )}
        <Slider
          arrows={false}
          infinite={false}
          swipeToSlide={true}
          responsive={responsiveSetting}
          className={styles.slider}
          variableWidth={true}
          ref={sliderRef}
          rows={1}
          slidesPerRow={1}
          afterChange={changeFilterAfterScroll}
        >
          {schedule?.map((scheduleDay) => (
            <Day key={scheduleDay.day.getDate()} slickDay={scheduleDay} />
          ))}
        </Slider>
      </div>
      <div className={styles.legendaWrapper}>
        <div className={classNames(styles.legenda)}>
          <div
            className={classNames(styles.legendaColor, styles.legendaAway)}
          ></div>
          <span>{t('homepage.scheduleWidget.legendAway')}</span>
        </div>
        <div className={styles.legenda}>
          <div className={styles.legendaColor}></div>
          <span>{t('homepage.scheduleWidget.legendHome')}</span>
        </div>
      </div>
    </>
  );

  return (
    <div className={styles.calendar} id={'game-calendar-row'}>
      {isLoading || !getTodayScheduleIndex || schedule?.length === 0 ? (
        <Spinner />
      ) : isSeasonError || isError || !schedule ? (
        <ErrorWidget.Error isOnDarkBackground />
      ) : (
        render()
      )}
    </div>
  );
});

export default ScheduleHPBlock;
