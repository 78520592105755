import { ReactElement, useEffect, useRef, useState } from 'react';
import styles from './PostWritingForm.module.scss';
import { DEFAULT_IMAGE_NAME } from 'constants/constants';
import { DefaultImageObject, ForumCategory, SmileDto } from 'types/Types';
import { observer } from 'mobx-react-lite';
import { useStore } from 'store/store';
import classNames from 'classnames';
import photo from 'assets/icons/photo.svg';
import link from 'assets/icons/link-2.svg';
import emoji from 'assets/icons/emoji.svg';
import { Tooltip, notification } from 'antd';
import { getDefaultPlayerImage, postForumMessage } from 'api/api';
import { getSQLDateTimeString } from 'helpers/helpers';
import { useTranslation } from 'react-i18next';
import ShowOn from 'components/core/adaptivity/ShowOn';

type PostWritingFormProps = {
  smiles: Array<SmileDto>;
  category?: ForumCategory;
  onCancel: () => void;
};

const replaceBetween = (
  start: number,
  end: number,
  what: string,
  value: string
) => {
  return value.substring(0, start) + what + value.substring(end);
};

const PostWritingForm = observer(
  ({ smiles, category, onCancel }: PostWritingFormProps): ReactElement => {
    const { userStore } = useStore();
    const { t } = useTranslation();
    const textAreaRef = useRef<any>(null);
    const [defaultPhoto, setDefaultPhoto] = useState<DefaultImageObject>();

    useEffect(() => {
      const getData = async () => {
        try {
          const photo = await getDefaultPlayerImage(DEFAULT_IMAGE_NAME);
          setDefaultPhoto(photo.data[0]);
        } catch (e: any) {
          console.error(e);
        }
      };
      getData();
    }, []);

    const getUserName = () => {
      if (userStore.currentUser?.name && userStore.currentUser?.surname) {
        return `${userStore.currentUser.name} ${userStore.currentUser.surname}`;
      } else {
        return userStore.currentUser?.username;
      }
    };

    const onCancelClick = () => {
      onCancel();
    };

    const onSubmitClick = async () => {
      try {
        if (
          textAreaRef.current?.value == null ||
          textAreaRef.current?.value === ''
        ) {
          throw new Error(t('memoryBookPage.postWritingFormProps.emptyText'));
        }

        if (userStore.currentUser && userStore.isLogged) {
          const response = await postForumMessage(
            {
              id: null,
              data: {
                user: userStore.currentUser.id,
                category: Number(category?.id) || 2,
                name:
                  userStore.currentUser.nickname ||
                  userStore.currentUser.username,
                display: true,
                date_time: getSQLDateTimeString(new Date()),
                // TODO: find what is the difference between date_time and timestamp there
                timestamp: getSQLDateTimeString(new Date()),
                text: textAreaRef.current?.value,
                karma: 0,
              },
            },
            userStore.getJWT()
          );

          if (response) {
            textAreaRef.current.value = '';
            window.location.reload();
          }
        }
      } catch (e: any) {
        const error = e?.response?.data?.error || e;
        console.log(error);
        if (error && error?.message) {
          console.log(error?.message);
        }

        notification.error({
          message: t('memoryBookPage.postWritingFormProps.postNotPublished', {
            message: error?.message || e?.message,
          }),
          placement: 'bottomLeft',
          duration: 15,
        });
      }
    };

    const insert_bbcode = (targetRef: any, type: string) => {
      const target = targetRef.current;
      let startPos = target.selectionStart;
      let endPos = target.selectionEnd;
      let selectedText = '';
      if (startPos != null && endPos != null) {
        selectedText = target.value.substring(startPos, endPos);
      }

      let startTag = '';
      let endTag = '';

      if (type == 'b' || type == 'i' || type == 'abc' || type == 'img') {
        startTag = '[' + type + ']';
        endTag = '[/' + type + ']';
        target.value = replaceBetween(
          startPos,
          endPos,
          startTag + selectedText + endTag,
          target.value
        );
      } else if (type == 'url') {
        if (selectedText.length)
          startTag = '[' + type + '=' + selectedText + ']';
        else startTag = '[' + type + ']';
        endTag = '[/' + type + ']';
        target.value = replaceBetween(
          startPos,
          endPos,
          startTag + 'ссылка' + endTag,
          target.value
        );
        endPos = startPos + 'ссылка'.length;
      }

      startPos += startTag.length;
      endPos += startTag.length;

      setTimeout(function () {
        target.focus();
        target.setSelectionRange(startPos, endPos);
      }, 10);

      return false;
    };

    const insertSmile = (type: string) => {
      const textAreaItem = textAreaRef.current;
      textAreaItem.value += ` ${type.trim()} `;
    };

    return (
      <div className={styles.wrapper}>
        <div className={styles.writingForm}>
          <div className={styles.formHeader}>
            {t('memoryBookPage.postWritingFormProps.addNewPost')}
          </div>
          <textarea ref={textAreaRef} className={styles.textarea}></textarea>
          <div className={styles.formatButtons}>
            <div
              className={styles.iconWrapper}
              onClick={() => insert_bbcode(textAreaRef, 'url')}
            >
              <img className={styles.icon} src={link} alt='' />
            </div>
            <div
              className={styles.iconWrapper}
              onClick={() => insert_bbcode(textAreaRef, 'img')}
            >
              <img className={styles.icon} src={photo} alt='' />
            </div>
            <Tooltip
              title={
                <div id='smiles'>
                  {smiles &&
                    smiles?.map((x: SmileDto) => {
                      return (
                        <a
                          key={`${x?.id}`}
                          onClick={() =>
                            insertSmile(` ${x?.attributes?.text} `)
                          }
                          title={t(
                            'memoryBookPage.postWritingFormProps.clickToEnter'
                          )}
                        >
                          <img
                            src={`${x?.attributes?.image?.data?.attributes?.url}`}
                          />
                        </a>
                      );
                    })}
                </div>
              }
              trigger='click'
              color={'white'}
            >
              <div className={styles.iconWrapper}>
                <img className={styles.icon} src={emoji} alt='' />
              </div>
            </Tooltip>
          </div>
        </div>
        <div className={styles.actionBlock}>
          <ShowOn largeDesktop smallDesktop largeTablet smallTablet>
            <div className={styles.userBlock}>
              <div className={styles.imageWrapper}>
                <img
                  className={styles.image}
                  src={
                    userStore.currentUser?.avatar?.url
                      ? userStore.currentUser?.avatar?.url
                      : defaultPhoto?.attributes?.image?.data?.attributes?.url
                  }
                  alt=''
                />
              </div>
              <div className={styles.userName}>{getUserName()}</div>
            </div>
          </ShowOn>

          <div className={styles.actionButtons}>
            <button
              className={classNames(styles.actionButton, styles.active)}
              onClick={onSubmitClick}
            >
              {t('memoryBookPage.postWritingFormProps.send')}
            </button>
            <button className={styles.actionButton} onClick={onCancelClick}>
              {t('memoryBookPage.postWritingFormProps.cancel')}
            </button>
          </div>
        </div>
      </div>
    );
  }
);

export default PostWritingForm;
