import styles from './SocialNetNewsCompactWidget.module.scss';
import SocialNetSelector from './SocialNetSelector/SocialNetSelector';
import { useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { SocialNetSelectorValue } from './SocialNetSelectorValue';
import VKNewsFeedComponent from './NewsFeedComponents/VKNewsFeedComponent/VKNewsFeedComponent';
import TelegramNewsFeedComponent from './NewsFeedComponents/TelegramNewsFeedComponent/TelegramNewsFeedComponent';
import { useTranslation } from 'react-i18next';

type SocialNetNewsCompactWidgetProps = {
  isHomePage: boolean;
};

/**
 * Render and return the SocialNetNewsCompactWidget component.
 *
 * @returns {React.ReactElement} The rendered SocialNetNewsCompactWidget component.
 */
export default function SocialNetNewsCompactWidget({
  isHomePage,
}: SocialNetNewsCompactWidgetProps): React.ReactElement {
  const { t } = useTranslation();
  const [value, setValue] = useState<SocialNetSelectorValue>(
    SocialNetSelectorValue.VK
  );

  const childSocNetComponent: React.ReactElement =
    value === SocialNetSelectorValue.VK ? (
      <VKNewsFeedComponent />
    ) : (
      <TelegramNewsFeedComponent />
    );

  return (
    <div
      className={isHomePage ? styles.wrapperHomePage : styles.wrapper}
      id={'social-network-column'}
    >
      <div style={{ marginBottom: '30px' }}>
        <span className={styles.header}>
          {t('homepage.socialsWidget.header')}
        </span>
      </div>
      <SocialNetSelector
        value={value}
        onVkClickCallback={() => setValue(SocialNetSelectorValue.VK)}
        onTelegramClickCallback={() =>
          setValue(SocialNetSelectorValue.Telegram)
        }
      />
      <div
        style={{ height: '415px', marginTop: '20px', overflow: 'hidden' }}
        id={'network-content'}
      >
        {childSocNetComponent}
      </div>
    </div>
  );
}
