import TicketCategory from 'model/entity/loko/type/TicketCategory';
import BaseStrapiDAO from '../base/BaseStrapiDAO';
import Strapi from 'strapi-sdk-js';

export default class TicketCategoryDAO extends BaseStrapiDAO<TicketCategory> {
  constructor(client: Strapi) {
    super({
      client: client,
      tableNameSingular: 'ticket-category',
      tableNamePlural: 'ticket-categories',
    });
  }
}
