import { ReactElement } from 'react';
import ArrowUp from '../../assets/arrow_up.svg';
import styles from './ScrollToTopButton.module.scss';
import { useTranslation } from 'react-i18next';

type ScrollToTopButtonType = {
  color?: string;
  textColor?: string;
};

const ScrollToTopButton = ({
  color,
  textColor,
}: ScrollToTopButtonType): ReactElement => {
  const { t } = useTranslation();
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    <button
      className={styles.button}
      onClick={scrollToTop}
      style={{ backgroundColor: color }}
    >
      <span className={styles.text} style={{ color: textColor }}>
        {t('scrollToTopButton')}
      </span>
      <img src={ArrowUp} className={styles.icon} alt='arrow up' />
    </button>
  );
};

export default ScrollToTopButton;
