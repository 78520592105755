import { ReactElement, RefObject, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import styles from './RosterTabsWithInfo.module.scss';
import { RosterDto } from '../../../../types/Types';
import PlayerCard from '../PlayerCard/PlayerCard';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

type RosterTabsWithInfoProps = {
  goalkeepers: RosterDto[];
  defenders: RosterDto[];
  forwards: RosterDto[];
  season?: string;
};

const RosterTabsWithInfo = ({
  goalkeepers,
  defenders,
  forwards,
  season,
}: RosterTabsWithInfoProps): ReactElement => {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const goalkeeperRef = useRef<HTMLDivElement>(null);
  const forwardRef = useRef<HTMLDivElement>(null);
  const defenderRef = useRef<HTMLDivElement>(null);
  const [activeTab, setActiveTab] =
    useState<RefObject<HTMLDivElement>>(goalkeeperRef);

  useEffect(() => {
    const positionParams = searchParams.get('position');
    switch (positionParams) {
      case 'defenders':
        changeActiveTab(defenderRef, true);
        break;
      case 'forwards':
        changeActiveTab(forwardRef, true);
        break;
      default:
        changeActiveTab(goalkeeperRef, true);
        break;
    }
  }, []);

  function changeActiveTab(
    ref: RefObject<HTMLDivElement>,
    isFirstRender = false
  ) {
    if (!isFirstRender) {
      let newPosition;
      switch (ref) {
        case defenderRef:
          newPosition = 'defenders';
          break;
        case forwardRef:
          newPosition = 'forwards';
          break;
        default:
          newPosition = 'goalkeepers';
          break;
      }
      searchParams.set('position', newPosition);
      setSearchParams(searchParams);
    }

    const currentElement = activeTab.current;
    if (currentElement?.className.includes(styles.textShow)) {
      currentElement?.classList.remove(styles.textShow);
    }
    const element = ref.current;
    if (!element?.className.includes(styles.textShow)) {
      element?.classList.add(styles.textShow);
    }
    setActiveTab(ref);
  }

  return (
    <>
      <div className={styles.tabBar} id='tab-bar'>
        <a
          onClick={() => changeActiveTab(goalkeeperRef)}
          className={activeTab === goalkeeperRef ? styles.active : ''}
        >
          <span
            className={
              activeTab === goalkeeperRef ? styles.active : styles.span
            }
          >
            {t('rosterPage.tabs.goalkeepers')}
          </span>
        </a>
        <a
          onClick={() => changeActiveTab(defenderRef)}
          className={activeTab === defenderRef ? styles.active : ''}
        >
          <span
            className={activeTab === defenderRef ? styles.active : styles.span}
          >
            {t('rosterPage.tabs.defenders')}
          </span>
        </a>
        <a
          onClick={() => changeActiveTab(forwardRef)}
          className={activeTab === forwardRef ? styles.active : ''}
        >
          <span
            className={activeTab === forwardRef ? styles.active : styles.span}
          >
            {t('rosterPage.tabs.forwards')}
          </span>
        </a>
      </div>
      <div>
        <div
          ref={goalkeeperRef}
          className={classNames(styles.text, styles.textShow)}
        >
          <div className={styles.wrapper}>
            {goalkeepers.map((player) => (
              <PlayerCard player={player} season={season} key={player.id} />
            ))}
          </div>
        </div>

        <div ref={forwardRef} className={styles.text}>
          <div className={styles.wrapper}>
            {forwards.map((player) => (
              <PlayerCard player={player} season={season} key={player.id} />
            ))}
          </div>
        </div>

        <div ref={defenderRef} className={styles.text}>
          <div className={styles.wrapper}>
            {defenders.map((player) => (
              <PlayerCard player={player} season={season} key={player.id} />
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default RosterTabsWithInfo;
