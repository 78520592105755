import { ReactElement } from 'react';
import { useStore } from '../../../store/store';
import styles from './PlayerStartInfo.module.scss';
import Breadcrumbs from '../../common/Breadcrumbs/Breadcrumbs';
import ShowOn from 'components/core/adaptivity/ShowOn';
import { useSearchParams } from 'react-router-dom';
import { SeasonsTeamCode } from 'types/Types';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

type PlayerStartInfoProps = {
  position: string;
  name: string;
  surname: string;
  info: string;
  number: string;
  id: number;
};

const PlayerStartInfo = observer(
  ({
    position,
    name,
    surname,
    info,
    number,
    id,
  }: PlayerStartInfoProps): ReactElement => {
    const { t } = useTranslation();
    const [searchParams] = useSearchParams();
    const { currentSeasonStore } = useStore();
    const seasonParam = searchParams.get('season');

    const getTeamCode = () => {
      if (seasonParam) {
        if (seasonParam === currentSeasonStore.currentSeason?.catastrophe) {
          return SeasonsTeamCode.catastrophe;
        } else {
          const season = seasonParam?.toLowerCase();

          return season.slice(4, 7);
        }
      } else {
        return SeasonsTeamCode.men;
      }
    };

    return (
      <div className={styles.startInfo}>
        <div className={styles.path}>
          <Breadcrumbs
            breadcrumbs={[
              {
                name: t('playerPage.breadcrumbRoster'),
                url: `/roster/${getTeamCode()}`,
              },
              {
                name: t('playerPage.breadcrumbPlayer', {
                  name: name,
                  surname: surname,
                }),
                url: `/player/${id}?season=${seasonParam}`,
              },
            ]}
          />
        </div>
        <div className={styles.flex}>
          <div>
            <ShowOn largeDesktop smallDesktop largeTablet smallTablet>
              <span className={styles.Position}>{position}</span>
              <span className={styles.Name}>{name}</span>
              <span className={styles.Surname}>{surname}</span>
              <div
                className={styles.Info}
                dangerouslySetInnerHTML={{ __html: info || '' }}
              />
            </ShowOn>

            <ShowOn largeMobile smallMobile>
              <span className={styles.PositionSmallTablet}>{position}</span>
              <span className={styles.NameSmallTablet}>{name}</span>
              <span className={styles.SurnameSmallTablet}>{surname}</span>
              <div
                className={styles.InfoSmallTablet}
                dangerouslySetInnerHTML={{ __html: info || '' }}
              />
            </ShowOn>
          </div>

          <div>
            <ShowOn largeDesktop smallDesktop largeTablet>
              <div className={styles.numberWrapper}>
                <span className={styles.numberIcon}>#</span>
                <span className={styles.number}>{number}</span>
              </div>
            </ShowOn>
            <ShowOn smallTablet>
              <span className={styles.numberIcon}>#</span>
              <span className={styles.number}>{number}</span>
            </ShowOn>
            <ShowOn largeMobile smallMobile>
              <span className={styles.numberIconSmallTablet}>#</span>
              <span className={styles.numberSmallTablet}>{number}</span>
            </ShowOn>
          </div>
        </div>
      </div>
    );
  }
);

export default PlayerStartInfo;
