import { observer } from 'mobx-react-lite';
import WidgetHeader from '../../WidgetHeader/WidgetHeader';
import styles from './LokoTVWidget.module.scss';
import { useState } from 'react';
import VideoList from './VideoList/VideoList';
import VideoPlayer from './VideoPlayer/VideoPlayer';
import { useTranslation } from 'react-i18next';

type LokoTVWidgetProps = {
  columnStyle?: boolean;
};

const LokoTVWidget = observer(({ columnStyle }: LokoTVWidgetProps) => {
  const { t } = useTranslation();
  const [selectedVideo, setSelectedVideo] = useState<string>('');

  // TODO: update header uri when related page will be ready
  return (
    <div className={styles.wrapper} id={'loko-tv-widget'}>
      <WidgetHeader
        headerText={t('homepage.lokoTvWidget.header')}
        linkText={t('homepage.lokoTvWidget.link')}
        uri={'/lokotv'}
      />
      <div className={columnStyle ? styles.contentColumn : styles.content}>
        <VideoPlayer selectedVideo={selectedVideo} />
        <VideoList
          selectVideo={setSelectedVideo}
          selectedVideo={selectedVideo}
        />
      </div>
    </div>
  );
});

export default LokoTVWidget;
