import styles from './PointInfoTooltip.module.scss';
import Slider, { Settings } from 'react-slick';
import ArrowLeftLine from '../../../assets/icons/ArrowLeftLine';
import ArrowRightLine from '../../../assets/icons/ArrowRightLIne';
import classNames from 'classnames';
import { useEffect, useRef, useState } from 'react';
import ReactImageGallery from 'react-image-gallery';
import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/scss/image-gallery.scss';
import '../../Album/CustomImageGallery.scss';

type PointInfoTooltipProps = {
  title: string;
  images?: Array<string>;
  description?: string;
  className?: string;
};

export default function PointInfoTooltip({
  title,
  description,
  images,
  className,
}: PointInfoTooltipProps) {
  const [currentSlide, setCurrentSlide] = useState<string | null>(null);

  useEffect(() => {
    handleSlideChange(0);
  }, []);

  const handleSlideChange = (current: number) => {
    setCurrentSlide(images?.at(current) ?? null);
    setCurrentSlideIndex(current);
  };

  const sliderSettings: Settings = {
    dots: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    prevArrow: <ArrowLeftLine fill='white' />,
    nextArrow: <ArrowRightLine fill='white' />,
    afterChange: handleSlideChange,
  };
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const galleryRef = useRef<ReactImageGallery | null>(null);
  const [currentSlideIndex, setCurrentSlideIndex] = useState<number | null>(
    null
  );

  const openGallery = () => {
    setIsModalOpen(true);
  };

  useEffect(() => {
    if (isModalOpen && images && galleryRef?.current) {
      galleryRef.current?.fullScreen();
    }
  }, [images, galleryRef, isModalOpen]);

  return (
    <>
      <div className={classNames(styles.tooltip, className)}>
        {images?.length ? (
          <div className={styles.tooltipImgContainer}>
            <Slider className={styles.slider} {...sliderSettings}>
              {images.map((img) => (
                <img
                  src={img}
                  alt='slider-img'
                  onClick={openGallery}
                  key={img}
                />
              ))}
            </Slider>
          </div>
        ) : null}
        <div className={styles.infoContainer}>
          <div className={styles.titleContainer}>
            <h3 className={styles.title}>{title}</h3>
          </div>
          {description && (
            <div className={styles.descriptionContainer}>
              <p className={styles.description}>{description}</p>
            </div>
          )}
        </div>
      </div>
      {isModalOpen && currentSlide && (
        <ImageGallery
          items={
            images
              ? images.map((url) => {
                  return {
                    original: url,
                    thumbnail: url,
                    originalClass: !isModalOpen ? 'customImage' : '',
                    thumbnailClass: 'customThumbnail',
                  };
                })
              : []
          }
          infinite={false}
          showPlayButton={false}
          additionalClass='gallery'
          onScreenChange={(value) => {
            setIsModalOpen(value);
          }}
          startIndex={currentSlideIndex || 0}
          ref={galleryRef}
        />
      )}
    </>
  );
}
