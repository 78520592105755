import { ReactElement, useRef } from "react";
import styles from "./HighlightCard.module.scss";

type HighlightCardProps = {
  src: string;
  caption: string;
};

function HighlightCard({ src, caption }: HighlightCardProps): ReactElement {
  const iframeRef = useRef<HTMLIFrameElement>(null);

  const handleFullscreen = () => {
    if (iframeRef.current) {
      if (iframeRef.current.requestFullscreen) {
        iframeRef.current.requestFullscreen();
      }
    }
  };

  return (
    <div className={styles.highlightCard}>
      <div className={styles.iframeContainer}>
        <iframe
          ref={iframeRef}
          src={src}
          title="Video Content"
          allowFullScreen
        ></iframe>
        <button onClick={handleFullscreen}></button>
      </div>
      <a title={caption} className={styles.caption}>{caption}</a>
    </div>
  );
}
export default HighlightCard;
