import { ReactElement, useEffect, useState } from 'react';
import ImageBgPageWrapper from '../../../common/ImageBgPageWrapper/ImageBgPageWrapper';
import Container from '../../../common/Container/Container';
import BreadcrumbsWrapper from 'components/common/BreadcrumbsWrapper/BreadcrumbsWrapper';
import PageHeader from '../../../common/PageHeader/PageHeader';
import SubscriptionTables from './SubscriptionTables/SubscriptionTables';
import styles from './SubscriptionPage.module.scss';
import ShowOn from 'components/core/adaptivity/ShowOn';
import ArenaSector from 'model/entity/loko/type/ArenaSector';
import lokoApi from 'model/api/lokoApi';
import ArenaSchemePriceComponent from 'assets/images/arenaSchemePriceComponent';
import { useTranslation } from 'react-i18next';
import ScalableDiv from 'components/common/ScalableDiv/ScalableDiv';
import MobilePhotoArenaBlock from '../TicketingProgramPage/MobilePhotoArenaBlock/MobilePhotoArenaBlock';

const SubscriptionPage = (): ReactElement => {
  const { t } = useTranslation();
  const [sectors, setSectors] = useState<Array<ArenaSector>>([]);
  const [mobileSectorInfo, setMobileSectorInfo] = useState<ArenaSector>();
  const [mobileActiveSector, setMobileActiveSector] = useState<string>('');

  useEffect(() => {
    const fetchSectors = async () => {
      try {
        const arenaSectorsData = await lokoApi.arenaSector.findAll({
          populate: '*',
        });
        setSectors(arenaSectorsData?.data);
      } catch (e: any) {
        console.log(e?.message);
      }
    };

    fetchSectors();
  }, []);
  return (
    <ImageBgPageWrapper>
      <Container paddingTop='50px' paddingBottom='60px'>
        <div className={styles.breadcrumbs}>
          <BreadcrumbsWrapper
            breadcrumbs={[
              {
                name: t('subscriptionPage.breadcrumb'),
                url: '/tickets/subscription',
              },
            ]}
          />
        </div>
        <PageHeader
          text={t('subscriptionPage.header')}
          paddingLeft={27}
          paddingBottom={60}
        />
        <Container paddingHorizontal='16px'>
          <ShowOn largeDesktop smallDesktop largeTablet smallTablet>
            <div className={styles.scheme}>
              <ArenaSchemePriceComponent data={sectors} />
            </div>
          </ShowOn>
          <ShowOn largeMobile smallMobile>
            <ScalableDiv>
              <div className={styles.scheme}>
                <ArenaSchemePriceComponent
                  data={sectors}
                  setMobileSectorInfo={setMobileSectorInfo}
                  setMobileActiveSector={setMobileActiveSector}
                />
              </div>
            </ScalableDiv>
            {mobileActiveSector &&
              mobileSectorInfo &&
              mobileSectorInfo.attributes.sys_name === mobileActiveSector && (
                <MobilePhotoArenaBlock
                  title={
                    mobileSectorInfo?.attributes?.arena_category?.data
                      ?.attributes?.name
                  }
                  description={mobileSectorInfo?.attributes?.description}
                  caption={mobileSectorInfo?.attributes?.name}
                  images={mobileSectorInfo?.attributes?.images?.data?.map(
                    (img) => img?.attributes?.url
                  )}
                  topPosition={700}
                  leftPosition={150}
                />
              )}
          </ShowOn>
          <SubscriptionTables />
        </Container>
      </Container>
    </ImageBgPageWrapper>
  );
};

export default SubscriptionPage;
