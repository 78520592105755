import { ReactElement, useState } from 'react';
import styles from './TableTh.module.scss';
import classNames from 'classnames';
import { StandingsTypes } from 'types/Types';

type TextAlign = 'left' | 'center' | 'right';

type TableThProps = {
  text: string;
  title: string;
  updateSorting: Function;
  updateSortingParam: string;
  index: number;
  tableBodyRef: React.RefObject<HTMLTableSectionElement>;
  width?: string;
  textAlign?: TextAlign;
  paddingLeft?: string;
  isFirstTh?: boolean;
  type?: StandingsTypes;
};

function StandingsPageTableBody({
  text,
  title,
  updateSorting,
  updateSortingParam,
  index,
  tableBodyRef,
  width,
  textAlign,
  paddingLeft,
  isFirstTh,
  type = StandingsTypes.number,
}: TableThProps): ReactElement {
  const [activeStyles, setActiveStyles] = useState<boolean>(false);
  const [count, setCount] = useState<number>(1);
  const [isArrowUp, setIsArrowUp] = useState<boolean>(true);

  function setColor(index: number) {
    const table = tableBodyRef.current;
    if (table) {
      const cells = table.querySelectorAll(`td:nth-child(${index})`);
      cells.forEach((cell) => {
        cell?.classList.toggle(styles.highlight);
      });
    }
  }

  function clickHandler() {
    count % 3 !== 2 && setColor(index);
    count % 3 !== 2 && setActiveStyles(!activeStyles);
    count % 3 !== 0 && setIsArrowUp(!isArrowUp);

    updateSorting(updateSortingParam, type);
    setCount(count + 1);
  }

  return (
    <th
      className={classNames(
        activeStyles ? styles.textActive : styles.text,
        isFirstTh ? styles.firstTh : ''
      )}
      title={title}
      style={{
        width: `${width}`,
        textAlign: textAlign,
        paddingLeft: `${paddingLeft}`,
      }}
    >
      <div className={styles.relative}>
        <div
          className={
            activeStyles
              ? isArrowUp
                ? styles.blockActiveArrowUp
                : styles.blockActive
              : styles.block
          }
        ></div>
        <a onClick={clickHandler}>{text}</a>
      </div>
    </th>
  );
}

export default StandingsPageTableBody;
