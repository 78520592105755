import { ReactElement, useRef, useState } from 'react';
import {
  AlbumSimple,
  DefaultImageObject,
  PhotoCategory,
} from '../../types/Types';
import ImageGallery from 'react-image-gallery';
import ExclamationMark from '../../assets/exclamation-mark.svg';
import 'react-image-gallery/styles/scss/image-gallery.scss';
import './CustomImageGallery.scss';
import styles from './Album.module.scss';
import ReactImageGallery from 'react-image-gallery';
import { useTranslation } from 'react-i18next';
import { customLocaleCompare } from 'helpers/helpers';

type AlbumProps = {
  album: PhotoCategory | AlbumSimple;
  defaultImage: DefaultImageObject;
};

const Album = ({ album, defaultImage }: AlbumProps): ReactElement => {
  const { t } = useTranslation();
  const [isFullScreen, setIsFullScreen] = useState<boolean>(false);
  const galleryRef = useRef<ReactImageGallery | null>(null);

  const getSlides = () => {
    if ((album as PhotoCategory).id !== undefined) {
      return (album as PhotoCategory)?.attributes?.photo?.data
        ?.sort((a, b) =>
          customLocaleCompare(a.attributes.name, b.attributes.name)
        )
        .map((photo) => {
          return {
            fullscreen: photo?.attributes?.url,
            original:
              photo?.attributes?.formats?.large?.url || photo?.attributes?.url,
            thumbnail:
              photo?.attributes?.formats?.thumbnail?.url ||
              photo?.attributes?.url,
            originalClass: !isFullScreen ? 'customImage' : '',
            thumbnailClass: 'customThumbnail',
            description: photo.attributes?.alternativeText ?? '',
          };
        });
    } else {
      return (album as AlbumSimple)?.photo?.map((url) => {
        return {
          original: url,
          thumbnail: url,
          originalClass: !isFullScreen ? 'customImage' : '',
          thumbnailClass: 'customThumbnail',
        };
      });
    }
  };
  return (
    <div className={styles.wrapper}>
      <ImageGallery
        items={
          (album as PhotoCategory)?.attributes?.photo.data ||
          (album as AlbumSimple).photo
            ? getSlides()
            : [
                {
                  original:
                    defaultImage?.attributes?.image.data?.attributes?.url,
                },
              ]
        }
        infinite={false}
        showPlayButton={false}
        additionalClass='gallery'
        onScreenChange={(value) => {
          setIsFullScreen(value);
        }}
        startIndex={galleryRef.current?.getCurrentIndex() || 0}
        ref={galleryRef}
      />
      <p className={styles.note}>
        <img className={styles.icon} src={ExclamationMark} alt='note' />
        {t('albumNote')}
      </p>
    </div>
  );
};

export default Album;
