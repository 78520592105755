import { ReactElement } from 'react';
import styles from './ShareInSocials.module.scss';
import LinkIcon from 'assets/link-icon.svg';
import { TelegramShareButton, VKShareButton } from 'react-share';
import TGIconTransparentSmall from 'assets/TGIconTransparentSmall';
import VkIconTransparentSmall from 'assets/VKIconTransparentSmall';
import Colors from 'constants/colors';

function ShareInSocials(): ReactElement {
  const handleCopy = async () => {
    await navigator.clipboard.writeText(window.location.href);
  };

  return (
    <div className={styles.shareContainer}>
      <div className={styles.social}>
        <div className={styles.socialText}>Поделиться</div>
        <div className={styles.socialIcons}>
          <VKShareButton
            url={window.location.href}
            className={styles.shareButton}
          >
            <VkIconTransparentSmall
              fillColor={Colors.GREY_TEXT}
              alterColor={Colors.MAIN_BLUE}
            />
          </VKShareButton>
          <TelegramShareButton
            url={window.location.href}
            className={styles.shareButton}
          >
            <TGIconTransparentSmall
              fillColor={Colors.GREY_TEXT}
              alterColor={Colors.MAIN_BLUE}
            />
          </TelegramShareButton>
        </div>
      </div>
      <div onClick={handleCopy} className={styles.copyLink}>
        <img src={LinkIcon} alt='copy-link' className={styles.copyImg} />
        <p>Скопировать ссылку</p>
      </div>
    </div>
  );
}

export default ShareInSocials;
