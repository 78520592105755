import { ReactElement } from 'react';
import cn from 'classnames';
import styles from './Stages.module.scss';

const TOURNAMENT_STAGES = [
  '1/8',
  '',
  '1/4',
  '',
  '1/2',
  '',
  'Финал',
  '',
  '1/2',
  '',
  '1/4',
  '',
  '1/8',
];

function Stages(): ReactElement {
  return (
    <div className={styles.stages}>
      {TOURNAMENT_STAGES.map((stage, index) => {
        if (!stage) {
          return <div key={index} className={styles.step} />;
        }

        return (
          <div
            key={index}
            className={cn(styles.stage, {
              [styles.final]: stage === 'Финал',
            })}
          >
            {stage}
          </div>
        );
      })}
    </div>
  );
}

export default Stages;
