import styles from './VideoList.module.scss';
import { ReactElement, useEffect, useState } from 'react';
import playIcon from 'assets/video-play-icon.svg';

import {
  getTwoDigitNumber,
  parseISO8601Duration,
} from '../../../../helpers/helpers';
import Spinner from '../../../Spinner/Spinner';
import ErrorWidget from '../../../common/ErrorWidget/ErrorWidget';
import { getYouTubeVideoList, getYouTubeVideos } from 'api/api';
import { useTranslation } from 'react-i18next';

type VideoListProps = {
  selectVideo: (id: string) => void;
  selectedVideo: string | undefined;
};

function VideoList({
  selectVideo,
  selectedVideo,
}: VideoListProps): ReactElement {
  const { t } = useTranslation();
  const [videos, setVideos] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    const getVideos = async () => {
      try {
        const videoList = await getYouTubeVideoList({ maxResults: 4 });
        const videosData = await getYouTubeVideos(
          videoList.items.map((item: any) => item.id.videoId)
        );

        const videoData = videosData?.items;
        setVideos(videoData);
        selectVideo(videoData[0]?.id);
      } catch (error) {
        setIsError(true);
      } finally {
        setIsLoading(false);
      }
    };

    getVideos();
  }, []);

  const renderDescription = (video: any) => {
    switch (video.snippet.liveBroadcastContent) {
      case 'live':
        return (
          <>
            <img src={playIcon} alt='' width={10} height={13} />
            <div className={styles.description}>Смотреть трансляцию</div>
          </>
        );
      case 'upcoming':
        return (
          <div className={styles.description}>Трансляция скоро начнется</div>
        );
      default:
        return (
          <div className={styles.description}>{video.snippet.description}</div>
        );
    }
  };

  const renderVideoList = () => {
    return videos.reduce((result, video, index, array) => {
      result.push(
        <div
          key={video.id}
          className={styles.video}
          onClick={() => onVideoSelect(video.id)}
        >
          <div className={styles.imgWrapper}>
            <img
              className={styles.previewImage}
              src={video.snippet.thumbnails.medium.url}
              alt={video.snippet.title}
            />
          </div>
          <div className={styles.descriptionBlock}>
            <h2 className={styles.title}>{video.snippet.title}</h2>
            <div className={styles.descriptionWrapper}>
              {renderDescription(video)}
            </div>
          </div>
        </div>
      );

      if (index !== array.length - 1) {
        result.push(
          <div className={styles.separator} key={`separator-${index}`} />
        );
      }

      return result;
    }, []);
  };

  const getDurationString = (isoDuration: string) => {
    const duration = parseISO8601Duration(isoDuration);

    if (!duration.hours && !duration.minutes && !duration.seconds) {
      return t('homepage.lokoTvWidget.watchTranslation');
    }

    return `${duration.hours ? duration.hours + ':' : ''}${
      duration.minutes
    }:${getTwoDigitNumber(duration.seconds.toString())}`;
  };

  const onVideoSelect = (id: string) => {
    selectVideo(id);
  };

  const renderContent = () => {
    if (isLoading) {
      return <Spinner />;
    }
    if (isError) {
      return <ErrorWidget.Error />;
    }
    return renderVideoList();
  };

  return (
    <div className={styles.videoListWrapper}>
      <div id={'loco-tv-video-list'} className={styles.videoList}>
        {renderContent()}
      </div>
    </div>
  );
}

export default VideoList;
