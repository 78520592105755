import Colors from 'constants/colors';
import { ReactElement } from 'react';

interface ProtocolBannerLeftLineProps {
  fillColor?: string;
  width?: number;
  height?: number;
}

export default function ProtocolBannerLeftLine({
  fillColor,
  width = 94,
  height = 110,
}: ProtocolBannerLeftLineProps): ReactElement {
  return (
    <svg
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      width={`${width}px`}
      height={`${height}px`}
      viewBox={`0 0 ${width} ${height}`}
    >
      <path
        fill={fillColor || Colors.GREY_TEXT}
        d='M58.03656,0.08936 0,110 35.02203,110 93.05859,0.08936 Z'
      />
    </svg>
  );
}
