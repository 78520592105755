import { ReactElement } from "react";
import styles from "./LokoTableRow.module.scss";
import classNames from "classnames";

interface LokoTableRowProps {
  data: any[];
}

const getPositionStyleById = (id: number, len: number): string => {
  if (id === 0) return styles.cellLeft;
  if (id === len - 1) return styles.cellRight;
  return styles.cellRegular;
};

const getTypeStyleByValue = (value: any): string => {
  return typeof value === "string" ? styles.cellText : styles.cellNumber;
};

const renderValue = (value: any): string => {
  if (typeof value === "number") return `${value}`;
  if (typeof value === "string") return value;
  return "-";
};

export default function LokoTableRow({
  data,
}: LokoTableRowProps): ReactElement {
  const render = () => {
    if (data.length === 0) {
      console.error(
        "Error in TicketingProgramTableRow: ticketCategoryId doesn't exist"
      );
      return <></>;
    }

    const elements: ReactElement[] = [];
    for (let i = 0; i < data.length; i++) {
      elements.push(
        <td className={styles.td} key={i}>
          <div
            className={classNames(
              getPositionStyleById(i, data.length),
              styles.cell,
              getTypeStyleByValue(data[i])
            )}
          >
            {renderValue(data[i])}
          </div>
        </td>
      );
    }

    return <tr className={styles.tr}>{elements}</tr>;
  };

  return render();
}
