import { observer } from 'mobx-react-lite';
import TopPlayerCard from './TopPlayerCard/TopPlayerCard';
import WidgetHeader from '../../WidgetHeader/WidgetHeader';
import styles from './LeadersWidget.module.scss';
import { ReactElement, useEffect, useState } from 'react';
import Spinner from '../../Spinner/Spinner';
import ErrorWidget from '../../common/ErrorWidget/ErrorWidget';
import {
  getDefaultPlayerImage,
  getDefaultSeason,
  getSeasonStats,
} from 'api/api';
import { DefaultImageObject, TopPlayerCardType } from 'types/Types';
import { DEFAULT_PLAYER_IMAGE_NAME } from 'constants/constants';
import { useTranslation } from 'react-i18next';
import {
  mapPlayersToReadable,
  getTopPoints,
  getTopGoals,
  getTopAssists,
  getTopGoalsAgainstAverage,
  loadTopPlayerByStats,
} from 'helpers/helpers';

type LeadersWidgetProps = {
  columnStyle?: boolean;
};

const LeadersWidget = observer(
  ({ columnStyle }: LeadersWidgetProps): ReactElement => {
    const { t } = useTranslation();
    const [defaultPhoto, setDefaultPhoto] = useState<DefaultImageObject>();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [isError, setIsError] = useState<boolean>(false);
    const [leaders, setLeaders] = useState<(TopPlayerCardType | undefined)[]>(
      []
    );

    useEffect(() => {
      const getLeaders = async () => {
        try {
          const currentSeasonResponse = await getDefaultSeason();
          const resp = await getSeasonStats(
            currentSeasonResponse?.data?.attributes?.men
          );
          const playerSeasonStats = mapPlayersToReadable(
            resp.PlayerStatsList.PlayerStats
          );

          const topPoints = getTopPoints(playerSeasonStats);
          const topGoals = getTopGoals(playerSeasonStats);
          const topAssists = getTopAssists(playerSeasonStats);
          const topGoalsAgainstAverage =
            getTopGoalsAgainstAverage(playerSeasonStats);

          const response = await Promise.allSettled([
            loadTopPlayerByStats(topPoints, 'ОЧКОВ', topPoints?.points || 0),
            loadTopPlayerByStats(topGoals, 'ГОЛОВ', topGoals?.goals || 0),
            loadTopPlayerByStats(
              topAssists,
              'ПЕРЕДАЧ',
              topAssists?.assists || 0
            ),
            loadTopPlayerByStats(
              topGoalsAgainstAverage,
              'КН',
              topGoalsAgainstAverage?.goals_against_average || 0
            ),
          ]);

          const topPlayers = response
            .filter((settledResponse) => settledResponse.status === 'fulfilled')
            .map((settledResponse) => {
              if (settledResponse.status === 'fulfilled') {
                return settledResponse.value;
              }
              return undefined;
            });
          setLeaders(topPlayers);
        } catch (error) {
          setIsError(true);
        } finally {
          setIsLoading(false);
        }
      };
      getLeaders();
    }, []);

    useEffect(() => {
      const getData = async () => {
        try {
          const photo = await getDefaultPlayerImage(DEFAULT_PLAYER_IMAGE_NAME);
          setDefaultPhoto(photo.data[0]);
        } catch (e: any) {
          console.error(e);
        }
      };
      getData();
    }, []);

    const renderContent = () => {
      if (isLoading) return <Spinner />;
      if (isError)
        return (
          <div style={{ height: '400px' }}>
            <ErrorWidget.Error />
          </div>
        );
      return (
        <div
          className={
            columnStyle ? styles.leadersInfoColumn : styles.leadersInfo
          }
        >
          {leaders.map((player) => (
            <TopPlayerCard
              key={player?.category}
              player={player}
              defaultImg={
                defaultPhoto
                  ? defaultPhoto.attributes?.image?.data?.attributes?.url
                  : ''
              }
            />
          ))}
        </div>
      );
    };

    return (
      <div className={styles.wrapper} id={'leaders-column'}>
        <WidgetHeader
          headerText={t('homepage.leadersWidget.header')}
          linkText={t('homepage.leadersWidget.link')}
          uri={'/stats/men'}
        />
        {renderContent()}
      </div>
    );
  }
);

export default LeadersWidget;
