import { ReactElement, RefObject, useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useStore } from 'store/store';
import CloseIcon from 'assets/icons/close-icon.svg';
import styles from './AuthWidget.module.scss';
import classNames from 'classnames';
import SignInForm from './SignInForm/SignInForm';
import SignUpForm from './SignUpForm/SignUpForm';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { emailConfirmation } from 'api/api';
import { notification } from 'antd';

const AuthWidget = observer((): ReactElement => {
  const signInRef = useRef<HTMLDivElement>(null);
  const signUpRef = useRef<HTMLDivElement>(null);
  const [activeTab, setActiveTab] =
    useState<RefObject<HTMLDivElement>>(signInRef);
  const [isConfirmEmail, setIsConfirmEmail] = useState(false);
  const { userStore } = useStore();
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const isConfirmed = useRef(false);

  useEffect(() => {
    const getData = async () => {
      const confirmation = searchParams.get('confirmation');
      if (confirmation) {
        try {
          await emailConfirmation(confirmation);

          notification.success({
            message: t('memoryBookPage.authWidget.confirmationSuccess'),
            placement: 'bottomLeft',
            duration: 15,
          });
        } catch (e: any) {
          notification.error({
            message: t('memoryBookPage.authWidget.confirmationError'),
            placement: 'bottomLeft',
            duration: 15,
          });
        } finally {
          searchParams.delete('confirmation');
          setSearchParams(searchParams);
        }
      }
    };

    if (!isConfirmed.current) {
      isConfirmed.current = true;

      getData();
    }
  }, []);

  const changeActiveTab = (ref: RefObject<HTMLDivElement>) => {
    const currentElement = activeTab.current;
    if (currentElement?.className.includes(styles.textShow)) {
      currentElement?.classList.remove(styles.textShow);
    }
    const element = ref.current;
    if (!element?.className.includes(styles.textShow)) {
      element?.classList.add(styles.textShow);
    }
    setActiveTab(ref);
  };

  const onRegistrationSuccess = () => {
    setIsConfirmEmail(true);
  };

  return (
    <div className={styles.authWidget}>
      {isConfirmEmail ? (
        <div className={styles.confirmNotification}>
          <div className={styles.header}>
            {t('memoryBookPage.authWidget.confirmRegistration')}
          </div>
          <div className={styles.message}>
            {t('memoryBookPage.authWidget.signUpSuccess')}
          </div>
          <div className={styles.closeButtonWrapper}>
            <img
              src={CloseIcon}
              alt='Close'
              onClick={() => setIsConfirmEmail(false)}
              className={styles.closeIcon}
            />
          </div>
        </div>
      ) : !userStore.isLogged ? (
        <>
          <div className={styles.tabBar}>
            <div
              onClick={() => changeActiveTab(signInRef)}
              className={classNames(
                styles.refItem,
                activeTab === signInRef ? styles.active : ''
              )}
            >
              {t('memoryBookPage.authWidget.signIn')}
            </div>
            <div className={styles.separator}>/</div>
            <div
              onClick={() => changeActiveTab(signUpRef)}
              className={classNames(
                styles.refItem,
                activeTab === signUpRef ? styles.active : ''
              )}
            >
              {t('memoryBookPage.authWidget.signUp')}
            </div>
          </div>
          <div>
            <div
              ref={signUpRef}
              className={classNames(styles.text, {
                [styles.textShow]: activeTab === signUpRef,
              })}
            >
              <div className={styles.wrapper}>
                <SignUpForm onRegistrationSuccess={onRegistrationSuccess} />
              </div>
            </div>

            <div
              ref={signInRef}
              className={classNames(styles.text, {
                [styles.textShow]: activeTab === signInRef,
              })}
            >
              <div className={styles.wrapper}>
                <SignInForm />
              </div>
            </div>
          </div>
        </>
      ) : null}
    </div>
  );
});

export default AuthWidget;
