import { useCallback, useState } from 'react';
import styles from './MobileHeaderSocials.module.scss';
import Row from 'components/core/layout/Row';
import Column from 'components/core/layout/Column';
import vkIcon from 'assets/menu_icons/vk.svg';
import tgIcon from 'assets/menu_icons/tg.svg';
import ytIcon from 'assets/menu_icons/youtube.svg';
import Url from 'constants/urls';
import { useTranslation } from 'react-i18next';

const MobileHeaderSocials = () => {
  const { t } = useTranslation();
  const [isSocialsOpen, setIsSocialsOpen] = useState(false);

  const items: { url: string; text: string; icon: string }[] = [
    {
      url: Url.socials.VK,
      text: t('header.vk'),
      icon: vkIcon,
    },
    {
      url: Url.socials.TELEGRAM,
      text: t('header.tg'),
      icon: tgIcon,
    },
    {
      url: Url.socials.YOUTUBE,
      text: t('header.yt'),
      icon: ytIcon,
    },
  ];

  const onTap = useCallback(() => {
    setIsSocialsOpen((prevIsSocialsOpen) => !prevIsSocialsOpen);
  }, []);

  return (
    <div className={styles.socialsWrapper}>
      <div onClick={onTap}>
        <Row
          alignItems='center'
          justifyContent='center'
          height='90px'
          width='178px'
        >
          <span className={styles.socialsButtonText}>
            {t('header.socials')}
          </span>
        </Row>
      </div>
      {isSocialsOpen && (
        <div className={styles.socialsDropdown}>
          <Column gap='20px'>
            {items.map((item) => (
              <a
                href={item.url}
                key={item.url}
                target='_blank'
                rel='noreferrer'
              >
                <Row gap='12px' alignItems='center'>
                  <img
                    src={item.icon}
                    alt={item.text}
                    className={styles.socialsDropdownItemIcon}
                  />
                  <span className={styles.socialsDropdownItemText}>
                    {item.text}
                  </span>
                </Row>
              </a>
            ))}
          </Column>
        </div>
      )}
    </div>
  );
};

export default MobileHeaderSocials;
