import styles from './MobileHeaderHamburger.module.scss';
import menuHamburger from 'assets/icons/menu_hamburger.svg';
import menuCross from 'assets/icons/menu_cross.svg';

interface MobileHeaderHamburgerProps {
  isOpen: boolean;
  onTap: () => void;
}

const MobileHeaderHamburger = ({
  isOpen,
  onTap,
}: MobileHeaderHamburgerProps) => (
  <div className={styles.hamburgerWrapper} onClick={onTap}>
    {isOpen ? (
      <img src={menuCross} alt='close' />
    ) : (
      <img src={menuHamburger} alt='open' />
    )}
  </div>
);

export default MobileHeaderHamburger;
