import { useEffect, useRef, useState } from 'react';
import {
  getDefaultPlayerImage,
  getExecutiveById,
  getExecutiveRosters,
  getPhotos,
} from '../../../api/api';
import {
  AlbumSimple,
  DefaultImageObject,
  ExecutiveDto,
  ExecutiveRosterDto,
  ExecutiveType,
  File,
} from '../../../types/Types';
import InfoWrapper from '../../Wrappers/InfoWrapper/InfoWrapper';
import ImageBgPageWrapper from '../../common/ImageBgPageWrapper/ImageBgPageWrapper';
import Container from '../../common/Container/Container';
import BreadcrumbsWrapper from 'components/common/BreadcrumbsWrapper/BreadcrumbsWrapper';
import { observer } from 'mobx-react-lite';
import { useParams, useSearchParams } from 'react-router-dom';
import ExecutiveSmallTiles from '../../ExecutivesSmallTiles/ExecutivesSmallTiles';
import BioBox from '../../PlayerPage/Bio/BioBox/BioBox';
import SetRespError, {
  formatYearFromDate,
  getAge,
  getDate,
  getFullExecutiveName,
  getLastSeasonForExecutive,
  getYearPostfix,
} from '../../../helpers/helpers';
import styles from './CoachPage.module.scss';
import CoachTabsWithInfo from '../../CoachTabsWithInfo/CoachTabsWithInfo';
import {
  DEFAULT_IMAGE_NAME,
  EXECUTIVESNAMES,
  LOKOMOTIV_CODE,
} from '../../../constants/constants';
import ShowOn from 'components/core/adaptivity/ShowOn';
import ErrorWidget from 'components/common/ErrorWidget/ErrorWidget';
import { useTranslation } from 'react-i18next';
import ReactImageGallery from 'react-image-gallery';
import ImageGallery from 'react-image-gallery';
import ErrorPage from '../ErrorPage/ErrorPage';
import Spinner from 'components/Spinner/Spinner';

type CoachPageProps = {
  is_catastrophe: boolean;
};

const CoachPage = observer(({ is_catastrophe }: CoachPageProps) => {
  const { t } = useTranslation();
  const [coach, setCoach] = useState<ExecutiveDto>();
  const [otherCoaches, setOtherCoaches] = useState<ExecutiveDto[]>([]);
  const [topPhotos, setTopPhotos] = useState<File[]>([]);
  const [startIndexAlbum, setStartIndexAlbum] = useState<number>(0);
  const [album, setAlbum] = useState<AlbumSimple>();
  const [isOpenAlbum, setIsOpenAlbum] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isNotFoundError, setIsNotFoundError] = useState<boolean>(false);
  const [isPhotosLoading, setIsPhotosLoading] = useState(false);
  const { coachId } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const [defaultPhoto, setDefaultPhoto] = useState<DefaultImageObject>();
  const galleryRef = useRef<ReactImageGallery | null>(null);
  const seasonParam = searchParams.get('season');

  useEffect(() => {
    const getData = async () => {
      try {
        const photo = await getDefaultPlayerImage(DEFAULT_IMAGE_NAME);
        setDefaultPhoto(photo.data[0]);
      } catch (e: any) {
        console.error(e);
      }
    };
    getData();
  }, []);

  useEffect(() => {
    const getExecutiveInfo = async () => {
      try {
        const coachResponse = await getExecutiveById(Number(coachId));
        const lastSeason = getLastSeasonForExecutive(coachResponse.data);
        if (!checkCoachInSeason(coachResponse.data) && lastSeason) {
          searchParams.set('season', lastSeason);
          setSearchParams(searchParams);
        }
        let otherCoaches;

        if (seasonParam) {
          const coachesResponse = await getExecutiveRosters(
            ExecutiveType.COACH,
            searchParams.get('season') ?? seasonParam
          );

          otherCoaches = coachesResponse.data
            ?.map((item: ExecutiveRosterDto) => {
              const executive = item.attributes.executive.data;

              executive.attributes.position = item.attributes.position;

              return executive;
            })
            .filter((executive: ExecutiveDto) =>
              is_catastrophe ? false : executive.id !== Number(coachId)
            );
        }

        setCoach(coachResponse.data);
        setOtherCoaches(otherCoaches);
      } catch (e: any) {
        SetRespError(e, setIsNotFoundError, setIsError);
      }
    };

    getExecutiveInfo();
  }, [coachId, seasonParam]);

  function checkCoachInSeason(executive: ExecutiveDto): boolean {
    if (!seasonParam) {
      return false;
    }

    const seasons =
      executive?.attributes?.executive_rosters?.data.map(
        (roster) => roster.attributes.season.data.attributes.name
      ) || [];
    return seasons.includes(seasonParam);
  }

  const getTopPhotos = async (limit: number = 9) => {
    if (coach) {
      if (!topPhotos.length) {
        setIsPhotosLoading(true);
      }
      try {
        const photos = await getPhotos(
          `${coach?.attributes?.name?.trim()} ${coach?.attributes?.surname?.trim()}`,
          limit
        );

        setTopPhotos(photos);
      } catch (error: any) {
        console.error(error?.response?.data?.error || error?.message);
      } finally {
        setIsPhotosLoading(false);
      }
    }
  };

  useEffect(() => {
    getTopPhotos(9);
  }, [coach]);

  async function openAlbum(startIndex?: number) {
    try {
      const photos = await getPhotos(
        `${coach?.attributes?.name?.trim()} ${coach?.attributes?.surname.trim()}`,
        -1
      );
      const albumTmp = {
        description: `${coach?.attributes?.name.trim()} ${coach?.attributes?.surname.trim()}`,
        photo: photos.map((photo: File) => photo.url),
      };
      setStartIndexAlbum(startIndex || 0);
      setAlbum(albumTmp);
      setIsOpenAlbum(true);
    } catch (error: any) {
      console.error(error?.response?.data?.error || error?.message);
    }
  }

  useEffect(() => {
    if (isOpenAlbum && album?.photo && galleryRef?.current) {
      galleryRef.current?.fullScreen();
    }
  }, [album?.photo, galleryRef, isOpenAlbum]);

  if (coach && coach.attributes.type !== ExecutiveType.COACH)
    return <ErrorPage />;

  if (
    coach &&
    coach?.attributes?.team_filter.data.every(
      (team) => team.attributes.code !== LOKOMOTIV_CODE
    )
  )
    return <ErrorPage />;

  return (
    <ImageBgPageWrapper
      imageInfo={{
        img_url: coach?.attributes?.bg_photo?.data?.attributes?.url ?? '',
        gradient_height: 200,
        image_height: 700,
      }}
    >
      <Container paddingTop='50px' paddingBottom='0px'>
        <BreadcrumbsWrapper
          breadcrumbs={[
            {
              name: is_catastrophe
                ? t('coachPage.breadcrumbCatastrophe')
                : EXECUTIVESNAMES.get(ExecutiveType.COACH),
              url: is_catastrophe ? '/catastrophe/coach' : '/coaches',
            },
            {
              name: getFullExecutiveName(coach),
              url: is_catastrophe
                ? `/catastrophe/coach/${coachId}`
                : `/coach/${coachId}?season=${seasonParam}`,
            },
          ]}
        />
        {isNotFoundError ? (
          <ErrorWidget.NotFound isOnDarkBackground />
        ) : isError ? (
          <ErrorWidget.Error isOnDarkBackground />
        ) : coach ? (
          <>
            <div className={styles.executiveHeader}>
              <div className={styles.info}>
                <ShowOn largeDesktop smallDesktop largeTablet>
                  <p className={styles.position}>
                    {coach?.attributes?.position ?? ''}
                  </p>
                  <p className={styles.name}>{getFullExecutiveName(coach)}</p>
                </ShowOn>
                <ShowOn smallTablet largeMobile smallMobile>
                  <p className={styles.positionMobile}>
                    {coach?.attributes?.position ?? ''}
                  </p>
                  <p className={styles.nameMobile}>
                    {getFullExecutiveName(coach)}
                  </p>
                </ShowOn>
                <div className={styles.mainInfo}>
                  <ShowOn largeDesktop smallDesktop largeTablet smallTablet>
                    <div className={styles.imageWrapper}>
                      <img
                        src={
                          coach?.attributes?.photo?.data?.attributes?.url
                            ? coach?.attributes?.photo?.data?.attributes?.url
                            : defaultPhoto?.attributes?.image?.data?.attributes
                                ?.url
                        }
                        alt='executive'
                      />
                    </div>
                  </ShowOn>
                  <div className={styles.bioWrapper}>
                    {coach?.attributes?.birth && (
                      <>
                        <BioBox
                          name={t('coachPage.bio.birthDate')}
                          value={getDate(coach?.attributes?.birth)}
                        />
                        {!coach.attributes.is_catastrophe && (
                          <BioBox
                            name={t('coachPage.bio.age')}
                            value={`${getAge(
                              coach?.attributes?.birth
                            )} ${getYearPostfix(
                              getAge(coach?.attributes?.birth)
                            )}`}
                          />
                        )}
                        <BioBox
                          name={t('coachPage.bio.birthPlace')}
                          value={
                            coach.attributes.birth_place ||
                            t('coachPage.bio.noValue')
                          }
                        />
                        <BioBox
                          name={t('coachPage.bio.citizenship')}
                          displayLogo={
                            coach.attributes.citizenship.data?.attributes.flag
                              .data?.attributes.url
                          }
                          value={
                            coach.attributes.citizenship.data?.attributes
                              .name || t('coachPage.bio.noValue')
                          }
                        />
                        {!coach.attributes.is_catastrophe && (
                          <BioBox
                            name={t('coachPage.bio.lokomotiveStartDate')}
                            value={
                              coach.attributes.lokomotive_start_date
                                ? t('coachPage.bio.lokomotiveStartDateYear', {
                                    year: formatYearFromDate(
                                      coach.attributes.lokomotive_start_date
                                    ),
                                  })
                                : t('coachPage.bio.noValue')
                            }
                          />
                        )}
                        {!coach.attributes.is_catastrophe && (
                          <BioBox
                            name={t('coachPage.bio.coachingExperience')}
                            value={
                              coach.attributes.coaching_experience
                                ? `${coach?.attributes
                                    ?.coaching_experience} ${getYearPostfix(
                                    coach?.attributes?.coaching_experience
                                  )}`
                                : t('coachPage.bio.noValue')
                            }
                          />
                        )}
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <InfoWrapper
              isWhiteBackground={true}
              isPaddingZero={true}
              borderRadius='10px'
            >
              <>
                {coach && (
                  <CoachTabsWithInfo
                    coach={coach}
                    images={topPhotos}
                    openAlbum={openAlbum}
                    getPhotos={getTopPhotos}
                    currentPhotosFetch={topPhotos}
                    isPhotosLoading={isPhotosLoading}
                  />
                )}
                {isOpenAlbum && album && (
                  <ImageGallery
                    items={
                      album.photo
                        ? album.photo?.map((url) => {
                            return {
                              original: url,
                              thumbnail: url,
                              originalClass: !isOpenAlbum ? 'customImage' : '',
                              thumbnailClass: 'customThumbnail',
                            };
                          })
                        : []
                    }
                    infinite={false}
                    showPlayButton={false}
                    additionalClass='gallery'
                    onScreenChange={(value) => {
                      setIsOpenAlbum(value);
                    }}
                    startIndex={startIndexAlbum || 0}
                    ref={galleryRef}
                  />
                )}
              </>
            </InfoWrapper>
          </>
        ) : (
          <Spinner />
        )}
        {otherCoaches?.length > 0 ? (
          <div className={styles.otherExecutives}>
            <p className={styles.title}>{t('coachPage.otherCoaches')}</p>
            <ExecutiveSmallTiles
              executives={otherCoaches}
              season={searchParams.get('season') || undefined}
              defaultPhoto={defaultPhoto}
            />
          </div>
        ) : (
          <></>
        )}
      </Container>
    </ImageBgPageWrapper>
  );
});

export default CoachPage;
