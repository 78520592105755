import styles from './ErrorWidgetBase.module.scss';
import errorMishaImg from '../../../../assets/images/error_misha.png';
import classNames from 'classnames';
import { FC } from 'react';

interface ErrorWidgetBaseProps {
  isOnDarkBackground?: boolean;
  isCompact?: boolean;
  strings: {
    title: string;
    subtitle: string;
    subtitleCompact: string;
  };
}

const ErrorWidgetBase: FC<ErrorWidgetBaseProps> = ({
  isOnDarkBackground,
  isCompact,
  strings,
}: ErrorWidgetBaseProps) => {
  return isCompact ? (
    <div className={styles.errorCompact}>
      <span
        className={classNames(
          styles.errorTitle,
          isOnDarkBackground
            ? styles.errorTitleDarkBG
            : styles.errorTitleLightBG
        )}
      >
        {strings.title}
      </span>
      <span
        className={classNames(
          styles.errorSubtitle,
          isOnDarkBackground
            ? styles.errorSubtitleDarkBG
            : styles.errorSubtitleLightBG
        )}
      >
        {strings.subtitleCompact}
      </span>
    </div>
  ) : (
    <div className={styles.errorWidget}>
      <div className={styles.errorWrapper}>
        <img src={errorMishaImg} alt='error' />
        <div className={styles.error}>
          <span
            className={classNames(
              styles.errorTitle,
              isOnDarkBackground
                ? styles.errorTitleDarkBG
                : styles.errorTitleLightBG
            )}
          >
            {strings.title}
          </span>
          <span
            className={classNames(
              styles.errorSubtitle,
              isOnDarkBackground
                ? styles.errorSubtitleDarkBG
                : styles.errorSubtitleLightBG
            )}
          >
            {strings.subtitle}
          </span>
        </div>
      </div>
    </div>
  );
};

export default ErrorWidgetBase;
