import { MenuElementDto } from 'types/Types';
import styles from './MobileHeaderDrawer.module.scss';
import MobileHeaderDrawerAccordion from './MobileHeaderDrawerAccordion/MobileHeaderDrawerAccordion';
import Column from 'components/core/layout/Column';
import MobileHeaderDrawerAccordionSocials from './MobileHeaderDrawerAccordion/MobileHeaderDrawerAccordionSocials';
import ShowOn from 'components/core/adaptivity/ShowOn';
import { observer } from 'mobx-react-lite';
import HeaderLinks from '../../MobileHeaderDrawerLinks/HeaderLinks';

interface MobileHeaderDrawerProps {
  allMenu: Array<MenuElementDto>;
  isScroll: boolean;
}

const MobileHeaderDrawer = observer(
  ({ allMenu, isScroll }: MobileHeaderDrawerProps) => {
    return (
      <div
        className={isScroll ? styles.hiddenTopPadding : styles.drawerPadding}
      >
        <HeaderLinks /> 
        <div className={styles.drawerItems}>
          <Column gap='20px'>
            {allMenu
              .filter((item) => item.attributes.name !== 'tickets')
              .map((item) => (
                <MobileHeaderDrawerAccordion menu={item} key={item.id} />
              ))}
            <ShowOn smallTablet largeMobile smallMobile>
              <MobileHeaderDrawerAccordionSocials />
            </ShowOn>
          </Column>
        </div>
      </div>
    );
  }
);

export default MobileHeaderDrawer;
