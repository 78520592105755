import { ReactElement } from 'react';
import styles from './ViewMoreButton.module.scss';
import { useTranslation } from 'react-i18next';

type ViewMoreButtonProps = {
  onClick: () => void;
  disabled?: boolean;
  marginTop?: number;
  marginBottom?: number;
};

function ViewMoreButton({
  onClick,
  disabled = false,
  marginTop,
  marginBottom,
}: ViewMoreButtonProps): ReactElement {
  const { t } = useTranslation();
  return (
    <button
      className={styles.btn}
      onClick={onClick}
      disabled={disabled}
      style={{ marginTop: `${marginTop}px`, marginBottom: `${marginBottom}px` }}
    >
      <span className={styles.text}>{t('viewMoreButton')}</span>
    </button>
  );
}

export default ViewMoreButton;
