import { makeAutoObservable } from 'mobx';
import { getSideMenuPartners, getSideMenuWidgets } from '../../api/api';
import { SideMenuPartners, SideMenuWidget } from '../../types/Types';
import { mapSideMenuPartner } from '../../mapping/mapping';

class SideMenuStore {
  constructor() {
    makeAutoObservable(this);
  }

  sideMenuPartners: SideMenuPartners[] = [];
  sideMenuWidgets: SideMenuWidget[] = [];

  async loadSideMenu() {
    try {
      const partnersResponse = await getSideMenuPartners();
      const partners = mapSideMenuPartner(partnersResponse?.data);
      const sortedPartners = partners?.sort((a, b) => a.priority - b.priority);
      this.sideMenuPartners = sortedPartners;
    } catch (e: any) {
      console.log('Failed to get side menu partners. See details below.');
      console.error(e?.response?.data?.error || e?.message);
    }

    try {
      const widgetResponse = await getSideMenuWidgets();
      const sortedWidgets = widgetResponse.data.sort(
        (a: SideMenuWidget, b: SideMenuWidget) =>
          a.attributes.priority - b.attributes.priority
      );
      this.sideMenuWidgets = sortedWidgets;
    } catch (e: any) {
      console.log('Failed to get side menu widgets. See details below.');
      console.error(e?.response?.data?.error || e?.message);
    }
  }
}

export default SideMenuStore;
