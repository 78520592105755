import { ReactElement, RefObject, useEffect, useRef, useState } from 'react';
import ImageBgPageWrapper from '../../common/ImageBgPageWrapper/ImageBgPageWrapper';
import Container from '../../common/Container/Container';
import BreadcrumbsWrapper from 'components/common/BreadcrumbsWrapper/BreadcrumbsWrapper';
import PageHeader from 'components/common/PageHeader/PageHeader';
import InfoWrapper from 'components/Wrappers/InfoWrapper/InfoWrapper';
import GameWidgetHP from 'components/GameWidgetHPBlock/GameWidgetHP/GameWidgetHP';
import Tournament from './Tournament/Tournament';
import {
  mapPlayoffStandingsToReadable,
  mapStandingsToReadable,
} from 'helpers/helpers';
import { useParams, useSearchParams } from 'react-router-dom';
import {
  getGamesByTeams,
  getGamesInSeason,
  getPlayoffStandings,
  getSeasonByCode,
  getSeasonByPostfix,
  getStandings,
  getStandingsLegacy,
} from 'api/api';
import {
  GameWithLogo,
  PlayoffStandingsReadableDto,
  Season,
  SeasonsTeamCode,
  StandingsReadableDto,
} from 'types/Types';
import styles from './PlayoffsPage.module.scss';
import PlayoffGame from './PlayoffGame/PlayoffGame';
import SliderWrapper from 'components/SliderWrapper/SliderWrapper';
import ShowOn from 'components/core/adaptivity/ShowOn';
import TournamentCross from './Tournament/TournamentCross';
import Select, { SelectOption } from 'components/common/Select/Select';
import { useStore } from 'store/store';
import classNames from 'classnames';
import ErrorWidget from 'components/common/ErrorWidget/ErrorWidget';

interface SeasonOption {
  key: string;
  value: string;
}

function PlayoffsPage(): ReactElement {
  const [searchParams, setSearchParams] = useSearchParams();
  const { teamCode } = useParams();
  const seasonParam = searchParams.get('season');
  const teamaParam = searchParams.get('teama');
  const teambParam = searchParams.get('teamb');

  const { lokomotivTeamsStore } = useStore();

  const pastGamesRef = useRef<HTMLDivElement>(null);
  const futureGamesRef = useRef<HTMLDivElement>(null);

  const [selectedSeason, setSelectedSeason] = useState<string>('');
  const [seasonPostfix, setSeasonPostfix] = useState<SeasonsTeamCode>(
    (teamCode as SeasonsTeamCode.men) ?? SeasonsTeamCode.men
  );
  const [activeTab, setActiveTab] =
    useState<RefObject<HTMLDivElement>>(pastGamesRef);
  const [generalStandings, setGeneralStandings] = useState<
    Array<StandingsReadableDto>
  >([]);
  const [playoffStandings, setPlayoffStandings] = useState<
    Array<PlayoffStandingsReadableDto>
  >([]);
  const [games, setGames] = useState<Array<GameWithLogo>>([]);
  const [season, setSeason] = useState<Season | undefined>();
  const [seasonOptions, setSeasonOptions] = useState<SeasonOption[]>([]);
  const [lokoGames, setLokoGames] = useState<Array<GameWithLogo>>([]);

  const tabs = [
    {
      tabRef: pastGamesRef,
      tabName: 'past_games',
      displayName: 'ПРОШЕДШИЕ МАТЧИ',
    },
    {
      tabRef: futureGamesRef,
      tabName: 'future_games',
      displayName: 'БУДУЩИЕ МАТЧИ',
    },
  ];

  useEffect(() => {
    const getLokoGames = async () => {
      let id: number | undefined;
      if (seasonPostfix === SeasonsTeamCode.men) {
        id =
          lokomotivTeamsStore.lokomotivTeams?.men?.data?.attributes
            ?.id_league_web;
      } else if (seasonPostfix === SeasonsTeamCode.lko) {
        id =
          lokomotivTeamsStore.lokomotivTeams?.lko?.data?.attributes
            ?.id_league_web;
      } else {
        id =
          lokomotivTeamsStore.lokomotivTeams?.l76?.data?.attributes
            ?.id_league_web;
      }

      if (selectedSeason && id) {
        const gamesData = await getGamesInSeason(
          selectedSeason,
          undefined,
          id,
          lokomotivTeamsStore.lokomotivTeamsIds
        );

        setLokoGames(gamesData.data);
      }
    };

    getLokoGames();
  }, [selectedSeason, seasonPostfix, lokomotivTeamsStore.lokomotivTeams]);

  useEffect(() => {
    const getSeasonOptions = async () => {
      if (seasonPostfix) {
        try {
          const seasons = await getSeasonByPostfix(`${seasonPostfix}-2`);

          const options = seasons.data.map((item: Season) => {
            return {
              key: item.attributes.code,
              value: `Сезон ${item.attributes.years_interval}`,
            } as SeasonOption;
          }) as SeasonOption[];

          if (options && options.length) {
            setSeasonOptions(options);

            if (
              seasonParam &&
              options.some((item) => item.key === seasonParam)
            ) {
              setSelectedSeason(seasonParam);
            } else {
              setSelectedSeason(options[0].key);
              searchParams.set('season', options[0].key);
            }
          }
        } catch (error) {
          console.error(error);
        }
      }
    };

    getSeasonOptions();
  }, [seasonPostfix]);

  useEffect(() => {
    const getData = async () => {
      try {
        if (selectedSeason) {
          const playoffStandingsReceived =
            await getPlayoffStandings(selectedSeason);
          let generalStandingsReceived;
          const seasonArg = selectedSeason.slice(0, 7);
          try {
            generalStandingsReceived = await getStandings(seasonArg);
          } catch (error) {
            generalStandingsReceived = await getStandingsLegacy(seasonArg);
          }
          const seasonReceived = await getSeasonByCode(selectedSeason);
          const playoffStandingsReadable = mapPlayoffStandingsToReadable(
            playoffStandingsReceived
          );
          const generalStandingsReadable = mapStandingsToReadable(
            generalStandingsReceived
          );

          setPlayoffStandings(playoffStandingsReadable);
          setGeneralStandings(generalStandingsReadable);
          setSeason(seasonReceived?.data[0]);
        }
      } catch (e: any) {
        setPlayoffStandings([]);
        setGeneralStandings([]);
      }
    };

    setSearchParams(searchParams);

    getData();
  }, [selectedSeason]);

  useEffect(() => {
    const getGames = async () => {
      const teamaCode = playoffStandings.find(
        (team) => team.teama === teamaParam
      )?.teamaCode;
      const teambCode = playoffStandings.find(
        (team) => team.teamb === teambParam
      )?.teambCode;

      if (!teamaCode || !teambCode) {
        return;
      }

      const games = await getGamesByTeams(teamaCode, teambCode, selectedSeason);

      setGames(games?.data || []);
    };

    getGames();
  }, [teamaParam, teambParam]);

  const changeActiveTab = (ref: RefObject<HTMLDivElement>) => {
    const currentElement = activeTab.current;
    if (currentElement?.className.includes(styles.textShow)) {
      currentElement?.classList.remove(styles.textShow);
    }
    const element = ref.current;
    if (!element?.className.includes(styles.textShow)) {
      element?.classList.add(styles.textShow);
    }
    setActiveTab(ref);
  };

  const renderSlider = (slidesToShow: number, isFutureGames = false) => (
    <SliderWrapper
      className={styles.slider}
      infinite={false}
      slidesToShow={slidesToShow}
      slides={playoffStandings
        ?.filter(
          (game) =>
            (!teamaParam && !teambParam) ||
            (game.teama === teamaParam && game.teamb === teambParam) ||
            (game.teama === teambParam && game.teamb === teamaParam)
        )
        ?.sort((a, b) => {
          const compare = (
            a: PlayoffStandingsReadableDto,
            b: PlayoffStandingsReadableDto
          ) => {
            const aDate = new Date(`${a.date}T${a.time}`);
            const bDate = new Date(`${b.date}T${b.time}`);
            return aDate.getTime() - bDate.getTime();
          };
          return isFutureGames ? compare(a, b) : compare(b, a);
        })
        ?.reduce((result: any[], game) => {
          const gameList = games && games.length ? games : lokoGames;
          const lokoGame = gameList?.find(
            (item) =>
              (item.attributes.team_1.data.attributes.code === game.teamaCode ||
                item.attributes.team_2.data.attributes.code ===
                  game.teambCode) &&
              game.date.toString().slice(0, 10) ===
                item.attributes.date?.toString().slice(0, 10)
          );

          if (lokoGame) {
            if (isFutureGames) {
              if (
                !lokoGame.attributes.score_1 &&
                !lokoGame.attributes.score_2
              ) {
                result.push(
                  <GameWidgetHP
                    key={lokoGame.id}
                    game={lokoGame}
                    isGameInFuture={isFutureGames}
                  />
                );
              }
            } else {
              if (
                lokoGame.attributes.score_1 > 0 ||
                lokoGame.attributes.score_2 > 0
              ) {
                result.push(
                  <GameWidgetHP
                    key={lokoGame.id}
                    game={lokoGame}
                    isGameInFuture={isFutureGames}
                  />
                );
              }
            }
          } else {
            if (isFutureGames) {
              if (!game.score || game.score === '0:0') {
                result.push(
                  <PlayoffGame
                    key={game.id}
                    game={game}
                    generalStandings={generalStandings}
                    isGameInFuture={isFutureGames}
                  />
                );
              }
            } else {
              if (game.score && game.score !== '0:0') {
                result.push(
                  <PlayoffGame
                    key={game.id}
                    game={game}
                    generalStandings={generalStandings}
                    isGameInFuture={isFutureGames}
                  />
                );
              }
            }
          }

          return result;
        }, [])}
    />
  );

  const getTeamNameByCode = (code: SeasonsTeamCode) => {
    switch (code) {
      case SeasonsTeamCode.lko:
        return 'ЛОКО';
      case SeasonsTeamCode.l76:
        return 'ЛОКО 76';
      default:
        return 'ЛОКОМОТИВ';
    }
  };

  const onSeasonSelect = (value: string) => {
    setSelectedSeason(value);
    searchParams.delete('teama');
    searchParams.delete('teamb');
    searchParams.set('season', value);
  };

  const getOptionValue = (key: string) => {
    const season = seasonOptions.find((item) => item.key === key);

    return season ? season.value : undefined;
  };

  function getSeasonSelectOptions(
    options: { key: string; value: string }[]
  ): SelectOption[] {
    return options.map((option) => {
      return {
        value: option.key,
        displayValue: option.value,
      };
    });
  }

  const teamSelectOptions = [
    {
      value: SeasonsTeamCode.men,
      displayValue: getTeamNameByCode(SeasonsTeamCode.men),
    },
    {
      value: SeasonsTeamCode.lko,
      displayValue: getTeamNameByCode(SeasonsTeamCode.lko),
    },
    {
      value: SeasonsTeamCode.l76,
      displayValue: getTeamNameByCode(SeasonsTeamCode.l76),
    },
  ];

  return (
    <>
      {teamCode && teamCode === SeasonsTeamCode.men ? (
        <ImageBgPageWrapper>
          <Container paddingTop='50px'>
            <BreadcrumbsWrapper
              breadcrumbs={[
                {
                  name: 'Кубок Гагарина',
                  url: `/playoffs/${teamCode}`,
                },
              ]}
            />
            <div className={styles.pageHeaderWrapper}>
              <PageHeader text='Кубок Гагарина' />
            </div>
            {generalStandings?.length > 0 &&
              playoffStandings?.length > 0 &&
              (season?.attributes.is_cross_playoff &&
              season?.attributes.cross_playoff_start_round ? (
                <TournamentCross
                  generalStandings={generalStandings}
                  playoffStandings={playoffStandings}
                  crossRound={season?.attributes.cross_playoff_start_round}
                />
              ) : (
                <Tournament
                  generalStandings={generalStandings}
                  playoffStandings={playoffStandings}
                />
              ))}

            <InfoWrapper isWhiteBackground={true}>
              <Container paddingHorizontal='20px' paddingBottom='30px'>
                <div className={styles.header}>
                  <div className={styles.tabsWrapper}>
                    <div className={styles.tabBar} id='tab-bar'>
                      {tabs.map((tab) => (
                        <button
                          key={tab.displayName}
                          onClick={() => changeActiveTab(tab.tabRef)}
                          className={
                            activeTab === tab.tabRef ? styles.active : ''
                          }
                          data-tab={tab.tabName}
                        >
                          {tab.displayName}
                        </button>
                      ))}
                    </div>
                  </div>

                  <div className={styles.filterWrapper}>
                    {seasonOptions && seasonOptions.length ? (
                      <Select
                        value={selectedSeason}
                        values={getSeasonSelectOptions(seasonOptions)}
                        onChange={onSeasonSelect}
                      />
                    ) : null}
                    <Select
                      value={seasonPostfix}
                      disabled={seasonPostfix === SeasonsTeamCode.men}
                      values={teamSelectOptions}
                      onChange={setSeasonPostfix}
                    />
                  </div>
                </div>
                {generalStandings?.length && playoffStandings?.length ? (
                  <>
                    {activeTab === pastGamesRef && (
                      <div
                        ref={pastGamesRef}
                        className={classNames(styles.text, {
                          [styles.textShow]: activeTab === pastGamesRef,
                        })}
                      >
                        <div className={styles.games}>
                          <ShowOn largeDesktop smallDesktop largeTablet>
                            {renderSlider(2.5)}
                          </ShowOn>
                          <ShowOn smallTablet>{renderSlider(2)}</ShowOn>
                          <ShowOn largeMobile smallMobile>
                            {renderSlider(1)}
                          </ShowOn>
                        </div>
                      </div>
                    )}
                    {activeTab === futureGamesRef && (
                      <div
                        ref={futureGamesRef}
                        className={classNames(styles.text, {
                          [styles.textShow]: activeTab === futureGamesRef,
                        })}
                      >
                        <div className={styles.games}>
                          <ShowOn largeDesktop smallDesktop largeTablet>
                            {renderSlider(2.5, true)}
                          </ShowOn>
                          <ShowOn smallTablet>{renderSlider(2, true)}</ShowOn>
                          <ShowOn largeMobile smallMobile>
                            {renderSlider(1, true)}
                          </ShowOn>
                        </div>
                      </div>
                    )}
                  </>
                ) : (
                  <div>Турнирные таблицы не найдены</div>
                )}
              </Container>
            </InfoWrapper>
          </Container>
        </ImageBgPageWrapper>
      ) : (
        <ErrorWidget.NotFound />
      )}
    </>
  );
}

export default PlayoffsPage;
