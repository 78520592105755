import InfoWrapper from 'components/Wrappers/InfoWrapper/InfoWrapper';
import ImageBgPageWrapper from 'components/common/ImageBgPageWrapper/ImageBgPageWrapper';
import { observer } from 'mobx-react-lite';
import styles from './MemoryBookPage.module.scss';
import Breadcrumbs from 'components/common/Breadcrumbs/Breadcrumbs';
import { useStore } from 'store/store';
import AuthWidget from './AuthWidget/AuthWidget';
import RulesSection from './RulesSection/RulesSection';
import PostSection from './PostSection/PostSection';
import ScrollToTopButton from 'components/ScrollToTopButton/ScrollToTopButton';
import UserProfileWidget from './UserProfileWidget/UserProfileWidget';
import Colors from 'constants/colors';
import ShowOn from 'components/core/adaptivity/ShowOn';
import { useTranslation } from 'react-i18next';

const MemoryBookPage = observer(() => {
  const { t } = useTranslation();
  const { userStore } = useStore();

  return (
    <ImageBgPageWrapper>
      <InfoWrapper
        borderRadius='0px'
        innerPaddingTop='20px'
        isWhiteBackground={false}
      >
        <div className={styles.headerWrapper}>
          <div className={styles.breadcrumbsWrapper}>
            <Breadcrumbs
              isWhiteColor={false}
              breadcrumbs={[
                { name: t('memoryBookPage.breadcrumb'), url: `/memorybook` },
              ]}
            />
          </div>
        </div>
        <ShowOn largeDesktop smallDesktop>
          <div className={styles.wrapper}>
            <div className={styles.content}>
              <PostSection />
            </div>
            <div className={styles.sideMenu}>
              {!userStore.isLogged ? <AuthWidget /> : <UserProfileWidget />}
              <RulesSection />
            </div>
          </div>
        </ShowOn>
        <ShowOn largeTablet smallTablet largeMobile smallMobile>
          <div className={styles.columnWrapper}>
            {!userStore.isLogged ? <AuthWidget /> : <UserProfileWidget />}
            <RulesSection />
            <div className={styles.postSectionWrapper}>
              <PostSection />
            </div>
          </div>
        </ShowOn>
        <div className={styles.scrollToTopWrapper}>
          <ScrollToTopButton color={Colors.STROKE_GREY} />
        </div>
      </InfoWrapper>
    </ImageBgPageWrapper>
  );
});

export default MemoryBookPage;
