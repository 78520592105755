import { ReactElement } from "react";
import AccordionHead from "./AccordionHead/AccordionHead";

interface AccordionProps {
  title: string;
  subtitle: string;
  children: React.ReactNode;
  isOpen: boolean;
  openCallback: () => void;
}

export default function Accordion({
  title,
  subtitle,
  children,
  isOpen,
  openCallback,
}: AccordionProps): ReactElement {
  return (
    <div>
      <div onClick={openCallback}>
        <AccordionHead title={title} subtitle={subtitle} isOpen={isOpen} />
      </div>
      {isOpen && <div>{children}</div>}
    </div>
  );
}
