interface FlexProps {
  children: React.ReactNode;
  weight: number;
}

const Flex = ({ children, weight }: FlexProps) => {
  return <div style={{ flex: weight }}>{children}</div>;
};

export default Flex;
