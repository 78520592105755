import { ReactElement, useEffect, useState } from 'react';
import styles from './ShotsTable.module.scss';
import { GameProtocolDto, GameWithLogo } from '../../../../../types/Types';
import classNames from 'classnames';

type PeriodsData = {
  period1: number;
  period2: number;
  period3: number;
  overtime: number;
  total: number;
};

type teamData = {
  khlId: string;
  inGameId: string;
  periods: PeriodsData;
  name: string;
  logo: string;
};

type ShotsTableProps = {
  protocol: GameProtocolDto;
  game: GameWithLogo;
};

function ShotsTable({ protocol, game }: ShotsTableProps): ReactElement {
  const [teams, setTeams] = useState<teamData[]>();

  useEffect(() => {
    setTeams([
      {
        khlId: protocol.GameSummary['@_teama'],
        inGameId: protocol.GameSummary['@_homeId'],
        periods: getPeriodsData('A'),
        name: game.attributes.team_1.data.attributes.name,
        logo: game.attributes.team_1.data.attributes.logo.data.attributes.url,
      },
      {
        khlId: protocol.GameSummary['@_teamb'],
        inGameId: protocol.GameSummary['@_visitorId'],
        periods: getPeriodsData('B'),
        name: game.attributes.team_2.data.attributes.name,
        logo: game.attributes.team_2.data.attributes.logo.data.attributes.url,
      },
    ]);
  }, [game, protocol]);

  const getPeriodsData = (teamId: string): PeriodsData => {
    const teamGoals = protocol.GameSummary.ShotsList?.Shot?.find(
      (item) => item['@_team'] === teamId
    );

    const period1 = teamGoals ? Number(teamGoals['@_p1']) : 0;
    const period2 = teamGoals ? Number(teamGoals['@_p2']) : 0;
    const period3 = teamGoals ? Number(teamGoals['@_p3']) : 0;
    const overtime = teamGoals ? Number(teamGoals['@_ot']) : 0;
    const total = period1 + period2 + period3 + overtime;

    return {
      period1,
      period2,
      period3,
      overtime,
      total,
    };
  };

  const renderRows = () => {
    if (teams) {
      return ['period1', 'period2', 'period3', 'overtime', 'total'].map(
        (period: string, index: number) => {
          if (
            period === 'overtime' &&
            !teams[0].periods['overtime'] &&
            !teams[1].periods['overtime']
          ) {
            return null;
          }

          let periodName: string;

          switch (index) {
            case 3:
              periodName = 'Овертайм';
              break;
            case 4:
              periodName = 'Всего';
              break;
            default:
              periodName = `${index + 1}-й`;
              break;
          }

          return (
            <tr
              className={classNames(styles.tableRow, {
                [styles.total]: index === 4,
              })}
              key={period + index}
            >
              <td>{periodName}</td>
              <td>{teams[0].periods[period as keyof PeriodsData]}</td>
              <td>{teams[1].periods[period as keyof PeriodsData]}</td>
            </tr>
          );
        }
      );
    }

    return null;
  };

  return (
    <div>
      {teams && (
        <>
          <div className={styles.header}>Броски в створ</div>
          <table className={styles.table}>
            <tbody className={styles.tableBody}>
              <tr className={styles.tableHeader}>
                <th title={'Период'}>Период</th>
                <th title={'Команда 1'}>{teams[0].name}</th>
                <th title={'Команда 2'}>{teams[1].name}</th>
              </tr>
              {renderRows()}
            </tbody>
          </table>
        </>
      )}
    </div>
  );
}

export default ShotsTable;
