import { ReactElement } from 'react';

interface UpperRightArrowProps {
  fillColor: string;
  rotateDegrees?: number;
  height: string;
  width: string;
}

export default function LikeIcon({
  fillColor,
  rotateDegrees = 0,
  height,
  width,
}: UpperRightArrowProps): ReactElement {
  return (
    <div
      style={{
        rotate: `${rotateDegrees}deg`,
        transition: 'all 0.3s',
        width: `${width}px`,
        height: `${height}px`,
      }}
    >
      <svg
        width={width}
        height={height}
        viewBox='0 0 24 24'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M15.9 4.5C15.9 3 14.418 2 13.26 2C12.454 2 12.391 2.612 12.267 3.82C12.212 4.35 12.146 4.994 12 5.75C11.614 7.752 10.28 10.31 9.004 11.075V17C9 19.25 9.75 20 13 20H16.773C18.949 20 19.476 18.567 19.672 18.036L19.685 18C19.799 17.694 20.043 17.453 20.323 17.18C20.633 16.874 20.987 16.527 21.25 16C21.561 15.377 21.52 14.823 21.483 14.33C21.46 14.031 21.439 13.755 21.5 13.5C21.564 13.23 21.646 13.025 21.725 12.829C21.868 12.473 22 12.143 22 11.5C22 10 21.252 9.002 19.685 9.002H15.5C15.5 9.002 15.9 6 15.9 4.5ZM5.5 10C5.10218 10 4.72064 10.158 4.43934 10.4393C4.15804 10.7206 4 11.1022 4 11.5V18.5C4 18.8978 4.15804 19.2794 4.43934 19.5607C4.72064 19.842 5.10218 20 5.5 20C5.89782 20 6.27936 19.842 6.56066 19.5607C6.84196 19.2794 7 18.8978 7 18.5V11.5C7 11.1022 6.84196 10.7206 6.56066 10.4393C6.27936 10.158 5.89782 10 5.5 10Z'
          fill={fillColor}
        />
      </svg>
    </div>
  );
}
