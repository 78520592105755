import { useEffect, useRef, useState } from 'react';
import styles from './ShowMoreText.module.scss';
import ViewMoreButton from '../ViewMoreButton/ViewMoreButton';

type ShowMoreTextProps = {
  content: string;
  marginTop?: number;
  marginBottom?: number;
};
function ShowMoreText({
  content,
  marginTop = 0,
  marginBottom = 0,
}: ShowMoreTextProps) {
  const [isTruncated, setIsTruncated] = useState(true);
  const [isOverflowing, setIsOverflowing] = useState(false);
  const divRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!divRef.current) return;
    if (divRef.current.scrollHeight > divRef.current.clientHeight) {
      setIsOverflowing(true);
    }
  }, [content]);

  const toggleTruncate = () => {
    setIsTruncated(!isTruncated);
    setIsOverflowing(false);
  };

  return (
    <div>
      <div
        ref={divRef}
        className={isTruncated ? styles.textTruncated : styles.text}
        dangerouslySetInnerHTML={{
          __html: content,
        }}
      />
      {isOverflowing && (
        <ViewMoreButton
          onClick={toggleTruncate}
          marginTop={marginTop}
          marginBottom={marginBottom}
        />
      )}
    </div>
  );
}

export default ShowMoreText;
