import SubscriptionType from "model/entity/loko/type/SubscriptionType";
import {
  SubscriptionData,
  SubscriptionTypeData,
} from "../../../../../types/Types";
import Subscription from "model/entity/loko/type/Subscription";

export class SubscriptionTablesData {
  public readonly subscriptionData: SubscriptionData;
  public readonly subscriptionTypesData: SubscriptionTypeData;

  public constructor(
    subscriptionData: SubscriptionData,
    subscriptionTypesData: SubscriptionTypeData
  ) {
    this.subscriptionData = subscriptionData;
    this.subscriptionTypesData = subscriptionTypesData;
  }

  public getSubscriptionsForType(
    subscriptionType: SubscriptionType
  ): Subscription[] {
    return this.subscriptionData.data.filter(
      (subscription) =>
        subscription.attributes.subscription_type.data.id ===
        subscriptionType.id
    );
  }

  public getMinPriceForSubscriptionType(
    subscriptionType: SubscriptionType
  ): number {
    const subscriptionPrices = this.getSubscriptionsForType(
      subscriptionType
    ).map((subscription) => subscription.attributes.price);

    return Math.min(...subscriptionPrices);
  }
}
