import { RefObject, useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react-lite';
import LogoNavigation from '../LogoNavigation/LogoNavigation';
import styles from './DesktopHeader.module.scss';
import { MenuElementDto } from '../../../types/Types';
import { ReactComponent as LogoSmall } from '../../../assets/logo_small.svg';
import MenuElement from '../MenuElement/MenuElement';
import useScrollPosition from '../../../hooks/UseScrollPosition';
import Container from '../../common/Container/Container';

interface DesktopHeaderProps {
  allMenu: Array<MenuElementDto>;
}

const DesktopHeader = observer(({ allMenu }: DesktopHeaderProps) => {
  const [isScroll, setIsScroll] = useState(false);
  const scrollPosition = useScrollPosition();
  const countPx = 142;
  let openMenuRef = useRef<HTMLLIElement>(null);

  useEffect(() => {
    const root = document.getElementById('root');
    if (scrollPosition >= countPx) {
      if (!root?.classList.contains(styles.scrolled)) {
        root?.classList.add(styles.scrolled);
        setIsScroll(true);
      }
    } else {
      if (root?.classList.contains(styles.scrolled)) {
        root?.classList.remove(styles.scrolled);
        setIsScroll(false);
      }
    }
  }, [scrollPosition]);

  function closeMenuMouseEnter(currentLi: RefObject<HTMLLIElement>) {
    const element = openMenuRef.current;
    if (openMenuRef !== currentLi) {
      if (element?.className.includes('open')) {
        element?.classList.remove('open');
      }
    } else {
      if (element?.className.includes('open')) {
        element?.classList.remove('open');
      }
    }
  }

  function openMenuMouseEnter(currentLi: RefObject<HTMLLIElement>) {
    const element = openMenuRef.current;
    const htmlCollection = document.getElementsByClassName('open');
    const elemnts = Array.prototype.slice.call(htmlCollection);
    elemnts.map((el) => el?.classList?.remove('open'));
    if (openMenuRef !== currentLi) {
      if (element?.className?.includes('open')) {
        element?.classList?.remove('open');
      }
      openMenuRef = currentLi;
      const newOpenRef = currentLi.current;
      newOpenRef?.classList?.add('open');
    } else {
      if (!element?.className?.includes('open')) {
        element?.classList?.add('open');
      }
    }
  }

  return (
    <>
      <div className={styles.navbarDesktop}>
        <LogoNavigation
          isScroll={isScroll}
          closeMenu={closeMenuMouseEnter}
          openMenuMouseEnter={openMenuMouseEnter}
          menuElement={allMenu.find(
            (element) => element.attributes.name === 'tickets'
          )}
        />
        <div className={styles.navbar}>
          <div className={styles.row}>
            <div className={styles.nav}>
              <Container paddingHorizontal='15px'>
                <ul className={styles.ul} id={'nav-menu-element'}>
                  {isScroll ? (
                    <div className={styles.logoWrapper}>
                      <div className={styles.logo}>
                        <a href='/'>
                          <LogoSmall />
                        </a>
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}
                  {allMenu &&
                    allMenu.map(
                      (element) =>
                        element.attributes.name !== 'tickets' && (
                          <MenuElement
                            key={element.id}
                            isScroll={isScroll}
                            menuElement={element}
                            closeMenu={closeMenuMouseEnter}
                            openMenuMouseEnter={openMenuMouseEnter}
                          />
                        )
                    )}
                  {/* <HeaderLangToggle /> */}
                </ul>
              </Container>
            </div>
          </div>
        </div>
      </div>
    </>
  );
});

export default DesktopHeader;
