import { ReactElement, useEffect, useState } from 'react';
import { getTopPartners } from '../../../api/api';
import { TopPartnersDto } from '../../../types/Types';
import Partner from './Partner/Partner';
import styles from './HeaderPartners.module.scss';
import PartnerError from './Partner/PartnerError';

function TopPartners(): ReactElement {
  const [partner, setPartner] = useState<TopPartnersDto>();
  const [fonbetPartner, setFonbetPartner] = useState<TopPartnersDto>();
  const windowInnerWidth = document.documentElement.clientWidth;

  useEffect(() => {
    const getPartners = async () => {
      const partnerNumber = localStorage.getItem(`top-partner-display-number`);
      const resp = await getTopPartners();
      const data = resp?.data;
      const partners = data?.sort(
        (a: TopPartnersDto, b: TopPartnersDto) =>
          a.attributes.order_index - b.attributes.order_index
      );

      if (windowInnerWidth > 860) {
        setPartner(partners && partners[0]);
        setFonbetPartner(partners && partners[1]);
      } else {
        if (partnerNumber && Number(partnerNumber) >= partners?.length) {
          setPartner(partners && partners[0]);
          localStorage.setItem(`top-partner-display-number`, '1');
        } else {
          setPartner(partners && partners[Number(partnerNumber)]);
          localStorage.setItem(
            `top-partner-display-number`,
            `${Number(partnerNumber) + 1}`
          );
        }
      }
    };
    getPartners();
  }, []);

  return (
    <div className={styles.partners} id={'header-partners-row'}>
      <div className={styles.row}>
        {partner ? (
          <Partner key={partner.id} partner={partner} />
        ) : (
          // <PartnerError />
          <></>
        )}
        {fonbetPartner ? (
          <Partner key={fonbetPartner.id} partner={fonbetPartner} />
        ) : (
          // <PartnerError />
          <></>
        )}
      </div>
    </div>
  );
}

export default TopPartners;
