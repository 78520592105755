import { ReactElement } from 'react';
import Container from '../Container/Container';
import ShowOn from 'components/core/adaptivity/ShowOn';
import Breadcrumbs from '../Breadcrumbs/Breadcrumbs';
import styles from './BreadcrumbsWrapper.module.scss';

type BreadcrumbInfo = {
  name: string;
  url: string;
};

type BreadcrumbsProps = {
  breadcrumbs: BreadcrumbInfo[];
  addRootPageRouteAutomatically?: boolean;
  isWhiteColor?: boolean;
};

export default function BreadcrumbsWrapper(
  props: BreadcrumbsProps
): ReactElement {
  return (
    <>
      <ShowOn largeDesktop smallDesktop>
        <div className={styles.wrapper}>
          <Breadcrumbs {...props} />
        </div>
      </ShowOn>
      <ShowOn largeTablet smallTablet largeMobile smallMobile>
        <Container paddingHorizontal='16px'>
          <Breadcrumbs {...props} />
        </Container>
      </ShowOn>
    </>
  );
}
