import { ReactElement, useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import {
  getArticleBottomBanner,
  getArticleById,
  getArticlesUsingFilters,
} from 'api/api';
import SetRespError, { getDateInfo } from 'helpers/helpers';
import { InlineBanner } from 'types/Types';
import ArticleLikesBlock from '../ArticleLikesBlock/ArticleLikesBlock';
import ImageBgPageWrapper from '../common/ImageBgPageWrapper/ImageBgPageWrapper';
import Container from '../common/Container/Container';
import styles from './ArticlePage.module.scss';
import BreadcrumbsWrapper from 'components/common/BreadcrumbsWrapper/BreadcrumbsWrapper';
import LinkButton from '../LinkButton/LinkButton';
import SideMenu from '../common/SideMenu/SideMenu';
import SliderWrapper from '../SliderWrapper/SliderWrapper';
import BookletItem from '../BookletsPage/BookletItem/BookletItem';
import ShareInSocials from 'components/common/ShareInSocials/ShareInSocials';
import Spinner from 'components/Spinner/Spinner';
import ErrorWidget from 'components/common/ErrorWidget/ErrorWidget';
import Article from 'model/entity/loko/type/Article';
import { useTranslation } from 'react-i18next';
import ErrorPage from 'components/pages/ErrorPage/ErrorPage';
import Slider from 'react-slick';
import { useMediaQuery } from 'react-responsive';
import ArrowLeftLine from '../../assets/icons/ArrowLeftLine';
import ArrowRightLine from '../../assets/icons/ArrowRightLIne';

const ArticlePage = (): ReactElement => {
  const { t } = useTranslation();
  const { articleId, bookletId } = useParams();
  const [article, setArticle] = useState<Article>();
  const [isArticleLoading, setIsArticleLoading] = useState<boolean>(true);
  const [isArticleError, setIsArticleError] = useState<boolean>(false);
  const [bookletsList, setBookletsList] = useState<Array<Article>>([]);
  const [isBookletsListLoading, setIsBookletsListLoading] =
    useState<boolean>(true);
  const [isBookletsListError, setIsBookletsListError] =
    useState<boolean>(false);
  const [isNotFoundArticleError, setIsNotFoundArticleError] =
    useState<boolean>(false);
  const [isNotFoundBookletError, setIsNotFoundBookletError] =
    useState<boolean>(false);
  const [pageParam, setPageParam] = useState<number>(1);
  const [banner, setBanner] = useState('');
  const [bannerLink, setBannerLink] = useState('');
  const [isBannerLoading, setIsBannerLoading] = useState<boolean>(true);
  const location = useLocation();
  const isMobile = useMediaQuery({
    maxWidth: 605,
  });

  useEffect(() => {
    const getData = async () => {
      try {
        const bannerObject: InlineBanner = await getArticleBottomBanner();
        setBanner(bannerObject?.attributes?.image?.data?.attributes?.url);
        setBannerLink(bannerObject?.attributes?.link);
      } finally {
        setIsBannerLoading(false);
      }
    };
    getData();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [articleId, bookletId]);

  useEffect(() => {
    const getData = async () => {
      try {
        const id = location.pathname.includes('booklet')
          ? bookletId
          : articleId;
        if (id) {
          const response = await getArticleById(id);
          if (
            (location.pathname.includes('booklet') &&
              response.data.attributes.article_category.data.attributes.name ===
                'Программки') ||
            (!location.pathname.includes('booklet') &&
              response.data.attributes.article_category.data.attributes.name !==
                'Программки')
          )
            setArticle(response?.data);
          else setIsNotFoundArticleError(true);
        }
      } catch (error) {
        SetRespError(error, setIsNotFoundArticleError, setIsArticleError);
      } finally {
        setIsArticleLoading(false);
      }
    };
    getData();
  }, [bookletId, articleId]);

  useEffect(() => {
    const getData = async () => {
      try {
        if (bookletId) {
          const resp = await getArticlesUsingFilters(
            '',
            '90',
            '',
            '',
            pageParam
          );
          if (pageParam === 1) {
            setBookletsList(
              resp?.data?.filter(
                (booklet: Article) => booklet.id.toString() !== bookletId
              )
            );
            return;
          }
          setBookletsList((prevState) => [
            ...prevState,
            ...resp?.data?.filter(
              (booklet: Article) => booklet.id.toString() !== bookletId
            ),
          ]);
        }
      } catch (error) {
        SetRespError(error, setIsNotFoundBookletError, setIsBookletsListError);
      } finally {
        setIsBookletsListLoading(false);
      }
    };
    getData();
  }, [pageParam, bookletId]);

  function formatText(details: string) {
    return details
      .replaceAll(
        '<figure class="table">',
        `<figure class="table" style="display: flex; align-items: center; justify-content: center;">`
      )
      .replaceAll('<blockquote>', `<blockquote style="font-style: italic;">`);
  }

  return isNotFoundArticleError ? (
    <ErrorPage />
  ) : (
    <ImageBgPageWrapper
      imageInfo={{
        img_url: article?.attributes?.image?.data?.attributes?.url,
        image: article?.attributes?.image?.data,
        image_height: 965,
        gradient_height: 166,
      }}
    >
      <Container paddingTop='50px'>
        <div className={styles.wrapper}>
          {isArticleLoading ? (
            <Spinner />
          ) : isArticleError ? (
            <ErrorWidget.Error isOnDarkBackground />
          ) : (
            article && (
              <>
                <div className={styles.breadcrumbsMargin}>
                  <BreadcrumbsWrapper
                    breadcrumbs={[
                      {
                        name: bookletId
                          ? t('articlePage.breadcrumb.booklets')
                          : t('articlePage.breadcrumb.news'),
                        url: bookletId ? '/booklets' : '/news',
                      },
                      {
                        name: article?.attributes?.title ?? '',
                        url: bookletId
                          ? `/booklet/${bookletId}`
                          : `/article/${articleId}`,
                      },
                    ]}
                  />
                </div>
                <div className={styles.content}>
                  <div className={styles.row}>
                    <div className={styles.colS8}>
                      <div className={styles.newsBlock}>
                        <div className={styles.header}>
                          <p className={styles.articleCategory}>
                            {
                              article?.attributes?.article_category?.data
                                ?.attributes.name
                            }
                          </p>
                          <p className={styles.articleDate}>
                            {getDateInfo(article)}
                          </p>
                        </div>
                        <h1 className={styles.newsBlockTitle}>
                          {article?.attributes?.title}
                        </h1>

                        {article?.attributes?.article_category?.data?.id ===
                        90 ? (
                          <div className={styles.newsBlockContentContainer}>
                            <div
                              className={styles.newsBlockContent}
                              dangerouslySetInnerHTML={{
                                __html: article?.attributes?.annotation,
                              }}
                            />
                            {article?.attributes?.booklet_url ? (
                              <a
                                href={article?.attributes?.booklet_url}
                                target='_blank'
                                className={styles.button}
                                rel='noreferrer'
                              >
                                {t('articlePage.read')}
                              </a>
                            ) : (
                              <p className={styles.emptyUrlMessage}>
                                {t('articlePage.urlNotFound')}
                              </p>
                            )}
                            {isBannerLoading ? (
                              <Spinner />
                            ) : (
                              banner && (
                                <a href={bannerLink} target='_blank'>
                                  <img src={banner} alt='' />
                                </a>
                              )
                            )}
                          </div>
                        ) : (
                          <div className={styles.newsBlockContentContainer}>
                            <div
                              className={styles.newsBlockContent}
                              dangerouslySetInnerHTML={{
                                __html: formatText(
                                  article?.attributes?.full_text
                                ),
                              }}
                            />
                            {isBannerLoading ? (
                              <Spinner />
                            ) : (
                              banner && (
                                <a href={bannerLink} target='_blank'>
                                  <img src={banner} alt='' />
                                </a>
                              )
                            )}
                          </div>
                        )}

                        <div className={styles.newsBlockContentFooter}>
                          <p className={styles.author}>
                            {article?.attributes?.author}
                          </p>
                          <ArticleLikesBlock article={article} isActive />
                        </div>
                        <ShareInSocials />
                        <div className={styles.footer}>
                          <div className={styles.tags}>
                            <div className={styles.tagSectionTitle}>
                              {t('articlePage.footer.tags')}
                            </div>
                            <div className={styles.tagsWrapper}>
                              {article.attributes.tags.map((item) => (
                                <div key={item.id} className={styles.tag}>
                                  {item.tag}
                                </div>
                              ))}
                            </div>
                          </div>
                          <div className={styles.linkButtonWrapper}>
                            <LinkButton
                              link={bookletId ? '/booklets' : '/news'}
                              title={
                                bookletId
                                  ? t('articlePage.footer.allBooklets')
                                  : t('articlePage.footer.allNews')
                              }
                            />
                          </div>
                        </div>
                      </div>
                      {isBookletsListLoading ? (
                        <Spinner />
                      ) : isNotFoundBookletError ? (
                        <ErrorWidget.NotFound />
                      ) : isBookletsListError ? (
                        <ErrorWidget.Error />
                      ) : (
                        bookletId &&
                        bookletsList.length > 0 && (
                          <div className={styles.otherBooklets}>
                            <h3 className={styles.otherBookletsTitle}>
                              {t('articlePage.otherBooklets')}
                            </h3>
                            {isMobile ? (
                              <div className={styles.sliderWrapper}>
                                <Slider
                                  className={styles.sliderBookletMobile}
                                  dots={false}
                                  speed={500}
                                  adaptiveHeight
                                  infinite={false}
                                  slidesToShow={1}
                                  slidesToScroll={1}
                                  arrows={true}
                                  prevArrow={<ArrowLeftLine />}
                                  nextArrow={<ArrowRightLine />}
                                >
                                  {bookletsList.map((booklet) => (
                                    <BookletItem
                                      isSmall
                                      booklet={booklet}
                                      key={booklet.id}
                                    />
                                  ))}
                                </Slider>
                              </div>
                            ) : (
                              <SliderWrapper
                                className={styles.bookletSlider}
                                slides={bookletsList.map((booklet) => (
                                  <BookletItem
                                    isSmall
                                    booklet={booklet}
                                    key={booklet.id}
                                  />
                                ))}
                                slidesToScroll={1}
                                slidesToShow={2}
                                dots={false}
                                speed={500}
                                adaptiveHeight
                                infinite={false}
                              />
                            )}
                          </div>
                        )
                      )}
                    </div>
                    <aside className={styles.colS4}>
                      <SideMenu />
                    </aside>
                  </div>
                </div>
              </>
            )
          )}
        </div>
      </Container>
    </ImageBgPageWrapper>
  );
};

export default ArticlePage;
