import { ReactElement } from 'react';
import { GRIP, POSITION_MAP } from '../../../constants/constants';
import {
  PlayerSeasonStats,
  PlayerWithRelations,
  Roster,
} from '../../../types/Types';
import BioBox from './BioBox/BioBox';
import styles from './Bio.module.scss';
import { getAge, getDate, getNoun } from '../../../helpers/helpers';
import { useSearchParams } from 'react-router-dom';
import StatisticNumber from '../StatisticInfo/StatiscticNumber/StaticsticNumber';
import { useTranslation } from 'react-i18next';

type BioProps = {
  player: PlayerWithRelations;
  playerStats?: PlayerSeasonStats;
  playerRoster?: Roster;
};

const Bio = ({ player, playerStats, playerRoster }: BioProps): ReactElement => {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const seasonParam = searchParams.get('season');
  const isCatastrophe = seasonParam === '2012MEN' ? true : false;
  const isGoalkeeper = player.attributes.position.data.id === 1;

  function getDateDebut(debut: Date) {
    let debutDate = new Date(debut);
    return `${debutDate.getFullYear()} г.`;
  }

  return (
    <div className={styles.cardBio}>
      {isCatastrophe ? (
        <div className={styles.columns}>
          <div className={styles.cardBioColumnCatastrophe}>
            {player && (
              <BioBox
                name={t('playerPage.bio.citizenship')}
                value={
                  player?.attributes?.citizenship
                    ? player?.attributes?.citizenship?.data?.attributes?.name
                    : '-'
                }
                displayLogo={
                  player?.attributes?.citizenship?.data?.attributes?.flag?.data
                    ?.attributes?.url
                }
              />
            )}
            {player && (
              <BioBox
                name={t('playerPage.bio.birthDate')}
                value={
                  player?.attributes?.birth
                    ? getDate(player?.attributes?.birth)
                    : '-'
                }
              />
            )}
            {player && (
              <BioBox
                name={t('playerPage.bio.birthPlace')}
                value={
                  player?.attributes?.birth_place
                    ? player?.attributes?.birth_place
                    : '-'
                }
              />
            )}
          </div>

          <div className={styles.cardBioColumnCatastrophe}>
            {player && (
              <BioBox
                name={t('playerPage.bio.hockeySchool')}
                value={
                  player?.attributes?.hockey_school
                    ? player.attributes.hockey_school
                    : '-'
                }
              />
            )}
            {player && (
              <BioBox
                name={t('playerPage.bio.debut')}
                value={
                  player?.attributes?.debut
                    ? getDateDebut(player?.attributes?.debut)
                    : '-'
                }
              />
            )}
            {player && (
              <BioBox
                name={t('playerPage.bio.position')}
                value={
                  player?.attributes?.grip?.data?.id
                    ? POSITION_MAP.get(player?.attributes?.position?.data.id)
                    : '-'
                }
              />
            )}
          </div>
        </div>
      ) : (
        <div className={styles.columns}>
          <div className={styles.column}>
            <div className={styles.numbers}>
              <StatisticNumber
                number={playerStats?.gamesPlayed}
                text={getNoun(
                  playerStats?.gamesPlayed || 0,
                  'ИГРА',
                  'ИГРЫ',
                  'ИГР'
                )}
              />

              {isGoalkeeper ? (
                <StatisticNumber
                  number={playerStats?.goalAgainstAverage}
                  text={t('playerPage.bio.goalAgainstAverage')}
                />
              ) : (
                <StatisticNumber
                  number={playerStats?.points}
                  text={getNoun(
                    playerStats?.points || 0,
                    'ОЧКО',
                    'ОЧКА',
                    'ОЧКОВ'
                  )}
                />
              )}
            </div>

            <div className={styles.cardBioColumn}>
              {player?.attributes?.birth && (
                <BioBox
                  name={t('playerPage.bio.birthDate')}
                  value={getDate(player?.attributes?.birth)}
                />
              )}
              {player?.attributes?.birth && !isCatastrophe && (
                <BioBox
                  name={t('playerPage.bio.age')}
                  value={getAge(player?.attributes?.birth)}
                  dopInfo={getNoun(
                    getAge(player?.attributes?.birth),
                    'год',
                    'года',
                    'лет'
                  )}
                />
              )}

              {
                <BioBox
                  name={t('playerPage.bio.height')}
                  value={
                    playerRoster?.attributes?.height ||
                    player?.attributes?.height ||
                    '-'
                  }
                  dopInfo='см'
                />
              }
              {
                <BioBox
                  name={t('playerPage.bio.weight')}
                  value={
                    playerRoster?.attributes?.weight ||
                    player?.attributes?.weight ||
                    '-'
                  }
                  dopInfo='кг'
                />
              }
              {player?.attributes?.grip?.data?.id && (
                <BioBox
                  name={t('playerPage.bio.grip')}
                  value={GRIP.get(player?.attributes?.grip?.data?.id)}
                />
              )}
              {player?.attributes?.grip?.data?.id && (
                <BioBox
                  name={t('playerPage.bio.position')}
                  value={POSITION_MAP.get(
                    player?.attributes?.position?.data.id
                  )}
                />
              )}
            </div>
          </div>

          <div className={styles.column}>
            <div className={styles.numbers}>
              {isGoalkeeper ? (
                <>
                  <StatisticNumber
                    number={playerStats?.savesPercent}
                    text={t('playerPage.bio.savesPercent')}
                  />
                  <StatisticNumber
                    number={playerStats?.shutouts}
                    text={getNoun(
                      playerStats?.shutouts || 0,
                      'СУХАЯ ИГРА',
                      'СУХИЕ ИГРЫ',
                      'СУХИХ ИГР'
                    )}
                  />
                </>
              ) : (
                <>
                  <StatisticNumber
                    number={playerStats?.goals}
                    text={getNoun(
                      playerStats?.goals || 0,
                      'ШАЙБА',
                      'ШАЙБЫ',
                      'ШАЙБ'
                    )}
                  />
                  <StatisticNumber
                    number={playerStats?.plusMinus}
                    text={t('playerPage.bio.plusMinus')}
                  />
                </>
              )}
            </div>

            <div className={styles.cardBioColumn}>
              {player?.attributes?.birth_place && (
                <BioBox
                  name={t('playerPage.bio.birthPlace')}
                  value={player?.attributes?.birth_place}
                />
              )}
              {player?.attributes?.citizenship && (
                <BioBox
                  name={t('playerPage.bio.citizenship')}
                  value={
                    player?.attributes?.citizenship?.data?.attributes?.name
                  }
                  displayLogo={
                    player?.attributes?.citizenship?.data?.attributes?.flag
                      ?.data?.attributes?.url
                  }
                />
              )}
              {player && (
                <BioBox
                  name={t('playerPage.bio.hockeySchool')}
                  value={
                    player?.attributes?.hockey_school
                      ? player?.attributes?.hockey_school
                      : '-'
                  }
                />
              )}
              {player && (
                <BioBox
                  name={t('playerPage.bio.debut')}
                  value={
                    player?.attributes?.debut
                      ? getDateDebut(player?.attributes?.debut)
                      : '-'
                  }
                />
              )}
              {player && (
                <BioBox
                  name={t('playerPage.bio.contractEnds')}
                  value={
                    player.attributes.hide_contract_info
                      ? '-'
                      : getDate(player?.attributes?.contract_ends)
                  }
                />
              )}
              {player && (
                <BioBox
                  name={t('playerPage.bio.contractType')}
                  value={
                    player.attributes.hide_contract_info
                      ? '-'
                      : player?.attributes?.contract_type?.data?.attributes.type
                  }
                />
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Bio;
