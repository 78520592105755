import { makeAutoObservable } from 'mobx';
import { MenuElementDto } from '../../types/Types';
import { getMenu } from 'api/api';

class MenuStore {
  constructor() {
    makeAutoObservable(this);

    this.menu = [];
  }

  isLoading: boolean = true;
  isError: boolean = false;
  menu: Array<MenuElementDto>;

  async loadMenu() {
    try {
      const resp = await getMenu();
      const sortedMenuItems = resp?.data?.sort(
        (a: MenuElementDto, b: MenuElementDto) =>
          a.attributes.display_order - b.attributes.display_order
      );
      this.menu = sortedMenuItems;
    } catch (error) {
      this.isError = true;
    } finally {
      this.isLoading = false;
    }
  }

  findMenuElementByCurrentPath(path: string): MenuElementDto | undefined {
    return this.menu.find((menuElement) => {
      const subMenuContainsCurrentUrl =
        menuElement.attributes.main_menu_item.data.some((subMenuElement) =>
          subMenuElement.attributes.url.includes(path)
        );
      if (subMenuContainsCurrentUrl) {
        return true;
      }
      return false;
    });
  }

  setMenu(menu: Array<MenuElementDto>) {
    this.menu = menu;
  }
}

export default MenuStore;
