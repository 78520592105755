import { useEffect, useState } from 'react';
import {
  AlbumSimple,
  DefaultImageObject,
  PhotoCategory,
} from '../../../types/Types';
import InfoWrapper from '../../Wrappers/InfoWrapper/InfoWrapper';
import ImageBgPageWrapper from '../../common/ImageBgPageWrapper/ImageBgPageWrapper';
import Container from '../../common/Container/Container';
import BreadcrumbsWrapper from 'components/common/BreadcrumbsWrapper/BreadcrumbsWrapper';
import PageHeader from 'components/common/PageHeader/PageHeader';
import { observer } from 'mobx-react-lite';
import { useNavigate, useParams } from 'react-router-dom';
import Album from '../../Album/Album';
import { getAlbumById, getDefaultImages } from '../../../api/api';
import {
  DEFAULT_IMAGE_NAME,
  DEFAULT_PHOTOGALLERY_NAME,
} from '../../../constants/constants';
import styles from './AlbumPage.module.scss';
import ErrorWidget from 'components/common/ErrorWidget/ErrorWidget';
import { useTranslation } from 'react-i18next';
import SelectArrow from 'assets/SelectArrow';
import ErrorPage from '../../pages/ErrorPage/ErrorPage';
import SetRespError from 'helpers/helpers';

const AlbumPage = observer(() => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [album, setAlbum] = useState<PhotoCategory | AlbumSimple>();
  const [defaultImages, setDefaultImages] = useState<DefaultImageObject[]>([]);
  const [isError, setIsError] = useState<boolean>(false);
  const [isNotFoundError, setIsNotFoundError] = useState<boolean>(false);

  const { albumId } = useParams();

  const getAlbumTitle = () => {
    if (!album) {
      return '';
    }

    if ('description' in album) {
      return album.description;
    }

    const { attributes } = album;

    return attributes?.description || '';
  };

  useEffect(() => {
    const getAlbumInfo = async () => {
      try {
        if (!albumId) {
          return;
        }

        const response = await getAlbumById(albumId);
        setAlbum(response?.data);
      } catch (e: any) {
        SetRespError(e, setIsNotFoundError, setIsError);
      }

      try {
        const images = await getDefaultImages();
        setDefaultImages(images?.data);
      } catch (e: any) {
        console.error(e?.response?.data?.error || e?.message);
      }
    };

    getAlbumInfo();
  }, [albumId]);

  return (
    <ImageBgPageWrapper
      imageInfo={{
        img_url:
          (album as PhotoCategory)?.attributes.cover?.data?.attributes?.url ||
          defaultImages?.find(
            (x) => x.attributes.description === DEFAULT_PHOTOGALLERY_NAME
          )?.attributes?.image?.data?.attributes?.url ||
          '',
        gradient_height: 166,
        image_height: 565,
      }}
    >
      <Container paddingTop='50px' paddingBottom='0px'>
        <BreadcrumbsWrapper
          breadcrumbs={[
            { name: t('albumPage.breadcrumbParent'), url: '/photogallery' },
            {
              name: getAlbumTitle(),
              url: `/photogallery/${albumId}`,
            },
          ]}
        />
        <div className={styles.headerWrapper}>
          <div className={styles.back} onClick={() => navigate(-1)}>
            <SelectArrow fillColor='#fff' rotateDegrees={90} />
            <span className={styles.backText}>{t('albumPage.back')}</span>
          </div>
          <PageHeader text={getAlbumTitle()} />
        </div>
        <InfoWrapper isWhiteBackground={true} isPaddingZero={true}>
          {isNotFoundError ? (
            <ErrorWidget.NotFound />
          ) : isError ? (
            <ErrorWidget.Error />
          ) : (
            <div className={styles.innerWrapper}>
              {album && (
                <Album
                  album={album}
                  defaultImage={
                    defaultImages.find(
                      (x) => x.attributes.description === DEFAULT_IMAGE_NAME
                    ) || defaultImages[0]
                  }
                />
              )}
            </div>
          )}
        </InfoWrapper>
      </Container>
    </ImageBgPageWrapper>
  );
});

export default AlbumPage;
