import { ReactElement, useEffect, useState } from 'react';
import {
  PlayerStatisticsDto,
  PlayerWithRelations,
  StatisticsDto,
} from '../../../types/Types';
import styles from './PlayerCarrierStatistic.module.scss';
import GoalkeeperCarrierTable from '../GoalkeeperCarrierTable/GoalkeeperCarrierTable';
import NotGoalkeeperCarrierTable from '../NotGoalkeeperCarrierTable/NotGoalkeeperCarrierTable';
import { useTranslation } from 'react-i18next';

type PlayerCarrierStatisticProps = {
  player: PlayerWithRelations;
};

type PlayerSeasonsType = {
  season: StatisticsDto;
  stats: PlayerStatisticsDto;
  isPlayoff: boolean;
};

const PlayerCarrierStatistic = ({
  player,
}: PlayerCarrierStatisticProps): ReactElement => {
  const { t } = useTranslation();
  const isGoalkeeper = player.attributes.position.data.id === 1;
  const [playerSeasons, setPlayerSeasons] = useState<Array<PlayerSeasonsType>>(
    []
  );

  useEffect(() => {
    const playerSeasonsArr: Array<PlayerSeasonsType> = [];

    player.attributes?.statistic?.forEach((season) => {
      if (season.regular_season) {
        playerSeasonsArr.push({
          season: season,
          stats: season.regular_season,
          isPlayoff: false,
        });
      }

      if (season.playoffs) {
        playerSeasonsArr.push({
          season: season,
          stats: season.playoffs,
          isPlayoff: true,
        });
      }
    });
    setPlayerSeasons(playerSeasonsArr);
  }, [player]);

  return (
    <table className={styles.table}>
      <thead>
        {isGoalkeeper ? (
          <tr className={styles.tableHeader}>
            <th
              title={t('playerPage.statsTable.title.season')}
              className={styles.firstTh}
            >
              <div className={styles.season}>
                {t('playerPage.statsTable.content.season')}
              </div>
            </th>
            <th title={t('playerPage.statsTable.title.league')}>
              {t('playerPage.statsTable.content.league')}
            </th>
            <th title={t('playerPage.statsTable.title.games')}>
              {t('playerPage.statsTable.content.games')}
            </th>
            <th title={t('playerPage.statsTable.title.goals')}>
              {t('playerPage.statsTable.content.goals')}
            </th>
            <th title={t('playerPage.statsTable.title.passes')}>
              {t('playerPage.statsTable.content.passes')}
            </th>
            <th title={t('playerPage.statsTable.title.points')}>
              {t('playerPage.statsTable.content.points')}
            </th>
            <th title={t('playerPage.statsTable.title.penalties')}>
              {t('playerPage.statsTable.content.penalties')}
            </th>
            <th title={t('playerPage.statsTable.title.missedGoals')}>
              {t('playerPage.statsTable.content.missedGoals')}
            </th>
            <th title={t('playerPage.statsTable.title.savePercentage')}>
              {t('playerPage.statsTable.content.savePercentage')}
            </th>
            <th title={t('playerPage.statsTable.title.goalsAgainstAverage')}>
              {t('playerPage.statsTable.content.goalsAgainstAverage')}
            </th>
            <th title={t('playerPage.statsTable.title.shutouts')}>
              {t('playerPage.statsTable.content.shutouts')}
            </th>
          </tr>
        ) : (
          <tr className={styles.tableHeader}>
            <th
              title={t('playerPage.statsTable.title.season')}
              className={styles.firstTh}
            >
              <div className={styles.season}>
                {t('playerPage.statsTable.content.season')}
              </div>
            </th>
            <th title={t('playerPage.statsTable.title.league')}>
              {t('playerPage.statsTable.content.league')}
            </th>
            <th title={t('playerPage.statsTable.title.games')}>
              {t('playerPage.statsTable.content.games')}
            </th>
            <th title={t('playerPage.statsTable.title.goals')}>
              {t('playerPage.statsTable.content.goals')}
            </th>
            <th title={t('playerPage.statsTable.title.passes')}>
              {t('playerPage.statsTable.content.passes')}
            </th>
            <th title={t('playerPage.statsTable.title.points')}>
              {t('playerPage.statsTable.content.points')}
            </th>
            <th title={t('playerPage.statsTable.title.penalties')}>
              {t('playerPage.statsTable.content.penalties')}
            </th>
          </tr>
        )}
      </thead>
      {playerSeasons.map((playerSeason, index) =>
        isGoalkeeper ? (
          <tbody key={`goalkeeper-${index}`}>
            <GoalkeeperCarrierTable
              season={playerSeason.season}
              stats={playerSeason.stats}
              isPlayoff={playerSeason.isPlayoff}
            />
          </tbody>
        ) : (
          <tbody key={`not-goalkeeper-${index}`}>
            <NotGoalkeeperCarrierTable
              season={playerSeason.season}
              stats={playerSeason.stats}
              isPlayoff={playerSeason.isPlayoff}
            />
          </tbody>
        )
      )}
    </table>
  );
};

export default PlayerCarrierStatistic;
