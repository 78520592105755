import { ReactElement, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Input, notification } from 'antd';
import { postEmail, registration } from 'api/api';
import styles from './SignUpForm.module.scss';
import { useStore } from 'store/store';
import {
  DOCUMENTS_SYS_NAMES,
  RESPONSE_MESSAGE_UNIQUE,
} from 'constants/constants';
import { validateEmail } from 'helpers/helpers';
import { useTranslation } from 'react-i18next';

type SignUpFormProps = {
  onRegistrationSuccess: () => void;
};

const SignUpForm = observer(
  ({ onRegistrationSuccess }: SignUpFormProps): ReactElement => {
    const [userName, setUserName] = useState('');
    const [userEmail, setUserEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [confirm, setConfirm] = useState(false);
    const [subscribe, setSubscribe] = useState(false);
    const [isValidEmail, setIsValidEmail] = useState(true);
    const [isPasswordsMatch, setIsPasswordsMatch] = useState(true);
    const [passwordError, setPasswordError] = useState('');
    const { documentsStore } = useStore();
    const { t } = useTranslation();

    const onEmailChange = (value: string) => {
      setUserEmail(value);

      if (!value || validateEmail(value)) {
        setIsValidEmail(true);
      } else {
        setIsValidEmail(false);
      }
    };

    const onConfirmPasswordChange = (value: string) => {
      setConfirmPassword(value);
      setIsPasswordsMatch(true);
    };

    const isButtonDisabled = () => {
      return (
        !isValidEmail ||
        !isPasswordsMatch ||
        !confirm ||
        !userName ||
        !userEmail ||
        !password ||
        !confirmPassword
      );
    };

    const signUp = async () => {
      if (password !== confirmPassword) {
        setIsPasswordsMatch(false);

        return;
      }

      try {
        const data = {
          email: userEmail,
          username: userName,
          password: password,
          password_verify: confirmPassword,
          newsletter_subscribed: subscribe,
          agreement_accepted: confirm,
        };

        const response = await registration(data);

        if (response.status === 200) {
          onRegistrationSuccess();
        }

        subscribeOnEmailNotification();
      } catch (e: any) {
        const error = e?.response?.data?.error || e?.message;

        if (error && error.message === 'Email or Username are already taken') {
          notification.error({
            message: t(
              'memoryBookPage.signUpForm.userWithThisLoginAlreadyExist'
            ),
            placement: 'bottomLeft',
            duration: 15,
          });
        } else if (
          error &&
          error.message === 'password must be at least 6 characters'
        ) {
          notification.error({
            message: t('memoryBookPage.signUpForm.passwordLeastSixCharacters'),
            placement: 'bottomLeft',
            duration: 15,
          });
        } else {
          notification.error({
            message: `${t('memoryBookPage.signUpForm.signUpFailed')}\n${t(
              'memoryBookPage.signUpForm.details'
            )}: ${error.message}`,
            placement: 'bottomLeft',
            duration: 15,
          });
        }
      }
    };

    const subscribeOnEmailNotification = async () => {
      try {
        if (!subscribe) {
          return;
        }

        const resp = await postEmail({
          data: {
            email: userEmail,
          },
        });
        if (resp?.data) {
          notification.success({
            message: t('footer.email.submit.success'),
            placement: 'bottomLeft',
            duration: 15,
          });
        }
      } catch (error: any) {
        if (
          error?.response?.data?.error?.message === RESPONSE_MESSAGE_UNIQUE ||
          error?.message === RESPONSE_MESSAGE_UNIQUE
        ) {
          notification.error({
            message: t('footer.email.submit.unique'),
            placement: 'bottomLeft',
            duration: 15,
          });
        } else {
          notification.error({
            message: t('footer.email.submit.unknownError'),
            placement: 'bottomLeft',
            duration: 15,
          });
        }
      }
    };

    const getTermsOfUseUrl = () => {
      const termsOfUse = documentsStore.documents.find(
        (item) => item.attributes.sys_name === DOCUMENTS_SYS_NAMES.termsOfUse
      );

      return termsOfUse ? termsOfUse.attributes.document_link : '';
    };

    const onPasswordChange = (value: string) => {
      setPassword(value);

      if (value && value.length < 6) {
        const error = t('memoryBookPage.signUpForm.passwordLessThan');
        setPasswordError(error);
      } else {
        setPasswordError('');
      }
    };

    return (
      <div className={styles.wrapper}>
        <div className={styles.inputWrapper}>
          <div className={styles.span}>
            {t('memoryBookPage.signUpForm.login')}
          </div>
          <div className={styles.nameInput}>
            <Input
              value={userName}
              placeholder={t('memoryBookPage.signUpForm.name')}
              onChange={(event) => setUserName(event.target.value)}
            />
          </div>
          <div className={styles.span}>
            {t('memoryBookPage.signUpForm.email')}
          </div>
          <div className={styles.nameInput}>
            <Input
              value={userEmail}
              placeholder={t('memoryBookPage.signUpForm.email')}
              onChange={(event) => onEmailChange(event.target.value)}
            />
            {!isValidEmail ? (
              <div className={styles.incorrectEmail}>
                {t('memoryBookPage.signUpForm.invalidEmail')}
              </div>
            ) : null}
          </div>
          <div className={styles.span}>
            {t('memoryBookPage.signUpForm.password')}
          </div>
          <div className={styles.passwordInput}>
            <Input.Password
              value={password}
              placeholder={t('memoryBookPage.signUpForm.password')}
              onChange={(event) => onPasswordChange(event.target.value)}
            />
            {passwordError ? (
              <div className={styles.passwordsShouldMatch}>{passwordError}</div>
            ) : null}
          </div>
          <div className={styles.span}>
            {t('memoryBookPage.signUpForm.repeatPassword')}
          </div>
          <div className={styles.passwordInput}>
            <Input.Password
              value={confirmPassword}
              placeholder={t('memoryBookPage.signUpForm.repeatPassword')}
              onChange={(event) => onConfirmPasswordChange(event.target.value)}
            />
            {!isPasswordsMatch ? (
              <div className={styles.passwordsShouldMatch}>
                {t('memoryBookPage.signUpForm.passwordsShouldMatch')}
              </div>
            ) : null}
          </div>
          <div className={styles.checkboxes}>
            <div className={styles.checkboxWrapper}>
              <input
                type='checkbox'
                checked={confirm}
                onChange={() => setConfirm(!confirm)}
              />
              <div className={styles.label}>
                {t('memoryBookPage.signUpForm.agreedWithTermsOfUse')}{' '}
                <a
                  className={styles.link}
                  href={getTermsOfUseUrl()}
                  target='_blank'
                  rel='noreferrer'
                >
                  {`(${t('memoryBookPage.signUpForm.termsOfUse')})`}
                </a>
              </div>
            </div>
            <div className={styles.checkboxWrapper}>
              <input
                type='checkbox'
                checked={subscribe}
                onChange={() => setSubscribe(!subscribe)}
              />
              <label className={styles.label}>
                {t('memoryBookPage.signUpForm.agreedWithEmailSubscription')}
              </label>
            </div>
          </div>
        </div>
        <div className={styles.buttonWrapper}>
          <button
            className={styles.button}
            disabled={isButtonDisabled()}
            onClick={signUp}
          >
            {t('memoryBookPage.signUpForm.signUp')}
          </button>
        </div>
      </div>
    );
  }
);

export default SignUpForm;
