import { ReactElement } from 'react';
import styles from './BuyTicketsButton.module.scss';
import ticketsIco from 'assets/ticket_white.svg';
import Url from 'constants/urls';
import { useTranslation } from 'react-i18next';

const BuyTicketsButton = (): ReactElement => {
  const { t } = useTranslation();
  return (
    <a href={Url.loko.TICKETS} target='_blank' rel='noreferrer'>
      <div className={styles.button}>
        <img src={ticketsIco} alt='' />
        <span className={styles.buttonText}>
          {t('ticketingProgramPage.buyTicket')}
        </span>
      </div>
    </a>
  );
};

export default BuyTicketsButton;
