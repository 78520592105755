import Colors from 'constants/colors';
import { ReactElement } from 'react';

interface ProtocolBannerRightLineProps {
  fillColor?: string;
  width?: number;
  height?: number;
}

export default function ProtocolBannerRightLine({
  fillColor,
  width = 94,
  height = 110,
}: ProtocolBannerRightLineProps): ReactElement {
  const viewBoxY = 110 - height;

  return (
    <svg
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      width={`${width}px`}
      height={`${height}px`}
      viewBox={`0 ${viewBoxY} ${width} ${height}`}
    >
      <path
        fill={fillColor || Colors.GREY_TEXT}
        d='M58.97797,0.08936 0.94141,110 35.96344,110 94,0.08936 Z'
      />
    </svg>
  );
}
