import Colors from 'constants/colors';
import { ReactElement } from 'react';

interface ProtocolBannerLeftBlockProps {
  fillColor?: string;
  width?: number;
  height?: number;
}

export default function ProtocolBannerLeftBlock({
  fillColor,
  width = 150,
  height = 110,
}: ProtocolBannerLeftBlockProps): ReactElement {
  const wDiff = width - 150;

  return (
    <svg
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      width={`${width}px`}
      height={`${height}px`}
      viewBox={`0 0 ${width} ${height}`}
    >
      <path
        fill={fillColor || Colors.DARK_GREY}
        d={`M ${91.34424 + wDiff},110 0,110 0,0.08935 ${
          149.38078 + wDiff
        },0.08935 Z`}
      />
    </svg>
  );
}
