import styles from './MobileHeaderDrawerAccordion.module.scss';
import Column from 'components/core/layout/Column';
import { useCallback, useState } from 'react';
import Row from 'components/core/layout/Row';
import MobileHeaderDrawerAccordionArrow from './MobileHeaderDrawerAccordionArrow';
import Url from 'constants/urls';
import { useTranslation } from 'react-i18next';

const MobileHeaderDrawerAccordionSocials = () => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const onTap = useCallback(() => setIsOpen((prevIsOpen) => !prevIsOpen), []);

  const items: { url: string; text: string }[] = [
    { url: Url.socials.VK, text: t('header.vk') },
    { url: Url.socials.TELEGRAM, text: t('header.tg') },
    { url: Url.socials.YOUTUBE, text: t('header.yt') },
  ];

  const renderItems = () => (
    <Column gap='12px'>
      {items.map((item) => (
        <a href={item.url} className={styles.itemText} target='_blank'>
          <span>{item.text}</span>
        </a>
      ))}
    </Column>
  );

  const renderTop = () => (
    <div onClick={onTap} className={styles.headerItem}>
      <Row alignItems='center' justifyContent='space-between'>
        <span className={styles.headerTextSocials}>{t('header.socials')}</span>
        <MobileHeaderDrawerAccordionArrow isOpen={isOpen} />
      </Row>
    </div>
  );

  return (
    <Column gap='10px'>
      {renderTop()}
      {isOpen && renderItems()}
    </Column>
  );
};

export default MobileHeaderDrawerAccordionSocials;
