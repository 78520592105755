import UpperRightArrow from 'assets/UpperRightArrow';
import Colors from 'constants/colors';

interface MobileHeaderDrawerAccordionArrowProps {
  isOpen: boolean;
}

const MobileHeaderDrawerAccordionArrow = ({
  isOpen,
}: MobileHeaderDrawerAccordionArrowProps) => (
  <UpperRightArrow
    fillColor={isOpen ? Colors.LIGHT_GREY : Colors.PRIMARY_RED}
    rotateDegrees={isOpen ? -90 : 90}
  />
);

export default MobileHeaderDrawerAccordionArrow;
