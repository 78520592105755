import { ReactElement, useEffect, useState, useRef } from 'react';
import { observer } from 'mobx-react-lite';
import { useStore } from 'store/store';
import {
  DefaultImageObject,
  PlayerWithRelations,
  Roster,
  RosterDto,
} from 'types/Types';
import { getDefaultImages, getPlayerById } from 'api/api';
import PlayerHoverCard from './PlayerHoverCard/PlayerHoverCart';
import PlayerNotHoverCard from './PlayerNotHoverCard/PlayerNotHoverCard';
import { Breakpoints } from 'constants/adaptivity';
import { HEIGHT } from 'constants/constants';
import { Link, useParams } from 'react-router-dom';
import ErrorWidget from 'components/common/ErrorWidget/ErrorWidget';

type RosterPlayerProps = {
  player: RosterDto;
  season?: string;
};

const PlayerCard = observer(
  ({ player, season: seasonProp }: RosterPlayerProps): ReactElement => {
    const { currentSeasonStore } = useStore();
    const [playerInfo, setPlayerInfo] = useState<PlayerWithRelations>();
    const [defaultImages, setDefaultImages] = useState<DefaultImageObject[]>(
      []
    );
    const [isError, setIsError] = useState<boolean>(false);
    const [isPlayerError, setIsPlayerError] = useState<boolean>(false);
    const [isHover, setIsHover] = useState<boolean>(false);
    const playerCardRef = useRef<any>(null);
    const [playerRoster, setPlayerRoster] = useState<Roster>();
    const [season, setSeason] = useState<string>('');
    const { teamCode } = useParams();

    useEffect(() => {
      const seasonCode = currentSeasonStore.getSeasonByCode(teamCode);

      if (seasonProp) {
        setSeason(seasonProp);
      } else if (seasonCode) {
        setSeason(seasonCode);
      }
    }, [teamCode, currentSeasonStore.currentSeason]);

    function getHeight() {
      if (window.innerWidth <= Breakpoints.largeMobile)
        return HEIGHT.get(Breakpoints.largeMobile);
      if (window.innerWidth <= Breakpoints.smallTablet)
        return HEIGHT.get(Breakpoints.smallTablet);
      if (window.innerWidth <= Breakpoints.largeTablet)
        return HEIGHT.get(Breakpoints.largeTablet);
      if (window.innerWidth <= Breakpoints.smallDesktop)
        return HEIGHT.get(Breakpoints.smallDesktop);
      return 474;
    }

    useEffect(() => {
      const getPlayerInfo = async () => {
        try {
          const resp = await getPlayerById(player.attributes.player.data.id);
          setPlayerInfo(resp?.data);
        } catch (e: any) {
          setIsPlayerError(true);
        }

        try {
          const images = await getDefaultImages();
          setDefaultImages(images?.data);
        } catch (e: any) {
          const error = e?.response?.data?.error;
          if (error) {
            // TODO: discuss with team what we need to do if no default images found
            // doesn't look like a critical issue
            setIsError(true);
          }
        }
      };

      getPlayerInfo();
    }, []);

    useEffect(() => {
      setPlayerRoster(
        playerInfo?.attributes.rosters.data.find(
          (roster) =>
            roster?.attributes?.season?.data?.attributes?.name === season
        )
      );
    }, [playerInfo]);

    return isPlayerError ? (
      <ErrorWidget.Error />
    ) : (
      <Link
        style={{ height: `${getHeight()}px` }}
        to={`/player/${player.attributes.player.data.id}?season=${season}`}
        onMouseEnter={() => setIsHover(true)}
        onMouseLeave={() => setIsHover(false)}
      >
        {isHover ? (
          <PlayerHoverCard
            player={player}
            defaultImages={defaultImages}
            playerInfo={playerInfo}
            height={
              playerRoster?.attributes.height ||
              player?.attributes?.player?.data?.attributes?.height
            }
            weight={
              playerRoster?.attributes.weight ||
              player?.attributes?.player?.data?.attributes?.weight
            }
          />
        ) : (
          <div ref={playerCardRef} style={{ height: '100%' }}>
            <PlayerNotHoverCard
              player={player}
              defaultImages={defaultImages}
              playerInfo={playerInfo}
            />
          </div>
        )}
      </Link>
    );
  }
);

export default PlayerCard;
