import styles from './FileUploadButton.module.scss';
import AddIcon from 'assets/icons/add-icon.svg';

type FileUploadButtonProps = {
  onFileSelect: (event: any) => void;
  disabled?: boolean;
};

const FileUploadButton = (props: FileUploadButtonProps) => {
  return (
    <>
      <label
        htmlFor='file-upload'
        className={`${styles.inputLabel} ${
          props.disabled ? styles.disabled : ''
        }`}
      >
        <img src={AddIcon} alt='' />
      </label>
      <input
        id='file-upload'
        type='file'
        name='image'
        accept='image/*'
        capture='environment'
        value=''
        onChange={props.onFileSelect}
        className={styles.imageInput}
        disabled={props.disabled}
      ></input>
    </>
  );
};

export default FileUploadButton;
