import { ReactElement, useEffect, useState } from 'react';
import { DefaultImageObject, ForumPost, SmileDto } from 'types/Types';
import styles from './PostItem.module.scss';
import { DEFAULT_IMAGE_NAME } from 'constants/constants';
import { getDefaultPlayerImage } from 'api/api';
const bbcode = require('tcbbcode');

type PostItemProps = {
  post: ForumPost;
  smiles: Array<SmileDto>;
};

function PostItem({ post, smiles }: PostItemProps): ReactElement {
  const [defaultPhoto, setDefaultPhoto] = useState<DefaultImageObject>();

  useEffect(() => {
    const getData = async () => {
      try {
        const photo = await getDefaultPlayerImage(DEFAULT_IMAGE_NAME);
        setDefaultPhoto(photo.data[0]);
      } catch (e: any) {
        console.error(e);
      }
    };
    getData();
  }, []);
  const decodeHtml = (html: string) => {
    const txt = document.createElement('textarea');
    txt.innerHTML = html;
    return txt.value;
  };

  const convertBbcodeToHtml = (bbcodedText: string) => {
    try {
      return bbcode(
        bbcodedText
          .replaceAll('[abc]', '[s]')
          .replaceAll('[/abc]', '[/s]')
          .replaceAll('&nbsp;', ' ')
          .replaceAll('&quot;', '"')
      );
    } catch (e) {
      console.log(e);
      return bbcodedText;
    }
  };

  const replaceSmiles = (text: string) => {
    for (let i = 0; i < smiles?.length; i++) {
      text = text.replaceAll(
        smiles[i]?.attributes?.text,
        `<img src='${smiles[i]?.attributes?.image?.data?.attributes?.url}'>`
      );
    }

    return text;
  };

  const getUserName = () => {
    if (post.user?.name && post.user?.surname) {
      return `${post.user.name} ${post.user.surname}`;
    } else {
      return post.user?.username;
    }
  };

  const getDateTimeString = () => {
    const date = new Date(post.attributes.date_time);

    return date
      ? `${formatNumber(date.getDate())}.${formatNumber(
          date.getMonth() + 1
        )}.${formatNumber(date.getFullYear())} / ${formatNumber(
          date.getHours()
        )}:${formatNumber(date.getMinutes())}`
      : '';
  };

  const formatNumber = (number: number) => {
    return number < 10 ? `0${number}` : number;
  };

  const handleLinkClick = (event: any) => {
    if (event.target.tagName === 'A') {
      window.open(event.target.href, '_blank');
      event.preventDefault();
    }
  };

  return (
    <div className={styles.post}>
      <div className={styles.imageWrapper}>
        <img
          className={styles.image}
          src={
            post.user?.avatar_url
              ? post.user?.avatar_url
              : defaultPhoto?.attributes?.image?.data?.attributes?.url
          }
          alt=''
        />
      </div>
      <div className={styles.postBody}>
        <div className={styles.postTitle}>
          <div className={styles.userName}>{getUserName()}</div>
          <div className={styles.date}>{getDateTimeString()}</div>
        </div>
        <div
          className={styles.content}
          dangerouslySetInnerHTML={{
            __html: decodeHtml(
              convertBbcodeToHtml(replaceSmiles(post?.attributes?.text))
            ),
          }}
          onClick={handleLinkClick}
        ></div>
      </div>
    </div>
  );
}

export default PostItem;
