import styles from './YearVideoFilter.module.scss';
import { useEffect, useState } from 'react';
import { LOKOMOTIV_YOUTUBE_OLDEST_VIDEO_YEAR } from '../../../constants/constants';
import { getYouTubeVideoList } from 'api/api';
import Select, { SelectOption } from 'components/common/Select/Select';

interface YearVideoFilterProps {
  onSelect: (optionsId: number | string) => void;
}

const YearVideoFilter = ({ onSelect }: YearVideoFilterProps) => {
  const [options, setOptions] = useState<{ value: number; label: string }[]>(
    []
  );
  const [value, setValue] = useState<number | string | undefined>();

  useEffect(() => {
    const getOptions = async () => {
      try {
        const response = await getYouTubeVideoList({ maxResults: 1 });
        const videos = response.items;
        const latestDate = new Date(videos[0].snippet.publishedAt);
        const latestYear = latestDate.getFullYear();
        const optionsList = [];

        for (
          let i = latestYear;
          i >= LOKOMOTIV_YOUTUBE_OLDEST_VIDEO_YEAR;
          i--
        ) {
          optionsList.push({
            value: i,
            label: i.toString(),
          });
        }

        setOptions(optionsList);
        setValue(latestYear);
        onSelect(latestYear);
      } catch (error) {
        setOptions([]);
        setValue('');
        onSelect('');
      }
    };

    getOptions();
  }, []);

  const onValueChange = (value: string) => {
    setValue(value);
    onSelect(value);
  };

  function getSelectOptions(
    options: { value: number; label: string }[]
  ): SelectOption[] {
    return options.map((option) => {
      return {
        value: option.value.toString(),
        displayValue: option.value.toString(),
      };
    });
  }

  return value ? (
    <div className={styles.wrapper}>
      <div className={styles.customSelect}>
        <Select
          defaultValue={value.toString()}
          values={getSelectOptions(options)}
          onChange={onValueChange}
        />
      </div>
    </div>
  ) : (
    <></>
  );
};

export default YearVideoFilter;
