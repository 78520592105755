const Colors = {
  WHITE: '#FFFFFF',
  PRIMARY_BLUE: '#00256D',
  PRIMARY_RED: '#C8102E',
  TRANSPARENT: '#00000000',
  LIGHT_GREY: '#E1E1E1',
  GREY_TEXT: '#A3A3A3',
  MAIN_BLUE: '#00256D',
  DARK_GREY: '#5b5b5b',
  STROKE_GREY: '#f1f1f2',
};

export default Colors;
