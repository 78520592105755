import React, {
  useState,
  useRef,
  useMemo,
  CSSProperties,
  useEffect,
} from 'react';
import classNames from 'classnames';
import useOnClickOutside from './useOnClickOutside';
import styles from './Select.module.scss';
import SelectArrow from 'assets/SelectArrow';
import Colors from 'constants/colors';
import Option from './Option/Option';

export interface SelectOption {
  value: string;
  displayValue: string;
}

const Select: React.FC<{
  defaultValue?: string;
  placeholder?: string;
  disabled?: boolean;
  values: SelectOption[];
  style?: CSSProperties;
  className?: string;
  optionStyle?: CSSProperties;
  onChange?: (value: any) => void;
  arrowColor?: string;
  selectArrowTop?: number;
  dropDownStyle?: CSSProperties;
  selectItemsStyle?: CSSProperties;
  value?: string;
}> = ({
  defaultValue,
  disabled,
  values,
  placeholder,
  onChange,
  style,
  optionStyle,
  arrowColor,
  selectArrowTop,
  dropDownStyle,
  selectItemsStyle,
  value,
  className,
}) => {
  const [selectedValue, setSelectedOption] = useState(
    defaultValue || values[0]?.value || ''
  );
  const [showDropdown, setShowDropdown] = useState(false);
  const selectContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (value) {
      setSelectedOption(value);
    }
  }, [value]);

  useOnClickOutside(selectContainerRef, () => setShowDropdown(false));

  const selectedText = useMemo(
    () =>
      values.find((item) => item.value === selectedValue)?.displayValue ||
      placeholder,
    [selectedValue, values, placeholder]
  );

  const clickHandler = (value: string) => {
    setSelectedOption(value);
    onChange && onChange(value);
    setShowDropdown(false);
  };

  return (
    <div
      className={classNames(
        styles.selectContainer,
        {
          [styles.containerShowDropdown]: showDropdown,
        },
        className
      )}
      ref={selectContainerRef}
      style={style}
    >
      <div
        className={styles.selectedText}
        onClick={() => !disabled && setShowDropdown(!showDropdown)}
        style={optionStyle}
      >
        {selectedText}
        <div className={styles.selectArrow} style={{ top: selectArrowTop }}>
          <SelectArrow
            fillColor={arrowColor ?? Colors.GREY_TEXT}
            rotateDegrees={showDropdown ? 180 : 0}
          />
        </div>
      </div>
      <ul
        className={classNames(styles.selectOptions, {
          [styles.showDropdownOptions]: showDropdown,
          [styles.hideDropdownOptions]: !showDropdown,
        })}
        style={dropDownStyle}
      >
        {values.map(({ value, displayValue }) => (
          <Option
            key={value}
            value={value}
            displayValue={displayValue}
            onClick={clickHandler}
            selectItemsStyle={selectItemsStyle}
          />
        ))}
      </ul>
    </div>
  );
};

export default Select;
