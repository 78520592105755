import { ReactElement, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { PlayerWithRelations } from 'types/Types';
import styles from './Biography.module.scss';
import ViewMoreButton from 'components/ViewMoreButton/ViewMoreButton';
import { useTranslation } from 'react-i18next';

const MAX_TEXT_CONTENT_HEIGHT = 800;

type BiographyProps = {
  player: PlayerWithRelations;
};

const Biography = ({ player }: BiographyProps): ReactElement => {
  const { t } = useTranslation();
  const textRef = useRef<HTMLDivElement>(null);
  const [isFullTextVisible, setIsFullTextVisible] = useState<boolean>(true);
  const [isMounted, setIsMounted] = useState<boolean>(false);

  useEffect(() => {
    if (textRef.current && !isMounted) {
      setIsFullTextVisible(
        textRef.current.clientHeight <= MAX_TEXT_CONTENT_HEIGHT
      );
      setIsMounted(true);
    }
  }, []);

  function formatText(details: string) {
    return details
      .replaceAll(
        '<p style="text-align:justify;">',
        `<p style="text-align: justify;" class=${styles.line}>`
      )
      .replaceAll(
        '<p style="text-align:center;">',
        `<p style="text-align: center;" class=${styles.line}>`
      )
      .replaceAll(
        '<p style="text-align:right;">',
        `<p style="text-align: right;" class=${styles.line}>`
      )
      .replaceAll(
        '<p style="text-align:left;">',
        `<p style="text-align: left;" class=${styles.line}>`
      )
      .replaceAll(
        '<figure class="table">',
        `<figure class="table" style="display: flex; align-items: center; justify-content: center;">`
      )
      .replaceAll('<p>', `<p class=${styles.line}>`)
      .replaceAll('<blockquote>', `<blockquote style="font-style: italic;">`)
      .replaceAll(
        '<h1>',
        `<p class=${styles.ellipse}><li class=${styles.li}><span class=${styles.ellipseSpan}>`
      )
      .replaceAll('</h1>', `</li></p></span>`);
  }

  return (
    <>
      {player?.attributes.details ? (
        <div
          ref={textRef}
          className={classNames(styles.text, {
            [styles.collapsed]: !isFullTextVisible,
          })}
          dangerouslySetInnerHTML={{
            __html: formatText(player?.attributes.details) || '',
          }}
        />
      ) : (
        <p>{t('playerPage.tabs.nothingHereYet')}</p>
      )}
      {!isFullTextVisible && (
        <ViewMoreButton
          onClick={() => setIsFullTextVisible(!isFullTextVisible)}
        />
      )}
    </>
  );
};

export default Biography;
