import { ReactElement, useEffect, useMemo, useState } from 'react';
import { PlayoffStandingsReadableDto, StandingsReadableDto } from 'types/Types';
import ShowOn from 'components/core/adaptivity/ShowOn';
import TournamentTree from './TournamentTree/TournamentTree';
import TournamentTable from './TournamentTable/TournamentTable';
import styles from './Tournament.module.scss';

const TournamentType = {
  west: '1',
  east: '2',
  final: '3',
};

type TournamentProps = {
  generalStandings: Array<StandingsReadableDto>;
  playoffStandings: Array<PlayoffStandingsReadableDto>;
};

type PlayoffGameProps = {
  [key: string]: Array<PlayoffStandingsReadableDto>;
};

export type GamesResultProps = {
  teama: number;
  teamb: number;
  teamaWins: number;
  teambWins: number;
  teamaRank: number;
  teambRank: number;
};

function Tournament({
  generalStandings,
  playoffStandings,
}: TournamentProps): ReactElement {
  const [round1West, setRound1West] = useState<Array<GamesResultProps>>([]);
  const [round2West, setRound2West] = useState<Array<GamesResultProps>>([]);
  const [round3West, setRound3West] = useState<Array<GamesResultProps>>([]);
  const [round1East, setRound1East] = useState<Array<GamesResultProps>>([]);
  const [round2East, setRound2East] = useState<Array<GamesResultProps>>([]);
  const [round3East, setRound3East] = useState<Array<GamesResultProps>>([]);
  const [finalResults, setFinalResults] = useState<GamesResultProps[]>([]);

  const shouldShowTable = useMemo(() => {
    const standingsWithConference = generalStandings.filter(
      (item) => item.conference
    );

    return standingsWithConference.length > 0;
  }, [generalStandings]);

  const sortMatches = (result: Array<GamesResultProps>) => {
    return result.sort((a, b) => {
      const lowestARank = Math.min(Number(a.teamaRank), Number(a.teambRank));
      const lowestBRank = Math.min(Number(b.teamaRank), Number(b.teambRank));

      return lowestARank - lowestBRank;
    });
  };

  const sortFirstRound = (
    round1: Array<GamesResultProps>,
    round2: Array<GamesResultProps>,
    setRound1: (value: Array<GamesResultProps>) => void
  ) => {
    if (round2?.length === 2) {
      const round1ResultSorted = [] as Array<GamesResultProps>;
      round2.forEach((pair2) =>
        round1.forEach((pair1) => {
          if (
            pair2.teamaRank === pair1.teamaRank ||
            pair2.teamaRank === pair1.teambRank ||
            pair2.teambRank === pair1.teamaRank ||
            pair2.teambRank === pair1.teambRank
          ) {
            round1ResultSorted.push(pair1);
          }
        })
      );
      setRound1(round1ResultSorted);
    } else {
      setRound1(round1);
    }
  };

  const getGamesResult = (teams: any) => {
    const result: Array<GamesResultProps> = [];
    Object.keys(teams).forEach((pair: string) => {
      const [team1, team2] = pair.split(':');
      let teamaWins = 0;
      let teambWins = 0;

      const matches = teams[pair];
      matches.forEach((match: PlayoffStandingsReadableDto) => {
        if (!match.score) return;
        const [teamaScore, teambScore] = match.score.split(':').map(Number);

        if (match.teama === team1) {
          if (teamaScore > teambScore) {
            teamaWins++;
          } else {
            teambWins++;
          }
        } else {
          if (teamaScore > teambScore) {
            teambWins++;
          } else {
            teamaWins++;
          }
        }
      });

      result.push({
        teamaWins,
        teambWins,
        teama: Number(team1),
        teamb: Number(team2),
        teamaRank:
          generalStandings?.find((team) => team.club_id === team1)
            ?.conference_rank ?? 0,
        teambRank:
          generalStandings?.find((team) => team.club_id === team2)
            ?.conference_rank ?? 0,
      });
    }, {});

    return sortMatches(result);
  };

  const groupByConferenceAndRound = (type: string, round: string) => {
    return playoffStandings
      .filter((game) => game.round === round)
      .reduce((result: PlayoffGameProps, game) => {
        const { teama, teamb } = game;
        const gamePair = `${teama}:${teamb}`;
        const gamePairReversed = `${teamb}:${teama}`;

        if (
          type !== TournamentType.final &&
          generalStandings.find((item) => item.club_id === teama)
            ?.conference !== type
        ) {
          return result;
        }

        if (!result[gamePair] && !result[gamePairReversed]) {
          result[gamePair] = [];
        }

        if (result[gamePairReversed]) {
          result[gamePairReversed].push(game);
        }

        if (result[gamePair]) {
          result[gamePair].push(game);
        }

        return result;
      }, {});
  };

  useEffect(() => {
    const getData = async () => {
      try {
        const round1GamesGroupedWest = groupByConferenceAndRound(
          TournamentType.west,
          '1'
        );
        const round2GamesGroupedWest = groupByConferenceAndRound(
          TournamentType.west,
          '2'
        );
        const round3GamesGroupedWest = groupByConferenceAndRound(
          TournamentType.west,
          '3'
        );
        const round1GamesGroupedEast = groupByConferenceAndRound(
          TournamentType.east,
          '1'
        );
        const round2GamesGroupedEast = groupByConferenceAndRound(
          TournamentType.east,
          '2'
        );
        const round3GamesGroupedEast = groupByConferenceAndRound(
          TournamentType.east,
          '3'
        );
        const roundGamesGroupedFinal = groupByConferenceAndRound(
          TournamentType.final,
          '4'
        );

        sortFirstRound(
          getGamesResult(round1GamesGroupedWest),
          getGamesResult(round2GamesGroupedWest),
          setRound1West
        );
        setRound2West(getGamesResult(round2GamesGroupedWest));
        setRound3West(getGamesResult(round3GamesGroupedWest));

        sortFirstRound(
          getGamesResult(round1GamesGroupedEast),
          getGamesResult(round2GamesGroupedEast),
          setRound1East
        );
        setRound2East(getGamesResult(round2GamesGroupedEast));
        setRound3East(getGamesResult(round3GamesGroupedEast));

        setFinalResults(getGamesResult(roundGamesGroupedFinal));
      } catch (e: any) {
        console.error(e);
      }
    };

    getData();
  }, [generalStandings, playoffStandings]);

  return (
    <>
      {shouldShowTable ? (
        <>
          <ShowOn largeDesktop smallDesktop>
            <TournamentTree
              round1West={round1West}
              round2West={round2West}
              round3West={round3West}
              round1East={round1East}
              round2East={round2East}
              round3East={round3East}
              finalResults={finalResults}
              generalStandings={generalStandings}
            />
          </ShowOn>
          <ShowOn largeTablet smallTablet largeMobile smallMobile>
            <TournamentTable
              round1West={round1West}
              round2West={round2West}
              round3West={round3West}
              round1East={round1East}
              round2East={round2East}
              round3East={round3East}
              finalResults={finalResults}
              generalStandings={generalStandings}
            />
          </ShowOn>
        </>
      ) : (
        <div className={styles.notFound}>Турнирные таблицы не найдены</div>
      )}
    </>
  );
}

export default Tournament;
