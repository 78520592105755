import { Dispatch, ReactElement, SetStateAction, useState } from 'react';
import styles from './TableTh.module.scss';
import classNames from 'classnames';
import { StandingsTypes } from 'types/Types';

type TextAlign = 'left' | 'center' | 'right';

type TableThProps = {
  text: string;
  title: string;
  updateSorting: Function;
  updateSortingParam: string;
  tableBodyRef: React.RefObject<HTMLTableSectionElement>;
  textAlign?: TextAlign;
  paddingLeft?: string;
  isGoalkeeper?: boolean;
  isFirstTh?: boolean;
  index: number;
  hidden?: boolean;
  additionalClasses?: string;
  isWhite?: boolean;
  isDefaultDescSort?: boolean;
  type?: StandingsTypes;
  isProtocolPage?: boolean;
  setIsDefaultSort?: Dispatch<SetStateAction<boolean>>;
};

function StandingsPageTableBody({
  text,
  title,
  updateSorting,
  updateSortingParam,
  textAlign,
  tableBodyRef,
  isGoalkeeper = false,
  isFirstTh = false,
  index,
  additionalClasses,
  hidden = false,
  isWhite = true,
  isProtocolPage = false,
  type = StandingsTypes.number,
  setIsDefaultSort,
}: TableThProps): ReactElement {
  const [activeStyles, setActiveStyles] = useState<boolean>(false);
  const [isArrowUp, setIsArrowUp] = useState<boolean>(true);
  const [count, setCount] = useState<number>(1);

  function setColor(index: number) {
    const table = tableBodyRef.current;
    if (table) {
      const cells = table.querySelectorAll(`td:nth-child(${index})`);
      cells.forEach((cell) => {
        cell?.classList.toggle(styles.highlight);
      });
    }
  }

  function clickHandler() {
    count % 3 !== 2 && setColor(index);
    count % 3 !== 2 && setActiveStyles(!activeStyles);
    count % 3 !== 0 && setIsArrowUp(!isArrowUp);

    if (count % 3 === 0 && isGoalkeeper) {
      if (setIsDefaultSort) {
        setIsDefaultSort(false);
      }
      updateSorting(updateSortingParam, isGoalkeeper, type, true);
    } else {
      if (count % 3 === 2) {
        if (setIsDefaultSort && isGoalkeeper) setIsDefaultSort(true);
      } else {
        setIsDefaultSort && setIsDefaultSort(false);
      }
      updateSorting(updateSortingParam, isGoalkeeper, type, false);
    }
    setCount(count + 1);
  }

  return (
    <th
      hidden={hidden}
      title={title}
      className={classNames(
        activeStyles ? styles.textActive : styles.text,
        additionalClasses,
        {
          [styles.firstTh]: isFirstTh,
          [styles.grey]: !isWhite,
        }
      )}
      style={textAlign ? { textAlign: textAlign } : {}}
      onClick={clickHandler}
    >
      <div className={styles.relative}>
        <div
          className={
            isProtocolPage
              ? styles.blockProtocol
              : activeStyles
              ? isArrowUp
                ? styles.blockActiveArrowUp
                : styles.blockActive
              : styles.block
          }
        ></div>
        <a className={styles.text}>{text}</a>
      </div>
    </th>
  );
}

export default StandingsPageTableBody;
