export default (function () {
  return {
    HCLBackend: {
      baseUrl: `${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_HOST}`,
    },
    HCLShopBackend: {
      baseUrl: `${process.env.REACT_APP_SHOP_PROTOCOL}://${process.env.REACT_APP_SHOP_HOST_API}`,
    },
    HCLFrontend: {
      baseUrl: `${process.env.REACT_APP_THIS_PROTOCOL}://${process.env.REACT_APP_THIS_HOST}`,
    },
    HCLShopFrontend: {
      baseUrl: `${process.env.REACT_APP_SHOP_PROTOCOL}://${process.env.REACT_APP_SHOP_HOST_FRONT}`,
    },
    KhlProtocolUpdateTimer:
      Number(process.env.REACT_APP_KHL_PROTOCOL_UPDATE_TIMER) || 60000,
  };
})();
