import UpperRightArrow from 'assets/UpperRightArrow';
import Colors from 'constants/colors';

interface MobileTableSectionAccordionArrowProps {
  isOpen: boolean;
}

const MobileTableSectionAccordionArrow = ({
  isOpen,
}: MobileTableSectionAccordionArrowProps) => (
  <UpperRightArrow fillColor={Colors.WHITE} rotateDegrees={isOpen ? -90 : 90} />
);

export default MobileTableSectionAccordionArrow;
