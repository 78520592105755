import classNames from 'classnames';
import { ReactElement } from 'react';
import ShowOn from 'components/core/adaptivity/ShowOn';
import styles from './PageHeader.module.scss';

interface PageHeaderProps {
  text: string;
  paddingLeft?: number;
  paddingBottom?: number;
}

export default function PageHeader({
  text: str,
  paddingLeft = 0,
  paddingBottom = 50,
}: PageHeaderProps): ReactElement {
  const renderTitle = (isSmall: boolean = false) => {
    return (
      <h1
        className={classNames(styles.pageHeader, {
          [styles.pageHeaderSmall]: isSmall,
        })}
        style={{
          paddingLeft: `${paddingLeft}px`,
          paddingBottom: `${paddingBottom}px`,
        }}
      >
        {str}
      </h1>
    );
  };

  return (
    <>
      <ShowOn largeDesktop smallDesktop>
        {renderTitle()}
      </ShowOn>
      <ShowOn largeTablet smallTablet largeMobile smallMobile>
        {renderTitle(true)}
      </ShowOn>
    </>
  );
}
