import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { AwayMatchesPageDto } from '../../../types/Types';
import { getAwayMatchesData } from '../../../api/api';
import ImageBgPageWrapper from '../../common/ImageBgPageWrapper/ImageBgPageWrapper';
import Container from '../../common/Container/Container';
import SideMenu from '../../common/SideMenu/SideMenu';
import RegistrationForm from './RegistrationForm/RegistrationForm';
import styles from './AwayMatchesPage.module.scss';
import Image from 'model/entity/loko/type/Image';
import ShowOn from 'components/core/adaptivity/ShowOn';
import MediaSlider from 'components/StaticPage/MediaSlider/MediaSlider';
import PageHeader from 'components/common/PageHeader/PageHeader';
import BreadcrumbsWrapper from 'components/common/BreadcrumbsWrapper/BreadcrumbsWrapper';
import { useTranslation } from 'react-i18next';

export type AwayMatchesSlideProps = {
  url: string;
  title: string;
  isVideo: boolean;
  isYoutube: boolean;
};

const AwayMatchesPage = () => {
  const { t } = useTranslation();
  const [data, setData] = useState<AwayMatchesPageDto>();
  const [media, setMedia] = useState<AwayMatchesSlideProps[] | null>(null);
  const [isRegistrationFormVisible, setIsRegistrationFormVisible] =
    useState<boolean>(false);

  useEffect(() => {
    const getData = async () => {
      const response = await getAwayMatchesData();

      setData(response.data);

      const slider = response.data.attributes?.slider ?? null;
      if (!slider) {
        return;
      }

      const mediaSlides =
        slider.media?.data?.map((item: Image) => ({
          url: item.attributes.url ?? '',
          isVideo: item.attributes.ext === '.mp4',
          title: item.attributes.name ?? '',
          isYoutube: false,
        })) ?? [];
      if (slider.youtube_urls?.length) {
        slider.youtube_urls.map((item: { url: string }) =>
          mediaSlides.push({
            url: item.url.replace('watch?v=', 'embed/'),
            isVideo: true,
            title: '',
            isYoutube: true,
          })
        );
      }

      setMedia(mediaSlides);
    };

    getData();
  }, []);

  const render = () => (
    <Container paddingTop='50px'>
      <div className={styles.wrapper}>
        {data && (
          <>
            <BreadcrumbsWrapper
              breadcrumbs={[
                {
                  name: data?.attributes.title ?? '',
                  url: '/away-matches',
                },
              ]}
            />
            <div className={styles.headerWrapper}>
              <PageHeader text={data?.attributes.title} paddingBottom={50} />
            </div>
            <div className={styles.contentWrapper}>
              <div
                className={classNames(styles.content, {
                  [styles.hideAd]: data?.attributes.hide_ad,
                })}
              >
                <div
                  className={styles.text}
                  dangerouslySetInnerHTML={{
                    __html: data.attributes.text || '',
                  }}
                />
                <button
                  className={styles.button}
                  onClick={() => setIsRegistrationFormVisible(true)}
                >
                  {data.attributes.signup_button_text ??
                    t('awayMatchesPage.signUpForGame')}
                </button>
                {media?.length ? (
                  <>
                    <h2 className={styles.subTitle}>
                      {t('awayMatchesPage.photoGallery')}
                    </h2>
                    <ShowOn
                      largeDesktop
                      smallDesktop
                      largeTablet
                      smallTablet
                      largeMobile
                    >
                      <MediaSlider media={media} width='100%' height='500px' />
                    </ShowOn>
                    <ShowOn smallMobile>
                      <MediaSlider media={media} width='100%' height='400px' />
                    </ShowOn>
                  </>
                ) : null}
              </div>
              {!data?.attributes.hide_ad && (
                <div className={styles.sideMenu}>
                  <SideMenu />
                </div>
              )}
            </div>
          </>
        )}
      </div>
    </Container>
  );

  return (
    <ImageBgPageWrapper>
      {render()}
      <RegistrationForm
        isVisible={isRegistrationFormVisible}
        close={() => setIsRegistrationFormVisible(false)}
      />
    </ImageBgPageWrapper>
  );
};

export default AwayMatchesPage;
