import { ReactElement, useState } from 'react';

interface VkIconTransparentSmallProps {
  fillColor: string;
  alterColor?: string;
}

export default function VkIconTransparentSmall({
  fillColor,
  alterColor,
}: VkIconTransparentSmallProps): ReactElement {
  const [color, setColor] = useState(fillColor);

  return (
    <svg
      width='16'
      height='16'
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      onMouseEnter={() => setColor(alterColor ? alterColor : fillColor)}
      onMouseLeave={() => setColor(fillColor)}
    >
      <g id='icons'>
        <path
          id='Vector'
          d='M8.71183 12.9906C3.24695 12.9906 0.129878 9.24415 0 3H2.73743C2.82735 7.57571 4.84545 9.49391 6.44396 9.91352V3.00999H9.02154V6.95629C10.6001 6.78645 12.2585 4.98814 12.818 3.00999H15.3906C15.1835 4.03866 14.7659 5.0134 14.1642 5.87303C13.5624 6.73266 12.7895 7.45861 11.8938 8.00531C12.8924 8.50056 13.7746 9.20182 14.4823 10.0628C15.1901 10.9239 15.7074 11.9251 16 13.0006H13.1577C12.8964 12.0647 12.3645 11.2268 11.6286 10.5923C10.8927 9.9577 9.98571 9.55473 9.02154 9.43397V13.0006L8.71183 12.9906Z'
          fill={color}
        />
      </g>
    </svg>
  );
}
