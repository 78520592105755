import Row from 'components/core/layout/Row';
import styles from './HeaderLinks.module.scss';
import { FC } from 'react';
import Colors from 'constants/colors';
import Url from 'constants/urls';

const Divider = ({ color }: { color: string }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='2'
      height='10'
      viewBox='0 0 2 10'
      fill='none'
    >
      <path opacity='0.6' d='M0.75 0V10' stroke={color} strokeOpacity='0.5' />
    </svg>
  );
};

type HeaderLinksProps = {
  isDesktop?: boolean;
};

const HeaderLinks: FC<HeaderLinksProps> = ({ isDesktop }: HeaderLinksProps) => {
  const color = isDesktop ? Colors.WHITE : Colors.PRIMARY_RED;
  return (
    <div className={styles.drawerLinks}>
      <Row
        alignItems='center'
        justifyContent={isDesktop ? 'center' : 'space-evenly'}
        gap={isDesktop ? '40px' : undefined}
        height={isDesktop ? '30px' : undefined}
      >
        <a
          href={Url.loko.LOKO}
          style={{ color: color }}
          target='_blank'
          rel='noreferrer'
        >
          <span>ЛОКО</span>
        </a>
        <Divider color={color} />
        <a
          href={Url.loko.LOKO76}
          style={{ color: color }}
          target='_blank'
          rel='noreferrer'
        >
          <span>ЛОКО – 76</span>
        </a>
        <Divider color={color} />
        <a
          href={Url.loko.ACADEMY}
          style={{ color: color }}
          target='_blank'
          rel='noreferrer'
        >
          <span>АКАДЕМИЯ</span>
        </a>
      </Row>
    </div>
  );
};

export default HeaderLinks;
