import { ReactElement } from 'react';
import { DEFAULT_PLAYER_IMAGE_NAME } from 'constants/constants';
import styles from './PlayerCardInfo.module.scss';
import ShowOn from 'components/core/adaptivity/ShowOn';
import StatisticInfo from '../StatisticInfo/StatisticInfo';
import { DefaultImageObject, PlayerWithRelations, Roster } from 'types/Types';
import ImageLoader from '../../common/ImageLoaders/ImageLoader/ImageLoader';
import StatisticInfoMobile from '../StatisticInfoMobile/StatisticInfoMobile';

type PlayerCardImageProps = {
  player: PlayerWithRelations;
  images: DefaultImageObject[];
  playerRoster: Roster;
};

function PlayerCardInfo({
  player,
  images,
  playerRoster,
}: PlayerCardImageProps): ReactElement {
  const defaultImage =
    images?.find((x) => x.attributes.description === DEFAULT_PLAYER_IMAGE_NAME)
      ?.attributes?.image?.data || '';
  return (
    <div className={styles.playerCardInfo}>
      <ShowOn largeDesktop smallDesktop largeTablet smallTablet>
        <div className={styles.photoWrapper}>
          <ImageLoader
            className={styles.photo}
            image={player?.attributes?.photo?.data || defaultImage}
            alt={player.attributes.name}
          />
        </div>
      </ShowOn>
      <ShowOn largeDesktop smallDesktop largeTablet smallTablet>
        <StatisticInfo player={player} playerRoster={playerRoster} />
      </ShowOn>
      <ShowOn largeMobile smallMobile>
        <StatisticInfoMobile player={player} playerRoster={playerRoster} />
      </ShowOn>
    </div>
  );
}

export default PlayerCardInfo;
