import styles from './Calendar.module.scss';
import Day from '../Day/Day';
import { ScheduleDay } from 'types/Types';
import ShowOn from 'components/core/adaptivity/ShowOn';
import DayMobile from '../Day/DayMobile';
import { observer } from 'mobx-react-lite';
import { useStore } from 'store/store';
import { useCallback } from 'react';

interface CalendarProps {
  schedulePreviuous: Array<ScheduleDay>;
  schedule: Array<ScheduleDay>;
  scheduleNext: Array<ScheduleDay>;
}

const Calendar = observer(
  ({ schedulePreviuous, schedule, scheduleNext }: CalendarProps) => {
    const { lokomotivTeamsStore } = useStore();

    const renderDay = useCallback(
      (day: ScheduleDay, thisMonth: boolean) => {
        const type =
          day.game && day.game?.attributes.date
            ? lokomotivTeamsStore.lokomotivTeamsIds.includes(
                day.game.attributes.team_1.data.attributes.id_league_web
              )
              ? 'isHome'
              : 'isAway'
            : thisMonth
            ? 'default'
            : 'notCurrent';

        return (
          <div key={day.game?.id || day.day.toDateString()}>
            <ShowOn largeDesktop smallDesktop>
              <Day slickDay={day} isCalendarPage={true} type={type} />
            </ShowOn>
            <ShowOn smallMobile smallTablet largeMobile largeTablet>
              <DayMobile slickDay={day} isCalendarPage={true} type={type} />
            </ShowOn>
          </div>
        );
      },
      [lokomotivTeamsStore.lokomotivTeamsIds]
    );

    return (
      <>
        <div className={styles.schedule}>
          {schedulePreviuous.map((day) => renderDay(day, false))}
          {schedule.map((day) => renderDay(day, true))}
          {scheduleNext.map((day) => renderDay(day, false))}
        </div>
      </>
    );
  }
);

export default Calendar;
