import { makeAutoObservable } from 'mobx';
import { Background } from '../../types/Types';
import { getAllPageBackgrounds } from '../../api/api';
import BackgroundInfo from './BackgroundInfo';

class BackgroundStore {
  constructor() {
    makeAutoObservable(this);
    this.backgrounds = new Map<string, BackgroundInfo>();
  }

  private backgrounds: Map<string, BackgroundInfo> = new Map();

  public getBackgroundByUrl(url: string): BackgroundInfo {
    return (
      this.backgrounds.get(url) ?? {
        img_url: undefined,
        gradient_height: undefined,
        image_height: undefined,
        image: undefined,
      }
    );
  }

  public loadBackgrounds = async (): Promise<void> => {
    try {
      const { data } = await getAllPageBackgrounds();

      data.forEach((background: Background) => {
        this.backgrounds.set(background.attributes.url, {
          img_url:
            background?.attributes?.image?.data?.attributes?.url ??
            background?.attributes?.image?.data?.attributes?.formats?.large
              ?.url,
          image: background?.attributes?.image?.data,
          gradient_height: background.attributes.gradient_height,
          image_height: background.attributes.image_height,
        });
      });
    } catch (e: any) {
      console.error(e?.response?.data?.error || e?.message);
    }
  };
}

export default BackgroundStore;
