import Strapi from 'strapi-sdk-js';
import BaseStrapiDAO from '../base/BaseStrapiDAO';
import ArticleCategory from 'model/entity/loko/type/ArticleCategory';

export default class ArticleCategoryDAO extends BaseStrapiDAO<ArticleCategory> {
  constructor(client: Strapi) {
    super({
      client: client,
      tableNameSingular: 'article-category',
      tableNamePlural: 'article-categories',
    });
  }
}
