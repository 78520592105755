import { ReactElement } from 'react';
import { usePDF } from 'react-to-pdf';
import { StandingsReadableDto } from 'types/Types';
import styles from './StandingPage.module.scss';
import { conferenceEast, conferenceWest } from 'constants/constants';

type StandingsPDFProps = {
  standings: StandingsReadableDto[];
};

function renderStandingsThead() {
  return (
    <thead>
      <tr>
        <th>#</th>
        <th className={styles.team}>Команда</th>
        <th>И</th>
        <th>В</th>
        <th>ВО</th>
        <th>ВБ</th>
        <th>ПБ</th>
        <th>ПО</th>
        <th>П</th>
        <th>Ш</th>
        <th>О</th>
        <th>%О</th>
      </tr>
    </thead>
  );
}

function renderTr(team: StandingsReadableDto, rank: number) {
  return (
    <tr>
      <td>{rank}</td>
      <td>{team.name}</td>
      <td>{team.games_played}</td>
      <td>{team.wins}</td>
      <td>{team.overtime_wins}</td>
      <td>{team.shootouts_wins}</td>
      <td>{team.shootouts_losses}</td>
      <td>{team.overtime_losses}</td>
      <td>{team.losses}</td>
      <td>
        {team.goals_for}:{team.goals_against}
      </td>
      <td>{team.points}</td>
      <td>{team.points_percent}</td>
    </tr>
  );
}

function StandingPagePDF({ standings }: StandingsPDFProps): ReactElement {
  const { toPDF, targetRef } = usePDF({ filename: 'standings.pdf' });
  return (
    <>
      <div ref={targetRef}>
        <div>Конференции</div>
        <div>{conferenceWest}</div>
        <table className='table w-full'>
          {renderStandingsThead()}
          <tbody>
            {standings.map((team) => {
              return team.conference_name === conferenceWest
                ? renderTr(team, team.conference_rank)
                : null;
            })}
          </tbody>
        </table>

        <div>{conferenceEast}</div>
        <table className='table w-full'>
          {renderStandingsThead()}
          <tbody>
            {standings.map((team) => {
              return team.conference_name === conferenceEast
                ? renderTr(team, team.conference_rank)
                : null;
            })}
          </tbody>
        </table>
      </div>
      <div className={styles.buttonDownloadWrapper}>
        <button onClick={() => toPDF()} className={styles.buttonDownload}>
          Скачать PDF
        </button>
      </div>
    </>
  );
}

export default StandingPagePDF;
