import styles from './VideoPlayer.module.scss';
import { ReactElement, useEffect, useRef, useState } from 'react';
import YouTube from 'react-youtube';

type VideoPlayerProps = {
  selectedVideo: string | undefined;
};

function VideoPlayer({ selectedVideo }: VideoPlayerProps): ReactElement {
  const playerWrapperRef = useRef<any>(null);
  const [minHeight, setMinHeight] = useState(0);

  const updateMinHeight = () => {
    if (playerWrapperRef.current) {
      const currentWidth = playerWrapperRef.current.offsetWidth;
      const calculatedMinHeight = currentWidth * 0.6;

      setMinHeight(calculatedMinHeight);
    }
  };

  useEffect(() => {
    updateMinHeight();

    window.addEventListener('resize', updateMinHeight);

    return () => {
      window.removeEventListener('resize', updateMinHeight);
    };
  }, []);

  return (
    <div
      className={styles.wrapper}
      ref={playerWrapperRef}
      style={{ minHeight: minHeight }}
    >
      <YouTube
        key={selectedVideo}
        className={styles.player}
        videoId={selectedVideo}
        opts={{ height: '100%', width: '100%' }}
      />
    </div>
  );
}

export default VideoPlayer;
