import { Link } from 'react-router-dom';
import styles from './Flag.module.scss';
import { ReactElement, useState } from 'react';

export type AchievementType = {
  title: string;
  period: string;
  page: number;
};

function Flag({ title, period, page }: AchievementType): ReactElement {
  const [isHover, setIsHover] = useState<boolean>(false);

  return (
    <Link to={page ? `/static-page/${page}` : {}}>
      <div className={styles.wrapper}>
        <div
          className={isHover ? styles.hoverFlag : styles.flag}
          onMouseEnter={() => setIsHover(true)}
          onMouseLeave={() => setIsHover(false)}
        >
          <div className={styles.innerWrapper}>
            <span className={styles.period}>{period}</span>
            <span className={styles.title}>{title}</span>
          </div>
        </div>
      </div>
    </Link>
  );
}

export default Flag;
