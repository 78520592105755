import { ReactElement } from 'react';
import classNames from 'classnames';
import { getMatchDateInfo } from '../../../helpers/helpers';
import { GameWithLogo } from '../../../types/Types';
import ScoreBox from '../../common/ScoreBox/ScoreBox';
import Team from '../Team/Team';
import { ReactComponent as Ticket } from '../../../assets/ticket_white.svg';
import styles from './GameWidget.module.scss';
import { Link } from 'react-router-dom';
import { LOKOMOTIV_CODE } from '../../../constants/constants';
import Url from 'constants/urls';
import ProtocolResponsive from 'assets/ProtocolResponsive';
import Colors from 'constants/colors';
import { useTranslation } from 'react-i18next';

type GameWidgetProps = {
  game: GameWithLogo;
  isGameInFuture: boolean;
};

function GameWidgetHP({ game, isGameInFuture }: GameWidgetProps): ReactElement {
  const { t } = useTranslation();
  const windowInnerWidth = document.documentElement.clientWidth;
  const isSmallMobile = windowInnerWidth <= 360;
  function getDate() {
    return `${getMatchDateInfo(game?.attributes?.date || '', true)}`;
  }

  const team1Code = game?.attributes.team_1?.data?.attributes?.code;

  const isHighlightsDisabled = () => {
    const seasonCode = game.attributes.season?.data?.attributes?.code;

    if (
      seasonCode &&
      (seasonCode.includes('LKO') || seasonCode.includes('L76'))
    ) {
      return !(
        game.attributes.article?.data && game.attributes?.highlights?.length
      );
    } else {
      return !game.attributes.article?.data;
    }
  };

  return (
    <div
      className={
        game.attributes.is_current
          ? classNames(styles.widgetWrapper, styles.widgetWrapperToday)
          : styles.widgetWrapper
      }
    >
      {game.attributes.is_current ? (
        <div
          className={classNames(styles.headerMatch, styles.headerMatchToday)}
        >
          {t('homepage.gameWidget.currentMatch')}
        </div>
      ) : isGameInFuture ? (
        <div className={styles.headerMatch}>
          {t('homepage.gameWidget.nextMatch')}
        </div>
      ) : (
        <div className={styles.headerMatch}>
          {t('homepage.gameWidget.prevMatch')}
        </div>
      )}
      <div
        className={
          game?.attributes?.is_current
            ? styles.headerDateCurrent
            : styles.headerDate
        }
      >
        {getDate()}
      </div>
      <div className={styles.widgetTeams}>
        <Team
          name={game.attributes?.team_1?.data?.attributes.name}
          city={game?.attributes?.team_1.data.attributes.city}
          isCurrent={game?.attributes?.is_current}
        >
          <img
            src={
              game.attributes?.team_1?.data?.attributes.logo?.data?.attributes
                .url
            }
            className={styles.logo}
            width={'70px'}
            height={'70px'}
          />
        </Team>
        <ScoreBox
          game={game}
          isGameInFuture={isGameInFuture}
          date={game?.attributes?.date}
          isCurrent={game?.attributes?.is_current}
          isHoverGame={false}
        />
        <Team
          name={game.attributes?.team_2?.data?.attributes.name}
          city={game?.attributes?.team_2.data.attributes.city}
          isCurrent={game?.attributes?.is_current}
        >
          <img
            src={
              game.attributes?.team_2?.data?.attributes.logo?.data?.attributes
                .url
            }
            className={styles.logo}
            width={'70px'}
            height={'70px'}
          />
        </Team>
      </div>
      {game.attributes.is_current ? (
        <div className={styles.container}>
          <Link
            to={`/protocol?gameId=${game.id}&live=true`}
            className={classNames(
              styles.widgetBtn,
              styles.widgetBtnWrapper,
              styles.alignBtn
            )}
          >
            <span className={styles.widgetBtnWatch}>
              {t('homepage.gameWidget.watch')}
            </span>
          </Link>
        </div>
      ) : isGameInFuture ? (
        <div className={styles.container}>
          {team1Code === LOKOMOTIV_CODE ? (
            <a
              className={styles.widgetBtn}
              href={game.attributes.tickets_link ?? Url.loko.TICKETS}
              target='_blank'
            >
              <div
                className={classNames(styles.widgetBtnWrapper, styles.alignBtn)}
              >
                <Ticket />
                <span className={styles.widgetBtnBuy}>
                  {t('homepage.gameWidget.buy')}
                </span>
              </div>
            </a>
          ) : null}
        </div>
      ) : (
        <div className={styles.wrapperBtnLastGame}>
          {game.attributes.article && (
            <Link
              className={classNames(styles.widgetBtn, styles.btnLastGame)}
              to={`/protocol?gameId=${game.id}&report=true`}
            >
              <div
                className={classNames(
                  styles.widgetBtnWrapper,
                  styles.alignBtnProtocol,
                  { [styles.disabled]: isHighlightsDisabled() }
                )}
              >
                <span className={styles.widgetBtnWatch}>
                  {t('homepage.gameWidget.highlights')}
                </span>
              </div>
            </Link>
          )}
          <Link
            className={classNames(
              styles.widgetBtn,
              styles.btnLastGame,
              styles.btnProtocol
            )}
            to={`/protocol?gameId=${game.id}`}
          >
            <div
              className={classNames(
                styles.widgetBtnWrapper,
                styles.alignBtnProtocol
              )}
            >
              <ProtocolResponsive
                fillColor={Colors.DARK_GREY}
                height={isSmallMobile ? '15.5' : '20'}
                width={isSmallMobile ? '15.5' : '20'}
              />
              <span className={styles.widgetBtnNext}>
                {t('homepage.gameWidget.protocol')}
              </span>
            </div>
          </Link>
        </div>
      )}
    </div>
  );
}

export default GameWidgetHP;
