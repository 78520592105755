import { ReactElement, RefObject, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import {
  CommonVideo,
  DefaultImageObject,
  File,
  PlayerInfoType,
  PlayerWithRelations,
  YoutubeRequestOptions,
} from '../../../types/Types';
import PlayerPhoto from '../PlayerPhoto/PlayerPhoto';
import styles from './PlayerTabsWithInfo.module.scss';
import PlayerStatistic from '../PlayerStatistic/PlayerStatistic';
import TopStory from '../../TopStory/TopStory';
import ViewMoreButton from '../../ViewMoreButton/ViewMoreButton';
import {
  getDefaultPlayerImage,
  getPaginationPhotos,
  getPlayerArticles,
  getVideos,
  getYouTubeVideosByTag,
} from '../../../api/api';
import Biography from './Biography/Biography';
import ShowOn from 'components/core/adaptivity/ShowOn';
import PlayerCarrierStatistic from '../PlayerCarrierStatistic/PlayerCarrierStatistic';
import { useTranslation } from 'react-i18next';
import { Breakpoints } from 'constants/adaptivity';
import { useMediaQuery } from 'react-responsive';
import PlayerVideo from '../PlayerVideo/PlayerVideo';
import { DEFAULT_IMAGE_NAME } from 'constants/constants';
import VideoModal from 'components/VideoModal/VideoModal';
import Spinner from 'components/Spinner/Spinner';

type PlayerTabsWithInfoProps = {
  player: PlayerWithRelations;
  openAlbum: Function;
};

const PlayerTabsWithInfo = ({
  player,
  openAlbum,
}: PlayerTabsWithInfoProps): ReactElement => {
  const { t } = useTranslation();
  const [activeMediaTab, setActiveMediaTab] = useState(0);
  const lokoStatsRef = useRef<HTMLDivElement>(null);
  const carrierStatsRef = useRef<HTMLDivElement>(null);
  const articlesRef = useRef<HTMLDivElement>(null);
  const photoRef = useRef<HTMLDivElement>(null);
  const aboutRef = useRef<HTMLDivElement>(null);
  const [activeTab, setActiveTab] =
    useState<RefObject<HTMLDivElement>>(aboutRef);
  const isSmallDesktop = useMediaQuery({
    maxWidth: Breakpoints.smallDesktop,
  });
  const ALBUM_PAGE_AMOUNT = isSmallDesktop ? 8 : 9;
  const NEWS_PAGE_AMOUNT = 9;
  const [pageCount, setPageCount] = useState<number>(ALBUM_PAGE_AMOUNT);
  const [topPhotos, setTopPhotos] = useState<File[]>([]);
  const [isLastFetchPhotos, setIsLastFetchPhotos] = useState(false);
  const [defaultPhoto, setDefaultPhoto] = useState<DefaultImageObject>();

  const [videoCount, setVideoCount] = useState<number>(ALBUM_PAGE_AMOUNT);
  const [strapiVideos, setStrapiVideos] = useState<File[]>([]);
  const [youtubeVideos, setyoutubeVideos] = useState<any[]>([]);
  const [videosToDisplay, setVideosToDisplay] = useState<
    { isYoutube: boolean; videoData: CommonVideo }[]
  >([]);
  const [youtubeNextPageToken, setYoutubeNextPageToken] = useState('');
  const [selectedVideo, setSelectedVideo] = useState<
    { isYoutube: boolean; source: string } | undefined
  >();

  const [newsCount, setNewsCount] = useState<number>(NEWS_PAGE_AMOUNT);
  const [topArticles, setTopArticles] = useState([]);
  const [isLastFetchArticles, setIsLastFetchArticles] = useState(false);
  const [totalArticlesCount, setTotalArticlesCount] = useState(0);
  const [isLoadingPhotos, setIsLoadingPhotos] = useState(true);

  useEffect(() => {
    const getData = async () => {
      try {
        const photo = await getDefaultPlayerImage(DEFAULT_IMAGE_NAME);
        setDefaultPhoto(photo.data[0]);
      } catch (e: any) {
        console.error(e);
      }
    };
    getData();
  }, []);

  useEffect(() => {
    setIsLoadingPhotos(true);
    const getTopPhotos = async () => {
      try {
        if (player) {
          const photos = await getPaginationPhotos(
            `${player?.attributes?.name} ${player?.attributes?.surname}`,
            pageCount
          );
          setTopPhotos(photos);
          const checkFetch = photos;
          if (checkFetch.length === topPhotos.length)
            setIsLastFetchPhotos(true);
        }
      } catch (error: any) {
        console.error(error?.response?.data?.error || error?.message);
      } finally {
        setIsLoadingPhotos(false);
      }
    };
    getTopPhotos();
  }, [player, pageCount]);

  useEffect(() => {
    const getStrapiVideos = async () => {
      try {
        if (player) {
          const videos = await getVideos(
            `${player?.attributes?.name} ${player?.attributes?.surname}`,
            -1
          );
          setStrapiVideos(videos);
        }
      } catch (error: any) {
        console.error(error?.response?.data?.error || error?.message);
      }
    };
    getStrapiVideos();
  }, [player]);

  useEffect(() => {
    if (activeMediaTab === 1) {
      getyoutubeVideos();
    }
  }, [player, activeMediaTab]);

  const getyoutubeVideos = async () => {
    if (youtubeVideos.length && !youtubeNextPageToken) {
      return;
    }

    try {
      const options: YoutubeRequestOptions = {
        maxResults: ALBUM_PAGE_AMOUNT,
      };

      if (youtubeNextPageToken) {
        options.pageToken = youtubeNextPageToken;
      }

      const result = await getYouTubeVideosByTag(
        player?.attributes?.surname,
        options
      );

      if (result.nextPageToken) {
        setYoutubeNextPageToken(result.nextPageToken);
      }

      if (result.items && result.items.length) {
        setyoutubeVideos([...youtubeVideos, ...result.items]);
      }
    } catch (error: any) {
      console.error(error?.response?.data?.error || error?.message);
    }
  };

  useEffect(() => {
    const topStrapiVideos = strapiVideos.slice(0, videoCount).map((video) => {
      return {
        isYoutube: false,
        videoData: {
          id: video.id.toString(),
          url: video.url,
          title: video.name
            .replace(/&quot;|&#039;/g, '"')
            .replace(/&#39;/g, "'"),
          date: video.createdAt,
          previewUrl: video.previewUrl,
        },
      };
    });
    let topyoutubeVideos: { isYoutube: boolean; videoData: CommonVideo }[] = [];

    if (topStrapiVideos.length < videoCount) {
      topyoutubeVideos = youtubeVideos
        .slice(0, videoCount - topStrapiVideos.length)
        .map((video) => {
          return {
            isYoutube: true,
            videoData: {
              id: video.id.videoId,
              url: '',
              title: video.snippet.title
                .replace(/&quot;|&#039;/g, '"')
                .replace(/&#39;/g, "'"),
              date: video.snippet.publishedAt,
              previewUrl: video.snippet.thumbnails.high.url,
            },
          };
        });
    }

    setVideosToDisplay([...topStrapiVideos, ...topyoutubeVideos]);
  }, [videoCount, strapiVideos, youtubeVideos]);

  useEffect(() => {
    if (activeMediaTab == 1) {
      if (videosToDisplay.length < videoCount && youtubeNextPageToken) {
        getyoutubeVideos();
      }
    }
  }, [activeMediaTab, videosToDisplay]);

  useEffect(() => {
    const getArticles = async () => {
      try {
        if (player) {
          const articles = await getPlayerArticles(
            `${player?.attributes?.name} ${player?.attributes?.surname}`,
            newsCount,
            0
          );
          setTopArticles(articles.data);
          setTotalArticlesCount(articles.meta.pagination.total);
          const checkFetch = articles.data;
          if (checkFetch.length === articles.data.length)
            setIsLastFetchArticles(true);
        }
      } catch (error: any) {
        console.error(error?.response?.data?.error || error?.message);
      }
    };
    getArticles();
  }, [player, newsCount]);

  function changeActiveTab(ref: RefObject<HTMLDivElement>) {
    const currentElement = activeTab.current;
    if (currentElement?.className.includes(styles.textShow)) {
      currentElement?.classList.remove(styles.textShow);
    }
    const element = ref.current;
    if (!element?.className.includes(styles.textShow)) {
      element?.classList.add(styles.textShow);
    }
    setActiveTab(ref);
  }

  const handleViewMoreButtonClick = (param: string) => {
    if (param === PlayerInfoType.PHOTOS) {
      setPageCount(pageCount + ALBUM_PAGE_AMOUNT);
    } else if (param === PlayerInfoType.VIDEOS) {
      setVideoCount(videoCount + ALBUM_PAGE_AMOUNT);
    }

    setNewsCount(newsCount + NEWS_PAGE_AMOUNT);
  };

  const onVideoSelect = (source: string, isYoutube: boolean) => {
    setSelectedVideo({
      source,
      isYoutube,
    });
  };

  const onClose = () => {
    setSelectedVideo(undefined);
  };

  return (
    <>
      <div className={styles.tabBar} id='tab-bar'>
        <a
          onClick={() => changeActiveTab(aboutRef)}
          className={activeTab === aboutRef ? styles.active : ''}
          data-tab='about-player'
        >
          <span
            className={activeTab === aboutRef ? styles.active : styles.span}
          >
            {t('playerPage.tabs.biography')}
          </span>
        </a>
        <a
          onClick={() => changeActiveTab(lokoStatsRef)}
          className={activeTab === lokoStatsRef ? styles.active : ''}
          data-tab='carrier-stats'
        >
          <span
            className={activeTab === lokoStatsRef ? styles.active : styles.span}
          >
            {t('playerPage.tabs.stats')}
          </span>
        </a>
        {player.attributes.statistic &&
          player.attributes.statistic.length > 0 && (
            <a
              onClick={() => changeActiveTab(carrierStatsRef)}
              className={activeTab === carrierStatsRef ? styles.active : ''}
              data-tab='carrier-stats'
            >
              <span
                className={
                  activeTab === carrierStatsRef ? styles.active : styles.span
                }
              >
                {t('playerPage.tabs.carrierStats')}
              </span>
            </a>
          )}
        <a
          onClick={() => changeActiveTab(photoRef)}
          className={activeTab === photoRef ? styles.active : ''}
          data-tab='photo-gallery'
        >
          <span
            className={activeTab === photoRef ? styles.active : styles.span}
          >
            {t('playerPage.tabs.photoAndVideo')}
          </span>
        </a>
        <a
          onClick={() => changeActiveTab(articlesRef)}
          className={activeTab === articlesRef ? styles.active : ''}
          data-tab='articles'
        >
          <span
            className={activeTab === articlesRef ? styles.active : styles.span}
          >
            {t('playerPage.tabs.news')}
          </span>
        </a>
      </div>
      <div className={styles.articleText}>
        <div
          ref={aboutRef}
          className={classNames(styles.text, styles.textShow)}
          id='about-player'
        >
          <Biography player={player} />
        </div>

        <div ref={lokoStatsRef} className={styles.text} id='carrier-stats'>
          <PlayerStatistic player={player} />
        </div>

        <div ref={carrierStatsRef} className={styles.text} id='carrier-stats'>
          <PlayerCarrierStatistic player={player} />
        </div>

        <div ref={articlesRef} className={styles.text} id='articles'>
          {topArticles.length !== 0 ? (
            <>
              <ShowOn largeDesktop smallDesktop largeTablet smallTablet>
                <TopStory
                  articles={topArticles}
                  startIndex={0}
                  count={newsCount}
                  isGridStyle
                  useImage
                />
              </ShowOn>
              <ShowOn largeMobile smallMobile>
                <TopStory
                  articles={topArticles}
                  startIndex={0}
                  count={newsCount}
                  isColumnStyle
                  useImage
                />
              </ShowOn>
              {totalArticlesCount < newsCount ? (
                <></>
              ) : (
                <ViewMoreButton
                  onClick={() => {
                    handleViewMoreButtonClick(PlayerInfoType.NEWS);
                  }}
                />
              )}
            </>
          ) : (
            <p>{t('playerPage.tabs.nothingHereYet')}</p>
          )}
        </div>

        <div ref={photoRef} className={styles.text} id='photo-gallery'>
          <div>
            <div className={styles.mediaTabs}>
              <button
                onClick={() => setActiveMediaTab(0)}
                className={classNames(styles.mediaTab, {
                  [styles.mediaTabActive]: activeMediaTab === 0,
                })}
              >
                {t('playerPage.tabs.photo')}
              </button>
              <button
                onClick={() => setActiveMediaTab(1)}
                className={classNames(styles.mediaTab, {
                  [styles.mediaTabActive]: activeMediaTab === 1,
                })}
              >
                {t('playerPage.tabs.video')}
              </button>
            </div>
            <div>
              {activeMediaTab === 0 ? (
                <>
                  <div className={styles.photosGrid}>
                    {topPhotos.length !== 0
                      ? topPhotos.map((image, index) => (
                          <PlayerPhoto
                            image={image}
                            openAlbum={openAlbum}
                            index={index}
                            key={image.id}
                          />
                        ))
                      : !isLoadingPhotos && (
                          <div className={styles.noPhotos}>
                            {t('playerPage.tabs.nothingHereYet')}
                          </div>
                        )}
                  </div>

                  {isLoadingPhotos ? (
                    <Spinner />
                  ) : topPhotos.length % 9 !== pageCount % 9 ||
                    isLastFetchPhotos ? (
                    <></>
                  ) : (
                    <ViewMoreButton
                      onClick={() => {
                        handleViewMoreButtonClick(PlayerInfoType.PHOTOS);
                      }}
                    />
                  )}
                </>
              ) : (
                <>
                  <div className={styles.photosGrid}>
                    {videosToDisplay.length !== 0 ? (
                      videosToDisplay.map((video, index) => (
                        <PlayerVideo
                          video={video.videoData}
                          openVideo={onVideoSelect}
                          isYoutube={video.isYoutube}
                          index={index}
                          key={video.videoData.id}
                          defaultImage={defaultPhoto}
                        />
                      ))
                    ) : (
                      <div className={styles.noPhotos}>
                        {t('playerPage.tabs.nothingHereYet')}
                      </div>
                    )}
                  </div>
                  {videosToDisplay.length <= videoCount &&
                  (youtubeNextPageToken ||
                    strapiVideos.length + youtubeVideos.length >
                      videosToDisplay.length) ? (
                    <ViewMoreButton
                      onClick={() => {
                        handleViewMoreButtonClick(PlayerInfoType.VIDEOS);
                      }}
                    />
                  ) : null}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      <VideoModal
        isVisible={!!selectedVideo}
        videoSource={selectedVideo ? selectedVideo.source : ''}
        close={onClose}
        isYoutube={selectedVideo?.isYoutube}
      />
    </>
  );
};

export default PlayerTabsWithInfo;
