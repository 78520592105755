import { ReactElement } from 'react';

interface TGIconTransparentProps {
  fillColor: string;
  width?: number;
  height?: number;
}

export default function TGIconTransparent({
  fillColor,
  width = 24,
  height = 20,
}: TGIconTransparentProps): ReactElement {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 24 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        id='Vector'
        fillRule='evenodd'
        clipRule='evenodd'
        d='M1.97298 8.71744L16.2702 2.83082C17.676 2.2158 22.4683 0.258918 22.4683 0.258918C22.4683 0.258918 24.6728 -0.603709 24.4891 1.48097C24.4252 2.3436 23.938 5.34682 23.4428 8.59764L21.9092 18.2303C21.9092 18.2303 21.7894 19.644 20.7431 19.8837C19.6968 20.1233 17.9875 19.029 17.676 18.7814C17.3645 18.5338 13.0753 15.8341 11.4858 14.4843C11.3237 14.3752 11.1919 14.2268 11.1026 14.053C11.0132 13.8793 10.9693 13.6857 10.9749 13.4904C10.9805 13.2951 11.0354 13.1044 11.1345 12.936C11.2336 12.7676 11.3737 12.6271 11.5418 12.5274C13.7542 10.4986 16.39 7.98262 17.9875 6.39315C18.7223 5.65033 19.4571 3.93307 16.39 6.01775L7.7398 11.8485C7.29842 12.0487 6.82111 12.1575 6.33658 12.1685C5.85204 12.1795 5.37029 12.0923 4.92029 11.9124C3.07523 11.3612 0.92665 10.6264 0.92665 10.6264C0.92665 10.6264 -0.543011 9.69988 1.97298 8.71744Z'
        fill={fillColor}
      />
    </svg>
  );
}
