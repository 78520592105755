import { ReactElement, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import {
  PlayerSeasonStats,
  PlayerWithRelations,
  Roster,
} from '../../../types/Types';
import { getPlayerSeasonStats } from 'api/api';
import { mapPlayerSeasonStatsDto } from 'mapping/mapping';
import styles from './StatisticInfo.module.scss';
import Bio from '../Bio/Bio';

type StatisticInfoProps = {
  player: PlayerWithRelations;
  playerRoster?: Roster;
};

function StatisticInfo({
  player,
  playerRoster,
}: StatisticInfoProps): ReactElement {
  const [searchParams] = useSearchParams();
  const seasonParam = searchParams.get('season');
  const [playerStats, setPlayerStats] = useState<PlayerSeasonStats>();

  useEffect(() => {
    const getPlayerStats = async () => {
      try {
        const resp = await getPlayerSeasonStats(
          player.attributes.id_league_web,
          seasonParam || ''
        );
        setPlayerStats(mapPlayerSeasonStatsDto(resp));
      } catch (error: any) {
        // Не выставляем ошибку, так как с этого уровня можем заблокировать только отрисовку Bio целиком,
        // а там могут быть частичные данные; Написали проблему в консоль, прошли дальше
        console.log(
          `Получение статистики для игрока ${player?.id}: ${error.message}`
        );
      }
    };
    getPlayerStats();
  }, []);

  return (
    <div className={styles.statistic}>
      <Bio
        player={player}
        playerStats={playerStats}
        playerRoster={playerRoster}
      />
    </div>
  );
}

export default StatisticInfo;
