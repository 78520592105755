import { useEffect, useState } from 'react';
import {
  getDefaultPlayerImage,
  getExecutiveById,
  getExecutivesByType,
} from '../../../api/api';
import {
  DefaultImageObject,
  ExecutiveDto,
  ExecutiveType,
} from '../../../types/Types';
import InfoWrapper from '../../Wrappers/InfoWrapper/InfoWrapper';
import ImageBgPageWrapper from '../../common/ImageBgPageWrapper/ImageBgPageWrapper';
import Container from '../../common/Container/Container';
import BreadcrumbsWrapper from 'components/common/BreadcrumbsWrapper/BreadcrumbsWrapper';
import { observer } from 'mobx-react-lite';
import { useParams, useSearchParams } from 'react-router-dom';
import ExecutiveSmallTiles from '../../ExecutivesSmallTiles/ExecutivesSmallTiles';
import BioBox from '../../PlayerPage/Bio/BioBox/BioBox';
import SetRespError, {
  getDate,
  getFullExecutiveName,
} from '../../../helpers/helpers';
import styles from './PressBioPage.module.scss';
import ExecutiveTabsWithInfo from '../../ExecutiveTabsWithInfo/ExecutiveTabsWithInfo';
import ShowOn from 'components/core/adaptivity/ShowOn';
import { DEFAULT_IMAGE_NAME, LOKOMOTIV_CODE } from 'constants/constants';
import ErrorWidget from 'components/common/ErrorWidget/ErrorWidget';
import ErrorPage from '../ErrorPage/ErrorPage';
import ImageLoader from 'components/common/ImageLoaders/ImageLoader/ImageLoader';
import { useTranslation } from 'react-i18next';
import Spinner from 'components/Spinner/Spinner';

const PressBioPage = observer(() => {
  const { t } = useTranslation();
  const [press, setPress] = useState<ExecutiveDto>();
  const [otherPress, setOtherPress] = useState<ExecutiveDto[]>([]);
  const [defaultPhoto, setDefaultPhoto] = useState<DefaultImageObject>();
  const [isError, setIsError] = useState<boolean>(false);
  const [isNotFoundError, setIsNotFoundError] = useState<boolean>(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const { pressId } = useParams();

  useEffect(() => {
    const getExecutiveInfo = async () => {
      try {
        const season = searchParams.get('season');
        const pressManagementResponse = await getExecutiveById(Number(pressId));
        const otherPressResponse = await getExecutivesByType(
          ExecutiveType.PRESS,
          season || '',
          true
        );

        const filteredManagement = otherPressResponse?.data?.filter(
          (item: ExecutiveDto) => item.id !== Number(pressId)
        );

        setPress(pressManagementResponse.data);
        setOtherPress(filteredManagement);
      } catch (e: any) {
        SetRespError(e, setIsNotFoundError, setIsError);
      }

      try {
        const photo = await getDefaultPlayerImage(DEFAULT_IMAGE_NAME);
        setDefaultPhoto(photo?.data[0]);
      } catch (e: any) {}
    };

    getExecutiveInfo();
  }, [pressId]);

  if (press && press.attributes.type !== ExecutiveType.PRESS)
    return <ErrorPage />;

  if (
    press &&
    press?.attributes?.team_filter.data.every(
      (team) => team.attributes.code !== LOKOMOTIV_CODE
    )
  )
    return <ErrorPage />;

  return (
    <ImageBgPageWrapper
      imageInfo={{
        img_url: press?.attributes?.bg_photo?.data?.attributes?.url ?? '',
        gradient_height: 200,
        image_height: 700,
      }}
    >
      <Container paddingTop='50px' paddingBottom='0px'>
        <BreadcrumbsWrapper
          breadcrumbs={[
            { name: t('pressBioPage.breadcrumbParent'), url: '/press' },
            {
              name: getFullExecutiveName(press),
              url: `/press/${pressId}`,
            },
          ]}
        />
        {isNotFoundError ? (
          <ErrorWidget.NotFound isOnDarkBackground />
        ) : isError ? (
          <ErrorWidget.Error isOnDarkBackground />
        ) : press ? (
          <>
            <div className={styles.executiveHeader}>
              <div className={styles.info}>
                <ShowOn largeDesktop smallDesktop largeTablet smallTablet>
                  <p className={styles.position}>
                    {press?.attributes?.position ?? ''}
                  </p>
                  <p className={styles.name}>{getFullExecutiveName(press)}</p>
                </ShowOn>
                <ShowOn largeMobile smallMobile>
                  <p className={styles.positionMobile}>
                    {press?.attributes?.position ?? ''}
                  </p>
                  <p className={styles.nameMobile}>
                    {getFullExecutiveName(press)}
                  </p>
                </ShowOn>
              </div>
              <div className={styles.mainInfo}>
                <ShowOn largeDesktop smallDesktop largeTablet>
                  <div className={styles.imageWrapper}>
                    <ImageLoader
                      image={
                        press?.attributes?.photo?.data?.attributes?.url ??
                        defaultPhoto?.attributes.image?.data?.attributes?.url ??
                        ''
                      }
                      alt={
                        `${press?.attributes?.surname} ${press?.attributes?.name}` ??
                        'executive'
                      }
                    />
                  </div>
                </ShowOn>
                <div className={styles.bioWrapper}>
                  {press?.attributes?.birth && (
                    <>
                      <BioBox
                        name={t('pressBioPage.bio.birthDate')}
                        value={getDate(press?.attributes?.birth)}
                      />
                      <BioBox
                        name={t('pressBioPage.bio.birthPlace')}
                        value={
                          press?.attributes.birth_place ||
                          t('pressBioPage.bio.noValue')
                        }
                      />
                    </>
                  )}
                </div>
              </div>
            </div>
            <InfoWrapper
              isWhiteBackground={true}
              isPaddingZero={true}
              borderRadius='10px'
            >
              <>{press && <ExecutiveTabsWithInfo executive={press} />}</>
            </InfoWrapper>
          </>
        ) : (
          <Spinner />
        )}
        {otherPress?.length > 0 && (
          <div className={styles.otherExecutives}>
            <p className={styles.title}>{t('pressBioPage.otherPress')}</p>
            <ExecutiveSmallTiles
              executives={otherPress}
              defaultPhoto={defaultPhoto}
            />
          </div>
        )}
      </Container>
    </ImageBgPageWrapper>
  );
});

export default PressBioPage;
