import { useCallback, useMemo, useState } from 'react';
import { MenuElementDto } from 'types/Types';
import ticketIcon from 'assets/ticket.svg';
import ticketWhiteIcon from 'assets/footer_ticket.svg';
import styles from './MobileHeaderTickets.module.scss';
import Row from 'components/core/layout/Row';
import { compareOrder } from 'helpers/helpers';
import Column from 'components/core/layout/Column';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

interface MobileHeaderTicketsProps {
  allMenu: Array<MenuElementDto>;
  isDrawer?: boolean;
}

const MobileHeaderTickets = ({
  allMenu,
  isDrawer,
}: MobileHeaderTicketsProps) => {
  const { t } = useTranslation();
  const [isTicketsOpen, setIsTicketsOpen] = useState(false);
  const ticketsMenuElement: MenuElementDto | undefined = useMemo(
    () =>
      allMenu.find(
        (menuElement: MenuElementDto) =>
          menuElement.attributes.name === 'tickets'
      ),
    [allMenu]
  );

  const onTap = useCallback(() => {
    setIsTicketsOpen((prevIsTicketsOpen) => !prevIsTicketsOpen);
  }, []);

  return (
    <div className={styles.ticketsWrapper}>
      <div
        onClick={onTap}
        className={isDrawer ? styles.ticketsButtonDrawer : styles.ticketsButton}
      >
        <Row
          alignItems='center'
          justifyContent='center'
          height='55px'
          gap='8px'
          width='150px'
        >
          <img src={isDrawer ? ticketWhiteIcon : ticketIcon} alt='' />
          <span
            className={
              isDrawer
                ? styles.ticketsButtonTextDrawer
                : styles.ticketsButtonText
            }
          >
            {t('header.tickets')}
          </span>
        </Row>
      </div>
      {isTicketsOpen && (
        <div
          className={classNames(
            styles.ticketsDropdown,
            isDrawer
              ? styles.ticketsDropdownDrawer
              : styles.ticketsDropdownHeader
          )}
        >
          <Column gap='20px' justifyContent='space-between'>
            {ticketsMenuElement &&
              ticketsMenuElement.attributes.main_menu_item.data
                .sort(compareOrder)
                .map((submenu) => (
                  <a
                    key={submenu.id}
                    href={submenu.attributes.url}
                    target={submenu.attributes.is_external ? '_blank' : ''}
                    rel='noreferrer'
                  >
                    <span
                      className={classNames(
                        styles.ticketsDropdownItemText,
                        isDrawer
                          ? styles.ticketsDropdownItemTextDrawer
                          : styles.ticketsDropdownItemTextHeader
                      )}
                    >
                      {submenu.attributes.display_name}
                    </span>
                  </a>
                ))}
          </Column>
        </div>
      )}
    </div>
  );
};

export default MobileHeaderTickets;
