import { ReactElement, PropsWithChildren } from 'react';
import { ReactComponent as Arrow } from '../../assets/upper_widget_header.svg';
import styles from './WidgetHeader.module.scss';

type WidgetHeaderProps = {
  headerText: string;
  linkText: string;
  uri: string;
  isExternal?: boolean;
  paddingLeftRight?: number;
  alignItems?: string;
  arrowMarginTop?: number;
};

function WidgetHeader({
  headerText,
  linkText,
  uri,
  isExternal = false,
  paddingLeftRight = 0,
  alignItems = 'baseline',
  arrowMarginTop,
}: PropsWithChildren<WidgetHeaderProps>): ReactElement {
  return (
    <div
      className={styles.header}
      style={{
        paddingLeft: paddingLeftRight,
        paddingRight: paddingLeftRight,
        alignItems: alignItems,
      }}
    >
      <span className={styles.headerText}>{headerText}</span>
      <a href={uri} className={styles.link} target={isExternal ? '_blank' : ''}>
        <div
          className={
            alignItems === 'center' ? styles.alignCenter : styles.headerBtn
          }
        >
          <span className={styles.headerBtnText}>{linkText}</span>
          <div style={{ marginTop: arrowMarginTop }}>
            <Arrow className={styles.arrowIcon} />
          </div>
        </div>
      </a>
    </div>
  );
}

export default WidgetHeader;
