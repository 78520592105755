import { ReactElement } from 'react';
import styles from './StatisticNumber.module.scss';

type StatisticNumberProps = {
  number: number | undefined;
  text: string;
};

function StatisticNumber({ number, text }: StatisticNumberProps): ReactElement {
  return (
    <div className={styles.statisticNumber}>
      {number ? (
        <span className={styles.statisticNumberValue}>{number}</span>
      ) : (
        <span className={styles.statisticNumberValue}>-</span>
      )}
      <div className={styles.statisticNumberText}>{text}</div>
    </div>
  );
}

export default StatisticNumber;
