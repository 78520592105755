import { ReactElement } from 'react';
import Slider, { Settings } from 'react-slick';
import styles from './SliderWrapper.module.scss';
import ArrowLeftLine from '../../assets/icons/ArrowLeftLine';
import ArrowRightLine from '../../assets/icons/ArrowRightLIne';
import classNames from 'classnames';

type SliderWrapperProps = Settings & {
  slides: Array<React.ReactNode>;
  className?: string;
  onLastSlide?: () => void;
  marginRightHidden?: boolean;
};

function SliderWrapper(props: SliderWrapperProps): ReactElement {
  const { onLastSlide, slides, className } = props;

  const afterChange = (index: number) => {
    if (index === slides.length - 3 && onLastSlide) {
      onLastSlide();
    }
  };

  const settings: Settings = {
    afterChange,
    arrows: true,
    prevArrow: <ArrowLeftLine />,
    nextArrow: <ArrowRightLine />,
    ...props,
  };
  return (
    <Slider
      className={classNames(
        className,
        props.marginRightHidden ? styles.marginRight : styles.slider
      )}
      {...settings}
    >
      {slides}
    </Slider>
  );
}
export default SliderWrapper;
