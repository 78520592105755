import { ReactElement } from "react";
import styles from "./InfrastructurePage.module.scss";
import InfrastructureTabsWithInfo from "../../InfrastructureTabsWithInfo/InfrastructureTabsWithInfo";


type InfrastructurePagePropsType = {
  pathname: string;
}

function InfrastructurePage({pathname}: InfrastructurePagePropsType): ReactElement {
  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <InfrastructureTabsWithInfo pathname={pathname} />
      </div>
    </div>
  );
}

export default InfrastructurePage;
