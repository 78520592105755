import styles from './Flags.module.scss';
import Slider from 'react-slick';
import { ReactElement, useEffect, useState } from 'react';
import { getAchievements } from '../../../api/api';
import { Achievement } from '../../../types/Types';
import Flag from './Flag/Flag';
import ArrowLeftLine from 'assets/icons/ArrowLeftLine';
import ArrowRightLine from 'assets/icons/ArrowRightLIne';

type FlagsProps = {
  isSlider?: boolean;
  slidesToShow?: number;
};

function Flags({
  isSlider = false,
  slidesToShow = 1,
}: FlagsProps): ReactElement {
  const [achievements, setAchievements] = useState<Array<Achievement>>([]);

  const sliderSettings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: slidesToShow,
    slidesToScroll: 1,
    arrows: true,
    prevArrow: <ArrowLeftLine fill='white' />,
    nextArrow: <ArrowRightLine fill='white' />,
  };

  useEffect(() => {
    const getData = async () => {
      try {
        const respAchievements = await getAchievements();
        setAchievements(respAchievements?.data);
      } catch (e: any) {
        console.log('Failed to get flags');
        console.error(e?.response?.data?.error || e?.message);
      }
    };

    getData();
  }, []);

  return !isSlider ? (
    <div className={styles.grid}>
      {achievements.map((achievement, index) => (
        <Flag
          key={`flag-${index}`}
          title={achievement.attributes?.title}
          period={achievement.attributes?.period}
          page={achievement.attributes?.page_id}
        />
      ))}
    </div>
  ) : (
    <Slider className={styles.slider} {...sliderSettings}>
      {achievements.map((achievement) => (
        <Flag
          title={achievement.attributes?.title}
          period={achievement.attributes?.period}
          page={achievement.attributes?.page_id}
        />
      ))}
    </Slider>
  );
}

export default Flags;
