import styles from './SocialNetSelector.module.scss';
import { SocialNetSelectorValue } from '../SocialNetSelectorValue';
import VKIconTransparent from '../../../../assets/VKIconTransparent';
import TGIconTransparent from '../../../../assets/TGIconTransparent';
import Colors from 'constants/colors';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import { Breakpoints } from 'constants/adaptivity';

interface SocialNetSelectorProps {
  value: SocialNetSelectorValue;
  onVkClickCallback: () => void;
  onTelegramClickCallback: () => void;
}

export default function SocialNetSelector(
  props: SocialNetSelectorProps
): React.ReactElement {
  const { t } = useTranslation();
  const isVkSelected = props.value === SocialNetSelectorValue.VK;
  const isMobile = useMediaQuery({
    maxWidth: Breakpoints.largeMobile,
  });

  const vkStyleClass = isVkSelected
    ? styles.selectorItemSelected
    : styles.selectorItem;

  const telegramStyleClass = isVkSelected
    ? styles.selectorItem
    : styles.selectorItemSelected;

  const vkIconColor = isVkSelected ? Colors.WHITE : Colors.PRIMARY_BLUE;
  const tgIconColor = isVkSelected ? Colors.PRIMARY_BLUE : Colors.WHITE;

  return (
    <div className={styles.selector}>
      <div
        className={vkStyleClass}
        onClick={props.onVkClickCallback}
        id={'vk-button'}
      >
        <VKIconTransparent
          fillColor={vkIconColor}
          width={isMobile ? 18 : 24}
          height={isMobile ? 11 : 15}
        />
        <span>{t('homepage.socialsWidget.vk')}</span>
      </div>
      <div
        className={telegramStyleClass}
        onClick={props.onTelegramClickCallback}
        id={'telegram-button'}
      >
        <TGIconTransparent
          fillColor={tgIconColor}
          width={isMobile ? 18 : 24}
          height={isMobile ? 14 : 20}
        />
        <span>{t('homepage.socialsWidget.tg')}</span>
      </div>
    </div>
  );
}
