import classNames from 'classnames';
import { ReactElement, useState } from 'react';
import { ReactComponent as RuFlag } from '../../../assets/ru_flag.svg';
import { ReactComponent as EnFlag } from '../../../assets/en_flag.svg';
import ShowOn from 'components/core/adaptivity/ShowOn';
import Column from 'components/core/layout/Column';
import Row from 'components/core/layout/Row';
import styles from './AssociateInfo.module.scss';
import i18n from 'i18n';
import { useTranslation } from 'react-i18next';
import ErrorWidget from 'components/common/ErrorWidget/ErrorWidget';
import { observer } from 'mobx-react-lite';
import { useStore } from 'store/store';

const AssociateInfo = observer((): ReactElement => {
  const { t } = useTranslation();
  const { documentsStore } = useStore();
  const [isDocsError, setIsDocsError] = useState<boolean>(false);

  const setLang = (lang: string) => {
    i18n.changeLanguage(lang);
    window.location.reload();
  };

  const renderText = () => (
    <>
      {documentsStore.documents?.map(
        (doc) =>
          doc.attributes.hide_document === false && (
            <a
              href={doc.attributes.document_link}
              key={doc.attributes.name}
              target='_blank'
            >
              <span className={classNames(styles.text, styles.hover)}>
                {doc.attributes.name}
              </span>
            </a>
          )
      )}
      <div className={styles.textLangFlex}>
        <div className={styles.langBoxFlex} onClick={() => setLang('ru')}>
          <RuFlag />
          <span className={styles.textLang}>RU</span>
        </div>
        <div className={styles.langBoxFlex} onClick={() => setLang('en')}>
          <EnFlag />
          <span className={styles.textLang}>EN</span>
        </div>
      </div>
    </>
  );

  const renderContent = () => (
    <>
      <span className={styles.text}>{t('footer.associate.loko')}</span>
      <span className={styles.text}>{t('footer.associate.tickets')}</span>
      <ShowOn largeDesktop smallDesktop largeTablet smallTablet>
        <Row justifyContent='space-between'>
          {isDocsError ? (
            <ErrorWidget.Error isOnDarkBackground />
          ) : (
            renderText()
          )}
        </Row>
      </ShowOn>
      <ShowOn largeMobile smallMobile>
        <Column gap='10px' alignItems='center'>
          {isDocsError ? (
            <ErrorWidget.Error isOnDarkBackground />
          ) : (
            renderText()
          )}
        </Column>
      </ShowOn>
    </>
  );

  return (
    <div className={styles.wrapper}>
      <ShowOn largeDesktop smallDesktop largeTablet smallTablet>
        <Column justifyContent='space-between' gap='15px'>
          {renderContent()}
        </Column>
      </ShowOn>
      <ShowOn largeMobile smallMobile>
        <div className={styles.mobileWidth}>
          <Column justifyContent='space-between' gap='15px'>
            {renderContent()}
          </Column>
        </div>
      </ShowOn>
    </div>
  );
});

export default AssociateInfo;
