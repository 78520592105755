import { TG_ID, TG_IFRAME_WIDGET_URL } from '../../../../../constants/constants';

/**
 * Render the Telegram News Feed Component.
 * @returns The rendered component.
 */
export default function TelegramNewsFeedComponent(): React.ReactElement {
  return (
    <>
      <iframe
        id={TG_ID}
        width='100%'
        height='415px'
        src={TG_IFRAME_WIDGET_URL}
      ></iframe>
    </>
  );
}
