import { AdvertisingWidgetDto } from 'types/Types';
import styles from './GameTypeWidget.module.scss';
import { ReactComponent as Ticket } from '../../../assets/ticket_white.svg';
import { getDateOrTimeForAdvertisingWidget } from 'helpers/helpers';

type ImageInCenterTypeWidgetType = {
  advertising: AdvertisingWidgetDto;
};

function GameTypeWidget({ advertising }: ImageInCenterTypeWidgetType) {
  return (
    <div>
      <div className={styles.gameDateContainer}>
        <div className={styles.gameDateText}>
          {getDateOrTimeForAdvertisingWidget(
            true,
            advertising.attributes.game?.data.attributes.date
          )}
        </div>
        <div className={styles.gameDateTimeText}>
          {getDateOrTimeForAdvertisingWidget(
            false,
            advertising.attributes.game?.data.attributes.date
          )}
        </div>
      </div>
      <div className={styles.gameLogoContainer}>
        <img
          src={
            advertising.attributes?.game?.data?.attributes?.team_1?.data
              ?.attributes.logo?.data?.attributes.url || ''
          }
          className={styles.logo}
        />
        - : -
        <img
          src={
            advertising.attributes?.game?.data?.attributes?.team_2?.data
              ?.attributes.logo?.data?.attributes.url || ''
          }
          className={styles.logo}
        />
      </div>
      <div className={styles.containerButton}>
        <a
          className={styles.widgetBtn}
          target='_blank'
          href={advertising.attributes.link}
        >
          <div className={styles.alignBtn}>
            <Ticket />
            <span className={styles.widgetBtnBuy}>Купить билет</span>
          </div>
        </a>
      </div>
    </div>
  );
}

export default GameTypeWidget;
