import { ReactElement, useEffect, useState } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import {
  getKhlPartnerHeader,
  getMhlPartnerHeader,
  getPartners,
  getFonbetPartnerHeader,
} from 'api/api';
import { sortPartnersDto } from 'helpers/helpers';
import { PartnerDto, PartnerHeaderDto, SeasonsTeamCode } from 'types/Types';
import ShowOn from 'components/core/adaptivity/ShowOn';
import { ReactComponent as ArrowUp } from 'assets/icons/arrowUpLeft.svg';
import { ReactComponent as ArrowDown } from 'assets/icons/arrowDownRight.svg';
import { Collapse } from 'antd';
import styles from './Partners.module.scss';
import ErrorWidget from 'components/common/ErrorWidget/ErrorWidget';

const Partners = (): ReactElement => {
  const [searchParams] = useSearchParams();
  const [partners, setPartners] = useState<Array<PartnerDto>>([]);
  const [mainPartners, setMainPartners] = useState<Array<PartnerDto>>([]);
  const [fonbetPartnerHeader, setFonbetPartnerHeader] =
    useState<PartnerHeaderDto>();
  const [khlPartnerHeader, setKhlPartnerHeader] = useState<PartnerHeaderDto>();
  const [mhlPartnerHeader, setMhlPartnerHeader] = useState<PartnerHeaderDto>();
  const [isMHL, setIsMHL] = useState<boolean>(false);
  const location = useLocation();
  const currentSeason = searchParams.get('season');

  useEffect(() => {
    if (currentSeason) {
      const season = currentSeason.toLowerCase();

      setIsMHL(
        season.includes(SeasonsTeamCode.lko) ||
          season.includes(SeasonsTeamCode.l76)
      );
    } else {
      const pathName = location.pathname?.toLowerCase();

      setIsMHL(
        pathName.includes(`/${SeasonsTeamCode.lko}`) ||
          pathName.includes(`/${SeasonsTeamCode.l76}`)
      );
    }
  }, [location, currentSeason]);

  useEffect(() => {
    const getPartnersData = async () => {
      const response = await Promise.all([
        getPartners(),
        getMhlPartnerHeader(),
        getKhlPartnerHeader(),
        getFonbetPartnerHeader(),
      ]);

      setPartners(
        !isMHL
          ? response[0]?.data
              ?.filter(
                (partner: PartnerDto) =>
                  partner?.attributes?.partner_category?.data?.attributes
                    ?.enabled &&
                  partner?.attributes?.partner_category?.data?.id === 1
              )
              .sort(sortPartnersDto)
          : response[0]?.data
              ?.filter(
                (partner: PartnerDto) =>
                  partner?.attributes?.partner_category?.data?.attributes
                    ?.enabled &&
                  partner?.attributes?.partner_category?.data?.id === 2
              )
              .sort(sortPartnersDto)
      );
      setMainPartners(
        response[0]?.data
          ?.filter(
            (partner: PartnerDto) =>
              partner?.attributes?.partner_category?.data?.attributes
                ?.enabled &&
              partner?.attributes?.partner_category?.data?.id === 3
          )
          .sort(sortPartnersDto)
      );
      setMhlPartnerHeader(response[1]?.data);
      setKhlPartnerHeader(response[2]?.data);
      setFonbetPartnerHeader(response[3]?.data);
    };

    getPartnersData();
  }, [isMHL]);

  const renderKhlHeader = () => (
    <div className={styles.row}>
      <div className={styles.partnersBox}>
        <div className={styles.header}>
          <span className={styles.text}>
            {khlPartnerHeader && khlPartnerHeader.attributes.text.toUpperCase()}
          </span>
        </div>
      </div>
    </div>
  );

  const renderMainPartners = () => (
    <div className={styles.partners}>
      <div className={styles.partnersWrap}>
        {mainPartners &&
          mainPartners.map((partner) => {
            return (
              partner?.attributes?.logo?.data?.attributes?.url &&
              partner.attributes.enabled && (
                <div key={partner.id} className={styles.partnerItemAlign}>
                  <a
                    href={partner?.attributes?.url}
                    rel='external'
                    target='_blank'
                    id={`{id-main-partner-${partner?.id}}`}
                  >
                    <img
                      src={partner?.attributes?.logo?.data?.attributes?.url}
                      alt={partner?.attributes?.name}
                      className={styles.partnersImg}
                    />
                  </a>
                </div>
              )
            );
          })}
      </div>
    </div>
  );

  const renderMhlHeader = () => (
    <div className={styles.row}>
      <div className={styles.partnersBox}>
        <div className={styles.header}>
          <span className={styles.text}>
            {isMHL
              ? mhlPartnerHeader &&
                mhlPartnerHeader.attributes.text.toUpperCase()
              : fonbetPartnerHeader &&
                fonbetPartnerHeader.attributes.text.toUpperCase()}
          </span>
        </div>
      </div>
    </div>
  );

  const renderMhlPartners = () => (
    <div className={styles.partners}>
      <div className={styles.partnersWrap}>
        {partners &&
          partners.map((partner) => {
            return (
              partner?.attributes?.logo?.data?.attributes?.url &&
              partner.attributes.enabled && (
                <div key={partner.id} className={styles.partnerItemGrid}>
                  <a
                    href={partner?.attributes?.url}
                    rel='external'
                    target='_blank'
                    id={`{id-mhl-partner-${partner?.id}}`}
                  >
                    <img
                      src={partner?.attributes?.logo?.data?.attributes?.url}
                      alt={
                        partner?.attributes?.partner_type?.data?.attributes
                          ?.type
                      }
                      className={styles.partnersImg}
                    />
                  </a>
                  <span className={styles.partnersSpan}>
                    {partner?.attributes?.partner_type?.data?.attributes?.type}
                  </span>
                </div>
              )
            );
          })}
      </div>
    </div>
  );

  return (
    <>
      <ShowOn largeDesktop smallDesktop largeTablet smallTablet>
        {khlPartnerHeader ? renderKhlHeader() : <></>}
        {mainPartners ? (
          renderMainPartners()
        ) : (
          <ErrorWidget.Error isOnDarkBackground />
        )}
        {mhlPartnerHeader ? renderMhlHeader() : <></>}
        {partners ? (
          renderMhlPartners()
        ) : (
          <ErrorWidget.Error isOnDarkBackground />
        )}
      </ShowOn>
      <ShowOn largeMobile smallMobile>
        <div className={styles.collapsibleHeaders}>
          <div className={styles.smallWidthRow}>
            <Collapse
              expandIcon={(prop) =>
                prop.isActive ? <ArrowUp /> : <ArrowDown />
              }
              expandIconPosition='end'
              className={styles.collapsible}
            >
              <Collapse.Panel
                header={renderKhlHeader()}
                key='khl-block'
                className={styles.collapsiblePanel}
              >
                <div className={styles.collapsibleContent}>
                  {renderMainPartners()}
                </div>
              </Collapse.Panel>
            </Collapse>
          </div>
          <div className={styles.smallWidthRow}>
            <Collapse
              expandIcon={(prop) =>
                prop.isActive ? <ArrowUp /> : <ArrowDown />
              }
              expandIconPosition='end'
              className={styles.collapsible}
            >
              <Collapse.Panel
                header={renderMhlHeader()}
                key='mhl-block'
                className={styles.collapsiblePanel}
              >
                <div className={styles.collapsibleContent}>
                  {renderMhlPartners()}
                </div>
              </Collapse.Panel>
            </Collapse>
          </div>
        </div>
      </ShowOn>
    </>
  );
};

export default Partners;
