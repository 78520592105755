import { ReactElement } from 'react';
import classNames from 'classnames';
import { getMatchDateInfo } from '../../../../helpers/helpers';
import { GameWithLogo } from '../../../../types/Types';
import Team from './Team/Team';
import { ReactComponent as Ticket } from '../../../../assets/ticket_white.svg';
import { ReactComponent as ProtocolIcon } from '../../../../assets/protocol_calendar.svg';
import styles from './HoverGame.module.scss';
import ScoreBox from '../../../common/ScoreBox/ScoreBox';
import Url from 'constants/urls';

type HoverGameProps = {
  game: GameWithLogo;
  isHome: boolean;
};

function HoverGame({ game, isHome }: HoverGameProps): ReactElement {
  function getDate() {
    if (game?.attributes?.date)
      return `${getMatchDateInfo(game?.attributes?.date || '', true)}`;
  }

  function isGameInFuture() {
    return new Date(game.attributes.date).getTime() > new Date().getTime();
  }

  function isHighlightsDisabled() {
    const seasonCode = game.attributes.season?.data?.attributes?.code;

    if (
      seasonCode &&
      (seasonCode.includes('LKO') || seasonCode.includes('L76'))
    ) {
      return !(
        game.attributes.article?.data && game.attributes?.highlights?.length
      );
    } else {
      return !game.attributes.article?.data;
    }
  }

  return (
    <div className={styles.widgetWrapper}>
      {game.attributes.is_current ? (
        <div
          className={classNames(styles.headerMatch, styles.headerMatchToday)}
        >
          Текущий матч
        </div>
      ) : isGameInFuture() ? (
        <div className={styles.headerMatch}>Будущий матч</div>
      ) : (
        <div className={styles.headerMatch}>Прошедший матч</div>
      )}

      <div className={styles.headerDate}>{getDate()}</div>

      <div className={styles.widgetTeams}>
        <Team
          name={game.attributes?.team_1?.data?.attributes.name}
          city={game?.attributes?.team_1.data.attributes.city}
        >
          <img
            src={
              game.attributes?.team_1?.data?.attributes.logo?.data?.attributes
                .url
            }
            className={styles.logo}
          />
        </Team>
        <ScoreBox
          game={game}
          isGameInFuture={isGameInFuture()}
          date={game?.attributes?.date}
          isCurrent={game?.attributes?.is_current}
          isHome={isHome}
          isHoverGame={true}
        />
        <Team
          name={game.attributes?.team_2?.data?.attributes.name}
          city={game?.attributes?.team_2.data.attributes.city}
        >
          <img
            src={
              game.attributes?.team_2?.data?.attributes.logo?.data?.attributes
                .url
            }
            className={styles.logo}
          />
        </Team>
      </div>
      {game.attributes.is_current ? (
        <div className={styles.container}>
          <a
            className={classNames(styles.widgetBtn, styles.alignBtnWatch, {
              [styles.blue]: !isHome,
            })}
            href={`/protocol?gameId=${game.id}`}
          >
            <span className={styles.widgetBtnWatch}>Смотреть трансляцию</span>
          </a>
        </div>
      ) : isGameInFuture() ? (
        isHome ? (
          <div className={styles.container}>
            <a
              className={styles.widgetBtn}
              href={game?.attributes?.tickets_link ?? Url.loko.TICKETS}
              target='_blank'
            >
              <div
                className={classNames(styles.widgetBtnWrapper, styles.alignBtn)}
              >
                <Ticket />
                <span className={styles.widgetBtnBuy}>Купить билет</span>
              </div>
            </a>
          </div>
        ) : (
          <></>
        )
      ) : (
        <div className={styles.wrapperBtnLastGame}>
          <a
            className={classNames(
              styles.widgetBtn,
              styles.btnLastGame,
              styles.alignBtnWatch,
              {
                [styles.blue]: !isHome,
                [styles.disabled]: isHighlightsDisabled(),
              }
            )}
            href={`/protocol?gameId=${game.id}&report=true`}
          >
            <span className={styles.widgetBtnWatch}>Смотреть хайлайты</span>
          </a>

          <a
            className={classNames(
              styles.widgetBtn,
              styles.btnLastGame,
              styles.btnProtocol
            )}
            href={`/protocol?gameId=${game.id}`}
          >
            <div
              className={classNames(
                styles.widgetBtnWrapper,
                styles.alignBtnProtocol
              )}
            >
              <ProtocolIcon />
              <span className={styles.widgetBtnNext}>Протокол</span>
            </div>
          </a>
        </div>
      )}
    </div>
  );
}

export default HoverGame;
