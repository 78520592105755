import { ReactElement, useEffect, useState } from 'react';
import { getPlayerSeasonStats, getSeasonByCode } from '../../../api/api';
import { mapPlayerSeasonStatsDto } from '../../../mapping/mapping';
import {
  PlayerSeasonStats,
  PlayerWithRelations,
  Season,
} from '../../../types/Types';
import GoalkeeperTable from '../GoalkeeperTable/GoalkeeperTable';
import NotGoalkeeperTable from '../NotGoalkeeperTable/NotGoalkeeperTable';

type PlayerSeasonStatsProps = {
  player: PlayerWithRelations;
  seasonParam: string | null;
};

function PlayerSeasonStatistics({
  player,
  seasonParam,
}: PlayerSeasonStatsProps): ReactElement {
  const [playerStats, setPlayerStats] = useState<PlayerSeasonStats>();
  const [season, setSeason] = useState<Season>();
  const isGoalkeeper = player?.attributes?.position?.data?.id === 1;

  useEffect(() => {
    const getData = async () => {
      try {
        const resp = await getPlayerSeasonStats(
          player?.attributes?.id_league_web,
          seasonParam || ''
        );
        setPlayerStats(mapPlayerSeasonStatsDto(resp));
      } catch (e: any) {
        const error = e?.response?.data?.error || e?.message;
        if (error) {
          setPlayerStats(undefined);
        }
      }
    };
    getData();
  }, []);

  useEffect(() => {
    const getData = async () => {
      const resp = await getSeasonByCode(seasonParam || '');
      setSeason(resp?.data[0]);
    };
    getData();
  }, []);

  return (
    <>
      {playerStats ? (
        isGoalkeeper ? (
          <GoalkeeperTable
            seasonParam={seasonParam}
            season={season}
            playerStats={playerStats}
          />
        ) : (
          <NotGoalkeeperTable
            seasonParam={seasonParam}
            season={season}
            playerStats={playerStats}
          />
        )
      ) : (
        <></>
      )}
    </>
  );
}

export default PlayerSeasonStatistics;
