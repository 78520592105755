import React, { RefObject, useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useStore } from 'store/store';
import {
  getSeasonByCode,
  getSeasonsByTeamId,
  getStandings,
  getStandingsLegacy,
} from 'api/api';
import {
  ConferenceTree,
  DivisionsData,
  Season,
  StandingsReadableDto,
} from 'types/Types';
import { mapStandingsToReadable } from 'helpers/helpers';
import styles from './StandingPage.module.scss';
import InfoWrapper from '../Wrappers/InfoWrapper/InfoWrapper';
import ImageBgPageWrapper from '../common/ImageBgPageWrapper/ImageBgPageWrapper';
import Breadcrumbs from '../common/Breadcrumbs/Breadcrumbs';
import ConferenceTab from './ConferenceTab/ConferenceTab';
import ChampionshipTab from './ChampionshipTab/ChampionshipTab';
import DivisionTab from './DivisionTab/DivisionTab';
import { useParams } from 'react-router-dom';
import ShowOn from 'components/core/adaptivity/ShowOn';
import Select from '../common/Select/Select';
import { Modal } from 'antd';
import printer from '../../assets/icons/printer-svgrepo-com.svg';
import StandingPagePDF from './StandingPagePDF';
import { t } from 'i18next';

// TODO: Refactor state management
const StandingsPage = observer(() => {
  const { currentSeasonStore } = useStore();
  const [standings, setStandings] = useState<Array<StandingsReadableDto>>([]);
  const [conferenceList, setConferenceList] = useState<Array<ConferenceTree>>(
    []
  );
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const conferenceRef = useRef<HTMLDivElement>(null);
  const championshipRef = useRef<HTMLDivElement>(null);
  const divisionRef = useRef<HTMLDivElement>(null);
  const [activeTab, setActiveTab] =
    useState<RefObject<HTMLDivElement>>(conferenceRef);
  const [activeSubTab, setActiveSubTab] = useState(0);
  const [seasonFilter, setSeasonFilter] = useState<string>();
  const [seasonPostfix, setSeasonPostfix] = useState<string>('MEN');
  const [season, setSeason] = useState<string>('');
  const { teamCode } = useParams();
  const [open, setOpen] = useState(false);
  const [seasons, setSeasons] = useState<Season[]>();

  const showModal = () => {
    setOpen(true);
  };

  const handleCancel = () => {
    setOpen(false);
  };

  const tabs = [
    {
      tabRef: conferenceRef,
      tabName: 'conference',
      displayName: 'КОНФЕРЕНЦИИ',
    },
    {
      tabRef: championshipRef,
      tabName: 'championship',
      displayName: 'ЧЕМПИОНАТ',
    },
    {
      tabRef: divisionRef,
      tabName: 'division',
      displayName: 'ДИВИЗИОНЫ',
    },
  ];

  function changeActiveTab(ref: RefObject<HTMLDivElement>) {
    const currentElement = activeTab.current;
    if (currentElement?.className.includes(styles.textShow)) {
      currentElement?.classList.remove(styles.textShow);
    }
    const element = ref.current;
    if (!element?.className.includes(styles.textShow)) {
      element?.classList.add(styles.textShow);
    }
    setActiveTab(ref);
  }

  useEffect(() => {
    (async () => {
      const seasonCode = currentSeasonStore.getSeasonByCode(teamCode);
      if (seasonCode) {
        const seasonParam = await getSeasonByCode(seasonCode);
        const seasonList = await getSeasonsByTeamId(
          seasonParam?.data[0]?.attributes.team
        );
        if (seasonList?.data) {
          const seasonsFiltered = seasonList.data.filter(
            (item) => item.attributes.code.length === 7
          );
          setSeasons(seasonsFiltered);
          setSeason(seasonsFiltered[0].attributes.code);
        }
      }
    })();
  }, [teamCode, currentSeasonStore, currentSeasonStore.currentSeason]);

  useEffect(() => {
    if (seasonPostfix === 'LKO' || seasonPostfix === 'L76') {
      changeActiveTab(divisionRef);
    }
  }, [seasonPostfix]);

  useEffect(() => {
    if (season) {
      setSeasonFilter(season?.toString().slice(2, 4));
      setSeasonPostfix(season?.toString().slice(4));
    }
  }, [season]);

  useEffect(() => {
    const getData = async () => {
      if (seasonFilter) {
        try {
          setIsLoading(true);
          let standingsReceived;
          const seasonArg = `20${seasonFilter}${seasonPostfix}` || '';
          try {
            standingsReceived = await getStandings(seasonArg);
          } catch (error) {
            standingsReceived = await getStandingsLegacy(seasonArg);
          }
          const standingsReadable = mapStandingsToReadable(standingsReceived);
          setStandings(standingsReadable);
        } catch (e: any) {
          setStandings([]);
        } finally {
          setIsLoading(false);
        }
      }
    };
    getData();
  }, [seasonFilter, seasonPostfix]);

  useEffect(() => {
    setConferenceList(getGroupsList());
  }, [standings, seasonFilter]);

  function getGroupsList() {
    let groupsList = new Array<ConferenceTree>();
    for (let i = 0; i < standings.length; i++) {
      let confEntry = groupsList.find((x) => x.id === standings[i]?.conference);
      if (!confEntry) {
        let tmpGroup = {
          id: standings[i]?.conference,
          name: standings[i]?.conference_name,
          divisions: new Array<DivisionsData>(),
        };

        if (standings[i]?.division) {
          tmpGroup.divisions.push({
            id: standings[i]?.division,
            name: standings[i]?.division_name,
          });
        }

        groupsList.push(tmpGroup);
      } else {
        if (standings[i]?.division) {
          if (
            !groupsList.find((x) =>
              x.divisions.find(
                (y: DivisionsData) => y.id === standings[i]?.division
              )
            )
          ) {
            confEntry.divisions.push({
              id: standings[i]?.division,
              name: standings[i]?.division_name,
            });
          }
        }
      }
    }

    groupsList.sort((a, b) => {
      if (!Number(a.id) || !Number(b.id)) {
        return 0;
      }
      return Number(a.id) - Number(b.id);
    });

    for (let i = 0; i < groupsList.length; i++) {
      groupsList[i].divisions.sort((a, b) => {
        if (!Number(a.id) || !Number(b.id)) {
          return 0;
        }
        return Number(a.id) - Number(b.id);
      });
    }

    return groupsList;
  }

  function changeFilter(e: string) {
    setSeasonFilter(e.slice(11) || '0');
  }

  const getSeasonSelectValue = (year?: string | number) =>
    year ? `СЕЗОН 20${Number(year) - 1}/${year}` : 'СЕЗОН';

  function getSeasonSelectOptions() {
    return (
      seasons?.map((season) => {
        return {
          value: `СЕЗОН ${season.attributes.years_interval}`,
          displayValue: `СЕЗОН ${season.attributes.years_interval}`,
        };
      }) ?? []
    );
  }

  function getTabsSelectOptions(
    tabs: Array<{
      tabRef: React.RefObject<HTMLDivElement>;
      tabName: string;
      displayName: string;
    }>
  ) {
    return tabs.map((tab) => {
      return {
        key: tab.displayName,
        value: tab.displayName,
        displayValue: tab.displayName,
      };
    });
  }

  function onChangeSelectValue(value: string) {
    const tab = tabs.find((el) => el.displayName === value);
    if (tab) {
      changeActiveTab(tab?.tabRef);
    }
  }

  function renderSelect() {
    return (
      <Select
        placeholder={getSeasonSelectValue(season.slice(2, 4))}
        values={getSeasonSelectOptions()}
        onChange={changeFilter}
      />
    );
  }

  return (
    <ImageBgPageWrapper>
      <InfoWrapper
        isWhiteBackground={false}
        innerPaddingTop='10px'
        borderRadius='0px'
      >
        <div className={styles.breadcrumbsPadding}>
          <Breadcrumbs
            breadcrumbs={[
              {
                name: `${t('standingPage.standingsTitle')}`,
                url: `/standings/${teamCode}`,
              },
            ]}
            isWhiteColor={false}
          />
        </div>
        <div className={styles.wrapper}>
          <div className={styles.innerWrapper}>
            <ShowOn largeDesktop smallDesktop largeTablet smallTablet>
              <div className={styles.titleFlex}>
                <h1 className={styles.title}>
                  {t('standingPage.standingsTitle')}
                </h1>
                <div className={styles.selectWrapper}>
                  <button onClick={showModal} className={styles.printerBtn}>
                    <img src={printer} width={20} height={20} alt='' />
                  </button>
                  {renderSelect()}
                </div>
                <Modal
                  open={open}
                  title={t('standingPage.khlTable')}
                  width={1000}
                  footer={[]}
                  onCancel={handleCancel}
                >
                  <StandingPagePDF standings={standings} />
                </Modal>
              </div>
              <div className={styles.tabBar} id='tab-bar'>
                {tabs.map((tab) => (
                  <a
                    key={tab.displayName}
                    onClick={() => changeActiveTab(tab.tabRef)}
                    className={activeTab === tab.tabRef ? styles.active : ''}
                    data-tab={tab.tabName}
                  >
                    <span className={styles.tabSpan}>{tab.displayName}</span>
                  </a>
                ))}
              </div>
            </ShowOn>

            <ShowOn largeMobile smallMobile>
              <h1 className={styles.title}>
                {t('standingPage.standingsTitle')}
              </h1>
              <div className={styles.selectsWrappers}>
                {renderSelect()}
                <Select
                  placeholder={
                    seasonPostfix === 'MEN' || seasonPostfix === 'MENprip'
                      ? 'КОНФЕРЕНЦИИ'
                      : 'ДИВИЗИОНЫ'
                  }
                  values={getTabsSelectOptions(tabs)}
                  onChange={onChangeSelectValue}
                />
              </div>
            </ShowOn>

            <ConferenceTab
              conferenceRef={conferenceRef}
              activeSubTab={activeSubTab}
              setActiveSubTab={setActiveSubTab}
              styles={styles}
              isLoading={isLoading}
              standings={standings}
              conferenceList={conferenceList}
            />

            <ChampionshipTab
              championshipRef={championshipRef}
              styles={styles}
              isLoading={isLoading}
              standings={standings}
            />

            <DivisionTab
              divisionRef={divisionRef}
              activeSubTab={activeSubTab}
              setActiveSubTab={setActiveSubTab}
              styles={styles}
              isLoading={isLoading}
              standings={standings}
              conferenceList={conferenceList}
            />
          </div>
        </div>
      </InfoWrapper>
    </ImageBgPageWrapper>
  );
});

export default StandingsPage;
