import { ReactElement } from 'react';
import { getMatchDateInfo } from 'helpers/helpers';
import Team from 'components/GameWidgetHPBlock/Team/Team';
import { PlayoffStandingsReadableDto, StandingsReadableDto } from 'types/Types';
import styles from './PlayoffGame.module.scss';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

type PlayoffGameProps = {
  game: PlayoffStandingsReadableDto;
  generalStandings: Array<StandingsReadableDto>;
  isGameInFuture?: boolean;
};

function PlayoffGame({
  game,
  generalStandings,
  isGameInFuture,
}: PlayoffGameProps): ReactElement {
  const { t } = useTranslation();
  const getDate = () => {
    if (!game?.date || !game?.time) {
      return;
    }

    const date = new Date(`${game.date} ${game.time}`);
    return getMatchDateInfo(date, true);
  };

  const getLogo = (teamId: string) =>
    generalStandings.find((team) => team.club_id === teamId)?.logo ?? '';

  return (
    <div className={styles.widgetWrapper}>
      {
        <div className={styles.headerMatch}>
          {isGameInFuture
            ? t('homepage.gameWidget.nextMatch')
            : t('homepage.gameWidget.prevMatch')}
        </div>
      }
      <div className={styles.headerDate}>{getDate()}</div>
      <div className={styles.widgetTeams}>
        <Team name={game?.teamaName} city={game?.teamaCity} isCurrent={false}>
          <img
            src={getLogo(game.teama)}
            className={styles.logo}
            alt='team-logo'
          />
        </Team>
        <div className={styles.scoreNumber}>
          <span
            className={classNames(styles.scoreText, {
              [styles.grey]: isGameInFuture,
            })}
          >
            {isGameInFuture ? '0 : 0' : game?.score?.split('').join(' ')}
          </span>
          <div className={styles.stadium}>{game?.arena}</div>
        </div>
        <Team name={game?.teambName} city={game?.teambCity} isCurrent={false}>
          <img
            src={getLogo(game.teamb)}
            className={styles.logo}
            alt='team-logo'
          />
        </Team>
      </div>
    </div>
  );
}

export default PlayoffGame;
