import { ReactElement } from 'react';
import { TopPartnersDto } from '../../../../types/Types';
import styles from './Partner.module.scss';

type TopPartnerProps = {
  partner: TopPartnersDto;
  last?: boolean;
};

const TopPartner = ({
  partner,
  last = false,
}: TopPartnerProps): ReactElement => (
  <div key={partner.id} className={last ? styles.partnerLast : styles.partner}>
    <a
      href={partner.attributes.url}
      rel='external'
      target='_blank'
      id={`top-partner-${partner?.id}`}
    >
      <img
        className={last ? styles.partnerLastImg : styles.partnerImg}
        src={partner.attributes.image?.data?.attributes.url}
        alt={partner.attributes.name}
      />
    </a>
  </div>
);

export default TopPartner;
