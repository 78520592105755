import { ReactElement, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useStore } from 'store/store';
import styles from './UserProfileWidget.module.scss';
import { DEFAULT_IMAGE_NAME } from 'constants/constants';
import { Modal } from 'antd';
import EditProfileModal from './EditProfileModal/EditProfileModal';
import { useTranslation } from 'react-i18next';
import { getDefaultPlayerImage } from 'api/api';
import { DefaultImageObject } from 'types/Types';

const UserProfileWidget = observer((): ReactElement => {
  const { userStore } = useStore();
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [defaultPhoto, setDefaultPhoto] = useState<DefaultImageObject>();

  useEffect(() => {
    const getData = async () => {
      try {
        const photo = await getDefaultPlayerImage(DEFAULT_IMAGE_NAME);
        setDefaultPhoto(photo.data[0]);
      } catch (e: any) {
        console.error(e);
      }
    };
    getData();
  }, []);

  const getUserName = () => {
    if (userStore.currentUser?.name && userStore.currentUser?.surname) {
      return `${userStore.currentUser.name} ${userStore.currentUser.surname}`;
    } else {
      return userStore.currentUser?.username;
    }
  };

  const onLogoutClick = () => {
    userStore.logout();
  };

  return (
    <div className={styles.userProfileWidget}>
      <div className={styles.imageWrapper}>
        <img
          className={styles.image}
          src={
            userStore.currentUser?.avatar?.url
              ? userStore.currentUser?.avatar?.url
              : defaultPhoto?.attributes?.image?.data?.attributes?.url
          }
          alt=''
        />
      </div>
      <div className={styles.profileData}>
        <div className={styles.userName}>{getUserName()}</div>
        <div className={styles.actionButtons}>
          <div className={styles.action} onClick={() => setIsModalOpen(true)}>
            {t('memoryBookPage.userProfileWidget.edit')}
          </div>
          <div className={styles.action} onClick={onLogoutClick}>
            {t('memoryBookPage.userProfileWidget.logout')}
          </div>
        </div>
      </div>
      <Modal
        open={isModalOpen}
        onCancel={() => setIsModalOpen(false)}
        footer={null}
        centered
        className={styles.modal}
        destroyOnClose={true}
      >
        <EditProfileModal onCancel={() => setIsModalOpen(false)} />
      </Modal>
    </div>
  );
});

export default UserProfileWidget;
