import { useState } from 'react';
import styles from './Sponsor.module.scss';
import { ReactComponent as SponsorArrowDownIcon } from 'assets/sponsor_arrow_down.svg';
import { ReactComponent as SponsorArrowUpIcon } from 'assets/sponsors_arrow_up.svg';
import { SponsorType } from 'types/Types';
import { addTargetBlank } from 'helpers/helpers';

type SponsorProps = {
  sponsor: SponsorType;
};

const Sponsor = ({ sponsor }: SponsorProps) => {
  const [showDescription, setShowDescription] = useState<boolean>(false);

  function fixRelativeUrls(htmlString: string) {
    // Создаем временный DOM-элемент для парсинга HTML
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = htmlString;

    // Находим все теги <a> в HTML
    const links = tempDiv.querySelectorAll('a');

    links.forEach((link) => {
      const href = link.getAttribute('href');
      // Проверяем, является ли ссылка относительной
      if (href && !href.startsWith('http://') && !href.startsWith('https://')) {
        link.href = `https://${href}`;
      }
    });

    return tempDiv.innerHTML;
  }

  return (
    <>
      {showDescription ? (
        <div className={styles.flexWrapper}>
          <div className={styles.flexWrapperGap}>
            <div className={styles.imageWrapper}>
              <img
                src={sponsor.attributes.logo.data?.attributes.url}
                alt='Нет изображения'
              />
            </div>
            <div className={styles.descriptionNotHidden}>
              <h4 className={styles.name}>{sponsor.attributes.name}</h4>
              <div
                className={styles.description}
                dangerouslySetInnerHTML={{
                  __html:
                    fixRelativeUrls(
                      addTargetBlank(sponsor.attributes.description)
                    ) || '',
                }}
              />
            </div>
          </div>
          <div
            className={styles.arrowWrapper}
            onClick={() => setShowDescription(!showDescription)}
          >
            {showDescription ? (
              <SponsorArrowUpIcon />
            ) : (
              <SponsorArrowDownIcon />
            )}
          </div>
        </div>
      ) : (
        <div className={styles.flexWrapperHidden}>
          <div className={styles.flexWrapperGapHidden}>
            <div className={styles.imageWrapper}>
              <img
                className={styles.image}
                src={sponsor.attributes.logo.data?.attributes.url}
                alt='Нет изображения'
              />
            </div>
            <h4 className={styles.name}>{sponsor.attributes.name}</h4>
          </div>
          <div
            className={styles.arrowWrapper}
            onClick={() => setShowDescription(!showDescription)}
          >
            {showDescription ? (
              <SponsorArrowUpIcon />
            ) : (
              <SponsorArrowDownIcon />
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default Sponsor;
