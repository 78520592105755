import { ReactElement } from 'react';
import { DefaultImageObject, ExecutiveDto } from '../../types/Types';
import ExecutivesSmallTile from '../ExecutivesSmallTile/ExecutivesSmallTile';
import styles from './ExecutivesSmallTiles.module.scss';

type ExecutiveTilesProps = {
  executives: ExecutiveDto[];
  season?: string;
  defaultPhoto?: DefaultImageObject;
};

function ExecutiveSmallTiles({
  executives,
  season,
  defaultPhoto,
}: ExecutiveTilesProps): ReactElement {
  return (
    <div className={styles.wrapper}>
      {executives.map((executive) => (
        <ExecutivesSmallTile
          season={season}
          executive={executive}
          key={executive.id}
          defaultPhoto={defaultPhoto}
        />
      ))}
    </div>
  );
}

export default ExecutiveSmallTiles;
