import { ReactElement } from 'react';
import { usePDF } from 'react-to-pdf';
import { PlayerSeasonStatsDtoReadable } from 'types/Types';
import styles from './StatsLokomotivPage.module.scss';
import { lokomotivStatsPDFname } from 'constants/constants';

type StatsLokomotivPagePDFProps = {
  notGoalkeepersStats: PlayerSeasonStatsDtoReadable[];
  goalkeepersStats: PlayerSeasonStatsDtoReadable[];
};

function StatsLokomotivPagePDF({
  notGoalkeepersStats,
  goalkeepersStats,
}: StatsLokomotivPagePDFProps): ReactElement {
  const { toPDF, targetRef } = usePDF({ filename: lokomotivStatsPDFname });
  return (
    <>
      <div ref={targetRef}>
        <div>Игроки</div>
        <table className='table w-full'>
          <thead>
            <tr>
              <th>#</th>
              <th>Игрок</th>
              <th>И</th>
              <th>Ш</th>
              <th>П</th>
              <th>О</th>
              <th>+/-</th>
              <th>ШТР</th>
              <th>ШБ</th>
              <th>ШМ</th>
              <th>ШО</th>
              <th>БВ</th>
              <th>%БВ</th>
              <th>%Вбр</th>
              <th>ВП/И</th>
            </tr>
          </thead>
          <tbody>
            {notGoalkeepersStats.map((player) => {
              return (
                <tr>
                  <td>{player.number}</td>
                  <td>
                    {player.namelocal} {player.lastname}
                  </td>
                  <td>{player.games_played}</td>
                  <td>{player.goals}</td>
                  <td>{player.assists}</td>
                  <td>{player.points}</td>
                  <td>{player.plus_minus}</td>
                  <td>{player.penalty_in_minutes}</td>
                  <td>{player.power_play_goals}</td>
                  <td>{player.shorthanded_goals}</td>
                  <td>{player.overtime_goals}</td>
                  <td>{player.shots_on_goal}</td>
                  <td>{player.shots_on_goal_percent}</td>
                  <td>{player.faceoffs_wons_percent}</td>
                  <td>{player.faceoffs_wons_percent}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
        <div>Вратари</div>
        <table className='table w-full'>
          <thead>
            <tr>
              <th>#</th>
              <th>Игрок</th>
              <th>И</th>
              <th>В</th>
              <th>П</th>
              <th>ИБ</th>
              <th>Бр</th>
              <th>ПШ</th>
              <th>ОБ</th>
              <th>%ОБ</th>
              <th>КН</th>
              <th>Ш</th>
              <th>A</th>
              <th>И'0'</th>
              <th>ШТР</th>
              <th>ВП</th>
            </tr>
          </thead>
          <tbody>
            {goalkeepersStats.map((player) => {
              return (
                <tr>
                  <td>{player.number}</td>
                  <td>
                    {player.namelocal} {player.lastname}
                  </td>
                  <td>{player.games_played}</td>
                  <td>{player.wins}</td>
                  <td>{player.losses}</td>
                  <td>{player.game_with_b_series}</td>
                  <td>{player.shots_on_goal}</td>
                  <td>{player.goals_against}</td>
                  <td>{player.savings}</td>
                  <td>{player.savings_percent}</td>
                  <td>{player.goals_against_average}</td>
                  <td>{player.goals}</td>
                  <td>{player.assists}</td>
                  <td>{player.shootouts}</td>
                  <td>{player.penalty_in_minutes}</td>
                  <td>{player.time_on_ice}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <div className={styles.buttonDownloadWrapper}>
        <button onClick={() => toPDF()} className={styles.buttonDownload}>
          Скачать PDF
        </button>
      </div>
    </>
  );
}

export default StatsLokomotivPagePDF;
