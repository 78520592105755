import { ReactElement } from 'react';
import styles from './PlayerNotHoverCard.module.scss';
import {
  DefaultImageObject,
  PlayerWithRelations,
  RosterDto,
  SeasonsTeamCode,
} from '../../../../../types/Types';
import { DEFAULT_IMAGE_NAME } from '../../../../../constants/constants';
import InfoBox from '../InfoBox/InfoBox';
import { getAge, getDate, getNoun } from '../../../../../helpers/helpers';
import { useParams } from 'react-router-dom';
import ImageLoader from '../../../../common/ImageLoaders/ImageLoader/ImageLoader';
import trauma from '../../../../../assets/trauma.svg';
import { useTranslation } from 'react-i18next';

type RosterPlayerProps = {
  player: RosterDto;
  defaultImages: DefaultImageObject[];
  playerInfo: PlayerWithRelations | undefined;
};

function PlayerNotHoverCard({
  player,
  defaultImages,
  playerInfo,
}: RosterPlayerProps): ReactElement {
  const { t } = useTranslation();
  const { teamCode } = useParams();

  const isCatastrophe = teamCode === SeasonsTeamCode.catastrophe;
  const defaultImage =
    defaultImages?.find((x) => x.attributes.description === DEFAULT_IMAGE_NAME)
      ?.attributes?.image?.data || '';

  return (
    <div className={styles.wrapper}>
      <div className={styles.imgWrapper}>
        <div>
          <ImageLoader
            className={styles.img}
            image={playerInfo?.attributes?.photo?.data || defaultImage}
          />
          {player.attributes?.trauma && (
            <img
              src={trauma}
              title={t('rosterPage.playerCard.injury')}
              className={styles.injury}
              alt=''
            />
          )}
        </div>
      </div>
      <div className={styles.nameWrapper}>
        <span className={styles.number}>{player.attributes.number}</span>
        <div className={styles.blockWrapper}>
          <span className={styles.name}>
            {player.attributes?.player?.data?.attributes?.name}
          </span>
          <span className={styles.name}>
            {player.attributes?.player?.data?.attributes?.surname}
          </span>
        </div>
      </div>
      <div className={styles.infoWrapper}>
        {playerInfo?.attributes?.citizenship && !isCatastrophe && (
          <InfoBox
            name={t('rosterPage.playerCard.citizenship')}
            value={
              playerInfo?.attributes?.citizenship?.data?.attributes?.name || ''
            }
            flagUri={
              playerInfo?.attributes?.citizenship?.data?.attributes?.flag?.data
                ?.attributes?.url
            }
          />
        )}
        {playerInfo?.attributes?.birth && !isCatastrophe && (
          <InfoBox
            name={t('rosterPage.playerCard.age')}
            value={getAge(playerInfo?.attributes?.birth)}
            dopInfoBox={getNoun(
              getAge(playerInfo?.attributes?.birth),
              'год',
              'года',
              'лет'
            )}
          />
        )}
        {!playerInfo?.attributes?.hide_contract_info && !isCatastrophe && (
          <InfoBox
            name={t('rosterPage.playerCard.contractEnds')}
            value={getDate(playerInfo?.attributes?.contract_ends) || ''}
          />
        )}
      </div>
    </div>
  );
}

export default PlayerNotHoverCard;
