import cn from 'classnames';
import { ReactElement, useEffect, useState } from 'react';
import {
  getAllRosterSeasons,
  getAlbumByFilters,
  getDefaultImages,
} from '../../api/api';
import { mapRosterSeasonsToYearsInterval } from '../../mapping/mapping';
import { DefaultImageObject, PhotoCategory } from '../../types/Types';
import ImageBgPageWrapper from '../common/ImageBgPageWrapper/ImageBgPageWrapper';
import Container from '../common/Container/Container';
import BreadcrumbsWrapper from 'components/common/BreadcrumbsWrapper/BreadcrumbsWrapper';
import PageHeader from 'components/common/PageHeader/PageHeader';
import InfoWrapper from '../Wrappers/InfoWrapper/InfoWrapper';
import AlbumCover from '../HomePage/PhotoGallery/AlbumCover/AlbumCover';
import ViewMoreButton from '../ViewMoreButton/ViewMoreButton';
import ScrollToTopButton from '../ScrollToTopButton/ScrollToTopButton';
import { MONTH } from '../../constants/constants';
import Spinner from '../Spinner/Spinner';
import styles from './Photogallery.module.scss';
import Select from 'components/common/Select/Select';
import ErrorWidget from 'components/common/ErrorWidget/ErrorWidget';
import { useTranslation } from 'react-i18next';

const ALBUM_PAGE_AMOUNT = 12;

const Photogallery = (): ReactElement => {
  const { t } = useTranslation();
  const [albums, setAlbums] = useState<Array<PhotoCategory>>([]);
  const [yearsOptions, setYearsOptions] = useState<
    Array<{ key: string; value: string }>
  >([]);
  const [category, setCategory] = useState<string | null>(null);
  const [month, setMonth] = useState<number | null>(null);
  const [year, setYear] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);
  const [isRosterSeasonsError, setIsRosterSeasonsError] =
    useState<boolean>(false);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [pageCount, setPageCount] = useState<number>(ALBUM_PAGE_AMOUNT);
  const [defaultImages, setDefaultImages] = useState<DefaultImageObject[]>([]);
  const [countColumns, setCountColumns] = useState<number>(0);

  useEffect(() => {
    const handleResize = () => {
      const windowWidth = window.innerWidth;
      if (windowWidth > 1000) {
        setCountColumns(3);
      } else if (windowWidth > 768) {
        setCountColumns(2);
      } else {
        setCountColumns(1);
      }
    };
    window.addEventListener('resize', handleResize);
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    const getData = async () => {
      try {
        const response = await getAlbumByFilters(
          category,
          month !== null ? month + 1 : null,
          year,
          pageCount
        );
        setTotalCount(response?.meta?.pagination?.total ?? 0);
        setAlbums(response?.data);
      } catch (e: any) {
        setIsError(true);
      } finally {
        setIsLoading(false);
      }
    };

    setIsLoading(true);
    getData();
  }, [pageCount, category, month, year]);

  useEffect(() => {
    const getData = async () => {
      try {
        const resp = await getAllRosterSeasons();
        setYearsOptions(mapRosterSeasonsToYearsInterval(resp?.data));
      } catch (error) {
        setIsRosterSeasonsError(true);
      }

      try {
        const images = await getDefaultImages();
        setDefaultImages(images?.data);
      } catch (error: any) {
        console.error(error?.response?.data?.error || error?.message);
      }
    };

    getData();
  }, []);

  useEffect(() => {
    setPageCount(ALBUM_PAGE_AMOUNT);
  }, [category, month, year]);

  const sectionSelectOptions = [
    {
      value: '',
      displayValue: t('photoGalleryPage.select.allSections'),
      onClick: setCategory,
    },
    {
      value: 'MEN',
      displayValue: t('photoGalleryPage.select.men'),
      onClick: setCategory,
    },
  ];

  function getYearSelectOptions(
    yearsOptions: Array<{ key: string; value: string }>
  ) {
    return yearsOptions.map((season) => {
      return {
        key: season.value,
        value: season.value,
        displayValue: season.value,
        onClick: setYear,
      };
    });
  }

  const monthSelectOptions = [
    {
      value: '',
      displayValue: t('photoGalleryPage.selectMonthAll'),
      onClick: () => setMonth(null),
    },
    ...Array.from(MONTH.entries()).map(([key, value]) => {
      return {
        value: key,
        displayValue: value,
        onClick: setMonth,
      };
    }),
  ];

  return (
    <ImageBgPageWrapper>
      <Container paddingTop='50px' paddingBottom='0px'>
        <BreadcrumbsWrapper
          breadcrumbs={[
            { name: t('photoGalleryPage.breadcrumb'), url: `/photogallery` },
          ]}
        />
        <div className={styles.pageHeaderWrapper}>
          <PageHeader text={t('photoGalleryPage.header')} />
        </div>
        <InfoWrapper isWhiteBackground={true} isPaddingZero={true}>
          <div className={styles.innerWrapper}>
            <div className={styles.searchWrapper}>
              <Select
                placeholder={t('photoGalleryPage.select.allSections')}
                className={cn(styles.select, styles.selectCategory)}
                values={sectionSelectOptions}
                onChange={setCategory}
              />

              {!isRosterSeasonsError && (
                <Select
                  placeholder={t('photoGalleryPage.selectYear')}
                  className={cn(styles.select, styles.selectYear)}
                  values={getYearSelectOptions(yearsOptions)}
                  onChange={setYear}
                />
              )}

              {!isRosterSeasonsError && (
                <Select
                  placeholder={t('photoGalleryPage.selectMonth')}
                  className={cn(styles.select, styles.selectMonth)}
                  disabled={!year}
                  values={monthSelectOptions}
                  onChange={setMonth}
                />
              )}
            </div>
            {isError ? (
              <ErrorWidget.Error />
            ) : (
              <>
                {albums && albums.length > 0 ? (
                  <div className={styles.photogalleryContainer}>
                    {albums?.map((album, i) => (
                      <AlbumCover
                        key={album.id}
                        album={album}
                        defaultPhoto={
                          defaultImages && defaultImages.length > 0
                            ? defaultImages[0]
                            : null
                        }
                      />
                    ))}
                  </div>
                ) : (
                  <div className={styles.photoNotFound}>
                    {t('photoGalleryPage.photoNotFound')}
                  </div>
                )}
                {pageCount < totalCount ? (
                  <div className={styles.viewMoreWrapper}>
                    {isLoading ? (
                      <Spinner />
                    ) : (
                      <ViewMoreButton
                        onClick={() =>
                          setPageCount(
                            (prevState) => prevState + ALBUM_PAGE_AMOUNT
                          )
                        }
                        disabled={isLoading}
                      />
                    )}
                  </div>
                ) : null}
              </>
            )}
            {albums && albums.length > countColumns && (
              <div className={styles.scrollToTopButton}>
                <ScrollToTopButton />
              </div>
            )}
          </div>
        </InfoWrapper>
      </Container>
    </ImageBgPageWrapper>
  );
};

export default Photogallery;
