import { ReactElement } from 'react';

interface UpperRightArrowProps {
  fillColor: string;
  rotateDegrees?: number;
  height: string;
  width: string;
}

export default function DislikeIcon({
  fillColor,
  rotateDegrees = 0,
  height,
  width,
}: UpperRightArrowProps): ReactElement {
  return (
    <div
      style={{
        rotate: `${rotateDegrees}deg`,
        transition: 'all 0.3s',
        width: `${width}px`,
        height: `${height}px`,
      }}
    >
      <svg
        width={width}
        height={height}
        viewBox='0 0 24 24'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M10.1039 17.5C10.1039 19 11.5859 20 12.7439 20C13.5499 20 13.6129 19.388 13.7369 18.18C13.7919 17.65 13.8579 17.006 14.0039 16.25C14.3899 14.248 15.7239 11.69 16.9999 10.925V5C17.0039 2.75 16.2539 2 13.0039 2H9.23091C7.0549 2 6.52791 3.433 6.33191 3.964L6.31891 4C6.20491 4.306 5.96091 4.547 5.68091 4.82C5.37091 5.126 5.01691 5.473 4.75391 6C4.44291 6.623 4.48391 7.177 4.52091 7.67C4.54391 7.969 4.56491 8.245 4.50391 8.5C4.43991 8.77 4.35791 8.975 4.27891 9.171C4.13591 9.527 4.00391 9.857 4.00391 10.5C4.00391 12 4.75191 12.998 6.31891 12.998H10.5039C10.5039 12.998 10.1039 16 10.1039 17.5ZM20.5039 12C20.9017 12 21.2833 11.842 21.5646 11.5607C21.8459 11.2794 22.0039 10.8978 22.0039 10.5V3.5C22.0039 3.10217 21.8459 2.72065 21.5646 2.43934C21.2833 2.15804 20.9017 2 20.5039 2C20.1061 2 19.7246 2.15804 19.4432 2.43934C19.1619 2.72065 19.0039 3.10217 19.0039 3.5V10.5C19.0039 10.8978 19.1619 11.2794 19.4432 11.5607C19.7246 11.842 20.1061 12 20.5039 12Z'
          fill={fillColor}
        />
      </svg>
    </div>
  );
}
