import { ReactElement, useEffect, useState } from 'react';
import PageHeader from 'components/common/PageHeader/PageHeader';
import BreadcrumbsWrapper from 'components/common/BreadcrumbsWrapper/BreadcrumbsWrapper';
import Container from '../../common/Container/Container';
import ImageBgPageWrapper from '../../common/ImageBgPageWrapper/ImageBgPageWrapper';
import { getPartners, getSubscriptionPrivilegesData } from '../../../api/api';
import {
  PartnerDto,
  SubscriptionPrivilegesPageDto,
} from '../../../types/Types';
import Parking from '../../../assets/icons/parking.svg';
import Bonus from '../../../assets/icons/bonus.svg';
import Discount from '../../../assets/icons/discount.svg';
import Gift from '../../../assets/icons/gift.svg';
import Shop from '../../../assets/icons/shop.svg';
import Playoff from '../../../assets/icons/playoff.svg';
import SliderWrapper from 'components/SliderWrapper/SliderWrapper';
import { sortPartnersDto } from 'helpers/helpers';
import styles from './SubscriptionPrivilegesPage.module.scss';
import ErrorWidget from 'components/common/ErrorWidget/ErrorWidget';
import { useTranslation } from 'react-i18next';
import Spinner from 'components/Spinner/Spinner';

const responsiveSetting = [
  {
    breakpoint: 1000,
    settings: {
      slidesToShow: 3,
    },
  },
  {
    breakpoint: 768,
    settings: {
      slidesToShow: 2,
    },
  },
  {
    breakpoint: 480,
    settings: {
      slidesToShow: 1,
    },
  },
];

const SubscriptionPrivilegesPage = (): ReactElement => {
  const { t } = useTranslation();
  const [pageData, setPageData] = useState<SubscriptionPrivilegesPageDto>();
  const [patners, setPartners] = useState<PartnerDto[]>();
  const [isError, setIsError] = useState<boolean>(false);

  const advantages = [
    {
      icon: Parking,
      title: t('subscriptionPrivilegesPage.advantages.parking'),
    },
    {
      icon: Bonus,
      title: t('subscriptionPrivilegesPage.advantages.cashback'),
    },
    {
      icon: Discount,
      title: t('subscriptionPrivilegesPage.advantages.discount'),
    },
    {
      icon: Gift,
      title: t('subscriptionPrivilegesPage.advantages.gift'),
    },
    {
      icon: Shop,
      title: t('subscriptionPrivilegesPage.advantages.shop'),
    },
    {
      icon: Playoff,
      title: t('subscriptionPrivilegesPage.advantages.playoff'),
    },
  ];

  useEffect(() => {
    const getData = async () => {
      try {
        const subscriptionDataResponse = await getSubscriptionPrivilegesData();
        const partnersResponse = await getPartners();

        setPageData(subscriptionDataResponse);
        setPartners(
          partnersResponse?.data
            ?.filter(
              (partner: PartnerDto) =>
                partner?.attributes?.partner_category?.data?.id === 3
            )
            .sort(sortPartnersDto)
        );
      } catch (error: any) {
        setIsError(true);
        console.error(error?.response?.data?.error || error?.message);
      }
    };

    getData();
  }, []);

  return patners ? (
    <ImageBgPageWrapper>
      <Container paddingTop='50px'>
        <div className={styles.wrapper}>
          <BreadcrumbsWrapper
            breadcrumbs={[
              {
                name: pageData?.data?.attributes.title ?? '',
                url: '/subscription-privileges',
              },
            ]}
          />
          <div className={styles.content}>
            <PageHeader text={pageData?.data?.attributes.title ?? ''} />
            <div className={styles.subscription}>
              <div className={styles.subscriptionInfo}>
                <h2 className={styles.subscriptionTitle}>
                  {pageData?.data?.attributes.subscription_title ?? ''}
                </h2>
                <p className={styles.subscriptionDescription}>
                  {pageData?.data?.attributes.subscription_description ?? ''}
                </p>
              </div>
              {pageData?.data?.attributes?.subscription_image?.data ? (
                <img
                  className={styles.subscriptionImage}
                  src={
                    pageData.data.attributes.subscription_image?.data
                      ?.attributes?.url
                  }
                  alt='subscription-img'
                />
              ) : null}
            </div>
            <div className={styles.header}>
              <h2 className={styles.title}>
                {t('subscriptionPrivilegesPage.prosTitle')}
              </h2>
              <p className={styles.description}>
                {t('subscriptionPrivilegesPage.prosDescription')}
              </p>
            </div>
            <div className={styles.advantagesList}>
              {advantages.map((item, index) => (
                <div key={index} className={styles.advantagesItem}>
                  <div className={styles.iconWrapper}>
                    <img className={styles.icon} src={item.icon} alt='icon' />
                  </div>
                  <p className={styles.text}>{item.title}</p>
                </div>
              ))}
            </div>
            <div className={styles.header}>
              <h2 className={styles.title}>
                {t('subscriptionPrivilegesPage.bonusesTitle')}
              </h2>
              <p className={styles.description}>
                {t('subscriptionPrivilegesPage.bonusesDescription')}
              </p>
            </div>
            {patners ? (
              <div className={styles.sliderContainer}>
                <SliderWrapper
                  className={styles.slider}
                  arrows={true}
                  dots={false}
                  infinite={false}
                  responsive={responsiveSetting}
                  slidesToShow={4}
                  slidesToScroll={1}
                  slides={patners.map((patner) => (
                    <div key={patner.id} className={styles.image}>
                      <img
                        src={patner.attributes.logo.data.attributes.url}
                        alt='partner-logo'
                      />
                    </div>
                  ))}
                  speed={500}
                />
              </div>
            ) : null}
          </div>
        </div>
      </Container>
    </ImageBgPageWrapper>
  ) : isError ? (
    <ErrorWidget.Error />
  ) : (
    <Spinner />
  );
};

export default SubscriptionPrivilegesPage;
