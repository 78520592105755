import styles from './MobileHeaderDrawerFooter.module.scss';
import { MenuElementDto } from 'types/Types';
import Row from 'components/core/layout/Row';
import MobileHeaderTickets from '../MobileHeaderTickets/MobileHeaderTickets';
import MobileHeaderShop from '../MobileHeaderShop/MobileHeaderShop';

interface MobileHeaderDrawerFooterProps {
  allMenu: Array<MenuElementDto>;
}

const MobileHeaderDrawerFooter = ({
  allMenu,
}: MobileHeaderDrawerFooterProps) => (
  <div className={styles.footer}>
    <Row alignItems='center' justifyContent='space-around'>
      <MobileHeaderTickets allMenu={allMenu} isDrawer />
      <div className={styles.divider}>
        <div className={styles.dividerLine}></div>
      </div>
      <MobileHeaderShop isDrawer />
    </Row>
  </div>
);

export default MobileHeaderDrawerFooter;
