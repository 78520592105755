import Strapi from 'strapi-sdk-js';
import TicketCategoryDAO from './dao/TicketCategoryDAO';
import config from 'config';
import ArticleDAO from './dao/ArticleDAO';
import SubscriptionTypeDAO from './dao/SubscriptionTypeDAO';
import ArenaCategoryDAO from './dao/ArenaCategoryDAO';
import SubscriptionDAO from './dao/SubscriptionDAO';
import ArenaSectorDAO from './dao/ArenaSectorDAO';
import ArticleCategoryDAO from './dao/ArticleCategoryDAO';

const lokoStrapi = new Strapi({
  url: config.HCLBackend.baseUrl,
});

const lokoApi = {
  arenaCategory: new ArenaCategoryDAO(lokoStrapi),
  arenaSector: new ArenaSectorDAO(lokoStrapi),
  articleCategory: new ArticleCategoryDAO(lokoStrapi),
  ticketCategory: new TicketCategoryDAO(lokoStrapi),
  article: new ArticleDAO(lokoStrapi),
  subscription: new SubscriptionDAO(lokoStrapi),
  subscriptionType: new SubscriptionTypeDAO(lokoStrapi),
};

export default lokoApi;
