import Strapi, { StrapiRequestParams, StrapiResponse } from 'strapi-sdk-js';

abstract class BaseStrapiDAO<T> {
  protected client: Strapi;
  protected tableNameSingular: string;
  protected tableNamePlural: string;

  constructor({
    client,
    tableNameSingular,
    tableNamePlural,
  }: {
    client: Strapi;
    tableNameSingular: string;
    tableNamePlural: string;
  }) {
    this.client = client;
    this.tableNameSingular = tableNameSingular;
    this.tableNamePlural = tableNamePlural;
  }

  public find(
    params?: StrapiRequestParams | undefined
  ): Promise<StrapiResponse<T[]>> {
    return this.client.find<T[]>(this.tableNamePlural, params);
  }

  public findOne(
    id: string | number,
    params?: StrapiRequestParams | undefined
  ): Promise<StrapiResponse<T>> {
    return this.client.findOne<T>(this.tableNamePlural, id, params);
  }

  public async findAll(
    params?: StrapiRequestParams | undefined
  ): Promise<StrapiResponse<T[]>> {
    let result = [];

    while (true) {
      const next: StrapiResponse<T[]> = await this.find({
        ...params,
        pagination: {
          start: result.length,
          limit: 25,
        },
      });

      if (next.data.length === 0) break;

      result.push(...next.data);
    }

    return { data: result, meta: { pagination: { total: result.length } } };
  }

  public create(
    data: T,
    params?: StrapiRequestParams | undefined
  ): Promise<StrapiResponse<T>> {
    return this.client.create<T>(this.tableNamePlural, data, params);
  }

  public update(
    id: string | number,
    data: T,
    params?: StrapiRequestParams | undefined
  ): Promise<StrapiResponse<T>> {
    return this.client.update(this.tableNamePlural, id, data, params);
  }

  public delete(
    id: string | number,
    params?: StrapiRequestParams | undefined
  ): Promise<StrapiResponse<T>> {
    return this.client.delete(this.tableNamePlural, id, params);
  }
}

export default BaseStrapiDAO;
