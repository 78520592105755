import {
  DefaultImageObject,
  PlayerSeasonStatsDtoReadable,
  StandingsTypes,
} from '../../../types/Types';
import TableTh from '../TableTh/TableTh';
import { useStore } from '../../../store/store';
import styles from './NotGoalkeeperStats.module.scss';
import { getNameSurname } from '../../../helpers/helpers';
import {
  DEFAULT_IMAGE_NAME,
  POSITION_MAP_LETTER,
} from '../../../constants/constants';
import { observer } from 'mobx-react-lite';
import ImageLoader from '../../common/ImageLoaders/ImageLoader/ImageLoader';
import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import ShowOn from 'components/core/adaptivity/ShowOn';
import { useTranslation } from 'react-i18next';

type NotGoalkeepersStatsProps = {
  notGoalkeepersStats: Array<PlayerSeasonStatsDtoReadable>;
  updateSorting: Function;
  defImg: DefaultImageObject[];
};

const NotGoalkeepersStats = observer(
  ({
    notGoalkeepersStats,
    updateSorting,
    defImg,
  }: NotGoalkeepersStatsProps) => {
    const { t } = useTranslation();
    const { currentSeasonStore } = useStore();
    const [season, setSeason] = useState<string>('');
    const { teamCode } = useParams();
    const tableBodyRef = useRef<HTMLTableSectionElement>(null);

    useEffect(() => {
      const seasonCode = currentSeasonStore.getSeasonByCode(teamCode);

      if (seasonCode) {
        setSeason(seasonCode);
      }
    }, [teamCode, currentSeasonStore.currentSeason]);

    return (
      <tbody ref={tableBodyRef}>
        <tr className={styles.tableHeader}>
          <TableTh
            text={t('statsLokomotivPage.stats.text.number')}
            title={t('statsLokomotivPage.stats.title.number')}
            tableBodyRef={tableBodyRef}
            updateSorting={updateSorting}
            updateSortingParam='number'
            isGoalkeeper={false}
            index={1}
            type={StandingsTypes.number}
          />
          <TableTh
            text={t('statsLokomotivPage.stats.text.namelocal')}
            title={t('statsLokomotivPage.stats.title.namelocal')}
            tableBodyRef={tableBodyRef}
            updateSorting={updateSorting}
            updateSortingParam='namelocal'
            isGoalkeeper={false}
            textAlign='left'
            isFirstTh={true}
            index={2}
            type={StandingsTypes.string}
          />
          <TableTh
            text={t('statsLokomotivPage.stats.text.gamesPlayed')}
            title={t('statsLokomotivPage.stats.title.gamesPlayed')}
            tableBodyRef={tableBodyRef}
            updateSorting={updateSorting}
            updateSortingParam='games_played'
            isGoalkeeper={false}
            index={3}
            type={StandingsTypes.number}
          />
          <TableTh
            text={t('statsLokomotivPage.stats.text.goals')}
            title={t('statsLokomotivPage.stats.title.goals')}
            tableBodyRef={tableBodyRef}
            updateSorting={updateSorting}
            updateSortingParam='goals'
            isGoalkeeper={false}
            index={4}
            type={StandingsTypes.number}
          />
          <TableTh
            text={t('statsLokomotivPage.stats.text.assistsPasses')}
            title={t('statsLokomotivPage.stats.title.assistsPasses')}
            tableBodyRef={tableBodyRef}
            updateSorting={updateSorting}
            updateSortingParam='assists'
            isGoalkeeper={false}
            index={5}
            type={StandingsTypes.number}
          />
          <TableTh
            text={t('statsLokomotivPage.stats.text.points')}
            title={t('statsLokomotivPage.stats.title.points')}
            tableBodyRef={tableBodyRef}
            updateSorting={updateSorting}
            updateSortingParam='points'
            isGoalkeeper={false}
            index={6}
            type={StandingsTypes.number}
          />
          <TableTh
            text={t('statsLokomotivPage.stats.text.plusMinus')}
            title={t('statsLokomotivPage.stats.title.plusMinus')}
            tableBodyRef={tableBodyRef}
            updateSorting={updateSorting}
            updateSortingParam='plus_minus'
            isGoalkeeper={false}
            index={7}
            type={StandingsTypes.number}
          />
          <TableTh
            text={t('statsLokomotivPage.stats.text.penaltyInMinutes')}
            title={t('statsLokomotivPage.stats.title.penaltyInMinutes')}
            tableBodyRef={tableBodyRef}
            updateSorting={updateSorting}
            updateSortingParam='penalty_in_minutes'
            isGoalkeeper={false}
            index={8}
            type={StandingsTypes.number}
          />
          <TableTh
            text={t('statsLokomotivPage.stats.text.powerPlayGoals')}
            title={t('statsLokomotivPage.stats.title.powerPlayGoals')}
            tableBodyRef={tableBodyRef}
            updateSorting={updateSorting}
            updateSortingParam='power_play_goals'
            isGoalkeeper={false}
            index={9}
            type={StandingsTypes.number}
          />
          <TableTh
            text={t('statsLokomotivPage.stats.text.shorthandedGoals')}
            title={t('statsLokomotivPage.stats.title.shorthandedGoals')}
            tableBodyRef={tableBodyRef}
            updateSorting={updateSorting}
            updateSortingParam='shorthanded_goals'
            isGoalkeeper={false}
            index={10}
            type={StandingsTypes.number}
          />
          <TableTh
            text={t('statsLokomotivPage.stats.text.overtimeGoals')}
            title={t('statsLokomotivPage.stats.title.overtimeGoals')}
            tableBodyRef={tableBodyRef}
            updateSorting={updateSorting}
            updateSortingParam='overtime_goals'
            isGoalkeeper={false}
            index={11}
            type={StandingsTypes.number}
          />
          <TableTh
            text={t('statsLokomotivPage.stats.text.shotsOnGoalNotGoalkeeper')}
            title={t('statsLokomotivPage.stats.title.shotsOnGoalNotGoalkeeper')}
            tableBodyRef={tableBodyRef}
            updateSorting={updateSorting}
            updateSortingParam='shots_on_goal'
            isGoalkeeper={false}
            index={12}
            type={StandingsTypes.number}
          />
          <TableTh
            text={t('statsLokomotivPage.stats.text.shotsOnGoalPercent')}
            title={t('statsLokomotivPage.stats.title.shotsOnGoalPercent')}
            tableBodyRef={tableBodyRef}
            updateSorting={updateSorting}
            updateSortingParam='shots_on_goal_percent'
            isGoalkeeper={false}
            index={13}
            type={StandingsTypes.number}
          />
          <TableTh
            text={t('statsLokomotivPage.stats.text.faceoffsWonsPercent')}
            title={t('statsLokomotivPage.stats.title.faceoffsWonsPercent')}
            tableBodyRef={tableBodyRef}
            updateSorting={updateSorting}
            updateSortingParam='faceoffs_wons_percent'
            isGoalkeeper={false}
            index={14}
            type={StandingsTypes.number}
          />
          <TableTh
            text={t('statsLokomotivPage.stats.text.timeOnIceAverage')}
            title={t('statsLokomotivPage.stats.title.timeOnIceAverage')}
            tableBodyRef={tableBodyRef}
            updateSorting={updateSorting}
            updateSortingParam='time_on_ice_average'
            isGoalkeeper={false}
            index={15}
            type={StandingsTypes.time}
          />
        </tr>
        {notGoalkeepersStats.map((rosterDtoWithStats) => (
          <tr key={rosterDtoWithStats.id} className={styles.tableTr}>
            <td>
              <span className={styles.number}>{rosterDtoWithStats.number}</span>
            </td>
            <td className={styles.club}>
              <a
                title={t('statsLokomotivPage.stats.profile')}
                href={
                  rosterDtoWithStats.idlocal
                    ? `/player/${rosterDtoWithStats.idlocal}?season=${season}`
                    : ''
                }
              >
                <div className={styles.playerInfo}>
                  <ShowOn largeDesktop smallDesktop largeTablet smallTablet>
                    <ImageLoader
                      className={styles.img}
                      image={
                        rosterDtoWithStats.photo?.data ||
                        defImg?.find(
                          (x) => x.attributes.description == DEFAULT_IMAGE_NAME
                        )?.attributes?.image?.data ||
                        ''
                      }
                    />
                  </ShowOn>

                  <div>
                    <span className={styles.name}>
                      {getNameSurname(rosterDtoWithStats)}
                    </span>
                    <span className={styles.position}>
                      {POSITION_MAP_LETTER.get(rosterDtoWithStats.position)}
                    </span>
                  </div>
                </div>
              </a>
            </td>
            <td>{rosterDtoWithStats.games_played}</td>
            <td>{rosterDtoWithStats.goals}</td>
            <td>{rosterDtoWithStats.assists}</td>
            <td>{rosterDtoWithStats.points}</td>
            <td>{rosterDtoWithStats.plus_minus}</td>
            <td>{rosterDtoWithStats.penalty_in_minutes}</td>
            <td>{rosterDtoWithStats.power_play_goals}</td>
            <td>{rosterDtoWithStats.shorthanded_goals}</td>
            <td>{rosterDtoWithStats.overtime_goals}</td>
            <td>{rosterDtoWithStats.shots_on_goal}</td>
            <td>{rosterDtoWithStats.shots_on_goal_percent}</td>
            <td>{rosterDtoWithStats.faceoffs_wons_percent}</td>
            <td>{rosterDtoWithStats.time_on_ice_average}</td>
          </tr>
        ))}
      </tbody>
    );
  }
);

export default NotGoalkeepersStats;
