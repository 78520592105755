import { ReactElement, PropsWithChildren } from 'react';
import classNames from 'classnames';
import styles from './InfoWrapper.module.scss';
import Container from '../../common/Container/Container';

type InfoWrapperProps = {
  children?: JSX.Element | JSX.Element[];
  isWhiteBackground?: boolean;
  innerPaddingTop?: string;
  borderRadius?: string;
  isPaddingZero?: boolean;
};

function InfoWrapper({
  children,
  isWhiteBackground,
  innerPaddingTop = '30px',
  borderRadius,
  isPaddingZero = false,
}: PropsWithChildren<InfoWrapperProps>): ReactElement {
  return (
    <Container>
      <div
        className={
          isPaddingZero
            ? classNames(styles.infoWrapperPaddingZero, styles.infoWrapper)
            : styles.infoWrapper
        }
      >
        <div
          style={{ borderRadius: borderRadius }}
          className={classNames(styles.infoWrapperInner, {
            [styles.whiteBackground]: isWhiteBackground,
          })}
        >
          <div
            className={styles.innerPadding}
            style={{ paddingTop: innerPaddingTop }}
          >
            {children}
          </div>
        </div>
      </div>
    </Container>
  );
}

export default InfoWrapper;
