import { ReactElement, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useStore } from 'store/store';
import { Input, Modal, notification } from 'antd';
import { login } from 'api/api';
import styles from './SignInForm.module.scss';
import RestorePasswordModal from '../RestorePasswordModal/RestorePasswordModal';
import { useTranslation } from 'react-i18next';
import { ReactComponent as Eye } from 'assets/icons/eye_clovesed.svg';

const SignInForm = observer((): ReactElement => {
  const { userStore } = useStore();
  const { t } = useTranslation();

  const [userName, setUserName] = useState('');
  const [password, setPassword] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);

  const signIn = async () => {
    try {
      const data = {
        identifier: userName,
        password,
      };

      const response = await login(data);
      if (response.data?.jwt) {
        userStore.login(response.data?.jwt);
      }
    } catch (e: any) {
      const error = e?.response?.data?.error || e?.message;

      if (error && error.message) {
        notification.error({
          message:
            error.message === 'Invalid identifier or password'
              ? t('memoryBookPage.signInForm.invalidLoginOrPassword')
              : error.message === 'Your account email is not confirmed'
              ? t('memoryBookPage.signInForm.accountNotActivated')
              : t('memoryBookPage.signInForm.loginFailed'),
          placement: 'bottomLeft',
          duration: 15,
        });
      } else {
        notification.error({
          message: t('memoryBookPage.signInForm.loginFailed'),
          placement: 'bottomLeft',
          duration: 15,
        });
      }
    } finally {
      setUserName('');
      setPassword('');
    }
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.inputWrapper}>
        <div className={styles.span}>
          {t('memoryBookPage.signInForm.login')}
        </div>
        <div className={styles.nameInput}>
          <Input
            value={userName}
            placeholder='Имя'
            onChange={(event) => setUserName(event.target.value)}
          />
        </div>
        <div className={styles.span}>
          {t('memoryBookPage.signInForm.password')}
        </div>
        <div className={styles.passwordInput}>
          <Input.Password
            value={password}
            placeholder={t('memoryBookPage.signInForm.password')}
            onChange={(event) => setPassword(event.target.value)}
          />
          <div
            className={styles.forgetPassword}
            onClick={() => setIsModalOpen(true)}
          >
            {t('memoryBookPage.signInForm.forgotPassword')}
          </div>
        </div>
      </div>
      <div className={styles.buttonWrapper}>
        <button className={styles.button} onClick={signIn}>
          {t('memoryBookPage.signInForm.signIn')}
        </button>
      </div>
      <Modal
        open={isModalOpen}
        onCancel={() => setIsModalOpen(false)}
        footer={null}
        centered
        className={styles.modal}
        destroyOnClose={true}
      >
        <RestorePasswordModal onCancel={() => setIsModalOpen(false)} />
      </Modal>
    </div>
  );
});

export default SignInForm;
