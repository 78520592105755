import { makeAutoObservable } from "mobx";
import { getProducts } from "../../api/api";
import { getNRandomInts } from "../../helpers/helpers";
import { Product } from "../../types/Types";

class ProductsStore {
  constructor() {
    makeAutoObservable(this);
  }

  isLoading: boolean = true;
  isError: boolean = false;
  products: Array<Product> = [];

  async loadProducts() {
    try {
      const resp = await getProducts();
      this.products = resp;
    } catch (error) {
      this.isError = true;
    } finally {
      this.isLoading = false;
    }
  }

  getRandomProducts(): Array<Product> {
    const indexes = getNRandomInts(4, this.products.length);

    return indexes.map((index) => this.products[index]);
  }
}

export default ProductsStore;
