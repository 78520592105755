export const Breakpoints = {
  largeDesktop: 1700,
  smallDesktop: 1200,
  largeTablet: 1000,
  smallTablet: 768,
  largeMobile: 480,
};

export const PageHeader = {
  desktop: 285,
  mobile: 217,
};

export const PaginationBreakpoints = {
  smallTablet: 600,
  largeMobile: 480,
};
