import { useEffect, useState } from 'react';
import {
  getDefaultPlayerImage,
  getExecutiveById,
  getExecutiveRosters,
} from '../../../api/api';
import {
  DefaultImageObject,
  ExecutiveDto,
  ExecutiveRosterDto,
  ExecutiveType,
} from '../../../types/Types';
import InfoWrapper from '../../Wrappers/InfoWrapper/InfoWrapper';
import ImageBgPageWrapper from '../../common/ImageBgPageWrapper/ImageBgPageWrapper';
import Container from '../../common/Container/Container';
import BreadcrumbsWrapper from 'components/common/BreadcrumbsWrapper/BreadcrumbsWrapper';
import { observer } from 'mobx-react-lite';
import { useParams, useSearchParams } from 'react-router-dom';
import ExecutiveSmallTiles from '../../ExecutivesSmallTiles/ExecutivesSmallTiles';
import BioBox from '../../PlayerPage/Bio/BioBox/BioBox';
import SetRespError, {
  getDate,
  getFullExecutiveName,
  getLastSeasonForExecutive,
} from '../../../helpers/helpers';
import styles from './StaffBioPage.module.scss';
import ExecutiveTabsWithInfo from '../../ExecutiveTabsWithInfo/ExecutiveTabsWithInfo';
import ShowOn from 'components/core/adaptivity/ShowOn';
import { DEFAULT_IMAGE_NAME, LOKOMOTIV_CODE } from 'constants/constants';
import ErrorWidget from 'components/common/ErrorWidget/ErrorWidget';
import ImageLoader from 'components/common/ImageLoaders/ImageLoader/ImageLoader';
import { useTranslation } from 'react-i18next';
import ErrorPage from '../ErrorPage/ErrorPage';
import Spinner from 'components/Spinner/Spinner';

type StaffBioPageProps = {
  is_catastrophe: boolean;
};

const StaffBioPage = observer(
  ({ is_catastrophe = false }: StaffBioPageProps) => {
    const { t } = useTranslation();
    const [staff, setStaff] = useState<ExecutiveDto>();
    const [otherStaff, setOtherStaff] = useState<ExecutiveDto[]>([]);
    const [defaultPhoto, setDefaultPhoto] = useState<DefaultImageObject>();
    const [isError, setIsError] = useState<boolean>(false);
    const [isNotFoundError, setIsNotFoundError] = useState<boolean>(false);
    const [searchParams, setSearchParams] = useSearchParams();
    const { staffId } = useParams();
    const seasonParam = searchParams.get('season');

    function checkStaffInSeason(executive: ExecutiveDto): boolean {
      if (!seasonParam) {
        return false;
      }

      const seasons =
        executive?.attributes?.executive_rosters?.data.map(
          (roster) => roster.attributes.season.data.attributes.name
        ) || [];
      return seasons.includes(seasonParam);
    }

    useEffect(() => {
      const getExecutiveInfo = async () => {
        try {
          const staffResponse = await getExecutiveById(Number(staffId));
          const lastSeason = getLastSeasonForExecutive(staffResponse.data);

          if (!checkStaffInSeason(staffResponse.data) && lastSeason) {
            searchParams.set('season', lastSeason);
            setSearchParams(searchParams);
          }

          const coachesResponse = await getExecutiveRosters(
            ExecutiveType.STAFF,
            searchParams.get('season') ?? seasonParam ?? ''
          );

          const otherStaff = coachesResponse.data
            ?.map((item: ExecutiveRosterDto) => {
              const executive = item.attributes.executive.data;

              executive.attributes.position = item.attributes.position;

              return executive;
            })
            .filter((executive: ExecutiveDto) =>
              is_catastrophe ? false : executive.id !== Number(staffId)
            );

          setStaff(staffResponse.data);
          setOtherStaff(otherStaff);
        } catch (e: any) {
          SetRespError(e, setIsNotFoundError, setIsError);
        }

        try {
          const photo = await getDefaultPlayerImage(DEFAULT_IMAGE_NAME);
          setDefaultPhoto(photo?.data[0]);
        } catch (e: any) {
          console.error(e?.response?.data?.error || e?.message);
        }
      };

      getExecutiveInfo();
    }, [staffId, seasonParam]);

    if (staff && staff.attributes.type !== ExecutiveType.STAFF)
      return <ErrorPage />;

    if (
      staff &&
      staff?.attributes?.team_filter.data.every(
        (team) => team.attributes.code !== LOKOMOTIV_CODE
      )
    )
      return <ErrorPage />;

    return (
      <ImageBgPageWrapper
        imageInfo={{
          img_url: staff?.attributes?.bg_photo?.data?.attributes?.url ?? '',
          gradient_height: 200,
          image_height: 700,
        }}
      >
        <Container paddingTop='50px' paddingBottom='0px'>
          <BreadcrumbsWrapper
            breadcrumbs={[
              {
                name: is_catastrophe
                  ? t('staffBioPage.breadcrumbCatastrophe')
                  : t('staffBioPage.breadcrumb'),
                url: is_catastrophe
                  ? `/catastrophe/${ExecutiveType.STAFF}`
                  : '/staff',
              },
              {
                name: getFullExecutiveName(staff),
                url: is_catastrophe
                  ? `/catastrophe/${ExecutiveType.STAFF}/${staffId}`
                  : `/staff/${staffId}`,
              },
            ]}
          />
          {isNotFoundError ? (
            <ErrorWidget.NotFound isOnDarkBackground />
          ) : isError ? (
            <ErrorWidget.Error isOnDarkBackground />
          ) : staff ? (
            <>
              <div className={styles.executiveHeader}>
                <div className={styles.info}>
                  <ShowOn largeDesktop smallDesktop largeTablet>
                    <p className={styles.position}>
                      {staff?.attributes?.position ?? ''}
                    </p>
                    <p className={styles.name}>{getFullExecutiveName(staff)}</p>
                  </ShowOn>
                  <ShowOn smallTablet largeMobile smallMobile>
                    <p className={styles.positionMobile}>
                      {staff?.attributes?.position ?? ''}
                    </p>
                    <p className={styles.nameMobile}>
                      {getFullExecutiveName(staff)}
                    </p>
                  </ShowOn>
                </div>
                <div className={styles.mainInfo}>
                  <ShowOn largeDesktop smallDesktop largeTablet>
                    <div className={styles.imageWrapper}>
                      <ImageLoader
                        image={
                          staff?.attributes?.photo?.data?.attributes?.url ??
                          defaultPhoto?.attributes.image?.data?.attributes
                            ?.url ??
                          ''
                        }
                        alt={
                          `${staff?.attributes?.surname} ${staff?.attributes?.name}` ??
                          'executive'
                        }
                      />
                    </div>
                  </ShowOn>
                  <div className={styles.bioWrapper}>
                    {staff?.attributes?.birth && (
                      <>
                        <BioBox
                          name={t('staffBioPage.bio.birthDate')}
                          value={getDate(staff?.attributes?.birth)}
                        />
                        <BioBox
                          name={t('staffBioPage.bio.birthPlace')}
                          value={
                            staff?.attributes.birth_place ||
                            t('staffBioPage.bio.noValue')
                          }
                        />
                      </>
                    )}
                  </div>
                </div>
              </div>
              <InfoWrapper
                isWhiteBackground={true}
                isPaddingZero={true}
                borderRadius='10px'
              >
                <>{staff && <ExecutiveTabsWithInfo executive={staff} />}</>
              </InfoWrapper>
            </>
          ) : (
            <Spinner />
          )}
          {otherStaff?.length ? (
            <div className={styles.otherExecutives}>
              <p className={styles.title}>
                {t('staffBioPage.otherExecutives')}
              </p>
              <ExecutiveSmallTiles
                executives={otherStaff}
                season={searchParams.get('season') || undefined}
                defaultPhoto={defaultPhoto}
              />
            </div>
          ) : (
            <></>
          )}
        </Container>
      </ImageBgPageWrapper>
    );
  }
);

export default StaffBioPage;
