import { AdvertisingWidgetDto } from 'types/Types';
import styles from './MobileNotGameTypeWidget.module.scss';
import { isContainsHTMLTags } from 'helpers/helpers';

type MobileGameTypeWidgetType = {
  advertising: AdvertisingWidgetDto;
};

function MobileNotGameTypeWidget({ advertising }: MobileGameTypeWidgetType) {
  return (
    <div className={styles.container}>
      <img
        src={advertising.attributes?.preview?.data.attributes.url || ''}
        className={styles.logo}
      />
      {isContainsHTMLTags(advertising.attributes.text) ? (
        <div
          dangerouslySetInnerHTML={{
            __html: advertising.attributes.text || '',
          }}
        />
      ) : (
        <span>{advertising.attributes.text}</span>
      )}
    </div>
  );
}

export default MobileNotGameTypeWidget;
