import Column from 'components/core/layout/Column';
import styles from './MainNewsMobileText.module.scss';
import { getDateInfo } from 'helpers/helpers';
import Row from 'components/core/layout/Row';
import { ReactComponent as ArrowIcon } from 'assets/upper_right_arrow.svg';
import Article from 'model/entity/loko/type/Article';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import Colors from 'constants/colors';

interface MainNewsMobileTextProps {
  article: Article;
}

const MainNewsMobileText = ({ article }: MainNewsMobileTextProps) => {
  const { t } = useTranslation();
  return (
    <div className={styles.mainNewsMobileWrapper}>
      <Column>
        <div className={styles.mainNewsFirstLine}>
          {getDateInfo(article) || ''}
          {article?.attributes.author ? (
            <span>— {article?.attributes.author}</span>
          ) : (
            <></>
          )}
        </div>
        <span className={styles.mainNewsSecondLine}>
          {article?.attributes.title}
        </span>
        <Link to={`/article/${article?.id}`}>
          <Row height='50px' justifyContent='space-between'>
            <div className={styles.linkText}>
              {t('homepage.mainNewsForward')}
            </div>
            <div className={styles.linkArrow}>
              <ArrowIcon color={Colors.PRIMARY_RED} />
            </div>
          </Row>
        </Link>
      </Column>
    </div>
  );
};

export default MainNewsMobileText;
