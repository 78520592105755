import BackgroundImageLoader from 'components/common/ImageLoaders/BackgroundImageLoader/BackgroundImageLoader';
import { getDateInfo } from '../../../helpers/helpers';
import ArticleLikesBlock from '../../ArticleLikesBlock/ArticleLikesBlock';
import styles from './TopStoryItem.module.scss';
import Article from 'model/entity/loko/type/Article';

interface TopStoryItemProps {
  isColumnStyle?: boolean;
  article: Article;
  count?: number;
  useImage?: boolean;
}

const TopStoryItem = ({
  isColumnStyle,
  article,
  count,
  useImage,
}: TopStoryItemProps) => {
  return (
    <a
      key={article.id}
      href={
        article?.attributes?.article_category?.data?.attributes?.name ===
        'Программки'
          ? `/booklet/${article.id}`
          : `/article/${article.id}`
      }
      className={styles.topstory}
      style={{
        width:
          !isColumnStyle && count
            ? `calc((100% - ${(count - 1) * 20}px) / ${count})`
            : '100%',
      }}
    >
      <div
        className={styles.topstoryInner}
        style={{ height: `${isColumnStyle ? '25%' : '31%'}` }}
      >
        <div className={styles.topstoryText}>
          <h1
            className={
              isColumnStyle
                ? styles.topstoryHeadingColumn
                : styles.topstoryHeading
            }
          >
            {article.attributes.title}
          </h1>
          <div className={styles.topstoryFooter}>
            <div className={styles.topstoryDate}>{getDateInfo(article)}</div>
            <ArticleLikesBlock article={article} />
          </div>
        </div>
      </div>
      {(article.attributes.preview_image?.data?.attributes.url ||
        article.attributes.image?.data) && (
        <BackgroundImageLoader
          image={
            useImage && article.attributes.image?.data
              ? article.attributes.image?.data
              : article.attributes.preview_image?.data?.attributes.url
              ? article.attributes.preview_image?.data
              : article.attributes.image?.data
          }
          styles={{ height: `${isColumnStyle ? '75%' : '69%'}` }}
          className={styles.topstoryImg}
        />
      )}
    </a>
  );
};

export default TopStoryItem;
