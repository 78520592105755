import { makeAutoObservable } from 'mobx';
import { getLokomotivTeams } from '../../api/api';
import { LokomotivTeams } from 'types/Types';

class LokomotivTeamsStore {
  constructor() {
    makeAutoObservable(this);
  }

  lokomotivTeams: LokomotivTeams | undefined;
  lokomotivTeamsIds: number[] = [];

  async loadLokomotivTeams() {
    try {
      const response = await getLokomotivTeams();

      this.lokomotivTeams = {
        men: response?.data?.attributes.men,
        lko: response?.data?.attributes.lko,
        l76: response?.data?.attributes.lko,
      };

      this.lokomotivTeamsIds = [];

      if (response?.data?.attributes?.men?.data?.attributes.id_league_web) {
        this.lokomotivTeamsIds.push(
          response.data.attributes?.men?.data?.attributes.id_league_web
        );
      }
      if (response?.data?.attributes?.lko?.data?.attributes.id_league_web) {
        this.lokomotivTeamsIds.push(
          response.data.attributes?.lko?.data?.attributes.id_league_web
        );
      }
      if (response?.data?.attributes?.l76?.data?.attributes.id_league_web) {
        this.lokomotivTeamsIds.push(
          response.data.attributes?.l76?.data?.attributes.id_league_web
        );
      }
    } catch (error: any) {
      console.error(error?.response?.data?.error || error?.message);
    }
  }
}

export default LokomotivTeamsStore;
