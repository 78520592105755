import { ReactElement, useCallback } from 'react';
import classNames from 'classnames';
import { GameWithLogo } from '../../../types/Types';
import styles from './ScoreBox.module.scss';
import { CONTINUATION } from '../../../constants/constants';
import { observer } from 'mobx-react-lite';
import { useStore } from 'store/store';
import { Link } from 'react-router-dom';

type ScoreBoxProps = {
  game: GameWithLogo;
  isGameInFuture: boolean;
  date: Date;
  isCurrent: boolean;
  isHoverGame: boolean;
  isHome?: boolean;
};

const ScoreBox = observer(
  ({
    game,
    isCurrent,
    isHoverGame,
    isHome,
    isGameInFuture,
  }: ScoreBoxProps): ReactElement => {
    const { lokomotivTeamsStore } = useStore();

    const isGameLost = useCallback(
      (game: GameWithLogo): boolean => {
        if (
          lokomotivTeamsStore.lokomotivTeamsIds.includes(
            game.attributes.team_1.data.attributes.id_league_web
          )
        )
          return game.attributes.score_1 < game.attributes.score_2;
        else return game.attributes.score_1 > game.attributes.score_2;
      },
      [lokomotivTeamsStore.lokomotivTeamsIds]
    );

    return (
      <div
        className={isHoverGame ? styles.scoreWrapperHover : styles.scoreWrapper}
      >
        <div
          className={classNames(styles.details, {
            [styles.detailsCurrent]: isCurrent && !isHoverGame,
          })}
        >
          {game.attributes.period_scores}
        </div>
        <Link
          to={
            game.attributes.article && game.attributes.article.data
              ? `/article/${game.attributes.article.data.id}`
              : {}
          }
          className={styles.scoreNumberWrapper}
        >
          <div
            className={classNames(styles.scoreNumber, {
              [styles.grey]:
                isGameInFuture ||
                (isHoverGame &&
                  !isGameInFuture &&
                  !game.attributes.score_1 &&
                  !game.attributes.score_2),
              [styles.scoreNumberHover]: isHoverGame,
              [styles.red]: !isGameLost(game) || isCurrent,
              [styles.blue]: !isHome && isHoverGame && isCurrent,
              [styles.white]: isCurrent && !isHoverGame,
              [styles.black]: isGameLost(game) && !isCurrent,
            })}
          >
            <span className={styles.scoreText}>
              {game.attributes.score_1 != null
                ? game.attributes.score_1
                : isHoverGame
                ? '-'
                : '0'}{' '}
              :{' '}
              {game.attributes.score_2 != null
                ? game.attributes.score_2
                : isHoverGame
                ? '-'
                : '0'}
            </span>
            <span className={styles.continuation}>
              {' '}
              {CONTINUATION.get(
                game?.attributes?.continuation?.data?.attributes?.name
              )}
            </span>
          </div>
        </Link>
        <div
          className={
            isCurrent && !isHoverGame ? styles.stadiumWhite : styles.stadium
          }
        >
          {game.attributes.stadium}
        </div>
      </div>
    );
  }
);

export default ScoreBox;
