import Slider, { Settings } from 'react-slick';
import styles from './MobilePhotoArenaBlock.module.scss';
import ArrowLeftLine from 'assets/icons/ArrowLeftLine';
import ArrowRightLine from 'assets/icons/ArrowRightLIne';
import { useEffect, useRef, useState } from 'react';
import ReactImageGallery from 'react-image-gallery';
import ImageGallery from 'react-image-gallery';

type MobilePhotoArenaBlockProps = {
  title: string;
  images?: Array<string>;
  topPosition?: number;
  leftPosition?: number;
  description?: string;
  caption?: string;
  className?: string;
};

function MobilePhotoArenaBlock({
  title,
  description,
  images,
  caption,
  topPosition,
  leftPosition,
  className,
}: MobilePhotoArenaBlockProps) {
  const [currentSlide, setCurrentSlide] = useState<string | null>(null);
  const [currentSlideIndex, setCurrentSlideIndex] = useState<number | null>(
    null
  );
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const galleryRef = useRef<ReactImageGallery | null>(null);

  const handleSlideChange = (currentSlide: number) => {
    setCurrentSlide(images?.at(currentSlide) ?? null);
    setCurrentSlideIndex(currentSlide);
  };

  const openGallery = () => {
    setIsModalOpen(true);
  };

  useEffect(() => {
    handleSlideChange(0);
  }, []);

  useEffect(() => {
    if (isModalOpen && images && galleryRef?.current) {
      galleryRef.current?.fullScreen();
    }
  }, [images, galleryRef, isModalOpen]);

  const sliderSettings: Settings = {
    dots: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    prevArrow: <ArrowLeftLine fill='white' />,
    nextArrow: <ArrowRightLine fill='white' />,
    afterChange: handleSlideChange,
  };

  return (
    <>
      <div
        style={{ top: `${topPosition}px`, left: `${leftPosition}px` }}
        className={styles.tooltip}
      >
        {images?.length && (
          <div
            className={styles.tooltipImgContainer}
            style={{ cursor: 'pointer' }}
          >
            <Slider className={styles.slider} {...sliderSettings}>
              {images.map((img, index) => (
                <img
                  src={img}
                  alt='slider-img'
                  onClick={openGallery}
                  key={index}
                />
              ))}
            </Slider>
          </div>
        )}
        {caption && (
          <div className={styles.captionContainer}>
            <div className={styles.caption}>
              <p>{caption}&nbsp;</p>
            </div>
          </div>
        )}
        <div className={styles.infoContainer}>
          <div className={styles.titleContainer}>
            <h3 className={styles.title}>{title}</h3>
          </div>
          {description && (
            <div className={styles.descriptionContainer}>
              <p className={styles.description}>{description}</p>
            </div>
          )}
        </div>
      </div>
      {isModalOpen && currentSlide && (
        <ImageGallery
          items={
            images
              ? images.map((url) => {
                  return {
                    original: url,
                    thumbnail: url,
                    originalClass: !isModalOpen ? 'customImage' : '',
                    thumbnailClass: 'customThumbnail',
                  };
                })
              : []
          }
          infinite={false}
          showPlayButton={false}
          additionalClass='gallery'
          onScreenChange={(value) => {
            setIsModalOpen(value);
          }}
          startIndex={currentSlideIndex || 0}
          ref={galleryRef}
        />
      )}
    </>
  );
}

export default MobilePhotoArenaBlock;
