import React, { CSSProperties } from 'react';
import styles from './Option.module.scss';

const Option: React.FC<{
  value: string;
  displayValue: string;
  onClick: (value: any) => void;
  selectItemsStyle?: CSSProperties;
}> = ({ value, displayValue, onClick, selectItemsStyle }) => (
  <li
    className={styles.Option}
    onClick={() => onClick(value)}
    style={selectItemsStyle}
  >
    <span>{displayValue}</span>
  </li>
);

export default Option;
