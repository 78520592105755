import { ReactElement, useEffect, useState } from 'react';
import styles from './GameProtocolPage.module.scss';
import Breadcrumbs from '../../common/Breadcrumbs/Breadcrumbs';
import { observer } from 'mobx-react-lite';
import { useParams, useSearchParams } from 'react-router-dom';
import {
  getGameById,
  getGameProtocol,
  getGameProtocolAdmin,
} from '../../../api/api';
import {
  GameProtocolDto,
  GameWithLogo,
  SeasonsTeamCode,
} from '../../../types/Types';
import GameProtocolTabs from './GameProtocolTabs/GameProtocolTabs';
import GoalStatsTable from './GameProtocolTables/GoalStatsTable/GoalStatsTable';
import ScoreTable from './GameProtocolTables/ScoreTable/ScoreTable';
import ShotsTable from './GameProtocolTables/ShotsTable/ShotsTable';
import PenaltyTable from './GameProtocolTables/PenaltyTable/PenaltyTable';
import config from '../../../config';
import Spinner from '../../Spinner/Spinner';
import { getRuDateString } from '../../../helpers/helpers';
import ScoreBanner from './GameProtocolTabs/ScoreBanner/ScoreBanner';
import ImageBgPageWrapper from 'components/common/ImageBgPageWrapper/ImageBgPageWrapper';
import ErrorWidget from 'components/common/ErrorWidget/ErrorWidget';
import InfoWrapper from 'components/Wrappers/InfoWrapper/InfoWrapper';

const GameProtocolPage = observer((): ReactElement => {
  const [searchParams] = useSearchParams();
  const gameId = searchParams.get('gameId');
  const [gameData, setGameData] = useState<GameWithLogo>();
  const [gameProtocol, setGameProtocol] = useState<GameProtocolDto>();
  const [isLoading, setIsLoading] = useState(false);
  const [isGameError, setIsGameError] = useState(false);
  const [isProtocolError, setIsProtocolError] = useState(false);
  const { teamCode } = useParams();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const getGameData = async () => {
      try {
        if (gameId) {
          setIsLoading(true);
          const result = await getGameById(Number(gameId));
          setGameData(result?.data);
          setIsLoading(false);
        }
      } catch (e) {
        setIsGameError(true);
      } finally {
        setIsLoading(false);
      }
    };

    getGameData();
  }, [gameId]);

  useEffect(() => {
    const getProtocolData = async () => {
      try {
        if (gameId) {
          const khlProtocolResult = await getGameProtocol(gameId);
          if (khlProtocolResult) {
            setGameProtocol({ GameSummary: khlProtocolResult.GameSummary });
          }
        }
      } catch (e: any) {
        console.error(
          'khlProtocolResult',
          e?.response?.data?.error || e?.message
        );
        const adminProtocolResult = await getGameProtocolAdmin(Number(gameId));
        if (adminProtocolResult?.data?.length) {
          setGameProtocol({
            GameSummary:
              adminProtocolResult?.data[0]?.attributes?.json?.GameSummary,
          });
        } else {
          setIsProtocolError(true);
        }
      } finally {
        setIsLoading(false);
      }
    };

    getProtocolData();

    let intervalId: NodeJS.Timer;

    if (gameData?.attributes.is_current) {
      intervalId = setInterval(getProtocolData, config.KhlProtocolUpdateTimer);
    }

    return () => {
      clearInterval(intervalId);
    };
  }, [gameData]);

  const getBreadcrumbsName = () => {
    if (!gameData) {
      return '';
    }
    const team1 = gameData?.attributes.team_1?.data.attributes.name;
    const team2 = gameData?.attributes.team_2?.data.attributes.name;
    const date = getRuDateString(new Date(gameData?.attributes.date));
    return team1 && team2 && date
      ? `${team1} vs ${team2} (${date.split(' ')[0].slice(0, -4)}${date
          .split(' ')[0]
          .slice(-2)}, ${date.split(' ')[1]})`
      : 'Не получены данные по команде и дате';
  };

  return (
    <ImageBgPageWrapper>
      <InfoWrapper
        borderRadius='0px'
        innerPaddingTop='20px'
        isWhiteBackground={false}
      >
        <div className={styles.headerWrapper}>
          <div className={styles.breadcrumbsWrapper}>
            <Breadcrumbs
              isWhiteColor={false}
              breadcrumbs={
                gameData
                  ? [
                      {
                        name: 'Календарь матчей',
                        url: `/schedule/${
                          teamCode ? teamCode : SeasonsTeamCode.men
                        }`,
                      },
                      {
                        name: getBreadcrumbsName(),
                        url: `/protocol?gameId=${gameData?.id}`,
                      },
                    ]
                  : [
                      {
                        name: 'Календарь матчей',
                        url: `/schedule/${
                          teamCode ? teamCode : SeasonsTeamCode.men
                        }`,
                      },
                    ]
              }
            />
          </div>
          <h1 className={styles.title}>Статистика матча</h1>
        </div>
        {gameData && gameProtocol && !isGameError && !isProtocolError ? (
          <ScoreBanner game={gameData} protocol={gameProtocol} />
        ) : (
          <></>
        )}
        <div className={styles.wrapper}>
          {isLoading ? (
            <Spinner className={styles.loader} />
          ) : gameData || gameProtocol ? (
            <>
              {
                <div className={styles.content}>
                  <GameProtocolTabs
                    game={gameData}
                    protocol={gameProtocol}
                    article={gameData?.attributes?.article?.data}
                  />
                </div>
              }
              <div className={styles.sideMenu}>
                {gameData && gameProtocol && gameProtocol.GameSummary && (
                  <div className={styles.sideTables}>
                    <ScoreTable game={gameData} protocol={gameProtocol} />
                    <GoalStatsTable game={gameData} protocol={gameProtocol} />
                    <PenaltyTable game={gameData} protocol={gameProtocol} />
                    <ShotsTable game={gameData} protocol={gameProtocol} />
                  </div>
                )}
              </div>
            </>
          ) : (
            <ErrorWidget.Error />
          )}
        </div>
      </InfoWrapper>
    </ImageBgPageWrapper>
  );
});

export default GameProtocolPage;
