import Subscription from 'model/entity/loko/type/Subscription';
import BaseStrapiDAO from '../base/BaseStrapiDAO';
import Strapi from 'strapi-sdk-js';

export default class SubscriptionDAO extends BaseStrapiDAO<Subscription> {
  constructor(client: Strapi) {
    super({
      client: client,
      tableNameSingular: 'subscription',
      tableNamePlural: 'subscriptions',
    });
  }
}
