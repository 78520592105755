import { ReactElement, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import styles from './RulesSection.module.scss';
import { useStore } from 'store/store';
import { DOCUMENTS_SYS_NAMES } from 'constants/constants';
import { getMemoryBookRules } from 'api/api';
import {
  MemoryBookRule,
  MemoryBookRulesDto,
} from 'model/entity/loko/type/MemoryBook';
import ShowOn from 'components/core/adaptivity/ShowOn';
import { Collapse } from 'antd';
import Colors from 'constants/colors';
import { useTranslation } from 'react-i18next';

const RulesSection = observer((): ReactElement => {
  const { documentsStore } = useStore();
  const { t } = useTranslation();
  const [allowed, setAllowed] = useState<MemoryBookRule[]>([]);
  const [forbidden, setForbidden] = useState<MemoryBookRule[]>([]);

  useEffect(() => {
    const getRules = async () => {
      const response = await getMemoryBookRules();

      const rules = response.data as MemoryBookRulesDto;

      setAllowed(rules?.attributes.allowed || []);
      setForbidden(rules?.attributes.forbidden || []);
    };

    getRules();
  }, []);

  const getPrivacyUrl = () => {
    const privacyStatement = documentsStore.documents.find(
      (item) =>
        item.attributes.sys_name === DOCUMENTS_SYS_NAMES.privacyStatement
    );

    return privacyStatement ? privacyStatement.attributes.document_link : '';
  };

  const renderContent = () => {
    return (
      <div className={styles.wrapper}>
        <div>
          <a
            className={styles.link}
            href={getPrivacyUrl()}
            target='_blank'
            rel='noreferrer'
          >
            {t('memoryBookPage.rulesSection.privacy')}
          </a>
        </div>
        <div>
          {allowed && allowed.length ? (
            <>
              <div className={styles.header}>
                {t('memoryBookPage.rulesSection.allowed')}
              </div>
              <ul className={styles.list}>
                {allowed.map((rule) => (
                  <li key={rule.id}>{rule.rule}</li>
                ))}
              </ul>
            </>
          ) : null}
        </div>
        <div>
          {forbidden && forbidden.length ? (
            <>
              <div className={styles.header}>
                {t('memoryBookPage.rulesSection.forbidden')}
              </div>
              <ul className={styles.list}>
                {forbidden.map((rule) => (
                  <li key={rule.id}>{rule.rule}</li>
                ))}
              </ul>
            </>
          ) : null}
        </div>
      </div>
    );
  };

  return (
    <>
      <ShowOn largeDesktop smallDesktop>
        {renderContent()}
      </ShowOn>
      <ShowOn largeTablet smallTablet largeMobile smallMobile>
        <Collapse
          bordered={false}
          style={{
            background: Colors.WHITE,
            fontFamily: 'L_Halvar_Mittelschrift-Regular',
            fontSize: '16px',
          }}
          className={styles.collapse}
          items={[
            {
              key: '1',
              label: t('memoryBookPage.rulesSection.publishRules'),
              children: renderContent(),
            },
          ]}
        />
      </ShowOn>
    </>
  );
});

export default RulesSection;
