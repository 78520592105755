import { ReactElement, RefObject, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import styles from './GameProtocolTabs.module.scss';
import { Broadcast, GameProtocolDto, GameWithLogo } from 'types/Types';
import GameProtocolArticle from './GameProtocolArticle/GameProtocolArticle';
import GameStatsTable from './GameStatsTable/GameStatsTable';
import { useSearchParams } from 'react-router-dom';
import { getDateWithDayOfWeekCommon } from 'helpers/helpers';
import { getGameBroadcast } from 'api/api';
import Spinner from '../../../Spinner/Spinner';
import Article from 'model/entity/loko/type/Article';

type GameProtocolTabsProps = {
  article?: Article;
  protocol?: GameProtocolDto;
  game?: GameWithLogo;
};

function GameProtocolTabs({
  article,
  protocol,
  game,
}: GameProtocolTabsProps): ReactElement {
  const [searchParams] = useSearchParams();
  const reportRef = useRef<HTMLDivElement>(null);
  const statsRef = useRef<HTMLDivElement>(null);
  const broadcastRef = useRef<HTMLDivElement>(null);
  const [activeTab, setActiveTab] =
    useState<RefObject<HTMLDivElement>>(broadcastRef);
  const [broadcast, setBroadcast] = useState<Broadcast | undefined>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const windowInnerWidth = document.documentElement.clientWidth;
  const isMobile = windowInnerWidth < 480;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (!game?.attributes.is_current) {
      const isReport = searchParams.get('report');

      if (isReport === 'true' && article) {
        changeActiveTab(reportRef);
      } else {
        changeActiveTab(statsRef);
      }
    } else {
      changeActiveTab(broadcastRef);
    }
  }, [game?.attributes.is_current]);

  useEffect(() => {
    const loadBroadcast = async () => {
      setIsLoading(true);

      try {
        if (game?.attributes.is_current) {
          if (game.attributes.broadcast_url) {
            setBroadcast({ url: game.attributes.broadcast_url });
          } else {
            const broadcastData = await getGameBroadcast(game.id);

            setBroadcast(broadcastData);
          }
        }
      } catch (error: any) {
        console.error(error?.response?.data?.error || error?.message);
      } finally {
        setIsLoading(false);
      }
    };

    loadBroadcast();
  }, [game]);

  function changeActiveTab(ref: RefObject<HTMLDivElement>) {
    const currentElement = activeTab.current;
    if (currentElement?.className.includes(styles.textShow)) {
      currentElement?.classList.remove(styles.textShow);
    }
    const element = ref.current;
    if (!element?.className.includes(styles.textShow)) {
      element?.classList.add(styles.textShow);
    }
    setActiveTab(ref);
  }

  return (
    <>
      <div
        className={
          isMobile
            ? classNames(styles.tabBar, styles.tabBarMobile)
            : styles.tabBar
        }
      >
        {game?.attributes.is_current && (
          <a
            onClick={() => changeActiveTab(broadcastRef)}
            className={activeTab === broadcastRef ? styles.active : ''}
          >
            ПРЯМАЯ ТРАНСЛЯЦИЯ
          </a>
        )}
        {article && (
          <a
            onClick={() => changeActiveTab(reportRef)}
            className={activeTab === reportRef ? styles.active : ''}
          >
            ОТЧЕТ
          </a>
        )}
        {
          // TODO: check with team if we show stats tab always
          !game?.attributes.is_current && protocol && protocol.GameSummary && (
            <a
              onClick={() => changeActiveTab(statsRef)}
              className={activeTab === statsRef ? styles.active : ''}
            >
              СТАТИСТИКА
            </a>
          )
        }
      </div>
      <div>
        <div
          ref={reportRef}
          className={classNames(styles.text, {
            [styles.textShow]: activeTab === reportRef,
          })}
        >
          <div className={styles.wrapper}>
            {article && game && (
              <GameProtocolArticle article={article} game={game} />
            )}
          </div>
        </div>

        <div
          ref={statsRef}
          className={classNames(styles.text, {
            [styles.textShow]: activeTab === statsRef,
          })}
        >
          <div className={styles.wrapper}>
            {game && protocol ? (
              <GameStatsTable game={game} protocol={protocol} />
            ) : null}
          </div>
        </div>
        <div
          ref={broadcastRef}
          className={classNames(styles.text, {
            [styles.textShow]: activeTab === broadcastRef,
          })}
        >
          <div className={styles.wrapper}>
            {isLoading ? (
              <Spinner />
            ) : !game || !broadcast /*|| !game.attributes.is_current*/ ? (
              <p>На данный момент трансляции нет</p>
            ) : (
              <>
                <div className={styles.dateWrapper}>
                  <p className={styles.date}>
                    {getDateWithDayOfWeekCommon(game.attributes.date)}
                  </p>
                </div>
                <div className={styles.broadcastContainer}>
                  <div className={styles.iframeWrapper}>
                    <iframe
                      title='khl-live'
                      frameBorder='0'
                      width='100%'
                      height='100%'
                      scrolling='no'
                      marginHeight={0}
                      marginWidth={0}
                      src={broadcast.url}
                      allowFullScreen
                    ></iframe>
                  </div>
                  <div className={styles.broadCastInfoWrapper}>
                    <p>
                      {game.attributes.display_value
                        ?.slice(0, game.attributes.display_value.indexOf('-'))
                        ?.replace(':', '-')}
                    </p>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default GameProtocolTabs;
