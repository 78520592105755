import { ReactElement } from 'react';
import styles from './Container.module.scss';

interface ContainerProps {
  children: React.ReactNode;
  paddingTop?: string;
  paddingBottom?: string;
  paddingHorizontal?: string;
}

export default function Container({
  children,
  paddingTop = '0px',
  paddingBottom = '0px',
  paddingHorizontal = '0px',
}: ContainerProps): ReactElement {
  return (
    <div
      className={styles.container}
      style={{
        paddingTop: paddingTop,
        paddingBottom: paddingBottom,
        paddingLeft: paddingHorizontal,
        paddingRight: paddingHorizontal,
      }}
    >
      {children}
    </div>
  );
}
