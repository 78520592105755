import React, { ReactElement } from 'react';
import Spinner from 'components/Spinner/Spinner';
import { StandingsReadableDto } from 'types/Types';
import StandingsPageTableBody from '../StandingPageTableBody/StandingPageTableBody';

type ChampionshipTabProps = {
  championshipRef: React.RefObject<HTMLDivElement>;
  styles: any;
  isLoading: boolean;
  standings: StandingsReadableDto[];
};

function ChampionshipTab({
  championshipRef,
  styles,
  isLoading,
  standings,
}: ChampionshipTabProps): ReactElement {
  return (
    <div ref={championshipRef} className={styles.text} id='championship'>
      {!isLoading && standings && standings.length > 0 ? (
        <table className={styles.table}>
          <div className={styles.statsTableWrapper}>
            {standings && (
              <StandingsPageTableBody data={standings} isDivisions={false} />
            )}
          </div>
        </table>
      ) : isLoading ? (
        <Spinner />
      ) : (
        <div>Турнирные таблицы не найдены</div>
      )}
    </div>
  );
}

export default ChampionshipTab;
