import Colors from 'constants/colors';
import { ReactElement } from 'react';

interface UpperRightArrowProps {
  fillColor: string;
  height: string;
  width: string;
}

export default function ProtocolResponsive({
  fillColor,
  height,
  width,
}: UpperRightArrowProps): ReactElement {
  return (
    <div
      style={{
        width: `${width}px`,
        height: `${height}px`,
      }}
    >
      <svg
        width={width}
        height={height}
        viewBox='0 0 20 20'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M10.8083 7.90039H15.1834'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
          stroke={fillColor || Colors.DARK_GREY}
        />
        <path
          d='M5.81665 7.90039L6.44165 8.52539L8.31665 6.65039'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
          stroke={fillColor || Colors.DARK_GREY}
        />
        <path
          d='M10.8083 13.7334H15.1834'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
          stroke={fillColor || Colors.DARK_GREY}
        />
        <path
          d='M5.81665 13.7334L6.44165 14.3584L8.31665 12.4834'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
          stroke={fillColor || Colors.DARK_GREY}
        />
        <path
          d='M7.99984 18.8337H12.9998C17.1665 18.8337 18.8332 17.167 18.8332 13.0003V8.00033C18.8332 3.83366 17.1665 2.16699 12.9998 2.16699H7.99984C3.83317 2.16699 2.1665 3.83366 2.1665 8.00033V13.0003C2.1665 17.167 3.83317 18.8337 7.99984 18.8337Z'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
          stroke={fillColor || Colors.DARK_GREY}
        />
      </svg>
    </div>
  );
}
