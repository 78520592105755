import { ReactElement, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { getArticlesUsingFilters, getTopArticle } from '../../api/api';
import ArticleItem from './ArticleItem/ArticleItem';
import PageHeader from 'components/common/PageHeader/PageHeader';
import BreadcrumbsWrapper from 'components/common/BreadcrumbsWrapper/BreadcrumbsWrapper';
import Container from '../common/Container/Container';
import ImageBgPageWrapper from '../common/ImageBgPageWrapper/ImageBgPageWrapper';
import styles from './NewsPage.module.scss';
import ViewMoreButton from '../ViewMoreButton/ViewMoreButton';
import SideMenu from '../common/SideMenu/SideMenu';
import ArticleMainItem from '../ArticleMainItem/ArticleMainItem';
import Spinner from '../Spinner/Spinner';
import ScrollToTopButton from 'components/ScrollToTopButton/ScrollToTopButton';
import ErrorWidget from 'components/common/ErrorWidget/ErrorWidget';
import Article from 'model/entity/loko/type/Article';
import { useTranslation } from 'react-i18next';

const NewsPage = (): ReactElement => {
  const { t } = useTranslation();
  const [articlesList, setArticlesList] = useState<Array<Article>>([]);
  const [topArticle, setTopArticle] = useState<Article>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);
  const [isTopError, setIsTopError] = useState<boolean>(false);
  const [pageParam, setPageParam] = useState<number>(1);
  const [searchParams] = useSearchParams();
  const searchParam = searchParams.get('search');
  const categoryParam = searchParams.get('category');
  const yearParam = searchParams.get('year');
  const monthParam = searchParams.get('month');

  useEffect(() => {
    const getData = async () => {
      try {
        setIsLoading(true);
        const resp = await getArticlesUsingFilters(
          searchParam,
          categoryParam,
          yearParam,
          monthParam,
          pageParam
        );
        const filteredData = resp?.data?.filter(
          (article: Article) =>
            article.attributes.article_category?.data?.id &&
            article.attributes.article_category.data.id !== 90 &&
            article.attributes.article_category.data.id !== 5
        );
        if (pageParam === 1) {
          setArticlesList(filteredData);
        } else {
          setArticlesList((prevState) => [...prevState, ...filteredData]);
        }
      } catch (error) {
        setIsError(true);
      } finally {
        setIsLoading(false);
      }
    };
    getData();
  }, [pageParam]);

  useEffect(() => {
    const fetchTopArticle = async () => {
      try {
        const response = await getTopArticle();
        setTopArticle(response?.data[0]);
      } catch (error) {
        setIsTopError(true);
      }
    };
    fetchTopArticle();
  }, [articlesList]);

  const showMoreData = () => {
    setPageParam((prevState) => prevState + 1);
  };

  return (
    <ImageBgPageWrapper>
      <Container paddingTop='50px'>
        <div className={styles.wrapper}>
          <BreadcrumbsWrapper
            breadcrumbs={[
              {
                name: t('newsPage.breadcrumb'),
                url: '/news',
              },
            ]}
          />
          <div className={styles.pageHeaderWrapper}>
            <PageHeader text={t('newsPage.header')} />
          </div>
          {isError ? (
            <ErrorWidget.Error isOnDarkBackground />
          ) : (
            <div className={styles.content}>
              {isTopError ? (
                <ErrorWidget.Error />
              ) : (
                topArticle && (
                  <ArticleMainItem
                    link={`/article/${topArticle.id}`}
                    item={topArticle}
                    backgroundImage={topArticle.attributes.image?.data}
                  />
                )
              )}
              <div className={styles.row}>
                <div className={styles.colSm8}>
                  <div className={styles.articles}>
                    {articlesList
                      .filter((item: Article) => item.id !== topArticle?.id)
                      .map((article) => (
                        <ArticleItem key={article.id} article={article} />
                      ))}
                  </div>
                  <br />
                  {isLoading ? (
                    <Spinner />
                  ) : (
                    <ViewMoreButton onClick={showMoreData} />
                  )}
                  <div className={styles.ScrollToTopButtonWrapper}>
                    <ScrollToTopButton />
                  </div>
                </div>
                <aside className={styles.colSm4}>
                  <SideMenu />
                </aside>
              </div>
            </div>
          )}
        </div>
      </Container>
    </ImageBgPageWrapper>
  );
};

export default NewsPage;
