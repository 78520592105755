import { ReactElement } from 'react';
import { ExecutiveDto } from '../../types/Types';
import styles from './ExecutiveStatisticTable.module.scss';
import { useTranslation } from 'react-i18next';

type ExecutiveStatisticTableProps = {
  executive: ExecutiveDto;
};

function ExecutiveStatisticTable({
  executive,
}: ExecutiveStatisticTableProps): ReactElement {
  const { t } = useTranslation();
  const { attributes } = executive;

  const isGoalkeeperRegular = attributes.statistics?.some(
    (item) => item.is_goalkeeper && item.regular_season
  );
  const isGoalkeeperPlayoff = attributes.statistics?.some(
    (item) => item.is_goalkeeper && item.playoffs
  );

  return (
    <table className={styles.table}>
      <tr className={styles.tableHeader}>
        <th
          className={styles.bigCell}
          title={t('executivePage.table.season')}
          rowSpan={2}
        >
          <div>{t('executivePage.table.season')}</div>
        </th>
        <th
          className={styles.bigCell}
          title={t('executivePage.table.team')}
          rowSpan={2}
        >
          <div>{t('executivePage.table.team')}</div>
        </th>
        <th
          className={styles.bigCell}
          title={t('executivePage.table.league')}
          rowSpan={2}
        >
          <div>{t('executivePage.table.league')}</div>
        </th>
        <th
          className={styles.middleCell}
          rowSpan={1}
          colSpan={isGoalkeeperRegular ? 9 : 5}
        >
          <div>{t('executivePage.table.regular')}</div>
        </th>
        <th
          className={styles.middleCell}
          rowSpan={1}
          colSpan={isGoalkeeperPlayoff ? 9 : 5}
        >
          <div>{t('executivePage.table.playoff')}</div>
        </th>
      </tr>
      <tr className={styles.tableHeader}>
        <th
          className={styles.smallCell}
          title={t('executivePage.table.title.gamesPlayed')}
        >
          <div>{t('executivePage.table.text.gamesPlayed')}</div>
        </th>
        <th
          className={styles.smallCell}
          title={t('executivePage.table.title.goals')}
        >
          <div>{t('executivePage.table.text.goals')}</div>
        </th>
        <th
          className={styles.smallCell}
          title={t('executivePage.table.title.assistsPasses')}
        >
          <div>{t('executivePage.table.text.assistsPasses')}</div>
        </th>
        <th
          className={styles.smallCell}
          title={t('executivePage.table.title.points')}
        >
          <div>{t('executivePage.table.text.points')}</div>
        </th>
        <th
          className={styles.smallCell}
          title={t('executivePage.table.title.penaltyInMinutes')}
        >
          <div>{t('executivePage.table.text.penaltyInMinutes')}</div>
        </th>
        {isGoalkeeperRegular && (
          <>
            <th
              className={styles.smallCell}
              title={t('executivePage.table.title.missedGoals')}
            >
              <div>{t('executivePage.table.text.missedGoals')}</div>
            </th>
            <th
              className={styles.smallCell}
              title={t('executivePage.table.title.savePercentage')}
            >
              <div>{t('executivePage.table.text.savePercentage')}</div>
            </th>
            <th
              className={styles.smallCell}
              title={t('executivePage.table.title.goalsAgainstAverage')}
            >
              <div>{t('executivePage.table.text.goalsAgainstAverage')}</div>
            </th>
            <th
              className={styles.smallCell}
              title={t('executivePage.table.title.shutouts')}
            >
              <div>{t('executivePage.table.text.shutouts')}</div>
            </th>
          </>
        )}
        <th
          className={styles.smallCell}
          title={t('executivePage.table.title.gamesPlayed')}
        >
          <div>{t('executivePage.table.text.gamesPlayed')}</div>
        </th>
        <th
          className={styles.smallCell}
          title={t('executivePage.table.title.goals')}
        >
          <div>{t('executivePage.table.text.goals')}</div>
        </th>
        <th
          className={styles.smallCell}
          title={t('executivePage.table.title.assistsPasses')}
        >
          <div>{t('executivePage.table.text.assistsPasses')}</div>
        </th>
        <th
          className={styles.smallCell}
          title={t('executivePage.table.title.points')}
        >
          <div>{t('executivePage.table.text.points')}</div>
        </th>
        <th
          className={styles.smallCell}
          title={t('executivePage.table.title.penaltyInMinutes')}
        >
          <div>{t('executivePage.table.text.penaltyInMinutes')}</div>
        </th>
        {isGoalkeeperPlayoff && (
          <>
            <th
              className={styles.smallCell}
              title={t('executivePage.table.title.missedGoals')}
            >
              <div>{t('executivePage.table.text.missedGoals')}</div>
            </th>
            <th
              className={styles.smallCell}
              title={t('executivePage.table.title.savePercentage')}
            >
              <div>{t('executivePage.table.text.savePercentage')}</div>
            </th>
            <th
              className={styles.smallCell}
              title={t('executivePage.table.title.goalsAgainstAverage')}
            >
              <div>{t('executivePage.table.text.goalsAgainstAverage')}</div>
            </th>
            <th
              className={styles.smallCell}
              title={t('executivePage.table.title.shutouts')}
            >
              <div>{t('executivePage.table.text.shutouts')}</div>
            </th>
          </>
        )}
      </tr>
      {attributes?.statistics &&
        attributes.statistics.map((item) => (
          <tr key={item.id} className={styles.tableRow}>
            <td>{item?.season ?? '-'}</td>
            <td>{item?.team ?? '-'}</td>
            <td>{item?.league ?? '-'}</td>
            <td>{item?.regular_season?.games ?? '-'}</td>
            <td>{item?.regular_season?.goals ?? '-'}</td>
            <td>{item?.regular_season?.passes ?? '-'}</td>
            <td>{item?.regular_season?.points ?? '-'}</td>
            <td>{item?.regular_season?.penalties ?? '-'}</td>
            {item?.is_goalkeeper && (
              <>
                <td>{item?.regular_season?.missed_goals ?? '-'}</td>
                <td>{item?.regular_season?.save_percentage ?? '-'}</td>
                <td>{item?.regular_season?.goals_against_average ?? '-'}</td>
                <td>{item?.regular_season?.shutouts ?? '-'}</td>
              </>
            )}
            <td>{item?.playoffs?.games ?? '-'}</td>
            <td>{item?.playoffs?.goals ?? '-'}</td>
            <td>{item?.playoffs?.passes ?? '-'}</td>
            <td>{item?.playoffs?.points ?? '-'}</td>
            <td>{item?.playoffs?.penalties ?? '-'}</td>
            {item?.is_goalkeeper && (
              <>
                <td>{item?.playoffs?.missed_goals ?? '-'}</td>
                <td>{item?.playoffs?.save_percentage ?? '-'}</td>
                <td>{item?.playoffs?.goals_against_average ?? '-'}</td>
                <td>{item?.playoffs?.shutouts ?? '-'}</td>
              </>
            )}
          </tr>
        ))}
    </table>
  );
}

export default ExecutiveStatisticTable;
