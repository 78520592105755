import Strapi from 'strapi-sdk-js';
import BaseStrapiDAO from '../base/BaseStrapiDAO';
import ArenaSector from 'model/entity/loko/type/ArenaSector';

export default class ArenaSectorDAO extends BaseStrapiDAO<ArenaSector> {
  constructor(client: Strapi) {
    super({
      client: client,
      tableNameSingular: 'arena-sector',
      tableNamePlural: 'arena-sectors',
    });
  }
}
