import { ReactElement, useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { recoveryPassword } from 'api/api';
import ImageBgPageWrapper from 'components/common/ImageBgPageWrapper/ImageBgPageWrapper';
import InfoWrapper from 'components/Wrappers/InfoWrapper/InfoWrapper';
import styles from './RecoveryPasswordPage.module.scss';
import { Input, notification } from 'antd';
import { useTranslation } from 'react-i18next';

function RecoveryPasswordPage(): ReactElement {
  const { t } = useTranslation();

  const [searchParams] = useSearchParams();
  const [code, setCode] = useState<string>('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [isPasswordsMatch, setIsPasswordsMatch] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const token = searchParams.get('code');
    setCode(token || '');
  }, []);

  const onSubmit = async () => {
    if (password !== confirmPassword) {
      setIsPasswordsMatch(false);

      return;
    }

    try {
      const data = {
        password,
        passwordConfirmation: confirmPassword,
        code,
      };

      const response = await recoveryPassword(data);
      if (response?.data?.jwt) {
        navigate('/memorybook');
      }
    } catch (e: any) {
      const error = e?.response?.data?.error || e?.message;
      console.log(error);
      if (error && error.message === 'Incorrect code provided') {
        notification.error({
          message: t('recoveryPasswordPage.invalidCode'),
          placement: 'bottomLeft',
          duration: 15,
        });
      } else if (error.message === 'password must be at least 6 characters') {
        notification.error({
          message: t('recoveryPasswordPage.passwordResetLeastSixCharacters'),
          placement: 'bottomLeft',
          duration: 15,
        });
      } else {
        notification.error({
          message: t('recoveryPasswordPage.passwordResetFailed'),
          placement: 'bottomLeft',
          duration: 15,
        });
      }
    }
  };

  const onConfirmPasswordChange = (value: string) => {
    setConfirmPassword(value);
    setIsPasswordsMatch(true);
  };

  return (
    <ImageBgPageWrapper>
      <InfoWrapper
        borderRadius='0px'
        innerPaddingTop='20px'
        isWhiteBackground={false}
      >
        <div className={styles.wrapper}>
          <h1 className={styles.title}>{t('recoveryPasswordPage.title')}</h1>
          <div className={styles.inputsBlock}>
            <div className={styles.inputWrapper}>
              <div className={styles.span}>
                {t('recoveryPasswordPage.password')}
              </div>
              <div className={styles.passwordInput}>
                <Input.Password
                  value={password}
                  placeholder={t('recoveryPasswordPage.password')}
                  onChange={(event) => setPassword(event.target.value)}
                />
              </div>
            </div>
            <div className={styles.inputWrapper}>
              <div className={styles.span}>
                {t('recoveryPasswordPage.repeatPassword')}
              </div>
              <div className={styles.passwordInput}>
                <Input.Password
                  value={confirmPassword}
                  placeholder={t('recoveryPasswordPage.repeatPassword')}
                  onChange={(event) =>
                    onConfirmPasswordChange(event.target.value)
                  }
                />
                {!isPasswordsMatch ? (
                  <div className={styles.passwordsShouldMatch}>
                    {t('recoveryPasswordPage.passwordsShouldMatch')}
                  </div>
                ) : null}
              </div>
            </div>
            <div>
              <button
                className={styles.actionButton}
                onClick={onSubmit}
                disabled={!password || !confirmPassword}
              >
                {t('recoveryPasswordPage.resetPassword')}
              </button>
            </div>
          </div>
        </div>
      </InfoWrapper>
    </ImageBgPageWrapper>
  );
}

export default RecoveryPasswordPage;
