import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import resourcesToBackend from 'i18next-resources-to-backend';

const languageDetector = new LanguageDetector();
languageDetector.addDetector({
  name: 'default',

  lookup(options) {
    // options -> are passed in options
    return 'ru';
  },

  cacheUserLanguage(lng, options) {
    // options -> are passed in options
    // lng -> current language, will be called after init and on changeLanguage
    // store it
  },
});

i18n
  .use(initReactI18next)
  .use(languageDetector)
  .use(
    resourcesToBackend(
      (language: string, namespace: string) =>
        import(`./l10n/${language}/${namespace}.json`)
    )
  )
  .init({
    fallbackLng: 'ru',
    interpolation: {
      escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    },
    detection: {
      order: ['localStorage', 'default'],
    },
  });

export default i18n;
