import Strapi from 'strapi-sdk-js';
import BaseStrapiDAO from '../base/BaseStrapiDAO';
import SubscriptionType from 'model/entity/loko/type/SubscriptionType';

export default class SubscriptionTypeDAO extends BaseStrapiDAO<SubscriptionType> {
  constructor(client: Strapi) {
    super({
      client: client,
      tableNameSingular: 'subscription-type',
      tableNamePlural: 'subscription-types',
    });
  }
}
