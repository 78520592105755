import cn from 'classnames';
import { ReactElement } from 'react';
import { StandingsReadableDto } from 'types/Types';
import { GamesResultProps } from '../../Tournament';
import styles from './TeamTile.module.scss';

type TeamTipe = 'a' | 'b' | 'final';
type ResultPosition = 'left' | 'center' | 'right';
type TeamTileProps = {
  data: GamesResultProps;
  generalStandings: Array<StandingsReadableDto>;
  type: TeamTipe;
  position: ResultPosition;
};

function TeamTile({
  data,
  generalStandings,
  type,
  position,
}: TeamTileProps): ReactElement {
  const tileData = () => {
    switch (type) {
      case 'a':
        return {
          score: data?.teamaWins,
          logo:
            generalStandings.find(
              (team) => team.club_id === data?.teama?.toString()
            )?.logo ?? '',
          isWinner: data?.teamaWins > data?.teambWins,
        };
      case 'b':
        return {
          score: data?.teambWins,
          logo:
            generalStandings.find(
              (team) => team.club_id === data?.teamb?.toString()
            )?.logo ?? '',
          isWinner: data?.teambWins > data?.teamaWins,
        };
      case 'final':
        if (data?.teamaWins === data?.teambWins) {
          return {};
        }

        if (data?.teamaWins > data?.teambWins && data?.teamaWins >= 4) {
          return {
            score: data?.teamaWins,
            logo:
              generalStandings.find(
                (team) => team.club_id === data?.teama?.toString()
              )?.logo ?? '',
          };
        }

        if (data?.teambWins > data?.teamaWins && data?.teambWins >= 4) {
          return {
            score: data?.teambWins,
            logo:
              generalStandings.find(
                (team) => team.club_id === data?.teamb?.toString()
              )?.logo ?? '',
          };
        }

        return {};
      default:
        return {};
    }
  };

  const { score, logo, isWinner } = tileData();

  const renderContent = () => {
    switch (position) {
      case 'left':
        return (
          <>
            {logo ? (
              <img className={styles.logo} src={logo} alt='team-logo' />
            ) : null}
            <div className={cn(styles.score, styles.left)}>{score}</div>
          </>
        );
      case 'center':
        return logo ? (
          <img className={styles.logo} src={logo} alt='team-logo' />
        ) : null;
      case 'right':
        return (
          <>
            <div className={cn(styles.score, styles.right)}>{score}</div>
            {logo ? (
              <img className={styles.logo} src={logo} alt='team-logo' />
            ) : null}
          </>
        );
      default:
        return <></>;
    }
  };

  return (
    <div
      className={cn(styles.tile, {
        [styles.winner]: isWinner,
        [styles.final]: type === 'final' && data,
      })}
    >
      {renderContent()}
    </div>
  );
}

export default TeamTile;
