import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getRosterBySeasonCode, getSeasonsByTeamId } from '../../../api/api';
import { RosterDto, Season, SeasonsTeamCode } from '../../../types/Types';
import {
  doFilterRosterByPosition,
  doSortByDisplayOrder,
  sortSeasonOptions,
} from '../../../helpers/helpers';
import { useStore } from '../../../store/store';
import InfoWrapper from '../../Wrappers/InfoWrapper/InfoWrapper';
import styles from './RosterPage.module.scss';
import RosterTabsWithInfo from './RosterTabsWithInfo/RosterTabsWithInfo';
import ImageBgPageWrapper from '../../common/ImageBgPageWrapper/ImageBgPageWrapper';
import Container from '../../common/Container/Container';
import { observer } from 'mobx-react-lite';
import Breadcrumbs from 'components/common/Breadcrumbs/Breadcrumbs';
import ErrorWidget from 'components/common/ErrorWidget/ErrorWidget';
import { useTranslation } from 'react-i18next';
import Select, { SelectOption } from 'components/common/Select/Select';
import Spinner from 'components/Spinner/Spinner';

const RosterPage = observer(() => {
  const { t } = useTranslation();
  const { teamCode } = useParams();
  const { currentSeasonStore } = useStore();
  const [goalkeepers, setGoalkeepers] = useState<Array<RosterDto>>([]);
  const [defenders, setDefenders] = useState<Array<RosterDto>>([]);
  const [forwards, setForwards] = useState<Array<RosterDto>>([]);
  const [seasonsOptions, setSeasonsOptions] =
    useState<Array<{ key: string; value: string }>>();
  const [season, setSeason] = useState<string>('');
  const [isRosterError, setIsRosterError] = useState<boolean>(false);
  const [isSeasonError, setIsSeasonError] = useState<boolean>(false);
  const [isCatastrophe, setIsCatastrophe] = useState<boolean>(false);
  const [isLoadingSeasons, setIsLoadingSeasons] = useState<boolean>(true);
  const [isLoadingRoster, setIsLoadingRoster] = useState<boolean>(true);

  useEffect(() => {
    const fetch = async () => {
      try {
        setIsLoadingSeasons(true);
        const seasonList = await getSeasonsByTeamId(teamCode || 'men');
        const seasonOptions = getSeasonsFilterOptions(seasonList.data);
        seasonOptions.sort((a, b) => sortSeasonOptions(a.key, b.key));
        setSeasonsOptions(seasonOptions);

        if (teamCode === 'catastrophe') {
          setIsCatastrophe(true);
        }

        const seasonCode = currentSeasonStore.getSeasonByCode(teamCode);
        if (seasonCode) {
          const isPrip = seasonCode.includes('prip');
          if (isPrip && seasonOptions) {
            setSeason(seasonOptions[0].key);
          } else {
            setSeason(seasonCode);
          }
        }

        setIsSeasonError(false);
      } catch (e) {
        setIsSeasonError(true);
      } finally {
        setIsLoadingSeasons(false);
      }
    };

    if (!currentSeasonStore.isLoading) fetch();
  }, [
    currentSeasonStore.currentSeason,
    teamCode,
    currentSeasonStore.isLoading,
  ]);

  useEffect(() => {
    const fetch = async () => {
      try {
        setIsLoadingRoster(true);
        const response = await getRosterBySeasonCode(season);
        setGoalkeepers(
          doSortByDisplayOrder(doFilterRosterByPosition(response?.data, 1))
        );
        setDefenders(
          doSortByDisplayOrder(doFilterRosterByPosition(response?.data, 2))
        );
        setForwards(
          doSortByDisplayOrder(doFilterRosterByPosition(response?.data, 3))
        );

        setIsRosterError(false);
      } catch (error) {
        setIsRosterError(true);
      } finally {
        setIsLoadingRoster(false);
      }
    };

    if (season.length > 0) {
      fetch();
    }
  }, [season, isCatastrophe]);

  function changeFilter(value: string) {
    setSeason(value || '');
  }

  function getSeasonsFilterOptions(
    seasonsList: Season[]
  ): Array<{ key: string; value: string }> {
    const seasonsOptions = new Map();
    seasonsList &&
      seasonsList.forEach((season) => {
        !season.attributes.code.endsWith('prip') &&
          !season.attributes.code.endsWith('prip-2') &&
          !season.attributes.code.endsWith('-3') &&
          seasonsOptions.set(
            season.attributes.code,
            `${season.attributes.name} ${season.attributes.years_interval}`
          );
      });
    return Array.from(seasonsOptions, ([key, value]) => ({ key, value }));
  }

  function getSeasonSelectOptions(
    options: { key: string; value: string }[]
  ): SelectOption[] {
    return options.map((option) => {
      return {
        value: option.key,
        displayValue: option.value,
      };
    });
  }

  return (
    <ImageBgPageWrapper>
      <Container paddingTop='50px' paddingBottom='0px'>
        <div className={styles.wrapperBreadcrumbs}>
          <Breadcrumbs
            breadcrumbs={[
              {
                name: t('rosterPage.breadcrumb'),
                url: `/roster/${teamCode}`,
              },
            ]}
          />
        </div>
        {isCatastrophe && (
          <div className={styles.catastropheHeaderText}>Жертвы катастрофы</div>
        )}
        <InfoWrapper
          isWhiteBackground={true}
          isPaddingZero={true}
          innerPaddingTop='0px'
        >
          <div className={styles.wrapper}>
            <div className={styles.innerWrapper}>
              <div className={styles.titleFlex}>
                <h1 className={styles.title}>{t('rosterPage.title')}</h1>
                {teamCode !== SeasonsTeamCode.catastrophe && (
                  <Select
                    className={styles.select}
                    placeholder='СЕЗОН'
                    value={season}
                    values={
                      seasonsOptions
                        ? getSeasonSelectOptions(seasonsOptions)
                        : []
                    }
                    onChange={changeFilter}
                  />
                )}
              </div>

              {currentSeasonStore.isLoading ||
              isLoadingRoster ||
              isLoadingSeasons ? (
                <Spinner />
              ) : isSeasonError ? (
                <ErrorWidget.NotFound />
              ) : isRosterError ? (
                <ErrorWidget.Error />
              ) : (
                <RosterTabsWithInfo
                  goalkeepers={goalkeepers}
                  defenders={defenders}
                  forwards={forwards}
                  season={season}
                />
              )}
            </div>
          </div>
        </InfoWrapper>
      </Container>
    </ImageBgPageWrapper>
  );
});

export default RosterPage;
