import { StrapiRequestParams, StrapiResponse } from 'strapi-sdk-js';
import BaseStrapiDAO from './BaseStrapiDAO';
import i18n from 'i18n';

type _LocalizedData = {
  id: number;
  attributes: {
    locale: string;
    localizations: {
      data: Array<{
        id: number;
        attributes: {
          locale: string;
        };
      }>;
    };
  };
};

abstract class BaseLocalizedStrapiDAO<T> extends BaseStrapiDAO<T> {
  public find(
    params?: StrapiRequestParams | undefined
  ): Promise<StrapiResponse<T[]>> {
    return super.find({
      ...params,
      locale: i18n.resolvedLanguage === 'en' ? 'en' : 'ru',
    });
  }

  public findAll(
    params?: StrapiRequestParams | undefined
  ): Promise<StrapiResponse<T[]>> {
    return super.findAll({
      ...params,
      locale: i18n.resolvedLanguage === 'en' ? 'en' : 'ru',
    });
  }

  public async findOne(
    id: string | number,
    params?: StrapiRequestParams | undefined
  ): Promise<StrapiResponse<T>> {
    const result: StrapiResponse<T> = await super.findOne(id, {
      ...params,
      populate: '*',
    });
    try {
      const data = result.data as _LocalizedData;
      if (data.attributes.locale !== i18n.resolvedLanguage) {
        return super.findOne(data.attributes.localizations.data[0].id, params);
      } else {
        return result;
      }
    } catch (error) {
      return result;
    }
  }
}

export default BaseLocalizedStrapiDAO;
