import { MenuElementDto } from 'types/Types';
import styles from './MobileHeaderDrawerAccordion.module.scss';
import Column from 'components/core/layout/Column';
import { useCallback, useMemo, useState } from 'react';
import Row from 'components/core/layout/Row';
import classNames from 'classnames';
import MobileHeaderDrawerAccordionArrow from './MobileHeaderDrawerAccordionArrow';

interface MobileHeaderDrawerAccordionProps {
  menu: MenuElementDto;
}

const MobileHeaderDrawerAccordion = ({
  menu,
}: MobileHeaderDrawerAccordionProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const onTap = useCallback(() => setIsOpen((prevIsOpen) => !prevIsOpen), []);

  const sortedItems = useMemo(() => {
    return menu.attributes.main_menu_item.data.sort(
      (a, b) => a?.attributes?.display_order - b?.attributes?.display_order
    );
  }, [menu.attributes.main_menu_item.data]);

  const renderItems = () => (
    <Column gap='12px'>
      {sortedItems.map((item) => (
        <a href={item.attributes.url} key={item.id} className={styles.itemText}>
          <span>{item.attributes.display_name}</span>
        </a>
      ))}
    </Column>
  );

  const renderTop = () => (
    <div onClick={onTap} className={styles.headerItem}>
      <Row alignItems='center' justifyContent='space-between'>
        <span
          className={classNames(
            styles.headerText,
            isOpen ? styles.headerTextOpen : styles.headerTextClosed
          )}
        >
          {menu.attributes.display_name}
        </span>
        <MobileHeaderDrawerAccordionArrow isOpen={isOpen} />
      </Row>
    </div>
  );

  return (
    <Column gap='10px'>
      {renderTop()}
      {isOpen && renderItems()}
    </Column>
  );
};

export default MobileHeaderDrawerAccordion;
