import { ReactElement } from 'react';

interface UpperRightArrowProps {
  fillColor: string;
  rotateDegrees?: number;
}

export default function SelectArrow({
  fillColor,
  rotateDegrees = 0,
}: UpperRightArrowProps): ReactElement {
  return (
    <div
      style={{
        display: 'flex',
        rotate: `${rotateDegrees}deg`,
        transition: 'all 0.3s',
      }}
    >
      <svg
        width='14'
        height='8'
        viewBox='0 0 14 8'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M6.29288 4.58069C6.6834 4.97121 7.31657 4.97121 7.70709 4.58069L11.5963 0.691482C11.9868 0.300958 12.62 0.300957 13.0105 0.691482L13.1113 0.792224C13.5018 1.18275 13.5018 1.81591 13.1113 2.20644L7.70709 7.6106C7.31657 8.00113 6.6834 8.00113 6.29288 7.6106L0.888713 2.20644C0.498188 1.81591 0.498188 1.18275 0.888713 0.792224L0.989455 0.691482C1.37998 0.300958 2.01314 0.300957 2.40367 0.691481L6.29288 4.58069Z'
          fill={fillColor}
        />
      </svg>
    </div>
  );
}
