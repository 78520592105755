import { ReactElement, RefObject } from 'react';
import styles from './Footer.module.scss';
import FooterWrapper from '../Wrappers/FooterWraper/FooterWraper';
import Partners from './Partners/Partners';
import SubscribeItems from './SubscribeItems/SubscribeItems';
import EmailBlock from './EmailBlock/EmailBlock';
import Buttons from './Buttons/Buttons';
import Info from './Info/Info';
import AssociateInfo from './AssociateInfo/AssociateInfo';
import Row from 'components/core/layout/Row';
import ShowOn from 'components/core/adaptivity/ShowOn';
import Column from 'components/core/layout/Column';

type FooterType = {
  footerRef: RefObject<HTMLDivElement>;
};

function Footer({ footerRef }: FooterType): ReactElement {
  return (
    <FooterWrapper>
      <footer ref={footerRef} className={styles.footer}>
        <SubscribeItems />
        <ShowOn largeDesktop smallDesktop largeTablet smallTablet>
          <Row gap='16px'>
            <EmailBlock />
            <Buttons />
          </Row>
        </ShowOn>
        <ShowOn largeMobile smallMobile>
          <Column gap='15px'>
            <EmailBlock />
            <div className={styles.buttonsWrapper}>
              <Buttons />
            </div>
          </Column>
        </ShowOn>
        <Partners />
        <Info />
        <AssociateInfo />
      </footer>
    </FooterWrapper>
  );
}

export default Footer;
