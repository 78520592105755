import { ReactElement, useEffect, useState } from 'react';
import { getLastGame, getNextGame } from 'api/api';
import { GameWithLogo, SeasonsTeamCode } from 'types/Types';
import GameWidgetHP from './GameWidgetHP/GameWidgetHP';
import WidgetHeader from '../WidgetHeader/WidgetHeader';
import { useStore } from 'store/store';
import { observer } from 'mobx-react-lite';
import ShowOn from '../core/adaptivity/ShowOn';
import Column from '../core/layout/Column';
import Row from '../core/layout/Row';
import { useParams } from 'react-router-dom';
import Spinner from 'components/Spinner/Spinner';
import ErrorWidget from 'components/common/ErrorWidget/ErrorWidget';
import { useTranslation } from 'react-i18next';

const GameWidgetHPBlock = observer((): ReactElement => {
  const { t } = useTranslation();
  const [lastGame, setLastGame] = useState<GameWithLogo>();
  const [playedGameNotLast, setPlayedGameNotLast] = useState<GameWithLogo>();
  const [nextGame, setNextGame] = useState<GameWithLogo>();
  const [nextGameNotCurrent, setNextGameNotCurrent] = useState<GameWithLogo>();
  const { lokomotivTeamsStore } = useStore();
  const { teamCode } = useParams();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isError, setIsError] = useState<boolean>(false);

  useEffect(() => {
    const getData = async () => {
      try {
        if (
          lokomotivTeamsStore.lokomotivTeams?.men?.data?.attributes
            ?.id_league_web
        ) {
          getLastGame(
            lokomotivTeamsStore.lokomotivTeams?.men?.data?.attributes
              .id_league_web
          ).then((lastGameResp) => {
            setLastGame(lastGameResp?.data[0]);
            setPlayedGameNotLast(lastGameResp?.data[1]);
          });

          getNextGame(
            lokomotivTeamsStore.lokomotivTeams?.men?.data?.attributes
              .id_league_web
          ).then((nextGameResp) => {
            setNextGame(nextGameResp?.data[0]);
            setNextGameNotCurrent(nextGameResp?.data[1]);
          });
        }
      } catch (error) {
        setIsError(true);
      } finally {
        setIsLoading(false);
      }
    };
    getData();
  }, [lokomotivTeamsStore.lokomotivTeams]);

  const renderFirst = () => {
    if (nextGame && nextGame.attributes?.is_current && nextGameNotCurrent) {
      return <GameWidgetHP game={nextGame} isGameInFuture={true} />;
    } else {
      if (!nextGame) {
        return (
          playedGameNotLast && (
            <GameWidgetHP game={playedGameNotLast} isGameInFuture={false} />
          )
        );
      }
      return (
        lastGame && <GameWidgetHP game={lastGame} isGameInFuture={false} />
      );
    }
  };

  const renderSecond = () => {
    if (nextGame && nextGame.attributes?.is_current && nextGameNotCurrent) {
      return <GameWidgetHP game={nextGameNotCurrent} isGameInFuture={true} />;
    } else {
      if (nextGame) {
        return (
          nextGame && <GameWidgetHP game={nextGame} isGameInFuture={true} />
        );
      }
      return (
        lastGame && <GameWidgetHP game={lastGame} isGameInFuture={false} />
      );
    }
  };

  const renderColumn = () => {
    return (
      <Column gap='20px'>
        {renderFirst()}
        {renderSecond()}
      </Column>
    );
  };

  const renderRow = () => {
    return (
      <Row gap='20px'>
        {renderFirst()}
        {renderSecond()}
      </Row>
    );
  };

  return (
    <div>
      <WidgetHeader
        headerText={t('homepage.gameWidget.header')}
        linkText={t('homepage.gameWidget.link')}
        uri={`/schedule/${teamCode ? teamCode : SeasonsTeamCode.men}`}
      />

      {isLoading ? (
        <Spinner />
      ) : isError || !lastGame ? (
        <ErrorWidget.Error />
      ) : (
        <>
          <ShowOn smallMobile largeMobile largeDesktop smallDesktop>
            {renderColumn()}
          </ShowOn>
          <ShowOn largeTablet smallTablet>
            {renderRow()}
          </ShowOn>
        </>
      )}
    </div>
  );
});

export default GameWidgetHPBlock;
