import { ReactElement, useEffect, useState } from 'react';
import styles from './ScoreBanner.module.scss';
import {
  GameProtocolDto,
  GameWithLogo,
  ProtocolGameSummaryDto,
} from 'types/Types';
import classNames from 'classnames';
import ShowOn from 'components/core/adaptivity/ShowOn';
import { Breakpoints, PageHeader } from 'constants/adaptivity';
import ProtocolBannerLeftBlock from 'assets/ProtocolBannerLeftBlock';
import ProtocolBannerLeftLine from 'assets/ProtocolBannerLeftLine';
import ProtocolBannerRightBlock from 'assets/ProtocolBannerRightBlock';
import ProtocolBannerRightLine from 'assets/ProtocolBannerRightLine';
import { CONTINUATION } from 'constants/constants';
import { t } from 'i18next';

type ScoreBannerProps = {
  game: GameWithLogo;
  protocol: GameProtocolDto;
};

function ScoreBanner({ game, protocol }: ScoreBannerProps): ReactElement {
  const [isStuck, setStuck] = useState(false);

  const handleScroll = () => {
    const heightLimit =
      window.innerWidth < Breakpoints.smallDesktop
        ? PageHeader.mobile
        : PageHeader.desktop;

    if (window.scrollY > heightLimit) {
      setStuck(true);
    } else {
      setStuck(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const getShotsString = (
    data: ProtocolGameSummaryDto | undefined
  ): number | string => {
    if (!data) {
      return '-';
    }

    const sum = data['@_summary']
      ? data['@_summary']
      : Number(data['@_p1']) +
        Number(data['@_p2']) +
        Number(data['@_p3']) +
        Number(data['@_ot']);

    return `БР: ${sum}`;
  };

  return (
    <div className={styles.bannerWrapper}>
      <div
        className={classNames(styles.scoreBanner, { [styles.stuck]: isStuck })}
      >
        <div className={styles.leftBlock}>
          <ShowOn largeDesktop smallDesktop largeTablet>
            <div className={styles.block}>
              <ProtocolBannerLeftBlock
                fillColor={
                  game.attributes?.team_1?.data?.attributes?.main_color
                }
              />
            </div>
            <div className={styles.line}>
              <ProtocolBannerLeftLine
                fillColor={
                  game.attributes?.team_1?.data?.attributes?.additional_color
                }
              />
            </div>
          </ShowOn>
        </div>
        <div className={styles.content}>
          <div className={styles.team}>
            <div className={styles.imageWrapper}>
              <img
                className={styles.image}
                src={
                  game?.attributes.team_1.data.attributes.logo.data.attributes
                    .url || ''
                }
                alt={game?.attributes.team_1?.data.attributes.name}
              />
            </div>
            <ShowOn largeDesktop smallDesktop largeTablet smallTablet>
              <div className={styles.textWrapper}>
                <div className={styles.city}>
                  {game?.attributes.team_1.data.attributes.city}
                </div>
                <div className={styles.teamName}>
                  {game?.attributes.team_1.data.attributes.name}
                </div>
                <div className={styles.shots}>
                  {getShotsString(
                    protocol.GameSummary?.ShotsList?.Shot?.find(
                      (item) => item['@_team'] === 'A'
                    )
                  )}
                </div>
              </div>
            </ShowOn>
          </div>
          <div className={styles.score}>
            <div>{game?.attributes.score_1}</div>
            <div className={styles.finalWrapper}>
              <div className={styles.final}>
                <div>
                  {game?.attributes.is_current
                    ? `${t('gameProtocolPage.gameIsOn')}`
                    : `${t('gameProtocolPage.gameIsFinished')}`}
                </div>
              </div>
              <span className={styles.continuation}>
                {CONTINUATION.get(
                  game?.attributes?.continuation?.data?.attributes?.name
                )}
              </span>
            </div>
            <div>{game?.attributes.score_2}</div>
          </div>
          <div className={classNames(styles.team, styles.justifyAtEnd)}>
            <ShowOn largeDesktop smallDesktop largeTablet smallTablet>
              <div
                className={classNames(styles.textWrapper, styles.alignAtEnd)}
              >
                <div className={styles.city}>
                  {game?.attributes.team_2.data.attributes.city}
                </div>
                <div className={styles.teamName}>
                  {game?.attributes.team_2.data.attributes.name}
                </div>
                <div className={styles.shots}>
                  {getShotsString(
                    protocol.GameSummary?.ShotsList?.Shot?.find(
                      (item) => item['@_team'] === 'B'
                    )
                  )}
                </div>
              </div>
            </ShowOn>
            <div className={styles.imageWrapper}>
              <img
                className={styles.image}
                src={
                  game?.attributes.team_2.data.attributes.logo.data.attributes
                    .url || ''
                }
                alt={game?.attributes.team_2?.data.attributes.name}
              />
            </div>
          </div>
        </div>
        <div className={styles.rightBlock}>
          <ShowOn largeDesktop smallDesktop largeTablet>
            <div className={styles.line}>
              <ProtocolBannerRightLine
                fillColor={
                  game.attributes?.team_2?.data?.attributes?.additional_color
                }
              />
            </div>
            <div className={styles.block}>
              <ProtocolBannerRightBlock
                fillColor={game.attributes?.team_2?.data?.attributes.main_color}
              />
            </div>
          </ShowOn>
        </div>
      </div>
    </div>
  );
}

export default ScoreBanner;
