import { ReactElement, useMemo } from 'react';
import styles from './Breadcrumbs.module.scss';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

interface BreadcrumbInfo {
  name: string;
  url: string;
}

interface BreadcrumbsProps {
  breadcrumbs: BreadcrumbInfo[];
  addRootPageRouteAutomatically?: boolean;
  isWhiteColor?: boolean;
}

const Breadcrumbs = ({
  breadcrumbs,
  addRootPageRouteAutomatically = true,
  isWhiteColor = true,
}: BreadcrumbsProps): ReactElement => {
  const { t } = useTranslation();
  const finalBreadcrumbs = useMemo(
    () =>
      addRootPageRouteAutomatically
        ? [
            {
              name: t('breadcrumbs.home'),
              url: '/',
            },
            ...breadcrumbs,
          ]
        : breadcrumbs,
    [addRootPageRouteAutomatically, breadcrumbs]
  );

  const renderCrumbs = () => {
    let crumbElements: ReactElement[] = [];
    for (let index = 0; index < finalBreadcrumbs.length; index++) {
      if (index !== 0) {
        const separatorElement = (
          <span
            key={index * 2}
            className={isWhiteColor ? styles.separator : styles.separatorDark}
          >
            {' / '}
          </span>
        );
        crumbElements.push(separatorElement);
      }

      const crumb = finalBreadcrumbs[index];

      const element = (
        <a href={crumb.url} key={crumb.name}>
          <span
            className={
              index === finalBreadcrumbs.length - 1
                ? classNames(styles.current, {
                    [styles.darkCurrent]: !isWhiteColor,
                  })
                : classNames(styles.other, {
                    [styles.darkOther]: !isWhiteColor,
                  })
            }
            key={index * 2 + 1}
          >
            {crumb.name}
          </span>
        </a>
      );
      crumbElements.push(element);
    }
    return crumbElements;
  };

  return <div className={styles.breadcrumbs}>{renderCrumbs()}</div>;
};

export default Breadcrumbs;
