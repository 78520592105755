import { useEffect, useState } from 'react';
import {
  getDefaultPlayerImage,
  getExecutiveById,
  getExecutivesByType,
} from '../../../api/api';
import {
  DefaultImageObject,
  ExecutiveDto,
  ExecutiveType,
} from '../../../types/Types';
import InfoWrapper from '../../Wrappers/InfoWrapper/InfoWrapper';
import ImageBgPageWrapper from '../../common/ImageBgPageWrapper/ImageBgPageWrapper';
import Container from '../../common/Container/Container';
import BreadcrumbsWrapper from 'components/common/BreadcrumbsWrapper/BreadcrumbsWrapper';
import { observer } from 'mobx-react-lite';
import { useParams, useSearchParams } from 'react-router-dom';
import ExecutiveSmallTiles from '../../ExecutivesSmallTiles/ExecutivesSmallTiles';
import ExecutiveTabsWithInfo from '../../ExecutiveTabsWithInfo/ExecutiveTabsWithInfo';
import BioBox from '../../PlayerPage/Bio/BioBox/BioBox';
import SetRespError, {
  getAge,
  getDate,
  getFullExecutiveName,
  getYearPostfix,
} from '../../../helpers/helpers';
import styles from './ExecutivePage.module.scss';
import ShowOn from 'components/core/adaptivity/ShowOn';
import { DEFAULT_IMAGE_NAME, LOKOMOTIV_CODE } from 'constants/constants';
import ErrorWidget from 'components/common/ErrorWidget/ErrorWidget';
import ImageLoader from 'components/common/ImageLoaders/ImageLoader/ImageLoader';
import { useTranslation } from 'react-i18next';
import ErrorPage from '../ErrorPage/ErrorPage';
import Spinner from 'components/Spinner/Spinner';

const ExecutivePage = observer(() => {
  const { t } = useTranslation();
  const [executive, setExecutive] = useState<ExecutiveDto>();
  const [otherExecutives, setOtherExecutives] = useState<ExecutiveDto[]>([]);
  const [defaultPhoto, setDefaultPhoto] = useState<DefaultImageObject>();
  const [isError, setIsError] = useState<boolean>(false);
  const [isNotFoundError, setIsNotFoundError] = useState<boolean>(false);
  const [searchParams] = useSearchParams();
  const { executiveId } = useParams();

  useEffect(() => {
    const getExecutiveInfo = async () => {
      try {
        const season = searchParams.get('season');
        const executiveResponse = await getExecutiveById(Number(executiveId));
        const executivesResponse = await getExecutivesByType(
          ExecutiveType.EXECUTIVE,
          season || '',
          true
        );

        const filteredExecutives = executivesResponse?.data?.filter(
          (item: ExecutiveDto) => item.id !== Number(executiveId)
        );
        setExecutive(executiveResponse?.data);
        setOtherExecutives(filteredExecutives);
      } catch (e: any) {
        SetRespError(e, setIsNotFoundError, setIsError);
      }

      try {
        const photo = await getDefaultPlayerImage(DEFAULT_IMAGE_NAME);
        setDefaultPhoto(photo?.data[0]);
      } catch (e: any) {
        console.error(e?.response?.data?.error || e?.message);
      }
    };

    getExecutiveInfo();
  }, [executiveId]);

  if (executive && executive?.attributes.type !== ExecutiveType.EXECUTIVE)
    return <ErrorPage />;

  if (
    executive &&
    executive?.attributes?.team_filter.data.every(
      (team) => team.attributes.code !== LOKOMOTIV_CODE
    )
  )
    return <ErrorPage />;

  return (
    <ImageBgPageWrapper
      imageInfo={{
        img_url: executive?.attributes?.bg_photo?.data?.attributes?.url ?? '',
        gradient_height: 200,
        image_height: 700,
      }}
    >
      <Container paddingTop='50px' paddingBottom='0px'>
        <BreadcrumbsWrapper
          breadcrumbs={[
            { name: t('executivePage.breadcrumb'), url: '/executives' },
            {
              name: getFullExecutiveName(executive),
              url: `/executive/${executiveId}`,
            },
          ]}
        />
        {isNotFoundError ? (
          <ErrorWidget.NotFound isOnDarkBackground />
        ) : isError ? (
          <ErrorWidget.Error isOnDarkBackground />
        ) : executive ? (
          <>
            <div className={styles.executiveHeader}>
              <div className={styles.info}>
                <ShowOn largeDesktop smallDesktop largeTablet smallTablet>
                  <p className={styles.position}>
                    {executive?.attributes?.position ?? ''}
                  </p>
                  <p className={styles.name}>
                    {getFullExecutiveName(executive, false)}
                  </p>
                  <p className={styles.surname}>
                    {executive?.attributes?.surname ?? ''}
                  </p>
                </ShowOn>
                <ShowOn largeMobile smallMobile>
                  <p className={styles.positionMobile}>
                    {executive?.attributes?.position ?? ''}
                  </p>
                  <p className={styles.nameMobile}>
                    {getFullExecutiveName(executive)}
                  </p>
                </ShowOn>
                <div className={styles.bioWrapper}>
                  {executive?.attributes?.birth && (
                    <>
                      <BioBox
                        name={t('executivePage.bio.birthDate')}
                        value={getDate(executive?.attributes?.birth)}
                      />
                      <BioBox
                        name={t('executivePage.bio.age')}
                        value={`${getAge(
                          executive?.attributes?.birth
                        )} ${getYearPostfix(
                          getAge(executive?.attributes?.birth)
                        )}`}
                      />
                    </>
                  )}
                </div>
              </div>
              <ShowOn largeDesktop smallDesktop largeTablet>
                <div className={styles.imageWrapper}>
                  <ImageLoader
                    image={
                      executive?.attributes?.photo?.data?.attributes?.url ??
                      defaultPhoto?.attributes.image?.data?.attributes?.url ??
                      ''
                    }
                    alt={
                      `${executive?.attributes?.surname} ${executive?.attributes?.name}` ??
                      'executive'
                    }
                  />
                </div>
              </ShowOn>
            </div>
            <InfoWrapper
              isWhiteBackground={true}
              isPaddingZero={true}
              borderRadius='10px'
            >
              {executive && <ExecutiveTabsWithInfo executive={executive} />}
            </InfoWrapper>
          </>
        ) : (
          <Spinner />
        )}
        {otherExecutives?.length > 0 && (
          <div className={styles.otherExecutives}>
            <p className={styles.title}>{t('executivePage.otherExecutives')}</p>
            <ExecutiveSmallTiles
              executives={otherExecutives}
              defaultPhoto={defaultPhoto}
            />
          </div>
        )}
      </Container>
    </ImageBgPageWrapper>
  );
});

export default ExecutivePage;
