import { makeAutoObservable } from 'mobx';
import { getDocuments } from 'api/api';
import { DocumentsDto } from 'types/Types';

class DocumentsStore {
  constructor() {
    makeAutoObservable(this);
  }

  documents: DocumentsDto[] = [];

  async loadDocuments() {
    const response = await getDocuments();
    const docs = response?.data.sort(
      (a: DocumentsDto, b: DocumentsDto) =>
        a.attributes.display_order - b.attributes.display_order
    );

    this.documents = docs;
  }
}

export default DocumentsStore;
