import cn from 'classnames';
import { ReactElement, useState } from 'react';
import { DefaultImageObject, ExecutiveDto } from '../../types/Types';
import styles from './ExecutiveTile.module.scss';
import { useNavigate } from 'react-router-dom';
import { getDate, getFullExecutiveName } from '../../helpers/helpers';
import ImageLoader from '../common/ImageLoaders/ImageLoader/ImageLoader';
import { useTranslation } from 'react-i18next';

type ExecutiveTileProps = {
  executive: ExecutiveDto;
  season?: string;
  defaultPhoto?: DefaultImageObject;
};

const ExecutiveTile = ({
  executive,
  season,
  defaultPhoto,
}: ExecutiveTileProps): ReactElement => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isHovered, setIsHovered] = useState<boolean>(false);
  const { id, attributes } = executive;

  return (
    <div
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      className={styles.wrapper}
      onClick={() =>
        attributes?.is_catastrophe
          ? navigate(`/catastrophe/${attributes?.type}/${id}`)
          : navigate(
              `/${attributes?.type}/${id}${season ? `?season=${season}` : ''}`
            )
      }
    >
      {isHovered ? (
        <ImageLoader
          className={styles.image}
          image={
            attributes?.bg_photo?.data?.attributes?.url ??
            defaultPhoto?.attributes?.image?.data?.attributes?.url ??
            ''
          }
          alt={attributes?.name}
        />
      ) : (
        <ImageLoader
          className={styles.image}
          image={
            attributes?.photo?.data?.attributes?.url ??
            defaultPhoto?.attributes?.image?.data?.attributes?.url ??
            ''
          }
          alt={attributes?.name}
        />
      )}
      {attributes?.is_catastrophe ? (
        <>
          <p className={styles.position}>{attributes?.position ?? ''}</p>
          <p className={styles.fullname}>
            {getFullExecutiveName(executive) ?? ''}
          </p>
          <p className={styles.birthText}>{t('executivesPage.birthDate')}</p>
          <p className={styles.birth}>{getDate(attributes?.birth)}</p>
        </>
      ) : (
        <>
          <p className={styles.fullname}>
            {getFullExecutiveName(executive) ?? ''}
          </p>
          <p className={cn(styles.position, styles.bottomPosition)}>
            {attributes?.position ?? ''}
          </p>
        </>
      )}
    </div>
  );
};

export default ExecutiveTile;
