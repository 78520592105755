import Article from 'model/entity/loko/type/Article';
import BaseLocalizedStrapiDAO from '../base/BaseLocalizedStrapiDAO';
import Strapi from 'strapi-sdk-js';

export default class ArticleDAO extends BaseLocalizedStrapiDAO<Article> {
  constructor(client: Strapi) {
    super({
      client: client,
      tableNameSingular: 'article',
      tableNamePlural: 'articles',
    });
  }

  public getTopStories(quantity: number) {
    return this.find({
      populate: '*',
      filters: {
        tops: {
          $eq: true,
        },
        tops_order: {
          $gte: 1,
          $lte: 4,
        },
        $or: [
          {
            team_filter: {
              code: {
                $null: true,
              },
            },
          },
          {
            team_filter: {
              code: {
                $eq: 'LOK',
              },
            },
          },
        ],
      },
      sort: ['tops_order:asc'],
      pagination: {
        page: 0,
        pageSize: quantity,
      },
    });
  }

  // TODO: getArticlesUsingFilters [hard]
  // TODO: getTopArticle [easy]
  // TODO: getPlayerArticles [easy]
  // TODO: likeArticle [easy]
  // TODO: dislikeArticle [easy]

  // TODO: getArticleById replace with DAO find method
}
