import ArenaCategory from 'model/entity/loko/type/ArenaCategory';
import BaseStrapiDAO from '../base/BaseStrapiDAO';
import Strapi from 'strapi-sdk-js';

export default class ArenaCategoryDAO extends BaseStrapiDAO<ArenaCategory> {
  constructor(client: Strapi) {
    super({
      client: client,
      tableNameSingular: 'arena-category',
      tableNamePlural: 'arena-categories',
    });
  }
}
