import { ReactElement, PropsWithChildren } from "react";
import styles from './styles.module.scss'

type FooterWrapperProps = {
    children?: JSX.Element | JSX.Element[];
}

function FooterWrapper({ children }: PropsWithChildren<FooterWrapperProps>): ReactElement {
    return (
        <div className={styles.footerWrapper}>
            <div className={styles.footerWrapperInner}>
                {children}
            </div>
        </div>
    );
}

export default FooterWrapper;
