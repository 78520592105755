import { ReactElement, useState } from 'react';
import { observer } from 'mobx-react-lite';
import styles from './RestorePasswordModal.module.scss';
import { Input, notification } from 'antd';
import classNames from 'classnames';
import { forgotPassword } from 'api/api';
import { validateEmail } from 'helpers/helpers';
import { useTranslation } from 'react-i18next';

type RestorePasswordModalProps = {
  onCancel: () => void;
};

const RestorePasswordModal = observer(
  ({ onCancel }: RestorePasswordModalProps): ReactElement => {
    const { t } = useTranslation();

    const [email, setEmail] = useState('');
    const [isValidEmail, setIsValidEmail] = useState(true);

    const onEmailChange = (value: string) => {
      setEmail(value);

      if (!value || validateEmail(value)) {
        setIsValidEmail(true);
      } else {
        setIsValidEmail(false);
      }
    };

    const onCancelClick = () => {
      onCancel();
    };

    const onSubmitClick = async () => {
      try {
        const data = {
          email,
        };

        const response = await forgotPassword(data);

        if (response?.data?.ok) {
          notification.success({
            message: t('memoryBookPage.restorePasswordModal.emailSent'),
            placement: 'bottomLeft',
            duration: 15,
          });

          onCancel();
        }
      } catch (e: any) {
        const error = e?.response?.data?.error || e?.message;
        console.log(error);

        notification.error({
          message: t('memoryBookPage.restorePasswordModal.unknownError'),
          placement: 'bottomLeft',
          duration: 15,
        });
      }
    };

    return (
      <div className={styles.editProfileModal}>
        <div className={styles.header}>
          {t('memoryBookPage.restorePasswordModal.header')}
        </div>
        <div className={styles.inputsBlock}>
          <div className={styles.inputWrapper}>
            <div className={styles.span}>
              {t('memoryBookPage.restorePasswordModal.email')}
            </div>
            <div className={styles.input}>
              <Input
                value={email}
                placeholder='Эл. почта'
                onChange={(event) => onEmailChange(event.target.value)}
              />
              {!isValidEmail ? (
                <div className={styles.incorrectEmail}>
                  {t('memoryBookPage.restorePasswordModal.invalidEmail')}
                </div>
              ) : null}
            </div>
          </div>
        </div>
        <div className={styles.actionButtons}>
          <button
            className={classNames(styles.actionButton, styles.active)}
            onClick={onSubmitClick}
          >
            {t('memoryBookPage.restorePasswordModal.sendEmail')}
          </button>
          <button className={styles.actionButton} onClick={onCancelClick}>
            {t('memoryBookPage.restorePasswordModal.cancel')}
          </button>
        </div>
      </div>
    );
  }
);

export default RestorePasswordModal;
