import { Property } from "csstype";

interface RowProps {
  children: React.ReactNode;
  alignItems?: Property.AlignItems;
  justifyContent?: Property.JustifyContent;
  flexWrap?: Property.FlexWrap;
  width?: string;
  height?: string;
  gap?: string;
}

const Row = ({
  children,
  alignItems,
  justifyContent,
  flexWrap,
  width,
  height,
  gap,
}: RowProps) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        flexWrap: flexWrap,
        alignItems: alignItems,
        justifyContent: justifyContent,
        width: width,
        height: height,
        gap: gap,
      }}
    >
      {children}
    </div>
  );
};

export default Row;
