import { ReactElement, useEffect, useState } from 'react';
import styles from './LokoTVPage.module.scss';
import {
  getBeginningOfTheYear,
  getEndOfTheYear,
} from '../../helpers/helpers';
import ViewMoreButton from '../ViewMoreButton/ViewMoreButton';
import VideoModal from '../VideoModal/VideoModal';
import YearVideoFilter from './YearVideoFilter/YearVideoFilter';
import { YoutubeRequestOptions } from '../../types/Types';
import { ReactComponent as Arrow } from '../../assets/upper_right_arrow.svg';
import SideMenu from '../common/SideMenu/SideMenu';
import Container from '../common/Container/Container';
import BreadcrumbsWrapper from 'components/common/BreadcrumbsWrapper/BreadcrumbsWrapper';
import PageHeader from 'components/common/PageHeader/PageHeader';
import ImageBgPageWrapper from '../common/ImageBgPageWrapper/ImageBgPageWrapper';
import ScrollToTopButton from 'components/ScrollToTopButton/ScrollToTopButton';
import { getYouTubeVideoList, getYouTubeVideos } from 'api/api';
import Url from 'constants/urls';
import { useTranslation } from 'react-i18next';
import Colors from 'constants/colors';

const LokoTvPage = (): ReactElement => {
  const { t } = useTranslation();
  const [videos, setVideos] = useState<any[]>([]);
  const [nextPageToken, setNextPageToken] = useState<string>('');
  const [selectedVideo, setSelectedVideo] = useState<string>('');
  const [selectedYear, setSelectedYear] = useState<
    number | string | undefined
  >();

  const getVideos = async (initial = false) => {
    if (!selectedYear) {
      return;
    }

    const options: YoutubeRequestOptions = {
      maxResults: 13,
      publishedAfter: getBeginningOfTheYear(selectedYear),
      publishedBefore: getEndOfTheYear(selectedYear),
    };

    if (!initial && nextPageToken) {
      options.pageToken = nextPageToken;
    }

    try {
      const videoList = await getYouTubeVideoList(options);
      const videosData = await getYouTubeVideos(
        videoList?.items?.map((item: any) => item.id.videoId)
      );

      const videoData = videosData?.items;

      setVideos(
        !initial && nextPageToken ? [...videos, ...videoData] : videoData
      );
      setNextPageToken(videoList?.data?.nextPageToken);
    } catch (error: any) {
      setNextPageToken('');
    }
  };

  useEffect(() => {
    getVideos(true);
  }, [selectedYear]);

  const loadMoreVideos = async () => {
    getVideos();
  };

  const onClose = () => {
    setSelectedVideo('');
  };

  const navigateToYoutube = () => {
    window.open(Url.socials.YOUTUBE);
  };

  return (
    <>
      <ImageBgPageWrapper>
        <Container paddingTop='50px'>
          <BreadcrumbsWrapper
            breadcrumbs={[
              {
                name: t('lokoTvPage.breadcrumb'),
                url: '/lokotv',
              },
            ]}
          />
          <div className={styles.contentWrapper}>
            <PageHeader text={t('lokoTvPage.header')} />
          </div>
          <div className={styles.wrapper}>
            <div className={styles.content}>
              <YearVideoFilter onSelect={setSelectedYear} />
              <div>
                {videos && videos.length > 0 && (
                  <div
                    className={styles.mainVideo}
                    onClick={() => setSelectedVideo(videos[0].id)}
                  >
                    <img
                      className={styles.previewImageMainVideo}
                      src={videos[0].snippet.thumbnails.medium.url}
                      alt={videos[0].snippet.title}
                    />
                    <div className={styles.descriptionMainVideo}>
                      <div className={styles.title}>
                        {videos[0].snippet.title}
                      </div>
                      <div className={styles.date}>
                        {videos[0].snippet.description}
                      </div>
                    </div>
                  </div>
                )}
              </div>

              <div className={styles.videoList}>
                {videos &&
                  videos.length > 0 &&
                  videos.map(
                    (video, index) =>
                      video !== videos[0] && (
                        <div
                          key={`${video.id}_${index}`}
                          className={styles.video}
                          onClick={() => setSelectedVideo(video.id)}
                        >
                          <img
                            className={styles.previewImage}
                            src={video.snippet.thumbnails.medium.url}
                            alt={video.snippet.title}
                          />
                          <div className={styles.description}>
                            <div className={styles.title}>
                              {video.snippet.title}
                            </div>
                            <div className={styles.date}>
                              {video.snippet.description}
                            </div>
                          </div>
                        </div>
                      )
                  )}
              </div>
              {nextPageToken ? (
                <>
                  <div className={styles.buttonWrapper}>
                    <ViewMoreButton onClick={loadMoreVideos} />
                  </div>
                  <div className={styles.ScrollToTopButtonWrapper}>
                    <ScrollToTopButton />
                  </div>
                </>
              ) : (
                <div className={styles.buttonWrapper}>
                  <button
                    className={styles.youtubeLinkButton}
                    onClick={navigateToYoutube}
                  >
                    <div className={styles.text}>
                      {t('lokoTvPage.navigateToYoutube')}
                    </div>
                    <Arrow color={Colors.PRIMARY_RED} />
                  </button>
                </div>
              )}
            </div>
            <div className={styles.sideMenu}>
              <SideMenu />
            </div>
          </div>
        </Container>
      </ImageBgPageWrapper>
      <VideoModal
        isVisible={!!selectedVideo}
        videoSource={selectedVideo}
        close={onClose}
        isYoutube
      />
    </>
  );
};

export default LokoTvPage;
