import { ArenaPointsType } from '../types/Types';
import ParkingIcon from '../assets/icons/arena_points/VIPParking.png';
import RoadIcon from '../assets/icons/arena_points/Road_2.png';
import Entrance from '../assets/icons/arena_points/Entrance.png';
import TicketOffice from '../assets/icons/arena_points/Tickets_office.png';
import Hall from '../assets/icons/arena_points/Ice_hockey_hall.png';
import TransportStop from '../assets/icons/arena_points/Transport_stop.png';
import PressEntrance from '../assets/icons/arena_points/press_entrance_icon.png';
import Building from '../assets/icons/arena_points/building.png';
import Football from '../assets/icons/arena_points/football.png';
import Tennis from '../assets/icons/arena_points/tennis.png';
import Pharmacy from '../assets/icons/arena_points/pharmacy.png';

export const mfskPoints: Array<ArenaPointsType> = [
  {
    bottom: 75,
    right: 87,
    alt: 'mfsk-press',
    icon: PressEntrance,
  },
  {
    bottom: 69,
    right: 93,
    alt: 'mfsk-parking-1',
    icon: ParkingIcon,
  },
  {
    bottom: 61,
    right: 70,
    alt: 'mfsk-parking-2',
    icon: ParkingIcon,
  },
  {
    bottom: 61,
    right: 54,
    alt: 'mfsk-parking-3',
    icon: ParkingIcon,
  },
  {
    bottom: 5,
    right: 90,
    alt: 'mfsk-road-1',
    icon: RoadIcon,
  },
  {
    bottom: 77.5,
    right: 98,
    alt: 'mfsk-road-2',
    icon: RoadIcon,
  },
  {
    bottom: 69,
    right: 84,
    alt: 'mfsk-entrance',
    icon: Entrance,
  },
  {
    bottom: 71,
    right: 87.5,
    alt: 'mfsk-tickets-office',
    icon: TicketOffice,
  },
  {
    bottom: 14,
    right: 80,
    alt: 'mfsk-transport-stop',
    icon: TransportStop,
  },
  {
    bottom: 77,
    right: 80,
    alt: 'mfsk-hall',
    icon: Hall,
  },
  {
    bottom: 66.5,
    right: 33,
    alt: 'mfsk-building',
    icon: Building,
  },
  {
    bottom: 38.5,
    right: 27,
    alt: 'mfsk-football',
    icon: Football,
  },
  {
    bottom: 68.5,
    right: 15,
    alt: 'mfsk-tennis',
    icon: Tennis,
  },
  {
    bottom: 68.5,
    right: 67,
    alt: 'mfsk-pharmacy',
    icon: Pharmacy,
  },
];
