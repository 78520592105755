import { ReactElement } from 'react';
import styles from './InfoBlock.module.scss';
import { SubMenuElementDto } from '../../../../types/Types';
import classNames from 'classnames';
import { compareOrder } from '../../../../helpers/helpers';
import ShowOn from 'components/core/adaptivity/ShowOn';
import { ReactComponent as ArrowUp } from '../../../../assets/icons/arrowUpLeft.svg';
import { ReactComponent as ArrowDown } from '../../../../assets/icons/arrowDownRight.svg';
import { Collapse } from 'antd';

type MenuElementProps = {
  menuElementName: string;
  submenuElements: SubMenuElementDto[];
  menuElementTitle: string;
};

const MEMORY_BLOCK_TITLE = '07092011';

function InfoBlock({
  menuElementName,
  submenuElements,
  menuElementTitle,
}: MenuElementProps): ReactElement {
  const renderHeader = () => (
    <span className={styles.headerText}>{menuElementTitle}</span>
  );

  const renderContent = () => (
    <div className={styles.container}>
      {submenuElements &&
        submenuElements.sort(compareOrder).map((submenu) => (
          <a
            className={classNames(styles.linkText, `${styles.submenu}`)}
            key={submenu.id}
            href={submenu.attributes.url}
            target={submenu.attributes.is_external ? '_blank' : ''}
            rel='noreferrer'
          >
            {submenu.attributes.display_name}
          </a>
        ))}
    </div>
  );

  return (
    <div
      className={
        menuElementName === MEMORY_BLOCK_TITLE
          ? styles.menuElement
          : classNames([styles[menuElementName]])
      }
    >
      <ShowOn largeDesktop smallDesktop largeTablet smallTablet>
        {renderHeader()}
        {renderContent()}
      </ShowOn>
      <ShowOn largeMobile smallMobile>
        <div className={styles.collapsibleSection}>
          <Collapse
            expandIcon={(prop) => (prop.isActive ? <ArrowUp /> : <ArrowDown />)}
            expandIconPosition='end'
            className={styles.collapsible}
          >
            <Collapse.Panel
              header={renderHeader()}
              key={`info-block-${menuElementName}`}
              className={styles.collapsiblePanel}
            >
              <div className={styles.collapsibleContent}>{renderContent()}</div>
            </Collapse.Panel>
          </Collapse>
        </div>
      </ShowOn>
    </div>
  );
}

export default InfoBlock;
