import { ReactElement } from 'react';
import { StandingsReadableDto } from 'types/Types';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { GamesResultProps } from '../../Tournament';
import styles from './RoundSection.module.scss';

type TournamentTreeProps = {
  data: Array<GamesResultProps>;
  generalStandings: Array<StandingsReadableDto>;
  conferenceName?: string;
};

function RoundSection({
  data,
  generalStandings,
  conferenceName,
}: TournamentTreeProps): ReactElement {
  const navigate = useNavigate();
  const { teamCode } = useParams();
  const [searchParams] = useSearchParams();

  const navigateToDetails = (pair: GamesResultProps) => {
    const seasonParam = searchParams.get('season');
    navigate(
      `/playoffs/${teamCode}?teama=${pair?.teama}&teamb=${pair?.teamb}${
        seasonParam ? `&season=${seasonParam}` : ''
      }`
    );
  };

  const getLogo = (teamId: number) =>
    generalStandings.find((team) => team.club_id === teamId?.toString())
      ?.logo ?? '';

  const getName = (teamId: number) =>
    generalStandings.find((team) => team.club_id === teamId?.toString())
      ?.name ?? '';

  return (
    <div className={styles.round}>
      {conferenceName && (
        <div className={styles.conference}>{conferenceName}</div>
      )}
      {data?.map((pair) => (
        <div
          key={`${pair.teama}:${pair.teamb}`}
          className={styles.row}
          onClick={() => navigateToDetails(pair)}
        >
          <div className={styles.information}>
            <p className={styles.name}>{getName(pair?.teama)}</p>
            <img
              className={styles.logo}
              src={getLogo(pair?.teama)}
              alt='team-logo'
            />
          </div>
          <p className={styles.score}>
            {pair?.teamaWins}:{pair?.teambWins}
          </p>
          <div className={styles.information}>
            <img
              className={styles.logo}
              src={getLogo(pair?.teamb)}
              alt='team-logo'
            />
            <p className={styles.name}>{getName(pair?.teamb)}</p>
          </div>
        </div>
      ))}
    </div>
  );
}

export default RoundSection;
