import { ReactElement } from "react";
import styles from "./AccordionHead.module.scss";
import arrow from "../../../../assets/icons/arrow.svg";
import arrowUp from "../../../../assets/icons/arrow_up.svg";

interface AccordionHeadProps {
  title: string;
  subtitle: string;
  isOpen: boolean;
}

export default function AccordionHead({
  title,
  subtitle,
  isOpen,
}: AccordionHeadProps): ReactElement {
  const iconSrc = isOpen ? arrowUp : arrow;

  return (
    <div className={styles.accordionHeadWrapper}>
      <div className={styles.accordionHead}>
        <div className={styles.textColumn}>
          <span className={styles.title}>{title}</span>
          <span className={styles.subtitle}>{subtitle}</span>
        </div>
        <img src={iconSrc} alt="" className={styles.icon} />
      </div>
    </div>
  );
}
