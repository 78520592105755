import { useTranslation } from 'react-i18next';
import { FC } from 'react';
import ErrorWidgetBase from './ErrorWidgetBase/ErrorWidgetBase';

interface ErrorWidgetProps {
  isOnDarkBackground?: boolean;
  isCompact?: boolean;
}

const ErrorWidgetError: FC<ErrorWidgetProps> = (props: ErrorWidgetProps) => {
  const { t } = useTranslation();
  return (
    <ErrorWidgetBase
      {...props}
      strings={{
        title: t('errorWidget.title'),
        subtitle: t('errorWidget.subtitle'),
        subtitleCompact: t('errorWidget.subtitleCompact'),
      }}
    />
  );
};

const ErrorWidgetNotFound: FC<ErrorWidgetProps> = (props: ErrorWidgetProps) => {
  //const { t } = useTranslation();
  // TODO: Localize later
  return (
    <ErrorWidgetBase
      {...props}
      strings={{
        title: 'Не найдено',
        subtitle: 'Ошибка в данных или недействительная ссылка',
        subtitleCompact: 'Ошибка в данных или недействительная ссылка',
      }}
    />
  );
};

const ErrorWidget = {
  Error: ErrorWidgetError,
  NotFound: ErrorWidgetNotFound,
};

export default ErrorWidget;
