import React, { ReactElement } from 'react';
import Spinner from 'components/Spinner/Spinner';
import {
  ConferenceTree,
  DivisionsData,
  StandingsReadableDto,
} from 'types/Types';
import StandingsPageTableBody from '../StandingPageTableBody/StandingPageTableBody';
import ShowOn from 'components/core/adaptivity/ShowOn';
import classnames from 'classnames';

type DivisionTabProps = {
  divisionRef: React.RefObject<HTMLDivElement>;
  activeSubTab: number;
  setActiveSubTab: React.Dispatch<React.SetStateAction<number>>;
  styles: any;
  isLoading: boolean;
  standings: StandingsReadableDto[];
  conferenceList: ConferenceTree[];
};

function DivisionTab({
  divisionRef,
  activeSubTab,
  setActiveSubTab,
  styles,
  isLoading,
  standings,
  conferenceList,
}: DivisionTabProps): ReactElement {
  function filterDivision(id: string): Array<StandingsReadableDto> {
    return standings.filter((x: StandingsReadableDto) => x.division === id);
  }

  return (
    <div ref={divisionRef} className={styles.text} id='division'>
      <div className={styles.subTabs}>
        <span
          onClick={() => setActiveSubTab(0)}
          className={activeSubTab === 0 ? styles.active : styles.subTab}
        >
          <ShowOn largeDesktop smallDesktop largeTablet smallTablet>
            Конференция «Запад»
          </ShowOn>
          <ShowOn largeMobile smallMobile>
            «Запад»
          </ShowOn>
        </span>
        <span
          onClick={() => setActiveSubTab(1)}
          className={activeSubTab === 1 ? styles.active : styles.subTab}
        >
          <ShowOn largeDesktop smallDesktop largeTablet smallTablet>
            Конференция «Восток»
          </ShowOn>
          <ShowOn largeMobile smallMobile>
            «Восток»
          </ShowOn>
        </span>
      </div>
      {!isLoading && standings && standings.length > 0 ? (
        conferenceList &&
        conferenceList.length > 0 && (
          <React.Fragment key={conferenceList[activeSubTab]?.id}>
            {conferenceList[activeSubTab]?.divisions &&
            conferenceList[activeSubTab]?.divisions?.length > 0 ? (
              conferenceList[activeSubTab]?.divisions?.map(
                (division: DivisionsData) => {
                  return (
                    <React.Fragment key={division?.id}>
                      <h3 className={styles.divisionText}>
                        {!division.name.includes('Дивизион') ? 'Дивизион ' : ''}
                        «{division.name}»
                      </h3>
                      <div
                        className={classnames(
                          styles.statsTableWrapper,
                          styles.tableMarginBottom
                        )}
                      >
                        <table className={styles.table}>
                          <div className={styles.statsTableWrapper}>
                            {standings && (
                              <StandingsPageTableBody
                                data={filterDivision(division?.id)}
                                isDivisions={true}
                              />
                            )}
                          </div>
                        </table>
                      </div>
                    </React.Fragment>
                  );
                }
              )
            ) : (
              <div>Турнирные таблицы не найдены</div>
            )}
          </React.Fragment>
        )
      ) : isLoading ? (
        <Spinner />
      ) : (
        <div>Турнирные таблицы не найдены.</div>
      )}
    </div>
  );
}

export default DivisionTab;
