import Column from 'components/core/layout/Column';
import Article from 'model/entity/loko/type/Article';

interface MainNewsMobileImageProps {
  article: Article;
  height?: string;
}

const MainNewsMobileImage = ({ article, height }: MainNewsMobileImageProps) => {
  return (
    <>
      <Column justifyContent='end' height={height}>
        <img
          style={{ width: '100%' }}
          src={article?.attributes.preview_image?.data?.attributes?.url}
          alt=''
        />
      </Column>
    </>
  );
};

export default MainNewsMobileImage;
