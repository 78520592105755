import { ReactElement } from 'react';
import { File } from '../../../types/Types';
import styles from './PlayerPhoto.module.scss';

type PlayerPhotoProps = {
  image: File;
  openAlbum: Function;
  index: number;
};

function PlayerPhoto({
  image,
  openAlbum,
  index,
}: PlayerPhotoProps): ReactElement {
  return (
    <div className={styles.photoWrapper}>
      <div
        key={image.id}
        onClick={() => openAlbum(index)}
        className={styles.photo}
        style={{ backgroundImage: `url('${image.url}'` }}
      />
    </div>
  );
}

export default PlayerPhoto;
