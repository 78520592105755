// Using jsx because react-vk do not have type declarations

import VK, { Group } from "react-vk";
import { VK_GROUP_ID } from "../../../../../constants/constants";

/**
 * Render a VK News Feed component.
 * 
 * @returns {JSX.Element} The rendered VK News Feed component.
 */
export default function VKNewsFeedComponent() {
  // Options: https://vk.com/dev/widget_community
  // React wrapper: https://github.com/mmarkelov/react-vk/tree/master#react-vk
  return (
    <VK>
      <Group
        groupId={VK_GROUP_ID}
        options={{
          mode: 4, // Display mode: 4 - News feed
          width: "auto",
          height: 415, // Height of the component
          no_cover: 1, // Hide cover photo
          color3: "00256D", // Brand color
        }}
      />
    </VK>
  );
}
