import { ReactElement, useRef, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import styles from './EmailBlock.module.scss';
import classNames from 'classnames';
import ShowOn from 'components/core/adaptivity/ShowOn';
import Column from 'components/core/layout/Column';
import Row from 'components/core/layout/Row';
import { deleteEmail, postEmail } from 'api/api';
import { validateEmail } from 'helpers/helpers';
import { RESPONSE_MESSAGE_UNIQUE } from 'constants/constants';
import { notification } from 'antd';
import { useTranslation } from 'react-i18next';

const EmailBlock = (): ReactElement => {
  const { t } = useTranslation();
  const [email, setEmail] = useState<string>();
  const [isOnButtonClick, setIsOnButtonClick] = useState<boolean>(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const captchaRef = useRef<ReCAPTCHA>(null);

  const handleClickConfirm = async () => {
    notification.info({
      message: (
        <>
          <span className={styles.notificationText}>
            {t('footer.email.submit.confirm')}
          </span>
          <div className={styles.unsubscribeBtnsContainer}>
            <button
              onClick={unsubscribe}
              className={classNames(
                styles.unsubscribeBtnYes,
                styles.unsubscribeBtn
              )}
            >
              {t('footer.email.submit.yes')}
            </button>
            <button
              onClick={() => notification.destroy()}
              className={classNames(
                styles.unsubscribeBtnNo,
                styles.unsubscribeBtn
              )}
            >
              {t('footer.email.submit.no')}
            </button>
          </div>
        </>
      ),
      placement: 'bottomLeft',
      duration: 15,
    });
  };

  const unsubscribe = async () => {
    notification.destroy();
    try {
      if (email) {
        await deleteEmail(email);
      }
    } catch (error: any) {
      console.error(error?.response?.data?.error || error?.message);
    }
    if (inputRef && inputRef.current) {
      inputRef.current.value = '';
    }
    setEmail('');
    notification.success({
      message: t('footer.email.submit.unsubscribed'),
      placement: 'bottomLeft',
      duration: 15,
    });
  };

  const onSubmit = async () => {
    if (email && validateEmail(email)) {
      try {
        const resp = await postEmail({
          data: {
            email: email,
          },
        });
        if (resp?.data) {
          notification.success({
            message: t('footer.email.submit.success'),
            placement: 'bottomLeft',
            duration: 15,
          });
        }
        if (inputRef && inputRef.current) {
          inputRef.current.value = '';
        }
        setEmail('');
      } catch (error: any) {
        if (
          error?.response?.data?.error?.message === RESPONSE_MESSAGE_UNIQUE ||
          error?.message === RESPONSE_MESSAGE_UNIQUE
        ) {
          notification.info({
            message: (
              <>
                <span>{t('footer.email.submit.unique')}</span>
                <button
                  onClick={handleClickConfirm}
                  className={classNames(
                    styles.unsubscribeBtnConfirm,
                    styles.unsubscribeBtn
                  )}
                >
                  {t('footer.email.submit.unsubscribe')}
                </button>
              </>
            ),
            placement: 'bottomLeft',
            duration: 15,
          });
        } else {
          notification.error({
            message: t('footer.email.submit.unknownError'),
            placement: 'bottomLeft',
            duration: 15,
          });
        }
      }
    } else {
      notification.error({
        message: t('footer.email.submit.incorrect'),
        placement: 'bottomLeft',
        duration: 15,
      });
    }
    setIsOnButtonClick(false);
    captchaRef.current?.reset();
  };

  const renderDesktopForm = () => (
    <div className={styles.innerWrapper}>
      <div className={styles.formRow}>
        <input
          className={styles.input}
          placeholder={t('footer.email.formEmailPlaceholder')}
          onChange={(e) => setEmail(e.target.value)}
          ref={inputRef}
        ></input>
        <button
          className={classNames(styles.button)}
          onClick={() => setIsOnButtonClick(true)}
        >
          <span className={styles.buttonText}>
            {t('footer.email.formButtonText')}
          </span>
        </button>
      </div>
      <div className={styles.textRow}>
        <div className={styles.politicText}>{t('footer.email.policyText')}</div>
      </div>
    </div>
  );

  const renderTabletForm = () => (
    <>
      <div className={styles.divInput}>
        <input
          className={styles.input}
          placeholder={t('footer.email.formEmailPlaceholder')}
          onChange={(e) => setEmail(e.target.value)}
          ref={inputRef}
        ></input>
      </div>
      <button
        className={classNames(styles.button)}
        onClick={() => setIsOnButtonClick(true)}
      >
        <span className={styles.buttonText}>
          {t('footer.email.formButtonText')}
        </span>
      </button>
      <div className={styles.politicText}>{t('footer.email.policyText')}</div>
    </>
  );

  const renderMobileForm = () => (
    <>
      <input
        className={styles.input}
        placeholder={t('footer.email.formEmailPlaceholder')}
        onChange={(e) => setEmail(e.target.value)}
        ref={inputRef}
      ></input>

      <button
        className={classNames(styles.button)}
        onClick={() => setIsOnButtonClick(true)}
      >
        <span className={styles.buttonText}>
          {t('footer.email.formButtonText')}
        </span>
      </button>
      <div className={styles.politicText}>{t('footer.email.policyText')}</div>
    </>
  );

  const renderDesktopContent = () => (
    <div className={styles.contentWrapper}>
      <span className={styles.text}>{t('footer.email.subscribeText')}</span>
      <div className={styles.content}>
        <ShowOn largeDesktop smallDesktop largeTablet>
          <Row gap='10px' height='60px'>
            {renderDesktopForm()}
          </Row>
        </ShowOn>
      </div>
    </div>
  );

  const renderMobileContent = () => (
    <>
      <span className={styles.text}>{t('footer.email.subscribeText')}</span>
      <div className={styles.content}>
        <ShowOn largeTablet>
          <Row gap='10px' height='60px'>
            {renderTabletForm()}
          </Row>
        </ShowOn>
        <ShowOn smallTablet>
          <div className={styles.middleWidth}>
            <Row gap='10px' flexWrap='wrap'>
              {renderTabletForm()}
            </Row>
          </div>
        </ShowOn>
        <ShowOn largeMobile smallMobile>
          <div className={styles.smallWidth}>
            <Column gap='10px'>{renderMobileForm()}</Column>
          </div>
        </ShowOn>
      </div>
    </>
  );

  return (
    <div className={styles.container}>
      <ShowOn largeDesktop smallDesktop largeTablet>
        <div className={styles.desktopWrapper}>{renderDesktopContent()}</div>
      </ShowOn>
      <ShowOn smallTablet>
        <div className={styles.tabletWrapper}>{renderMobileContent()}</div>
      </ShowOn>
      <ShowOn largeMobile smallMobile>
        <div className={styles.mobileWrapper}>
          <div className={styles.smallWidth}>{renderMobileContent()}</div>
        </div>
      </ShowOn>
      {
        <div className={styles.captcha}>
          {isOnButtonClick && (
            <ReCAPTCHA
              sitekey={`${process.env.REACT_APP_SITE_KEY}`}
              onChange={onSubmit}
              ref={captchaRef}
            />
          )}
        </div>
      }
    </div>
  );
};

export default EmailBlock;
