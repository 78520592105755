import { ReactElement } from 'react';
import { PlayerStatisticsDto, StatisticsDto } from '../../../types/Types';
import styles from './GoalkeeperCarrierTable.module.scss';
import { useTranslation } from 'react-i18next';

type GoalkeeperCarrierTableProps = {
  season: StatisticsDto;
  stats: PlayerStatisticsDto;
  isPlayoff: boolean;
};

function GoalkeeperCarrierTable({
  season,
  stats,
  isPlayoff,
}: GoalkeeperCarrierTableProps): ReactElement {
  const { t } = useTranslation();
  return (
    <tr className={styles.tableTr}>
      <td className={styles.firstTd}>
        <div className={styles.firstTd}>
          <div>
            <span>{season.season}</span>
            <span className={styles.season}>
              {isPlayoff
                ? t('playerPage.statsTable.seasonType.playoff')
                : t('playerPage.statsTable.seasonType.regular')}
            </span>
            <span className={styles.season}>{season?.team}</span>
          </div>
        </div>
      </td>
      <td>{season?.league ?? '-'}</td>
      <td>{stats?.games ?? '-'}</td>
      <td>{stats?.goals ?? '-'}</td>
      <td>{stats?.passes ?? '-'}</td>
      <td>{stats?.points ?? '-'}</td>
      <td>{stats?.penalties ?? '-'}</td>
      <td>{stats?.missed_goals ?? '-'}</td>
      <td>{stats?.save_percentage ?? '-'}</td>
      <td>{stats?.goals_against_average ?? '-'}</td>
      <td>{stats?.shutouts ?? '-'}</td>
    </tr>
  );
}

export default GoalkeeperCarrierTable;
