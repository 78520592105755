import { ReactElement } from 'react';
import { PlayerWithRelations } from '../../../types/Types';
import styles from './PlayerStatistic.module.scss';
import PlayerSeasonStatistics from '../PlayerSeasonStatistics/PlayerSeasonStatistics';
import { useTranslation } from 'react-i18next';

type PlayerStatisticProps = {
  player: PlayerWithRelations;
};

const PlayerStatistic = ({ player }: PlayerStatisticProps): ReactElement => {
  const { t } = useTranslation();
  const isGoalkeeper = player.attributes.position.data.id === 1;

  return (
    <table className={styles.table}>
      <thead>
        {isGoalkeeper ? (
          <tr className={styles.tableHeader}>
            <th
              title={t('playerPage.statsTable.title.season')}
              className={styles.firstTh}
            >
              <div className={styles.season}>
                {t('playerPage.statsTable.content.season')}
              </div>
            </th>
            <th title={t('playerPage.statsTable.title.games')}>
              {t('playerPage.statsTable.content.games')}
            </th>
            <th title={t('playerPage.statsTable.title.wins')}>
              {t('playerPage.statsTable.content.wins')}
            </th>
            <th title={t('playerPage.statsTable.title.lose')}>
              {t('playerPage.statsTable.content.lose')}
            </th>
            <th title={t('playerPage.statsTable.title.shootoutSeries')}>
              {t('playerPage.statsTable.content.shootoutSeries')}
            </th>
            <th title={t('playerPage.statsTable.title.shotsOnGoal')}>
              {t('playerPage.statsTable.content.shotsOnGoal')}
            </th>
            <th title={t('playerPage.statsTable.title.missedGoals')}>
              {t('playerPage.statsTable.content.missedGoals')}
            </th>
            <th title={t('playerPage.statsTable.title.save')}>
              {t('playerPage.statsTable.content.save')}
            </th>
            <th title={t('playerPage.statsTable.title.savePercentage')}>
              {t('playerPage.statsTable.content.savePercentage')}
            </th>
            <th title={t('playerPage.statsTable.title.goalsAgainstAverage')}>
              {t('playerPage.statsTable.content.goalsAgainstAverage')}
            </th>
            <th title={t('playerPage.statsTable.title.goals')}>
              {t('playerPage.statsTable.content.goals')}
            </th>
            <th title={t('playerPage.statsTable.title.assists')}>
              {t('playerPage.statsTable.content.assists')}
            </th>
            <th title={t('playerPage.statsTable.title.shutouts')}>
              {t('playerPage.statsTable.content.shutouts')}
            </th>
            <th title={t('playerPage.statsTable.title.penalties')}>
              {t('playerPage.statsTable.content.penalties')}
            </th>
            <th title={t('playerPage.statsTable.title.timeOnIce')}>
              {t('playerPage.statsTable.content.timeOnIce')}
            </th>
          </tr>
        ) : (
          <tr className={styles.tableHeader}>
            <th
              title={t('playerPage.statsTable.title.season')}
              className={styles.firstTh}
            >
              <div className={styles.season}>
                {t('playerPage.statsTable.content.season')}
              </div>
            </th>
            <th title={t('playerPage.statsTable.title.games')}>
              {t('playerPage.statsTable.content.games')}
            </th>
            <th title={t('playerPage.statsTable.title.goals')}>
              {t('playerPage.statsTable.content.goals')}
            </th>
            <th title={t('playerPage.statsTable.title.passes')}>
              {t('playerPage.statsTable.content.passes')}
            </th>
            <th title={t('playerPage.statsTable.title.points')}>
              {t('playerPage.statsTable.content.points')}
            </th>
            <th title={t('playerPage.statsTable.title.plusMinus')}>
              {t('playerPage.statsTable.content.plusMinus')}
            </th>
            <th title={t('playerPage.statsTable.title.penalties')}>
              {t('playerPage.statsTable.content.penalties')}
            </th>
            <th title={t('playerPage.statsTable.title.powerPlayGoals')}>
              {t('playerPage.statsTable.content.powerPlayGoals')}
            </th>
            <th title={t('playerPage.statsTable.title.shorthandedGoals')}>
              {t('playerPage.statsTable.content.shorthandedGoals')}
            </th>
            <th title={t('playerPage.statsTable.title.overtimeGoals')}>
              {t('playerPage.statsTable.content.overtimeGoals')}
            </th>
            <th title={t('playerPage.statsTable.title.shotsOnGoal2')}>
              {t('playerPage.statsTable.content.shotsOnGoal2')}
            </th>
            <th title={t('playerPage.statsTable.title.shotsOnGoalPercent')}>
              {t('playerPage.statsTable.content.shotsOnGoalPercent')}
            </th>
            <th title={t('playerPage.statsTable.title.faceOffPercent')}>
              {t('playerPage.statsTable.content.faceOffPercent')}
            </th>
            <th title={t('playerPage.statsTable.title.timeOnIceAverage')}>
              {t('playerPage.statsTable.content.timeOnIceAverage')}
            </th>
          </tr>
        )}
      </thead>
      <tbody>
        {player.attributes.rosters.data.map((season, index) =>
          season?.attributes?.season?.data?.attributes?.name ? (
            <PlayerSeasonStatistics
              seasonParam={season?.attributes?.season?.data?.attributes?.name}
              player={player}
              key={`PlayerSeasonStatistics-${index}`}
            />
          ) : (
            <></>
          )
        )}
      </tbody>
    </table>
  );
};

export default PlayerStatistic;
