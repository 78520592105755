import { ReactElement } from 'react';
import errorMisha from 'assets/error_misha.png';
import styles from './ErrorPage.module.scss';
import { useTranslation } from 'react-i18next';

const ErrorPage = (): ReactElement => {
  const { t } = useTranslation();
  return (
    <div className={styles.wrapper}>
      <div className={styles.numberWrapper}>
        <h1 className={styles.number}>{t('errorPage.four')}</h1>
        <img src={errorMisha} alt='not found' className={styles.img} />
        <h1 className={styles.number}>{t('errorPage.four')}</h1>
      </div>
      <div className={styles.textWrapper}>
        <h2 className={styles.headerText}>{t('errorPage.header')}</h2>
        <span className={styles.text}>{t('errorPage.text')}</span>
        <a href='/' className={styles.link}>
          <button className={styles.button}>{t('errorPage.link')}</button>
        </a>
      </div>
    </div>
  );
};

export default ErrorPage;
