import { ReactElement, useEffect, useState } from 'react';
import styles from './NewsWidget.module.scss';
import TopStory from '../../TopStory/TopStory';
import { useLocation } from 'react-router-dom';
import ErrorWidget from '../ErrorWidget/ErrorWidget';
import Article from 'model/entity/loko/type/Article';
import lokoApi from 'model/api/lokoApi';
import { useMediaQuery } from 'react-responsive';
import { Breakpoints } from 'constants/adaptivity';

const NewsWidget = (): ReactElement => {
  const [articles, setArticles] = useState<Array<Article>>([]);
  const route = useLocation().pathname;
  const isSmallDesktop = useMediaQuery({
    minWidth: Breakpoints.smallTablet + 1,
    maxWidth: Breakpoints.smallDesktop,
  });

  useEffect(() => {
    const getData = async () => {
      const resp = await lokoApi.article.getTopStories(4);

      setArticles(
        resp?.data?.reduce((result: Article[], article: Article) => {
          if (!route.includes(`/article/${article.id}`)) {
            result.push(article);
          }

          return result;
        }, [])
      );
    };
    getData();
  }, [route]);

  const renderTitle = () => {
    return route.includes('/article') ? 'Другие новости' : 'Новости';
  };

  return articles ? (
    <div className={styles.wrapper}>
      <div className={styles.title}>{renderTitle()}</div>
      <TopStory
        articles={articles?.slice(0, 3)}
        startIndex={0}
        isColumnStyle={isSmallDesktop ? false : true}
        useImage
      />
    </div>
  ) : (
    <ErrorWidget.Error />
  );
};

export default NewsWidget;
