import { useEffect, useState } from 'react';
import { getSponsors } from '../../../api/api';
import BreadcrumbsWrapper from 'components/common/BreadcrumbsWrapper/BreadcrumbsWrapper';
import PageHeader from 'components/common/PageHeader/PageHeader';
import ImageBgPageWrapper from '../../common/ImageBgPageWrapper/ImageBgPageWrapper';
import Container from '../../common/Container/Container';
import styles from './SponsorsPage.module.scss';
import SideMenu from '../../common/SideMenu/SideMenu';
import Sponsor from './Sponsor/Sponsor';
import { SponsorType } from '../../../types/Types';
import ShowOn from 'components/core/adaptivity/ShowOn';
import Row from 'components/core/layout/Row';
import Column from 'components/core/layout/Column';
import ErrorWidget from 'components/common/ErrorWidget/ErrorWidget';
import { useTranslation } from 'react-i18next';

const SponsorsPage = () => {
  const { t } = useTranslation();
  const [sponsors, setSponsors] = useState<Array<SponsorType>>([]);
  const [isError, setIsError] = useState<boolean>(false);

  useEffect(() => {
    const getData = async () => {
      try {
        const resp = await getSponsors();
        setSponsors(resp?.data);
      } catch (e: any) {
        setIsError(true);
      }
    };

    getData();
  }, []);

  const renderContent = () => (
    <>
      <div className={styles.content}>
        {sponsors.map((sponsor) => (
          <Sponsor key={sponsor.id} sponsor={sponsor} />
        ))}
      </div>
      <div className={styles.sideMenu}>
        <SideMenu />
      </div>
    </>
  );

  return (
    <ImageBgPageWrapper>
      <Container paddingTop='50px'>
        <div className={styles.wrapper}>
          {
            <>
              <BreadcrumbsWrapper
                breadcrumbs={[
                  {
                    name: t('sponsorsPage.breadcrumb'),
                    url: `/sponsors`,
                  },
                ]}
              />
              <div className={styles.pageHeaderWrapper}>
                <PageHeader text={t('sponsorsPage.header')} />
              </div>
              <div className={styles.contentWrapper}>
                <ShowOn largeDesktop smallDesktop>
                  <Row gap='20px'>
                    {isError ? <ErrorWidget.Error /> : renderContent()}
                  </Row>
                </ShowOn>
                <ShowOn largeTablet smallTablet largeMobile smallMobile>
                  <Column gap='20px'>
                    {isError ? <ErrorWidget.Error /> : renderContent()}
                  </Column>
                </ShowOn>
              </div>
            </>
          }
        </div>
      </Container>
    </ImageBgPageWrapper>
  );
};

export default SponsorsPage;
