import ShowOn from 'components/core/adaptivity/ShowOn';
import DesktopAdvertisingWidget from './DesktopAdvertisingWidget/DesktopAdvertisingWidget';
import MobileAdvertisingWidget from './MobileAdvertisingWidget/MobileAdvertisingWidget';
import { RefObject, useEffect, useState } from 'react';
import { AdvertisingWidgetDto } from 'types/Types';
import { getAdvertisingWidgets } from 'api/api';

type AdvertisingWidgetType = {
  footerRef: RefObject<HTMLDivElement>;
};

function AdvertisingWidget({ footerRef }: AdvertisingWidgetType) {
  const [advertising, setAdvertising] = useState<AdvertisingWidgetDto>();
  const [isFooterVisible, setIsFooterVisible] = useState(true);

  useEffect(() => {
    const getData = async () => {
      const resp = await getAdvertisingWidgets();
      setAdvertising(resp.data[0]);
    };
    getData();
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const footer = footerRef.current;
      if (footer) {
        const footerPosition = footer.getBoundingClientRect();
        setIsFooterVisible(footerPosition.top >= window.innerHeight);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return advertising ? (
    <div>
      <ShowOn largeDesktop>
        <DesktopAdvertisingWidget advertising={advertising} />
      </ShowOn>
      <ShowOn smallDesktop largeTablet smallTablet largeMobile smallMobile>
        {isFooterVisible && (
          <MobileAdvertisingWidget advertising={advertising} />
        )}
      </ShowOn>
    </div>
  ) : (
    <></>
  );
}

export default AdvertisingWidget;
