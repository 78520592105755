import { ReactElement, useEffect, useState } from 'react';
import styles from './ShootoutsTable.module.scss';
import { GameProtocolDto, GameWithLogo } from '../../../../../types/Types';
import classNames from 'classnames';

type ShootoutData = {
  playerANumber: string;
  playerAName: string;
  playerBNumber: string;
  playerBName: string;
  score: string;
};

type ShootoutsData = {
  teamA: {
    teamName: string;
    teamLogo: string;
    teamCity: string;
  };
  teamB: {
    teamName: string;
    teamLogo: string;
    teamCity: string;
  };
  shootouts: ShootoutData[];
};

type ShootoutsTableProps = {
  protocol: GameProtocolDto;
  game: GameWithLogo;
};

function ShootoutsTable({ protocol, game }: ShootoutsTableProps): ReactElement {
  const [shootoutData, setShootsData] = useState<ShootoutsData | null>();

  useEffect(() => {
    if (protocol.GameSummary.Shootout && protocol.GameSummary.Shootout.Shot) {
      const shootout = protocol.GameSummary.Shootout.Shot.map((item) => {
        const playerA =
          protocol.GameSummary?.PlayerStatsList[0].PlayerStats.find(
            (p) => p['@_jn']?.toString() === item['@_pnoA']
          );
        const playerB =
          protocol.GameSummary?.PlayerStatsList[1].PlayerStats.find(
            (p) => p['@_jn']?.toString() === item['@_pnoB']
          );

        return {
          playerAName: playerA
            ? `${playerA['@_firstname']?.split('')[0]}. ${
                playerA['@_lastname']
              }`
            : '',
          playerANumber: item['@_pnoA'],
          playerBNumber: item['@_pnoB'],
          playerBName: playerB
            ? `${playerB['@_firstname']?.split('')[0]}. ${
                playerB['@_lastname']
              }`
            : '',
          score: item['@_sc'],
        } as ShootoutData;
      });

      setShootsData({
        shootouts: shootout,
        teamA: {
          teamName: game.attributes.team_1.data.attributes.name,
          teamLogo:
            game.attributes.team_1.data.attributes.logo.data.attributes.url,
          teamCity: game.attributes.team_1.data.attributes.city,
        },
        teamB: {
          teamName: game.attributes.team_2.data.attributes.name,
          teamLogo:
            game.attributes.team_2.data.attributes.logo.data.attributes.url,
          teamCity: game.attributes.team_2.data.attributes.city,
        },
      });
    } else {
      setShootsData(null);
      return;
    }
  }, [game, protocol]);

  const renderRows = () => {
    if (!shootoutData) {
      return;
    }

    return shootoutData.shootouts.map((item, index) => (
      <div key={index} className={styles.tableRow}>
        <div className={styles.player}>
          <div className={styles.nameAndNumber}>
            <div className={styles.number}>{item.playerANumber}</div>
            <div>{item.playerAName}</div>
          </div>
          <div
            className={
              index === shootoutData.shootouts.length - 1
                ? styles.lastScore
                : styles.score
            }
          >
            {item.score.split(':')[0]}
          </div>
        </div>
        <div className={styles.player}>
          <div
            className={
              index === shootoutData.shootouts.length - 1
                ? styles.lastScore
                : styles.score
            }
          >
            {item.score.split(':')[1]}
          </div>
          <div className={styles.nameAndNumber}>
            <div>{item.playerBName}</div>
            <div className={styles.number}>{item.playerBNumber}</div>
          </div>
        </div>
      </div>
    ));
  };

  return shootoutData ? (
    <>
      <div
        className={styles.header}
      >{`Буллиты (${protocol.GameSummary['@_score']})`}</div>
      <div className={styles.tableHeader}>
        <div className={styles.team}>
          <img
            className={styles.logo}
            src={shootoutData.teamA.teamLogo}
            alt=''
          />
          <div className={styles.nameAndCity}>
            <div className={styles.name}>{shootoutData.teamA.teamName}</div>
            <div className={styles.city}>{shootoutData.teamA.teamCity}</div>
          </div>
        </div>
        <div className={styles.team}>
          <div className={classNames(styles.nameAndCity, styles.rtl)}>
            <div className={styles.name}>{shootoutData.teamB.teamName}</div>
            <div className={styles.city}>{shootoutData.teamB.teamCity}</div>
          </div>
          <img
            className={styles.logo}
            src={shootoutData.teamB.teamLogo}
            alt=''
          />
        </div>
      </div>
      {renderRows()}
    </>
  ) : (
    <></>
  );
}

export default ShootoutsTable;
