import { ReactElement } from 'react';
import { getDateInfo } from '../../../helpers/helpers';
import styles from './ArticleItem.module.scss';
import ArticleLikesBlock from '../../ArticleLikesBlock/ArticleLikesBlock';
import { Link } from 'react-router-dom';
import ImageLoader from '../../common/ImageLoaders/ImageLoader/ImageLoader';
import Article from 'model/entity/loko/type/Article';

type ArticleItemProps = {
  article: Article;
};

function ArticleItem({ article }: ArticleItemProps): ReactElement {
  return (
    <Link to={`/article/${article.id}`} className={styles.articleItem}>
      <div className={styles.imageContainer}>
        <ImageLoader
          image={
            article.attributes.image?.data ||
            article.attributes.preview_image.data
          }
          alt='article-item-image'
          className={styles.img}
        />
      </div>
      <div className={styles.content}>
        <div className={styles.text}>
          <p className={styles.category}>
            {article.attributes.article_category.data.attributes.name}
          </p>
          <h3 className={styles.title}>{article.attributes.title}</h3>
          <div
            className={styles.annotation}
            dangerouslySetInnerHTML={{ __html: article.attributes.annotation }}
          />
        </div>
        <div className={styles.footer}>
          <div className={styles.date}>{getDateInfo(article)}</div>
          <ArticleLikesBlock article={article} />
        </div>
      </div>
    </Link>
  );
}

export default ArticleItem;
