import { ReactElement, useEffect, useState } from 'react';
import { GamesResultProps } from '../Tournament';
import { StandingsReadableDto } from 'types/Types';
import RoundSection from './RoundSection/RoundSection';
import styles from './TournamentTable.module.scss';
import MobileTableSectionAccordionArrow from './MobileTableSectionAccordionArrow';

type TournamentTreeProps = {
  round1West: Array<GamesResultProps>;
  round2West: Array<GamesResultProps>;
  round3West: Array<GamesResultProps>;
  round1East: Array<GamesResultProps>;
  round2East: Array<GamesResultProps>;
  round3East: Array<GamesResultProps>;
  finalResults: Array<GamesResultProps>;
  generalStandings: Array<StandingsReadableDto>;
  crossRound?: number;
};

function TournamentTable({
  round1West,
  round2West,
  round3West,
  round1East,
  round2East,
  round3East,
  finalResults,
  generalStandings,
  crossRound,
}: TournamentTreeProps): ReactElement {
  const [openTabNumber, setOpenTabNumber] = useState<number | undefined>();

  useEffect(() => {
    if (finalResults.length) {
      setOpenTabNumber(0);
    } else if (round3East.length || round3West.length) {
      setOpenTabNumber(3);
    } else if (round2East.length || round2West.length) {
      setOpenTabNumber(2);
    } else if (round1East.length || round1West.length) {
      setOpenTabNumber(1);
    }
  }, [
    round1West,
    round2West,
    round3West,
    round1East,
    round2East,
    round3East,
    finalResults,
  ]);

  const onTabClick = (tabNumber: number) => {
    if (tabNumber === openTabNumber) {
      setOpenTabNumber(undefined);
    } else {
      setOpenTabNumber(tabNumber);
    }
  };

  return (
    <div className={styles.tournament}>
      {finalResults.length ? (
        <>
          <div className={styles.section} onClick={() => onTabClick(0)}>
            <div className={styles.title}>Финал чемпионата</div>
            <MobileTableSectionAccordionArrow isOpen={openTabNumber === 0} />
          </div>
          {openTabNumber === 0 ? (
            <RoundSection
              data={finalResults}
              generalStandings={generalStandings}
            />
          ) : null}
        </>
      ) : null}
      {round3East.length || round3West.length ? (
        <>
          <div className={styles.section} onClick={() => onTabClick(3)}>
            <div className={styles.title}>1/2 финала</div>
            <MobileTableSectionAccordionArrow isOpen={openTabNumber === 3} />
          </div>
          {openTabNumber === 3 ? (
            <>
              <RoundSection
                data={round3West}
                generalStandings={generalStandings}
                conferenceName={crossRound === 3 ? 'Группа А' : 'Запад'}
              />
              <RoundSection
                data={round3East}
                generalStandings={generalStandings}
                conferenceName={crossRound === 3 ? 'Группа Б' : 'Восток'}
              />
            </>
          ) : null}
        </>
      ) : null}
      {round2East.length || round2West.length ? (
        <>
          <div className={styles.section} onClick={() => onTabClick(2)}>
            <div className={styles.title}>1/4 финала</div>
            <MobileTableSectionAccordionArrow isOpen={openTabNumber === 2} />
          </div>
          {openTabNumber === 2 ? (
            <>
              <RoundSection
                data={round2West}
                generalStandings={generalStandings}
                conferenceName={crossRound === 2 ? 'Группа А' : 'Запад'}
              />
              <RoundSection
                data={round2East}
                generalStandings={generalStandings}
                conferenceName={crossRound === 2 ? 'Группа Б' : 'Восток'}
              />
            </>
          ) : null}
        </>
      ) : null}
      {round1East.length || round1West.length ? (
        <>
          <div className={styles.section} onClick={() => onTabClick(1)}>
            <div className={styles.title}>1/8 финала</div>
            <div className={styles.arrow}>
              <MobileTableSectionAccordionArrow isOpen={openTabNumber === 1} />
            </div>
          </div>
          {openTabNumber === 1 ? (
            <>
              <RoundSection
                data={round1West}
                generalStandings={generalStandings}
                conferenceName={'Запад'}
              />
              <RoundSection
                data={round1East}
                generalStandings={generalStandings}
                conferenceName={'Восток'}
              />
            </>
          ) : null}
        </>
      ) : null}
    </div>
  );
}

export default TournamentTable;
