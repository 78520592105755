import ShowOn from 'components/core/adaptivity/ShowOn';
import Row from 'components/core/layout/Row';
import lokoLogo from 'assets/new_logo.svg';
import lokoLogoText from 'assets/logo_text.svg';
import styles from './MobileHeaderLogo.module.scss';
import { Link } from 'react-router-dom';

const MobileHeaderLogo = () => (
  <a href='/'>
    <ShowOn largeTablet smallTablet>
      <Row height='100%' alignItems='center' justifyContent='center' gap='30px'>
        <img src={lokoLogo} alt='' width={45} height={45} />
        <img src={lokoLogoText} alt='локомотив' />
      </Row>
    </ShowOn>
    <ShowOn largeMobile>
      <Row height='100%' alignItems='center' justifyContent='center' gap='30px'>
        <img src={lokoLogo} alt='' width={45} height={45} />
      </Row>
    </ShowOn>
    <ShowOn smallMobile>
      <Row height='100%' alignItems='center' justifyContent='center' gap='30px'>
        <img src={lokoLogo} alt='' className={styles.logo} />
        <img src={lokoLogoText} alt='локомотив' className={styles.logoText} />
      </Row>
    </ShowOn>
  </a>
);

export default MobileHeaderLogo;
