import classNames from 'classnames';
import { ReactElement, useState } from 'react';
import { ExecutiveDto } from '../../types/Types';
import ExecutiveStatisticTable from '../ExecutiveStatisticTable/ExecutiveStatisticTable';
import styles from './ExecutiveTabsWithInfo.module.scss';
import ShowMoreText from '../ShowMoreText/ShowMoreText';
import { useTranslation } from 'react-i18next';

type ExecutiveTabsWithInfoProps = {
  executive: ExecutiveDto;
};

const ExecutiveTabsWithInfo = ({
  executive,
}: ExecutiveTabsWithInfoProps): ReactElement => {
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState<number>(0);
  const { attributes } = executive;

  return (
    <>
      <div className={styles.tabBar}>
        <p
          className={classNames(styles.tab, {
            [styles.active]: activeTab === 0,
          })}
          onClick={() => setActiveTab(0)}
        >
          {t('executivePage.tabs.biography')}
        </p>
        {attributes?.statistics?.length ? (
          <p
            className={classNames(styles.tab, {
              [styles.active]: activeTab === 1,
            })}
            onClick={() => setActiveTab(1)}
          >
            {t('executivePage.tabs.stats')}
          </p>
        ) : null}
      </div>
      <div className={styles.article}>
        {activeTab === 0 && (
          <>
            {attributes?.biography && (
              <>
                {attributes?.biography?.length ? (
                  <>
                    <ShowMoreText
                      content={attributes.biography}
                      marginTop={30}
                      marginBottom={30}
                    />
                  </>
                ) : null}
              </>
            )}
            {attributes?.player_career?.length ? (
              <>
                <p className={styles.title}>
                  {t('executivePage.tabs.playerCareer')}
                </p>
                <ShowMoreText
                  content={attributes.player_career}
                  marginTop={30}
                  marginBottom={30}
                />
              </>
            ) : null}
            {attributes?.executive_career?.length ? (
              <>
                <p className={styles.title}>
                  {t('executivePage.tabs.executiveCareer')}
                </p>
                <ShowMoreText
                  content={attributes.executive_career}
                  marginTop={30}
                  marginBottom={30}
                />
              </>
            ) : null}
            {!attributes.biography?.length &&
            !attributes.player_career?.length &&
            !attributes.executive_career?.length ? (
              <p> {t('executivePage.tabs.nothingHereYet')}</p>
            ) : null}
          </>
        )}
        {activeTab === 1 && <ExecutiveStatisticTable executive={executive} />}
      </div>
    </>
  );
};

export default ExecutiveTabsWithInfo;
