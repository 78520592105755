import { ReactElement, useEffect, useState } from 'react';
import { SubscriptionTablesData } from './SubscriptionTablesData';
import Accordion from '../../../../common/Accordion/Accordion';
import DescriptionBlock from '../../../../common/DescriptionBlock/DescriptionBlock';
import LokoTable from '../../../../common/LokoTable/LokoTable';
import LokoTableHeadCell from '../../../../common/LokoTable/LokoTableHeadCell/LokoTableHeadCell';
import LokoTableRow from '../../../../common/LokoTable/LokoTableRow/LokoTableRow';
import styles from './SubscriptionTables.module.scss';
import Spinner from '../../../../Spinner/Spinner';
import lokoApi from 'model/api/lokoApi';
import ErrorWidget from 'components/common/ErrorWidget/ErrorWidget';
import { useTranslation } from 'react-i18next';

const SubscriptionTables = (): ReactElement => {
  const { t } = useTranslation();
  const [accordionId, setAccordionId] = useState<number | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);

  const [subscriptionTablesData, setSubscriptionTablesData] =
    useState<SubscriptionTablesData | null>(null);

  useEffect(() => {
    const fetch = async () => {
      try {
        const [subscriptionData, subscriptionTypesData] = await Promise.all([
          lokoApi.subscription.findAll({ populate: '*' }),
          lokoApi.subscriptionType.findAll(),
        ]);

        const newData = new SubscriptionTablesData(
          subscriptionData,
          subscriptionTypesData
        );
        setSubscriptionTablesData(newData);
      } catch (e: any) {
        console.error(e?.message);
        setIsError(true);
      } finally {
        setIsLoading(false);
      }
    };

    setIsLoading(true);
    fetch();
  }, []);

  const render = (): ReactElement => {
    return isLoading ? (
      <Spinner />
    ) : isError ? (
      <ErrorWidget.Error />
    ) : (
      <div className={styles.dataWrapper}>
        {subscriptionTablesData?.subscriptionTypesData.data.map(
          (subscriptionType) => {
            if (
              subscriptionTablesData.getSubscriptionsForType(subscriptionType)
                .length === 0
            )
              return null;
            return (
              <Accordion
                title={subscriptionType.attributes.name}
                subtitle={t('subscriptionPage.table.minPrice', {
                  price:
                    subscriptionTablesData?.getMinPriceForSubscriptionType(
                      subscriptionType
                    ),
                })}
                key={subscriptionType.id}
                isOpen={accordionId === subscriptionType.id}
                openCallback={() =>
                  setAccordionId(
                    accordionId === subscriptionType.id
                      ? null
                      : subscriptionType.id
                  )
                }
              >
                <div className={styles.descriptionBlockWrapper}>
                  <DescriptionBlock>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: subscriptionType.attributes.description ?? '',
                      }}
                      className={styles.descriptionText}
                    ></div>
                  </DescriptionBlock>
                </div>
                <LokoTable
                  head={
                    <>
                      <tr>
                        <LokoTableHeadCell
                          cellHeight={80}
                          colSpan={1}
                          rowSpan={1}
                          isDark={false}
                        >
                          <span className={styles.lokoTableHeadCell}>
                            {t('subscriptionPage.table.category')}
                          </span>
                        </LokoTableHeadCell>
                        <LokoTableHeadCell
                          cellHeight={80}
                          colSpan={1}
                          rowSpan={1}
                          isDark={false}
                        >
                          <span className={styles.lokoTableHeadCell}>
                            {t('subscriptionPage.table.distribution')}
                          </span>
                        </LokoTableHeadCell>

                        <LokoTableHeadCell
                          cellHeight={80}
                          colSpan={1}
                          rowSpan={1}
                          isDark={false}
                        >
                          <span className={styles.lokoTableHeadCell}>
                            {t('subscriptionPage.table.price')}
                          </span>
                        </LokoTableHeadCell>
                      </tr>
                    </>
                  }
                  body={
                    <>
                      {subscriptionTablesData
                        .getSubscriptionsForType(subscriptionType)
                        .map((subscription) => (
                          <LokoTableRow
                            data={[
                              subscription.attributes.arena_category.data
                                .attributes.name,
                              subscription.attributes.name,
                              subscription.attributes.price,
                            ]}
                            key={subscription.id}
                          />
                        ))}
                    </>
                  }
                />
              </Accordion>
            );
          }
        )}
      </div>
    );
  };

  return render();
};

export default SubscriptionTables;
