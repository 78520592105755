import { ReactElement, useEffect, useRef, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import {
  getDefaultImages,
  getPhotos,
  getPlayerById,
  getPlayerSeasonStats,
} from '../../api/api';
import { getLastSeasonOfPlayer } from '../../helpers/helpers';
import {
  AlbumSimple,
  DefaultImageObject,
  File,
  PlayerSeasonStats,
  PlayerWithRelations,
  Roster,
} from '../../types/Types';
import PlayerCardInfo from './PlayerCardInfo/PlayerCardInfo';
import PlayerTabsWithInfo from './PlayerTabsWithInfo/PlayerTabsWithInfo';
import {
  ALBUM_OPEN_FAILED,
  DEFAULT_IMAGE_NAME,
  POSITION_MAP,
} from '../../constants/constants';
import PlayerStartInfo from './PlayerStartInfo/PlayerStartInfo';
import InfoWrapper from '../Wrappers/InfoWrapper/InfoWrapper';
import styles from './PlayerPage.module.scss';
import ImageBgPageWrapper from '../common/ImageBgPageWrapper/ImageBgPageWrapper';
import ErrorWidget from 'components/common/ErrorWidget/ErrorWidget';
import ReactImageGallery from 'react-image-gallery';
import ImageGallery from 'react-image-gallery';
import { notification } from 'antd';
import 'react-image-gallery/styles/scss/image-gallery.scss';
import '../Album/CustomImageGallery.scss';
import { mapPlayerSeasonStatsDto } from 'mapping/mapping';
import SetRespError from 'helpers/helpers';

const PlayerPage = (): ReactElement => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { playerId } = useParams();
  const [player, setPlayer] = useState<PlayerWithRelations>();
  const [playerRoster, setPlayerRoster] = useState<Roster>();
  const [album, setAlbum] = useState<AlbumSimple>();
  const [isOpenAlbum, setIsOpenAlbum] = useState(false);
  const [defaultImages, setDefaultImages] = useState<DefaultImageObject[]>([]);
  const [startIndexAlbum, setStartIndexAlbum] = useState<number>(0);
  const [isNotFoundError, setIsNotFoundError] = useState<boolean>(false);
  const [isPlayerError, setIsPlayerError] = useState<boolean>(false);
  const seasonParam = searchParams.get('season');
  const galleryRef = useRef<ReactImageGallery | null>(null);
  const [playerStats, setPlayerStats] = useState<PlayerSeasonStats>();

  useEffect(() => {
    const getPlayerInfo = async () => {
      try {
        const resp = await getPlayerById(Number(playerId));
        if (!checkPlayerPlayedInSeason(resp.data)) {
          searchParams.set('season', getLastSeasonOfPlayer(resp.data));
          setSearchParams(searchParams);
        }

        setPlayer(resp?.data);
        getCurrentSeasonStatistic(resp.data);
      } catch (e: any) {
        SetRespError(e, setIsNotFoundError, setIsPlayerError);
      }

      try {
        const images = await getDefaultImages();
        setDefaultImages(images.data);
      } catch (error: any) {
        console.error(error?.response?.data?.error || error?.message);
      }
    };
    getPlayerInfo();
  }, []);

  useEffect(() => {
    setPlayerRoster(
      player?.attributes.rosters.data.find(
        (roster) =>
          roster?.attributes?.season?.data?.attributes?.name === seasonParam
      )
    );
  }, [player]);

  const getCurrentSeasonStatistic = async (player: PlayerWithRelations) => {
    if (player?.attributes?.id_league_web) {
      const respStats = await getPlayerSeasonStats(
        player.attributes.id_league_web,
        seasonParam || ''
      );
      setPlayerStats(mapPlayerSeasonStatsDto(respStats));
    }
  };

  useEffect(() => {
    if (isOpenAlbum && album?.photo && galleryRef?.current) {
      galleryRef.current?.fullScreen();
    }
  }, [album?.photo, galleryRef, isOpenAlbum]);

  async function openAlbum(startIndex?: number) {
    try {
      const photos = await getPhotos(
        `${player?.attributes?.name} ${player?.attributes?.surname}`,
        -1
      );
      const albumTmp = {
        description: `${player?.attributes?.name} ${player?.attributes?.surname}`,
        photo: photos.map((photo: File) => photo.url),
      };
      setStartIndexAlbum(startIndex || 0);
      setAlbum(albumTmp);
      setIsOpenAlbum(true);
    } catch (error: any) {
      console.error(error?.response?.data?.error || error?.message);
      notification.error({
        message: ALBUM_OPEN_FAILED,
        placement: 'bottomLeft',
        duration: 15,
      });
    }
  }

  function checkPlayerPlayedInSeason(player: PlayerWithRelations): boolean {
    if (!seasonParam) {
      return false;
    }

    const seasons = player.attributes.rosters.data.map(
      (roster) => roster?.attributes?.season?.data?.attributes?.name
    );
    return seasons.includes(seasonParam);
  }

  return (
    <ImageBgPageWrapper
      imageInfo={{
        img_url:
          player?.attributes?.main_bg_photo?.data?.attributes?.url ||
          player?.attributes?.bg_photo?.data?.attributes?.url ||
          defaultImages?.find(
            (x) => x.attributes.description === DEFAULT_IMAGE_NAME
          )?.attributes?.image?.data?.attributes?.url ||
          '',
        image:
          player?.attributes?.main_bg_photo?.data ||
          defaultImages?.find(
            (x) => x.attributes.description === DEFAULT_IMAGE_NAME
          )?.attributes?.image?.data,
        gradient_height: 95, // настроить высоту градиента
        image_height: 1000, // Настроить высоту картинки
      }}
    >
      <div className={styles.playerStartInfo}>
        <PlayerStartInfo
          position={POSITION_MAP.get(player?.attributes?.position?.data?.id)}
          name={player?.attributes?.name || ''}
          surname={player?.attributes?.surname || ''}
          number={
            playerRoster?.attributes?.number?.toString() ||
            playerStats?.number?.toString() ||
            ''
          }
          info={player?.attributes.annotation || ''}
          id={player?.id || 0}
        />
        {player && playerRoster && defaultImages && (
          <PlayerCardInfo
            player={player}
            playerRoster={playerRoster}
            images={defaultImages}
          />
        )}
      </div>

      <div className={styles.wrapper}>
        <InfoWrapper isWhiteBackground={true}>
          <>
            {isNotFoundError ? (
              <ErrorWidget.NotFound />
            ) : isPlayerError ? (
              <ErrorWidget.Error />
            ) : (
              player &&
              playerRoster && (
                <PlayerTabsWithInfo player={player} openAlbum={openAlbum} />
              )
            )}
            {isOpenAlbum && album && (
              <ImageGallery
                items={
                  album.photo
                    ? album.photo?.map((url) => {
                        return {
                          original: url,
                          thumbnail: url,
                          originalClass: !isOpenAlbum ? 'customImage' : '',
                          thumbnailClass: 'customThumbnail',
                        };
                      })
                    : []
                }
                infinite={false}
                showPlayButton={false}
                additionalClass='gallery'
                onScreenChange={(value) => {
                  setIsOpenAlbum(value);
                }}
                startIndex={startIndexAlbum || 0}
                ref={galleryRef}
              />
            )}
          </>
        </InfoWrapper>
      </div>
    </ImageBgPageWrapper>
  );
};

export default PlayerPage;
