import { ReactElement, useEffect, useState } from 'react';
import styles from './ArticleLikesBlock.module.scss';
import { dislikeArticle, likeArticle } from '../../api/api';
import classNames from 'classnames';
import LikeIcon from 'assets/LikeIcon';
import DislikeIcon from 'assets/DislikeIcon';
import Colors from 'constants/colors';
import Article from 'model/entity/loko/type/Article';
import { notification } from 'antd';
import { VOTE_FAILED } from 'constants/constants';

type ArticleLikesBlockProps = {
  article: Article;
  likeIconActive?: string;
  dislikeIconActive?: string;
  isActive?: boolean;
  width?: number;
  height?: number;
  isMainNews?: boolean;
};

function ArticleLikesBlock({
  article,
  isActive,
  isMainNews = false,
}: ArticleLikesBlockProps): ReactElement {
  const [liked, setLiked] = useState<boolean>(false);
  const [disliked, setDisliked] = useState<boolean>(false);
  const countLikes = localStorage.getItem(`article-${article.id}-likes`);
  const countDislikes = localStorage.getItem(`article-${article.id}-dislikes`);
  const [likes, setLikes] = useState<number>(article.attributes.likes || 0);
  const [dislikes, setDislikes] = useState<number>(
    article.attributes.dislikes || 0
  );

  useEffect(() => {
    if (countLikes && article.attributes.likes < Number(countLikes)) {
      setLikes(Number(countLikes));
    }
    if (countDislikes && article.attributes.dislikes < Number(countDislikes)) {
      setDislikes(Number(countDislikes));
    }
  }, []);

  useEffect(() => {
    const likedStatus = localStorage.getItem(`article-${article.id}-liked`);
    const dislikedStatus = localStorage.getItem(
      `article-${article.id}-disliked`
    );

    if (likedStatus === 'true') {
      setLiked(true);
    }

    if (dislikedStatus === 'true') {
      setDisliked(true);
    }
  }, [article]);

  const handleLike = async () => {
    if (isEnabled()) {
      try {
        const updated = await likeArticle(article.id);
        setLikes(updated?.data?.likes || likes + 1);
        setLiked(true);
        localStorage.setItem(`article-${article.id}-liked`, 'true');
        localStorage.setItem(`article-${article.id}-likes`, `${likes + 1}`);
      } catch (e: any) {
        const error = e?.response?.data?.error || e?.message;
        console.log(error);
        notification.error({
          message: VOTE_FAILED,
          placement: 'bottomLeft',
          duration: 15,
        });
      }
    }
  };

  const handleDislike = async () => {
    if (isEnabled()) {
      try {
        const updated = await dislikeArticle(article.id);
        setDislikes(updated?.data?.dislikes || dislikes + 1);
        setDisliked(true);
        localStorage.setItem(`article-${article.id}-disliked`, 'true');
        localStorage.setItem(
          `article-${article.id}-dislikes`,
          `${dislikes + 1}`
        );
      } catch (e: any) {
        const error = e?.response?.data?.error || e?.message;
        console.log(error);
        notification.error({
          message: VOTE_FAILED,
          placement: 'bottomLeft',
          duration: 15,
        });
      }
    }
  };

  const isEnabled = () => {
    return isActive && !liked && !disliked;
  };

  return (
    <div className={isActive ? styles.activeWrapper : styles.wrapper}>
      <div
        className={classNames(styles.likeSection, {
          [styles.pointer]: isEnabled(),
        })}
        onClick={handleLike}
      >
        {isMainNews ? (
          <LikeIcon
            fillColor={liked ? Colors.WHITE : Colors.GREY_TEXT}
            height='24'
            width='24'
          />
        ) : (
          <LikeIcon
            fillColor={liked ? Colors.PRIMARY_BLUE : Colors.GREY_TEXT}
            height='16'
            width='16'
          />
        )}
        <div
          className={classNames(styles.counter, {
            [styles.active]: isActive,
            [isMainNews ? styles.likeFilledMainNews : styles.likeFilled]: liked,
            [styles.counterMainNews]: isMainNews,
          })}
        >
          {likes}
        </div>
      </div>

      <div
        className={classNames(styles.likeSection, {
          [styles.pointer]: isEnabled(),
        })}
        onClick={handleDislike}
      >
        {isMainNews ? (
          <DislikeIcon fillColor={Colors.GREY_TEXT} height='24' width='24' />
        ) : (
          <DislikeIcon fillColor={Colors.LIGHT_GREY} height='16' width='16' />
        )}

        <div
          className={classNames(
            styles.counter,
            disliked
              ? styles.dislikeFilled
              : isMainNews
              ? styles.dislike
              : styles.dislikeNotMainNews,
            {
              [styles.active]: isActive,
              [styles.counterMainNews]: isMainNews,
            }
          )}
        >
          {dislikes}
        </div>
      </div>
    </div>
  );
}

export default ArticleLikesBlock;
