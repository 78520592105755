import React, { ReactElement } from 'react';
import Spinner from 'components/Spinner/Spinner';
import classNames from 'classnames';
import { ConferenceTree, StandingsReadableDto } from 'types/Types';
import StandingsPageTableBody from '../StandingPageTableBody/StandingPageTableBody';
import ShowOn from 'components/core/adaptivity/ShowOn';

type ConferenceTabProps = {
  conferenceRef: React.RefObject<HTMLDivElement>;
  activeSubTab: number;
  setActiveSubTab: React.Dispatch<React.SetStateAction<number>>;
  styles: any;
  isLoading: boolean;
  standings: StandingsReadableDto[];
  conferenceList: ConferenceTree[];
};

function ConferenceTab({
  conferenceRef,
  activeSubTab,
  setActiveSubTab,
  styles,
  isLoading,
  standings,
  conferenceList,
}: ConferenceTabProps): ReactElement {
  function filterConference(id: string): Array<StandingsReadableDto> {
    return standings.filter((x: StandingsReadableDto) => x.conference === id);
  }

  return (
    <div
      ref={conferenceRef}
      className={classNames(styles.text, styles.textShow)}
      id='conference'
    >
      <div className={styles.subTabs}>
        <span
          onClick={() => setActiveSubTab(0)}
          className={activeSubTab === 0 ? styles.active : styles.subTab}
        >
          <ShowOn largeDesktop smallDesktop largeTablet smallTablet>
            Конференция «Запад»
          </ShowOn>
          <ShowOn largeMobile smallMobile>
            «Запад»
          </ShowOn>
        </span>
        <span
          onClick={() => setActiveSubTab(1)}
          className={activeSubTab === 1 ? styles.active : styles.subTab}
        >
          <ShowOn largeDesktop smallDesktop largeTablet smallTablet>
            Конференция «Восток»
          </ShowOn>
          <ShowOn largeMobile smallMobile>
            «Восток»
          </ShowOn>
        </span>
      </div>
      {!isLoading && standings && standings.length > 0 ? (
        conferenceList &&
        conferenceList.length > 0 && (
          <React.Fragment key={conferenceList[activeSubTab]?.id}>
            <div
              className={classNames(styles.text, styles.textShow)}
              id='conference'
            >
              <table className={styles.table}>
                <div className={styles.statsTableWrapper}>
                  {standings && (
                    <StandingsPageTableBody
                      data={filterConference(conferenceList[activeSubTab]?.id)}
                      isDivisions={false}
                      isConference={true}
                    />
                  )}
                </div>
              </table>
            </div>
          </React.Fragment>
        )
      ) : isLoading ? (
        <Spinner />
      ) : (
        <div>Турнирные таблицы не найдены</div>
      )}
    </div>
  );
}

export default ConferenceTab;
