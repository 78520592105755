import { makeAutoObservable } from 'mobx';
import { getDefaultSeason } from '../../api/api';
import { CurrentSeason, SeasonsTeamCode } from 'types/Types';

class CurrentSeasonStore {
  constructor() {
    makeAutoObservable(this);
  }

  currentSeason: CurrentSeason | undefined;
  isLoading: boolean = true;

  getSeasonByCode(code: string | undefined): string | undefined {
    if (code) {
      switch (code.toLowerCase()) {
        case SeasonsTeamCode.men:
          return this.currentSeason?.men;
        case SeasonsTeamCode.lko:
          return this.currentSeason?.lko;
        case SeasonsTeamCode.l76:
          return this.currentSeason?.l76;
        case SeasonsTeamCode.catastrophe:
          return this.currentSeason?.catastrophe;
        default:
          return undefined;
      }
    } else {
      return undefined;
    }
  }

  async loadCurrentSeason() {
    const response = await getDefaultSeason();

    this.currentSeason = {
      men: response?.data?.attributes?.men,
      l76: response?.data?.attributes?.l76,
      lko: response?.data?.attributes?.lko,
      catastrophe: '2012MEN',
    };
    this.isLoading = false;
  }
}

export default CurrentSeasonStore;
