import axios from 'axios';
import config from '../config';
import IResponse from '../types/base/IResponse';

export function getURI(urn: string): string {
  return `${config.HCLBackend.baseUrl}${urn}`;
}

export function getShopURI(urn: string): string {
  return `${config.HCLShopBackend.baseUrl}${urn}`;
}

export function getShopFrontURI(urn: string): string {
  return `${config.HCLShopFrontend.baseUrl}${urn}`;
}

export async function get(url: string): Promise<any> {
  // TODO: uncomment try-catch once all the 'get' methods ready
  // try {
  const data = await axios.get(getURI(url));
  return data.data || data;
  //} catch (e: any) {
  //console.log(`Request error: ${e.message}`);
  // return null;
  //}
}

export async function getShop(url: string): Promise<any> {
  // TODO: wrap into try-catch block, fix other thing appeared
  const data = await axios.get(getShopURI(url));
  return data.data || data;
}

export async function getAuthorized(
  url: string,
  token: string | null
): Promise<any> {
  // TODO: wrap into try-catch block, fix other thing appeared
  return axios.get(getURI(url), {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  });
}
export async function put(
  url: string,
  data: any,
  token?: string | null
): Promise<any> {
  return axios.put(getURI(url), data, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  });
}

export async function post(
  url: string,
  data: any,
  token?: string | null
): Promise<any> {
  // TODO: wrap into try-catch block, fix other things appeared
  if (token) {
    return axios.post(getURI(url), data, {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    });
  } else {
    return axios.post(getURI(url), data);
  }
}

export async function del(url: string): Promise<any> {
  return axios.delete(getURI(url));
}

export function getDateByStrapiFormating(date: Date) {
  const year = date.toLocaleString('default', { year: 'numeric' });
  const month = date.toLocaleString('default', { month: '2-digit' });
  const day = date.toLocaleString('default', { day: '2-digit' });

  return `${year}-${month}-${day}`;
}

export function getFilterIsLoko(
  indexOfAndFilter: number = 0,
  isOnlyLokomotiv: boolean = false,
  lokomotivId: number,
  lokomotivTeamsIds: number[]
) {
  const array = isOnlyLokomotiv ? [lokomotivId] : lokomotivTeamsIds;
  return array
    .map(
      (id, index) =>
        `&filters[$and][${indexOfAndFilter}][$or][${
          2 * index
        }][team_1][id_league_web][$eq]=${id}&filters[$and][${indexOfAndFilter}][$or][${
          2 * index + 1
        }][team_2][id_league_web][$eq]=${id}`
    )
    .join('');
}

export function getLokoIsHomeFilter(
  isOnlyLokomotiv: boolean = false,
  lokomotivId: number,
  lokomotivTeamsIds: number[]
) {
  const array = isOnlyLokomotiv ? [lokomotivId] : lokomotivTeamsIds;
  return array
    .map(
      (id, index) => `&filters[$or][${index}][team_1][id_league_web][$eq]=${id}`
    )
    .join('');
}

export function getLokoIsAwayFilter(
  isOnlyLokomotiv: boolean = false,
  lokomotivId: number,
  lokomotivTeamsIds: number[]
) {
  const array = isOnlyLokomotiv ? [lokomotivId] : lokomotivTeamsIds;
  return array
    .map(
      (id, index) => `&filters[$or][${index}][team_2][id_league_web][$eq]=${id}`
    )
    .join('');
}

export function getSearchArticlesFilter(searchParam: string | null): string {
  if (!searchParam) {
    return '';
  }
  return `&filters[full_text][$containsi]=${searchParam}`;
}

export function getCategoryArticlesFilter(
  categoryParam: string | null
): string {
  if (!categoryParam) {
    return '';
  }
  return `&filters[article_category][id][$eq]=${categoryParam}`;
}

export function getYearArticlesFilter(yearParam: string | null): string {
  if (!yearParam) {
    return '';
  }
  return `&filters[date][$contains]=${yearParam}-`;
}

export function getMonthArticlesFilter(monthParam: string | null): string {
  if (!monthParam) {
    return '';
  }
  const param = monthParam.length === 1 ? `0${monthParam}` : monthParam;

  return `&filters[date][$contains]=-${param}-`;
}

export function getDateArticlesFilter(
  yearParam: string | null,
  monthParam: string | null
): string {
  if (!yearParam && !monthParam) {
    return '';
  }
  if (yearParam && !monthParam) {
    return `&filters[date][$contains]=${yearParam}-`;
  }
  if (!yearParam && monthParam) {
    const param = monthParam.length === 1 ? `0${monthParam}` : monthParam;
    return `&filters[date][$contains]=-${param}-`;
  }
  if (yearParam && monthParam) {
    const param = monthParam.length === 1 ? `0${monthParam}` : monthParam;
    return `&filters[date][$contains]=${yearParam}-${param}-`;
  }
  return '';
}

export async function getAll<T>(
  firstPageUrl: string,
  otherPageUrl: (paginationStart: number) => string
): Promise<IResponse<T>> {
  const limit = 25;
  let resultData = [];
  const firstPage = await get(firstPageUrl);
  resultData.push(...firstPage.data);
  if (
    firstPage.meta &&
    firstPage.meta.pagination &&
    firstPage.meta.pagination.pageCount
  ) {
    const pagesAmount = firstPage.meta.pagination.pageCount;
    for (let i = 1; i <= pagesAmount; i++) {
      const nextPage = await get(otherPageUrl(i * limit));
      resultData.push(...nextPage.data);
    }
  }
  return { data: resultData };
}
