import classNames from 'classnames';
import { ReactElement, useEffect, useRef, useState } from 'react';
import styles from './InfrastructureTabsWithInfo.module.scss';
import SliderWrapper from '../SliderWrapper/SliderWrapper';
import { ComplexInformationResponse } from '../../types/Types';
import { getInfrastructureInformation } from '../../api/api';
import BioBox from '../PlayerPage/Bio/BioBox/BioBox';
import { useLocation, useNavigate } from 'react-router-dom';
import ShowOn from 'components/core/adaptivity/ShowOn';
import Spinner from 'components/Spinner/Spinner';
import { useMediaQuery } from 'react-responsive';
import { Breakpoints } from 'constants/adaptivity';
import { useTranslation } from 'react-i18next';
import InfrastructureMap from './InfrastructureMap/InfrastructureMap';
import ScalableDiv from 'components/common/ScalableDiv/ScalableDiv';
import ReactImageGallery, { ReactImageGalleryItem } from 'react-image-gallery';
import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/scss/image-gallery.scss';
import ImageLoader from 'components/common/ImageLoaders/ImageLoader/ImageLoader';
import videoThumbnail from 'assets/images/video_thumbnail.png';
import Image from 'model/entity/loko/type/Image';
import MFSKPage from 'components/pages/MFSKinfo/MFSKinfo';
import MFSKmap from 'components/pages/MFSKinfo/MFSKmap';
import { customLocaleCompare } from 'helpers/helpers';

type InfrastructureTabsWithInfoPropsType = {
  pathname: string;
};

function InfrastructureTabsWithInfo({
  pathname,
}: InfrastructureTabsWithInfoPropsType): ReactElement {
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState<number>(0);
  const [aboutComplex, setAboutComplex] =
    useState<ComplexInformationResponse>();
  const [arenaToday, setArenaToday] = useState<ComplexInformationResponse>();
  const [constructionProgress, setConstructionProgress] =
    useState<ComplexInformationResponse>();
  const [arenaTodaySlidesShow, setArenaTodaySlidesShow] = useState(2);
  const [constructionProgressSlidesShow, setConstructionProgressSlidesShow] =
    useState(2);
  const [mapScale, setMapScale] = useState(1.0);
  const navigate = useNavigate();
  const isLargeTablet = useMediaQuery({
    minWidth: Breakpoints.largeTablet + 1,
    maxWidth: Breakpoints.smallDesktop,
  });
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const [isLoadingAboutArenaContent, setIsLoadingAboutArenaContent] =
    useState(false);

  const galleryRef = useRef<ReactImageGallery | null>(null);
  const [openImageIndex, setOpenImageIndex] = useState<number>(0);

  const location = useLocation();
  const isArenaPath = location.pathname.includes('/arena');

  useEffect(() => {
    switch (pathname) {
      case 'about':
        setActiveTab(0);
        break;
      case 'construction-progress':
        setActiveTab(1);
        break;
      case 'today':
        setActiveTab(2);
        break;
    }
  }, [pathname]);

  useEffect(() => {
    const getData = async () => {
      try {
        setIsLoadingAboutArenaContent(true);
        const respInfo = await getInfrastructureInformation();

        const infrastructureInfo = respInfo.data.find((item: any) => {
          if (isArenaPath) {
            return item.attributes.name === 'УКРК Арена-2000. Локомотив';
          } else {
            return item.attributes.name === 'МФСК "Локомотив"';
          }
        });

        if (infrastructureInfo) {
          setAboutComplex(
            infrastructureInfo.attributes.page?.find(
              (page: any) => page.name === 'arena_about'
            )
          );
          setConstructionProgress(
            infrastructureInfo.attributes.page?.find(
              (page: any) => page.name === 'construction_progress'
            )
          );
          setArenaToday(
            infrastructureInfo.attributes.page?.find(
              (page: any) => page.name === 'arena_today'
            )
          );
        }
      } catch (error: any) {
        console.error(error?.response?.data?.error || error?.message);
      } finally {
        setIsLoadingAboutArenaContent(false);
      }
    };

    getData();
  }, [isArenaPath]);

  const renderVideo = (item: ReactImageGalleryItem) => {
    return (
      <div className={styles.videoItem}>
        <video controls muted controlsList='nofullscreen nodownload'>
          <source src={item.original} />
        </video>
      </div>
    );
  };

  useEffect(() => {
    if (!arenaToday?.images?.data) return;

    if (
      arenaToday.images?.data.length === 1 ||
      arenaToday.images?.data.length === 2
    ) {
      setArenaTodaySlidesShow(1);
      return;
    }

    setArenaTodaySlidesShow(2);
  }, [arenaToday?.images?.data]);

  useEffect(() => {
    if (!constructionProgress?.images?.data) return;

    if (
      constructionProgress?.images?.data.length === 1 ||
      constructionProgress?.images?.data.length === 2
    ) {
      setConstructionProgressSlidesShow(1);
      return;
    }

    setConstructionProgressSlidesShow(2);
  }, [constructionProgress?.images?.data]);

  const handleTabClick = (tab: number) => {
    setActiveTab(tab);
    navigateTo(tab);
  };

  const openGallery = (gallery: Array<Image>, imageId: number) => {
    setOpenImageIndex(gallery.findIndex((img) => img.id === imageId) || 0);
    setIsModalOpen(true);
  };

  useEffect(() => {
    if (isModalOpen && galleryRef?.current) {
      galleryRef.current?.fullScreen();
    }
  }, [galleryRef, isModalOpen]);

  const navigateTo = (tab: number) => {
    switch (tab) {
      case 0:
        navigate(isArenaPath ? '/arena/about' : '/mfsk/about');
        break;
      case 1:
        navigate(
          isArenaPath
            ? '/arena/construction-progress'
            : '/mfsk/construction-progress'
        );
        break;
      case 2:
        navigate(isArenaPath ? '/arena/today' : '/mfsk/today');
        break;
    }
  };

  const renderPage = (
    arena: ComplexInformationResponse | undefined,
    isAboutPage: boolean
  ) => {
    const gallery =
      arena?.images?.data
        ?.sort((a, b) =>
          customLocaleCompare(a.attributes.name, b.attributes.name)
        )
        .map((item) => {
          if (
            item.attributes?.url?.substring(
              item?.attributes?.url?.lastIndexOf('.') + 1
            ) === 'mp4'
          ) {
            return {
              thumbnail: videoThumbnail,
              original: item.attributes.url,
              description: 'Render custom slides (such as videos)',
              renderItem: renderVideo,
            };
          } else {
            return {
              original: item.attributes.url,
              thumbnail: item.attributes.url,
              originalClass: !isModalOpen ? 'customImage' : '',
              thumbnailClass: 'customThumbnail',
            };
          }
        }) || [];

    return (
      <>
        {isLoadingAboutArenaContent ? (
          <Spinner />
        ) : (
          <>
            <ShowOn largeDesktop smallDesktop>
              <div
                className={classNames(
                  styles.container,
                  styles.arenaTodayContainer
                )}
              >
                <div className={styles.contentContainer}>
                  <h2 className={styles.title}>
                    {(isAboutPage
                      ? t('infrastructurePage.tabs.arenaToday')
                      : t('infrastructurePage.tabs.constructionProgress')) ||
                      t('infrastructurePage.about.headerNotFound')}
                  </h2>
                  <div
                    className={styles.content}
                    dangerouslySetInnerHTML={{
                      __html:
                        arena?.description ||
                        t('infrastructurePage.about.descriptionNotFound'),
                    }}
                  />
                  <div className={styles.bio}>
                    {aboutComplex?.area && (
                      <BioBox
                        name={t('infrastructurePage.about.area')}
                        value={
                          aboutComplex?.area
                            ? t('infrastructurePage.about.areaValue', {
                                value: aboutComplex.area,
                              })
                            : ''
                        }
                        nameFontSize={18}
                        valueFontSize={22}
                      />
                    )}
                    {aboutComplex?.capacity && (
                      <BioBox
                        name={t('infrastructurePage.about.capacity')}
                        value={
                          aboutComplex?.capacity
                            ? t('infrastructurePage.about.capacityValue', {
                                value: aboutComplex?.capacity,
                              })
                            : ''
                        }
                        nameFontSize={18}
                        valueFontSize={22}
                      />
                    )}
                    {isArenaPath && aboutComplex?.vip_capacity && (
                      <BioBox
                        name={t('infrastructurePage.about.vipCapacity')}
                        value={
                          aboutComplex?.vip_capacity
                            ? t('infrastructurePage.about.capacityValue', {
                                value: aboutComplex?.vip_capacity,
                              })
                            : ''
                        }
                        nameFontSize={isLargeTablet ? 16 : 18}
                        valueFontSize={isLargeTablet ? 18 : 22}
                      />
                    )}
                  </div>
                </div>
                <div className={styles.carouselContainer}>
                  <SliderWrapper
                    speed={200}
                    lazyLoad='ondemand'
                    className={classNames(
                      styles.arenaTodayGallery,
                      arena?.images?.data && arena?.images?.data.length === 2
                        ? styles.arenaTodayGalleryTwoSlides
                        : ''
                    )}
                    infinite={true}
                    slidesToShow={
                      isAboutPage
                        ? arenaTodaySlidesShow
                        : constructionProgressSlidesShow
                    }
                    slidesToScroll={1}
                    slides={
                      arena?.images?.data
                        ? arena?.images?.data.map((image) => (
                            <div
                              key={image.id}
                              onClick={() =>
                                openGallery(arena?.images?.data, image.id)
                              }
                            >
                              <ImageLoader
                                image={image}
                                alt='complex-today-slide'
                              />
                            </div>
                          ))
                        : []
                    }
                  />
                </div>
              </div>
              {isModalOpen && (
                <ImageGallery
                  items={gallery}
                  infinite={false}
                  showPlayButton={false}
                  additionalClass='gallery'
                  onScreenChange={(value) => {
                    setIsModalOpen(value);
                  }}
                  startIndex={openImageIndex}
                  ref={galleryRef}
                />
              )}
            </ShowOn>

            <ShowOn largeTablet smallMobile smallTablet largeMobile>
              <div
                className={classNames(
                  styles.container,
                  styles.arenaTodayContainerMobile
                )}
              >
                <div className={styles.contentContainer}>
                  <h2 className={styles.title}>
                    {(isAboutPage
                      ? t('infrastructurePage.tabs.arenaToday')
                      : t('infrastructurePage.tabs.constructionProgress')) ||
                      t('infrastructurePage.about.headerNotFound')}
                  </h2>
                  <div
                    className={styles.content}
                    dangerouslySetInnerHTML={{
                      __html:
                        arena?.description ||
                        t('infrastructurePage.about.descriptionNotFound'),
                    }}
                  />
                  <div>
                    {aboutComplex?.area && (
                      <BioBox
                        name={t('infrastructurePage.about.area')}
                        value={
                          aboutComplex?.area
                            ? t('infrastructurePage.about.areaValue', {
                                value: aboutComplex.area,
                              })
                            : ''
                        }
                      />
                    )}
                    {aboutComplex?.capacity && (
                      <BioBox
                        name={t('infrastructurePage.about.capacity')}
                        value={
                          aboutComplex?.capacity
                            ? t('infrastructurePage.about.capacityValue', {
                                value: aboutComplex.capacity,
                              })
                            : ''
                        }
                      />
                    )}
                    {isArenaPath && aboutComplex?.vip_capacity && (
                      <BioBox
                        name={t('infrastructurePage.about.vipCapacity')}
                        value={
                          aboutComplex?.vip_capacity
                            ? t('infrastructurePage.about.capacityValue', {
                                value: aboutComplex.vip_capacity,
                              })
                            : ''
                        }
                      />
                    )}
                  </div>
                </div>
                <div className={styles.carouselContainer}>
                  <SliderWrapper
                    className={styles.mobileCarousel}
                    infinite={true}
                    slidesToShow={1}
                    slidesToScroll={1}
                    adaptiveHeight
                    slides={
                      arena?.images?.data
                        ? arena?.images?.data.map((image) => (
                            <div
                              key={image.id}
                              onClick={() =>
                                openGallery(arena?.images?.data, image.id)
                              }
                            >
                              <ImageLoader
                                image={image}
                                alt={image.attributes.alternativeText}
                              />
                            </div>
                          ))
                        : []
                    }
                  />
                </div>
              </div>
              {isModalOpen && (
                <ImageGallery
                  items={gallery}
                  infinite={false}
                  showPlayButton={false}
                  additionalClass='gallery'
                  onScreenChange={(value) => {
                    setIsModalOpen(value);
                  }}
                  startIndex={openImageIndex}
                  ref={galleryRef}
                />
              )}
            </ShowOn>
          </>
        )}
      </>
    );
  };

  const renderMapArticle = () => (
    <>
      {aboutComplex && (
        <ShowOn largeTablet smallTablet largeMobile smallMobile>
          <ScalableDiv onScaleUpdate={(scale) => setMapScale(scale)}>
            {isArenaPath ? (
              <InfrastructureMap
                aboutArena={aboutComplex}
                scale={mapScale}
                isBackground={false}
              />
            ) : (
              <MFSKmap
                aboutMfsk={aboutComplex}
                scale={mapScale}
                isBackground={false}
              />
            )}
          </ScalableDiv>
        </ShowOn>
      )}
      {isLoadingAboutArenaContent ? (
        <Spinner />
      ) : (
        aboutComplex &&
        (isArenaPath ? (
          <div className={classNames(styles.article, styles.pointerEventsNone)}>
            <div
              className={classNames(styles.container, styles.arenaContainer)}
            >
              <div
                className={classNames(
                  styles.contentContainer,
                  styles.arenaContentContainer
                )}
              >
                <h2 className={styles.title}>
                  {aboutComplex?.title ||
                    t('infrastructurePage.about.headerNotFound')}
                </h2>
                <div
                  className={styles.content}
                  dangerouslySetInnerHTML={{
                    __html:
                      aboutComplex?.description ||
                      t('infrastructurePage.about.descriptionNotFound'),
                  }}
                />

                <div className={styles.bio}>
                  <BioBox
                    name={t('infrastructurePage.about.area')}
                    value={
                      aboutComplex?.area
                        ? t('infrastructurePage.about.areaValue', {
                            value: aboutComplex.area,
                          })
                        : ''
                    }
                    nameFontSize={isLargeTablet ? 16 : 18}
                    valueFontSize={isLargeTablet ? 18 : 22}
                  />
                  <BioBox
                    name={t('infrastructurePage.about.capacity')}
                    value={
                      aboutComplex?.capacity
                        ? t('infrastructurePage.about.capacityValue', {
                            value: aboutComplex.capacity,
                          })
                        : ''
                    }
                    nameFontSize={isLargeTablet ? 16 : 18}
                    valueFontSize={isLargeTablet ? 18 : 22}
                  />
                  {isArenaPath && (
                    <BioBox
                      name={t('infrastructurePage.about.vipCapacity')}
                      value={
                        aboutComplex?.vip_capacity
                          ? t('infrastructurePage.about.capacityValue', {
                              value: aboutComplex.vip_capacity,
                            })
                          : ''
                      }
                      nameFontSize={isLargeTablet ? 16 : 18}
                      valueFontSize={isLargeTablet ? 18 : 22}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        ) : (
          <MFSKPage aboutMfsk={aboutComplex} />
        ))
      )}
    </>
  );

  return (
    <div className={styles.wrapper}>
      <div className={styles.contentWrapper}>
        {activeTab === 0 && aboutComplex && (
          <ShowOn largeDesktop smallDesktop>
            {isArenaPath ? (
              <InfrastructureMap
                aboutArena={aboutComplex}
                scale={1}
                isBackground={true}
              />
            ) : (
              <MFSKmap aboutMfsk={aboutComplex} scale={1} isBackground={true} />
            )}
          </ShowOn>
        )}

        <div className={classNames(styles.tabBar)}>
          <p
            className={classNames(styles.tab, {
              [styles.active]: activeTab === 0,
            })}
            onClick={() => handleTabClick(0)}
          >
            {t('infrastructurePage.tabs.about')}
          </p>
          <p
            className={classNames(styles.tab, {
              [styles.active]: activeTab === 1,
            })}
            onClick={() => handleTabClick(1)}
          >
            {t('infrastructurePage.tabs.constructionProgress')}
          </p>
          <p
            className={classNames(styles.tab, {
              [styles.active]: activeTab === 2,
            })}
            onClick={() => handleTabClick(2)}
          >
            {t('infrastructurePage.tabs.arenaToday')}
          </p>
        </div>

        {activeTab === 0 && renderMapArticle()}
        {activeTab === 1 && (
          <div className={styles.article}>
            {isLoadingAboutArenaContent ? (
              <Spinner />
            ) : (
              renderPage(constructionProgress, false)
            )}
          </div>
        )}
        {activeTab === 2 && (
          <div className={styles.article}>
            {isLoadingAboutArenaContent ? (
              <Spinner />
            ) : (
              renderPage(arenaToday, true)
            )}
          </div>
        )}
      </div>
    </div>
  );
}

export default InfrastructureTabsWithInfo;
