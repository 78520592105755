import {
  DefaultImageObject,
  PlayerSeasonStatsDtoReadable,
  StandingsTypes,
} from 'types/Types';
import TableTh from '../TableTh/TableTh';
import { useStore } from 'store/store';
import { getNameSurname } from 'helpers/helpers';
import styles from '../NotGoalkeeperStats/NotGoalkeeperStats.module.scss';
import { DEFAULT_IMAGE_NAME, POSITION_MAP_LETTER } from 'constants/constants';
import { observer } from 'mobx-react-lite';
import ImageLoader from '../../common/ImageLoaders/ImageLoader/ImageLoader';
import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import ShowOn from 'components/core/adaptivity/ShowOn';
import { useTranslation } from 'react-i18next';

type GoalkeepersStatsProps = {
  goalkeepersStats: Array<PlayerSeasonStatsDtoReadable>;
  updateSorting: Function;
  defImg: DefaultImageObject[];
  setIsDefaultSort: Dispatch<SetStateAction<boolean>>;
};

const GoalkeepersStats = observer(
  ({
    goalkeepersStats,
    updateSorting,
    defImg,
    setIsDefaultSort,
  }: GoalkeepersStatsProps) => {
    const { t } = useTranslation();
    const { currentSeasonStore } = useStore();
    const [season, setSeason] = useState<string>('');
    const { teamCode } = useParams();
    const tableBodyRef = useRef<HTMLTableSectionElement>(null);

    useEffect(() => {
      const seasonCode = currentSeasonStore.getSeasonByCode(teamCode);

      if (seasonCode) {
        setSeason(seasonCode);
      }
    }, [teamCode, currentSeasonStore.currentSeason]);

    return (
      <tbody ref={tableBodyRef}>
        <tr className={styles.tableHeader}>
          <TableTh
            text={t('statsLokomotivPage.stats.text.number')}
            title={t('statsLokomotivPage.stats.title.number')}
            updateSorting={updateSorting}
            tableBodyRef={tableBodyRef}
            updateSortingParam='number'
            isGoalkeeper={true}
            index={1}
            type={StandingsTypes.number}
            setIsDefaultSort={setIsDefaultSort}
          />
          <TableTh
            text={t('statsLokomotivPage.stats.text.namelocal')}
            title={t('statsLokomotivPage.stats.title.namelocal')}
            tableBodyRef={tableBodyRef}
            updateSorting={updateSorting}
            updateSortingParam='namelocal'
            isGoalkeeper={true}
            textAlign='left'
            index={2}
            type={StandingsTypes.string}
            isFirstTh={true}
            setIsDefaultSort={setIsDefaultSort}
          />
          <TableTh
            text={t('statsLokomotivPage.stats.text.gamesPlayed')}
            title={t('statsLokomotivPage.stats.title.gamesPlayed')}
            tableBodyRef={tableBodyRef}
            updateSorting={updateSorting}
            updateSortingParam='games_played'
            isGoalkeeper={true}
            index={3}
            type={StandingsTypes.number}
            setIsDefaultSort={setIsDefaultSort}
          />
          <TableTh
            text={t('statsLokomotivPage.stats.text.wins')}
            title={t('statsLokomotivPage.stats.title.wins')}
            tableBodyRef={tableBodyRef}
            updateSorting={updateSorting}
            updateSortingParam='wins'
            isGoalkeeper={true}
            index={4}
            type={StandingsTypes.number}
            setIsDefaultSort={setIsDefaultSort}
          />
          <TableTh
            text={t('statsLokomotivPage.stats.text.losses')}
            title={t('statsLokomotivPage.stats.title.losses')}
            tableBodyRef={tableBodyRef}
            updateSorting={updateSorting}
            updateSortingParam='losses'
            isGoalkeeper={true}
            index={5}
            type={StandingsTypes.number}
            setIsDefaultSort={setIsDefaultSort}
          />
          <TableTh
            text={t('statsLokomotivPage.stats.text.gameWithBSeries')}
            title={t('statsLokomotivPage.stats.title.gameWithBSeries')}
            tableBodyRef={tableBodyRef}
            updateSorting={updateSorting}
            updateSortingParam='game_with_b_series'
            isGoalkeeper={true}
            index={6}
            type={StandingsTypes.number}
            setIsDefaultSort={setIsDefaultSort}
          />
          <TableTh
            text={t('statsLokomotivPage.stats.text.shotsOnGoal')}
            title={t('statsLokomotivPage.stats.title.shotsOnGoal')}
            tableBodyRef={tableBodyRef}
            updateSorting={updateSorting}
            updateSortingParam='shots_on_goal'
            isGoalkeeper={true}
            index={7}
            type={StandingsTypes.number}
            setIsDefaultSort={setIsDefaultSort}
          />
          <TableTh
            text={t('statsLokomotivPage.stats.text.goalsAgainst')}
            title={t('statsLokomotivPage.stats.title.goalsAgainst')}
            tableBodyRef={tableBodyRef}
            updateSorting={updateSorting}
            updateSortingParam='goals_against'
            isGoalkeeper={true}
            index={8}
            type={StandingsTypes.number}
            setIsDefaultSort={setIsDefaultSort}
          />
          <TableTh
            text={t('statsLokomotivPage.stats.text.savings')}
            title={t('statsLokomotivPage.stats.title.savings')}
            tableBodyRef={tableBodyRef}
            updateSorting={updateSorting}
            updateSortingParam='savings'
            isGoalkeeper={true}
            index={9}
            type={StandingsTypes.number}
            setIsDefaultSort={setIsDefaultSort}
          />
          <TableTh
            text={t('statsLokomotivPage.stats.text.savingsPercent')}
            title={t('statsLokomotivPage.stats.title.savingsPercent')}
            tableBodyRef={tableBodyRef}
            updateSorting={updateSorting}
            updateSortingParam='savings_percent'
            isGoalkeeper={true}
            index={10}
            type={StandingsTypes.number}
            setIsDefaultSort={setIsDefaultSort}
          />
          <TableTh
            text={t('statsLokomotivPage.stats.text.goalsAgainstAverage')}
            title={t('statsLokomotivPage.stats.title.goalsAgainstAverage')}
            tableBodyRef={tableBodyRef}
            updateSorting={updateSorting}
            updateSortingParam={'goals_against_average'}
            isGoalkeeper={true}
            index={11}
            type={StandingsTypes.number}
            setIsDefaultSort={setIsDefaultSort}
          />
          <TableTh
            text={t('statsLokomotivPage.stats.text.goals')}
            title={t('statsLokomotivPage.stats.title.goals')}
            tableBodyRef={tableBodyRef}
            updateSorting={updateSorting}
            updateSortingParam='goals'
            isGoalkeeper={true}
            index={12}
            type={StandingsTypes.number}
            setIsDefaultSort={setIsDefaultSort}
          />
          <TableTh
            text={t('statsLokomotivPage.stats.text.assists')}
            title={t('statsLokomotivPage.stats.title.assists')}
            tableBodyRef={tableBodyRef}
            updateSorting={updateSorting}
            updateSortingParam='assists'
            isGoalkeeper={true}
            index={13}
            type={StandingsTypes.number}
            setIsDefaultSort={setIsDefaultSort}
          />
          <TableTh
            text={t('statsLokomotivPage.stats.text.shootouts')}
            title={t('statsLokomotivPage.stats.title.shootouts')}
            tableBodyRef={tableBodyRef}
            updateSorting={updateSorting}
            updateSortingParam='shootouts'
            isGoalkeeper={true}
            index={14}
            type={StandingsTypes.number}
            setIsDefaultSort={setIsDefaultSort}
          />
          <TableTh
            text={t('statsLokomotivPage.stats.text.penaltyInMinutes')}
            title={t('statsLokomotivPage.stats.title.penaltyInMinutes')}
            tableBodyRef={tableBodyRef}
            updateSorting={updateSorting}
            updateSortingParam='penalty_in_minutes'
            isGoalkeeper={true}
            index={15}
            type={StandingsTypes.number}
            setIsDefaultSort={setIsDefaultSort}
          />
          <TableTh
            text={t('statsLokomotivPage.stats.text.timeOnIce')}
            title={t('statsLokomotivPage.stats.title.timeOnIce')}
            tableBodyRef={tableBodyRef}
            updateSorting={updateSorting}
            updateSortingParam='time_on_ice'
            isGoalkeeper={true}
            index={16}
            type={StandingsTypes.time}
            setIsDefaultSort={setIsDefaultSort}
          />
        </tr>
        {goalkeepersStats.map((rosterDtoWithStats) => (
          <tr className={styles.tableTr}>
            <td className='left'>
              <span className={styles.number}>{rosterDtoWithStats.number}</span>
            </td>
            <td className={styles.club}>
              <a
                href={
                  rosterDtoWithStats.idlocal
                    ? `/player/${rosterDtoWithStats.idlocal}?season=${season}`
                    : ''
                }
                title={t('statsLokomotivPage.stats.profile')}
              >
                <div className={styles.playerInfo}>
                  <ShowOn largeDesktop smallDesktop largeTablet smallTablet>
                    <ImageLoader
                      className={styles.img}
                      image={
                        rosterDtoWithStats.photo?.data ||
                        defImg?.find(
                          (x) => x.attributes.description == DEFAULT_IMAGE_NAME
                        )?.attributes?.image?.data ||
                        ''
                      }
                    />
                  </ShowOn>
                  <div>
                    <span className={styles.name}>
                      {getNameSurname(rosterDtoWithStats)}
                    </span>
                    <span className={styles.position}>
                      {POSITION_MAP_LETTER.get(rosterDtoWithStats.position)}
                    </span>
                  </div>
                </div>
              </a>
            </td>
            <td>{rosterDtoWithStats.games_played}</td>
            <td>{rosterDtoWithStats.wins}</td>
            <td>{rosterDtoWithStats.losses}</td>
            <td>{rosterDtoWithStats.game_with_b_series}</td>
            <td>{rosterDtoWithStats.shots_on_goal}</td>
            <td>{rosterDtoWithStats.goals_against}</td>
            <td>{rosterDtoWithStats.savings}</td>
            <td>{rosterDtoWithStats.savings_percent}</td>
            <td>{rosterDtoWithStats.goals_against_average ?? '-'}</td>
            <td>{rosterDtoWithStats.goals}</td>
            <td>{rosterDtoWithStats.assists}</td>
            <td>{rosterDtoWithStats.shootouts}</td>
            <td>{rosterDtoWithStats.penalty_in_minutes}</td>
            <td>{rosterDtoWithStats.time_on_ice}</td>
          </tr>
        ))}
      </tbody>
    );
  }
);

export default GoalkeepersStats;
