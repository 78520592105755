import { ReactElement } from "react";
import styles from "./DescriptionBlock.module.scss";

interface DescriptionBlockProps {
  children: React.ReactNode;
}

export default function DescriptionBlock(props: DescriptionBlockProps): ReactElement {
  return <div className={styles.infoBlock}>{props.children}</div>;
}
