import React, { ReactElement, useEffect, useState } from 'react';
import DescriptionBlock from '../../../../common/DescriptionBlock/DescriptionBlock';
import LokoTable from '../../../../common/LokoTable/LokoTable';
import LokoTableRow from '../../../../common/LokoTable/LokoTableRow/LokoTableRow';
import LokoTableHeadCell from '../../../../common/LokoTable/LokoTableHeadCell/LokoTableHeadCell';
import Spinner from '../../../../Spinner/Spinner';
import { TicketPrice } from 'types/Types';
import {
  TICKET_AGE_CATEGORIES,
  TICKET_PRICE_CATEGORIES,
} from 'constants/constants';
import TicketCategory from 'model/entity/loko/type/TicketCategory';
import lokoApi from 'model/api/lokoApi';
import { useTranslation } from 'react-i18next';
import styles from './TicketingProgramTable.module.scss';

const TicketingProgramTable = (): ReactElement => {
  const { t } = useTranslation();
  const [ticketCategories, setTicketCategories] = useState<
    TicketCategory[] | null
  >([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data } = await lokoApi.ticketCategory.findAll({
          populate: '*',
          filters: {
            arena: {
              $eq: 'arena_2000',
            },
          },
        });
        setTicketCategories(data);
      } catch (e: any) {
        console.log(e?.message);
      }
    };

    fetchData();
  }, []);

  const render = (): ReactElement => {
    if (!ticketCategories)
      return (
        <DescriptionBlock>
          <span style={{ color: 'white' }}>
            {t('ticketingProgramPage.table.noDataMessage')}
          </span>
        </DescriptionBlock>
      );
    if (ticketCategories && ticketCategories.length > 0) {
      return (
        <>
          <LokoTable
            head={
              <>
                <tr>
                  <LokoTableHeadCell
                    rowSpan={3}
                    colSpan={1}
                    cellHeight={260}
                    isDark={true}
                  >
                    <span className={styles.LokoTableHeadCell}>
                      {t('ticketingProgramPage.table.arenaCategory')}
                    </span>
                  </LokoTableHeadCell>
                  <LokoTableHeadCell
                    rowSpan={1}
                    colSpan={8}
                    cellHeight={80}
                    isDark={true}
                  >
                    <span className={styles.LokoTableHeadCell}>
                      {t('ticketingProgramPage.table.matchCategory')}
                    </span>
                  </LokoTableHeadCell>
                </tr>
                <tr>
                  {TICKET_AGE_CATEGORIES.map((ageCategory: string) => (
                    <LokoTableHeadCell
                      rowSpan={1}
                      colSpan={4}
                      cellHeight={80}
                      isDark={true}
                      key={ageCategory}
                    >
                      <span className={styles.LokoTableHeadCell}>
                        {t('ticketingProgramPage.table.ticketAgeCategory', {
                          context: ageCategory,
                        })}
                      </span>
                    </LokoTableHeadCell>
                  ))}
                </tr>
                <tr>
                  {Array.from(TICKET_AGE_CATEGORIES).map(
                    (ageCategory: string) => (
                      <React.Fragment key={ageCategory}>
                        {Array.from(TICKET_PRICE_CATEGORIES.keys()).map(
                          (priceCategory: string) => (
                            <LokoTableHeadCell
                              rowSpan={1}
                              colSpan={1}
                              cellHeight={80}
                              isDark={true}
                              key={priceCategory}
                            >
                              <span className={styles.LokoTableHeadCell}>
                                {TICKET_PRICE_CATEGORIES.get(priceCategory)}
                              </span>
                            </LokoTableHeadCell>
                          )
                        )}
                      </React.Fragment>
                    )
                  )}
                </tr>
              </>
            }
            body={
              <>
                {ticketCategories.map((category: TicketCategory) => (
                  <LokoTableRow
                    data={[
                      category.attributes.arena_category.data.attributes.name,
                      ...[category.attributes.adult, category.attributes.child]
                        .map((ageCategory: TicketPrice | null) => [
                          ageCategory?.category_a_plus,
                          ageCategory?.category_a,
                          ageCategory?.category_b,
                          ageCategory?.category_c,
                        ])
                        .flat(),
                    ]}
                    key={category.id}
                  />
                ))}
              </>
            }
          />
        </>
      );
    }

    return <Spinner />;
  };

  return render();
};

export default TicketingProgramTable;
