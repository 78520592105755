import { Property } from "csstype";

interface ColumnProps {
  children: React.ReactNode;
  alignItems?: Property.AlignItems;
  justifyContent?: Property.JustifyContent;
  width?: string;
  height?: string;
  gap?: string;
}

const Column = ({
  children,
  alignItems,
  justifyContent,
  width,
  height,
  gap,
}: ColumnProps) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: alignItems,
        justifyContent: justifyContent,
        width: width,
        height: height,
        gap: gap,
      }}
    >
      {children}
    </div>
  );
};

export default Column;
