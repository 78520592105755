import { PropsWithChildren, ReactElement } from 'react';
import { useMediaQuery } from 'react-responsive';
import { Breakpoints } from 'constants/adaptivity';

interface ShowOnProps {
  largeDesktop?: boolean;
  smallDesktop?: boolean;
  largeTablet?: boolean;
  smallTablet?: boolean;
  largeMobile?: boolean;
  smallMobile?: boolean;
}

const ShowOn = ({
  children,
  largeDesktop,
  smallDesktop,
  largeTablet,
  smallTablet,
  largeMobile,
  smallMobile,
}: PropsWithChildren<ShowOnProps>): ReactElement => {
  const isLargeDesktop = useMediaQuery({
    minWidth: Breakpoints.largeDesktop + 1,
  });
  const isSmallDesktop = useMediaQuery({
    minWidth: Breakpoints.smallDesktop + 1,
    maxWidth: Breakpoints.largeDesktop,
  });
  const isLargeTablet = useMediaQuery({
    minWidth: Breakpoints.largeTablet + 1,
    maxWidth: Breakpoints.smallDesktop,
  });
  const isSmallTablet = useMediaQuery({
    minWidth: Breakpoints.smallTablet + 1,
    maxWidth: Breakpoints.largeTablet,
  });
  const isLargeMobile = useMediaQuery({
    minWidth: Breakpoints.largeMobile + 1,
    maxWidth: Breakpoints.smallTablet,
  });
  const isSmallMobile = useMediaQuery({
    maxWidth: Breakpoints.largeMobile,
  });

  const render = () => {
    if (isLargeDesktop && largeDesktop) return children;
    if (isSmallDesktop && smallDesktop) return children;
    if (isLargeTablet && largeTablet) return children;
    if (isSmallTablet && smallTablet) return children;
    if (isLargeMobile && largeMobile) return children;
    if (isSmallMobile && smallMobile) return children;
    return null;
  };

  return <>{render()}</>;
};

export default ShowOn;
