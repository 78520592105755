import { ReactElement } from 'react';
import styles from './SideMenu.module.scss';
import { observer } from 'mobx-react-lite';
import { useStore } from '../../../store/store';
import { SideMenuWidgets } from '../../../types/Types';
import SocialNetNewsCompactWidget from '../../HomePage/SocialNetNewsCompactWidget/SocialNetNewsCompactWidget';
import NewsWidget from '../NewsWidget/NewsWidget';
import LeadersWidget from '../../HomePage/LeadersWidget/LeadersWidget';
import LokoTVWidget from '../../HomePage/LokoTVWidget/LokoTVWidget';
import { useLocation } from 'react-router-dom';

const SideMenu = observer((): ReactElement => {
  const { sideMenuStore } = useStore();
  const route = useLocation().pathname;

  const onPartnerClick = (url: string) => {
    window.open(url);
  };

  const renderPartnersSection = () => {
    return (
      <div className={styles.partnerSection}>
        {sideMenuStore.sideMenuPartners.map((partner) => {
          return (
            partner.partner?.enabled &&
            (partner.partner?.partner_category?.data?.attributes?.enabled ||
              partner.partner?.partner_category?.data?.id !== 4) && (
              <div
                key={`${partner.id}_${partner.partner.name}`}
                className={styles.imageWrapper}
                onClick={() => onPartnerClick(partner.partner.url)}
              >
                <img src={partner.partner.logo.url} alt='' />
              </div>
            )
          );
        })}
      </div>
    );
  };

  const renderWidgetSection = () => {
    return (
      <div className={styles.partnerSection}>
        {sideMenuStore.sideMenuWidgets.map((widget) => {
          switch (widget.attributes.widget) {
            case SideMenuWidgets.Socials:
              return (
                <SocialNetNewsCompactWidget
                  key={'socials'}
                  isHomePage={false}
                />
              );
            case SideMenuWidgets.News:
              return route === '/news' ? (
                <div key={'news-default-element'}></div>
              ) : (
                <NewsWidget key={'news'} />
              );
            case SideMenuWidgets.Leaders:
              return <LeadersWidget columnStyle key={'leaders'} />;
            case SideMenuWidgets.LokoTV:
              return route === '/lokotv' ? (
                <div key={'news-default-element'}></div>
              ) : (
                <LokoTVWidget columnStyle key={'lokotv'} />
              );
            default:
              return <div key={'default-element'}></div>;
          }
        })}
      </div>
    );
  };

  return (
    <div className={styles.sideMenuWrapper}>
      {renderPartnersSection()}
      {renderWidgetSection()}
    </div>
  );
});

export default SideMenu;
