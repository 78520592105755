import { ReactElement, useEffect, useRef, useState } from 'react';
import {
  SortElement,
  StandingsReadableDto,
  StandingsTypes,
} from '../../../types/Types';
import styles from './StandingPageTableBody.module.scss';
import TableTh from './TableTh/TableTh';
import classNames from 'classnames';
import { customSort } from 'helpers/helpers';
import { defaultStandingSortArray } from 'constants/constants';
import { useStore } from 'store/store';
import { observer } from 'mobx-react-lite';
import ShowOn from 'components/core/adaptivity/ShowOn';
import { Tooltip } from 'antd';

type StandingsPageBodyProps = {
  data: Array<StandingsReadableDto>;
  isDivisions: Boolean;
  isConference?: Boolean;
};

const StandingsPageTableBody = observer(
  ({
    data,
    isDivisions,
    isConference = false,
  }: StandingsPageBodyProps): ReactElement => {
    const [standings, setStandings] = useState<Array<StandingsReadableDto>>([]);
    const [userSorting, setUserSorting] = useState<Array<SortElement>>([]);
    const tableBodyRef = useRef<HTMLTableSectionElement>(null);
    const { lokomotivTeamsStore } = useStore();
    const LokomotivID =
      lokomotivTeamsStore.lokomotivTeams?.men.data.attributes.id_league_web;

    useEffect(() => {
      setStandings([...data]);
    }, [data]);

    function updateSorting(key: string, type: StandingsTypes) {
      let newUserSorting = userSorting;
      const sortParam = newUserSorting.find(
        (sortParam) => sortParam.key === key
      );
      if (!sortParam) {
        newUserSorting.push({
          key: key,
          desc: true,
          type: type,
        });
      } else {
        if (sortParam.desc === true) {
          sortParam.desc = false;
        } else {
          newUserSorting = newUserSorting.filter(
            (newSortParam) => newSortParam.key != sortParam.key
          );
        }
      }

      const updatedValue = customSort(standings, [
        ...newUserSorting,
        ...defaultStandingSortArray,
      ]);

      setUserSorting(newUserSorting);
      setStandings([...updatedValue]);
    }

    return (
      <tbody ref={tableBodyRef}>
        <tr className={styles.tableHeader}>
          <TableTh
            text='#'
            title='Место'
            updateSorting={updateSorting}
            updateSortingParam={'rank'}
            type={StandingsTypes.number}
            tableBodyRef={tableBodyRef}
            index={1}
          />
          <TableTh
            text='Команда'
            title='Команда'
            updateSorting={updateSorting}
            updateSortingParam='name'
            width='100%'
            textAlign='left'
            paddingLeft='30px'
            type={StandingsTypes.string}
            tableBodyRef={tableBodyRef}
            index={2}
            isFirstTh={true}
          />
          <TableTh
            text='И'
            title='Количество проведённых игр'
            updateSorting={updateSorting}
            updateSortingParam='games_played'
            type={StandingsTypes.number}
            tableBodyRef={tableBodyRef}
            index={3}
          />
          <TableTh
            text='В'
            title='Выигрыши в основное время'
            updateSorting={updateSorting}
            updateSortingParam='wins'
            type={StandingsTypes.number}
            tableBodyRef={tableBodyRef}
            index={4}
          />
          <TableTh
            text='ВО'
            title='Выигрыши в овертайме'
            updateSorting={updateSorting}
            updateSortingParam='overtime_wins'
            type={StandingsTypes.number}
            tableBodyRef={tableBodyRef}
            index={5}
          />
          <TableTh
            text='ВБ'
            title='Выигрыши в послематчевых буллитах'
            updateSorting={updateSorting}
            updateSortingParam='shootouts_wins'
            type={StandingsTypes.number}
            tableBodyRef={tableBodyRef}
            index={6}
          />
          <TableTh
            text='ПБ'
            title='Проигрыши в послематчевых буллитах'
            updateSorting={updateSorting}
            updateSortingParam='shootouts_losses'
            type={StandingsTypes.number}
            tableBodyRef={tableBodyRef}
            index={7}
          />
          <TableTh
            text='ПO'
            title='Проигрыши в овертайме'
            updateSorting={updateSorting}
            updateSortingParam='overtime_losses'
            type={StandingsTypes.number}
            tableBodyRef={tableBodyRef}
            index={8}
          />
          <TableTh
            text='П'
            title='Проигрыши в основное время'
            updateSorting={updateSorting}
            updateSortingParam='losses'
            type={StandingsTypes.number}
            tableBodyRef={tableBodyRef}
            index={9}
          />
          <TableTh
            text='Ш'
            title='Шайбы'
            updateSorting={updateSorting}
            updateSortingParam='goals_for'
            width='12%'
            type={StandingsTypes.number}
            tableBodyRef={tableBodyRef}
            index={10}
          />
          <TableTh
            text='О'
            title='Количество набранных очков'
            updateSorting={updateSorting}
            updateSortingParam='points'
            type={StandingsTypes.number}
            tableBodyRef={tableBodyRef}
            index={11}
          />
          {standings[0]?.points_percent && (
            <TableTh
              text='%О'
              title='Процент очков'
              updateSorting={updateSorting}
              updateSortingParam='points_percent'
              type={StandingsTypes.number}
              tableBodyRef={tableBodyRef}
              index={12}
            />
          )}
        </tr>
        {standings.map((row: StandingsReadableDto, i) => {
          return (
            <tr
              key={row?.club_id}
              className={
                Number(row.club_id) === LokomotivID
                  ? classNames(styles.tableTr, styles.tableRed)
                  : i === 7 && isConference
                  ? classNames(styles.tableTr, styles.tableTrBottom)
                  : styles.tableTr
              }
            >
              <td>
                {!isDivisions
                  ? isConference
                    ? row?.conference_rank ?? i + 1 // для 2009 и 2010 нет conference_rank, но отображаются в правильном порядке
                    : row?.rank
                  : row?.division_rank}
              </td>
              <ShowOn largeDesktop smallDesktop largeTablet smallTablet>
                <td className={styles.club}>
                  <img src={row?.logo} alt={row?.name} height={'37.5px'} />
                  <div className={styles.clubInfo}>
                    <span
                      className={
                        Number(row.club_id) === LokomotivID
                          ? classNames(styles.clubName, styles.clubNameWhite)
                          : styles.clubName
                      }
                    >
                      {row?.name}
                    </span>
                    <span
                      className={
                        Number(row.club_id) === LokomotivID
                          ? classNames(styles.clubCity, styles.clubNameWhite)
                          : styles.clubCity
                      }
                    >
                      {row?.city}
                    </span>
                  </div>
                </td>
              </ShowOn>
              <ShowOn largeMobile smallMobile>
                <td className={styles.logoAlignCenter}>
                  <Tooltip placement='top' title={row?.name}>
                    <img src={row?.logo} alt={row?.name} height={'37.5px'} />
                  </Tooltip>
                </td>
              </ShowOn>
              <td>{row?.games_played}</td>
              <td>{row?.wins}</td>
              <td>{row?.overtime_wins}</td>
              <td>{row.shootouts_wins}</td>
              <td>{row?.shootouts_losses}</td>
              <td>{row?.overtime_losses}</td>
              <td>{row?.losses}</td>
              <td className={styles.nowrap}>
                {row?.goals_for} : {row?.goals_against}
              </td>
              <td>{row?.points}</td>
              {standings[0]?.points_percent && <td>{row?.points_percent}</td>}
            </tr>
          );
        })}
      </tbody>
    );
  }
);

export default StandingsPageTableBody;
