import { ReactElement, useEffect, useState } from 'react';
import styles from './PenaltyTable.module.scss';
import {
  GameProtocolDto,
  GameWithLogo,
  PenaltyDto,
  PenaltyKind,
} from '../../../../../types/Types';
import { baseAlphaNumericCompare } from '../../../../../helpers/helpers';
import playIcon from '../../../../../assets/penalty-play-icon.svg';
import { Tooltip } from 'antd';
import { getFoulsList } from 'api/api';

type PenaltyData = {
  preiod: string;
  time: string;
  duration: string;
  code: string;
  reason: string;
  playerNumber: string;
  playerName: string;
  team: {
    khlId: string;
    name: string;
    logo: string;
  };
};

type PenaltyTableProps = {
  protocol: GameProtocolDto;
  game: GameWithLogo;
};

function PenaltyTable({ protocol, game }: PenaltyTableProps): ReactElement {
  const [penaltyKinds, setPenaltyKinds] = useState<PenaltyKind[]>();
  const [penalties, setPenalties] = useState<PenaltyData[]>();
  const [totalOvertimes, setTotalOvertimes] = useState<number>(0);

  useEffect(() => {
    const isShootouts = !!protocol?.GameSummary?.Shootout;

    if (isShootouts) {
      setTotalOvertimes(1);
    } else {
      const penaltiesPeriods = penalties
        ? Array.from(
            new Set(penalties.map((penalty) => parseInt(penalty.preiod)))
          )
        : [];

      const khlGoals = Array.isArray(protocol.GameSummary.Goals.Goal)
        ? protocol.GameSummary.Goals.Goal
        : [protocol.GameSummary.Goals.Goal];
      const goalsPeriods = Array.from(
        new Set(khlGoals.map((goal) => parseInt(goal['@_per'])))
      );

      const totalPeriods = Array.from(
        new Set([...penaltiesPeriods, ...goalsPeriods])
      ).sort((a, b) => a - b);

      setTotalOvertimes(totalPeriods[totalPeriods.length - 1] - 3);
    }
  }, [protocol, penalties]);

  useEffect(() => {
    const getData = async () => {
      try {
        const resp = await getFoulsList();
        setPenaltyKinds(resp?.data);
      } catch (error: any) {
        console.error(error?.response?.data?.error || error?.message);
      }
    };
    getData();
  }, []);

  useEffect(() => {
    const teams = {
      [protocol.GameSummary['@_homeId']]: {
        khlId: protocol.GameSummary['@_teama'],
        name: game.attributes.team_1.data.attributes.name,
        logo: game.attributes.team_1.data.attributes.logo.data.attributes.url,
      },
      [protocol.GameSummary['@_visitorId']]: {
        khlId: protocol.GameSummary['@_teamb'],
        name: game.attributes.team_2.data.attributes.name,
        logo: game.attributes.team_2.data.attributes.logo.data.attributes.url,
      },
    };

    const penaltiesData = protocol.GameSummary.Penalties.length
      ? protocol.GameSummary.Penalties.reduce((result, item, index) => {
          if (!item.Penalty) {
            return result;
          }

          if (Array.isArray(item.Penalty)) {
            const penalties = item.Penalty.reduce((res, obj) => {
              if (obj['@_pnum'] === '0') {
                res.push({
                  preiod: obj['@_per'],
                  time: obj['@_tbeg'],
                  duration: obj['@_time'],
                  code: obj['@_code'],
                  reason: getPenaltyReason(obj['@_code']),
                  playerNumber: obj['@_pnum'],
                  playerName: 'Командный штраф',
                  team: Object.values(teams).find(
                    (t) => t.khlId === item['@_teamId']?.toString()
                  ),
                } as PenaltyData);
              } else {
                const player = protocol.GameSummary?.PlayerStatsList[
                  index
                ].PlayerStats.find(
                  (p) => p['@_jn']?.toString() === obj['@_pnum']
                );

                if (player && player['@_clubidt']) {
                  res.push({
                    preiod: obj['@_per'],
                    time: obj['@_tbeg'],
                    duration: obj['@_time'],
                    code: obj['@_code'],
                    reason: getPenaltyReason(obj['@_code']),
                    playerNumber: obj['@_pnum'],
                    playerName: player['@_lastname'],
                    team: teams[player['@_clubidt']?.toString()],
                  } as PenaltyData);
                }
              }

              return res;
            }, [] as PenaltyData[]);

            result.push(...penalties);
          } else {
            const obj = item.Penalty as PenaltyDto;

            if (obj['@_pnum'] === '0') {
              result.push({
                preiod: obj['@_per'],
                time: obj['@_tbeg'],
                duration: obj['@_time'],
                code: obj['@_code'],
                reason: getPenaltyReason(obj['@_code']),
                playerNumber: obj['@_pnum'],
                playerName: 'Командный штраф',
                team: Object.values(teams).find(
                  (t) => t.khlId === item['@_teamId']?.toString()
                ),
              } as PenaltyData);
            } else {
              const player = protocol.GameSummary?.PlayerStatsList[
                index
              ].PlayerStats.find(
                (p) => p['@_jn']?.toString() === obj['@_pnum']
              );

              if (player && player['@_clubidt']) {
                result.push({
                  preiod: obj['@_per'],
                  time: obj['@_tbeg'],
                  duration: obj['@_time'],
                  code: obj['@_code'],
                  reason: getPenaltyReason(obj['@_code']),
                  playerNumber: obj['@_pnum'],
                  playerName: player['@_lastname'],
                  team: teams[player['@_clubidt']?.toString()],
                } as PenaltyData);
              }
            }
          }

          return result;
        }, [] as PenaltyData[])
      : [];

    const sortedData = penaltiesData?.sort((a, b) =>
      baseAlphaNumericCompare(a.time, b.time)
    );
    setPenalties(sortedData);
  }, [game, protocol, penaltyKinds]);

  const getPenaltyReason = (code: string): string => {
    if (!penaltyKinds) {
      return '';
    }

    const penaltyObject = penaltyKinds?.find(
      (p) => p?.attributes?.code?.toString() == code
    );

    return penaltyObject?.attributes?.ru || '';
  };

  const renderPeriodTable = (period: number) => {
    const periodPenalties = penalties
      ? penalties.filter((item) => item.preiod === period.toString())
      : [];

    if (!periodPenalties?.length) {
      return null;
    }

    return (
      <table className={styles.table} key={period}>
        <tbody className={styles.tableBody}>
          <tr className={styles.tableHeader}>
            <th title={'Период'}>
              {period >= 4
                ? totalOvertimes > 1
                  ? `ОТ${period - 3}`
                  : 'ОТ'
                : `${period}-й`}
            </th>
            <th title={'Команда'}>Команда</th>
            <th title={'Удаления'} className={styles.deletions}>
              Удаления
            </th>
            <th></th>
          </tr>
          {periodPenalties.map((item, index) => (
            <tr className={styles.tableRow} key={index}>
              <td>
                <div className={styles.time}>
                  <img className={styles.playIcon} src={playIcon} alt='' />
                  <div>{item.time}</div>
                </div>
              </td>
              <td>
                <img className={styles.image} src={item.team.logo} alt='' />
              </td>
              <td>
                <div className={styles.nameAndReason}>
                  <div className={styles.name}>{item.playerName}</div>
                  <Tooltip placement='top' title={item.reason}>
                    <div className={styles.reason}>{item.reason}</div>
                  </Tooltip>
                </div>
              </td>
              <td>
                <div className={styles.duration}>{`(${item.duration})`}</div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  };

  const render = () => {
    if (penalties) {
      const periods = Array.from(
        new Set(penalties.map((penalty) => parseInt(penalty.preiod)))
      ).sort((a, b) => a - b);

      return periods.map((item) => renderPeriodTable(item));
    }

    return [];
  };

  return (
    <div>
      <div className={styles.header}>Штрафы</div>
      {render()}
    </div>
  );
}

export default PenaltyTable;
