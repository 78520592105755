import { ReactElement } from 'react';
import styles from './MainNews.module.scss';
import { ReactComponent as Arrow } from 'assets/news_arrow.svg';
import { getDateInfo } from '../../../helpers/helpers';
import ImageLoader from '../../common/ImageLoaders/ImageLoader/ImageLoader';
import Article from 'model/entity/loko/type/Article';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

type MainNewsProps = {
  article: Article;
};

function MainNews({ article }: MainNewsProps): ReactElement {
  const { t } = useTranslation();
  return (
    <div className={styles.newsInfo}>
      <div className={styles.newsInfoFlex}>
        <div className={styles.newsInfoColumn} id={'main-news-column'}>
          <div className={styles.date}>
            {getDateInfo(article) || ''}
            {article?.attributes.author ? (
              <span> — {article?.attributes.author}</span>
            ) : (
              <></>
            )}
          </div>
          <div className={styles.title}>{article?.attributes.title}</div>
          <Link
            className={styles.link}
            to={
              article?.attributes.external_link
                ? article.attributes.external_link
                : `/article/${article?.id}`
            }
            target={article?.attributes.external_link ? '_blank' : ''}
            rel='noopener noreferrer'
          >
            <span className={styles.linkText}>
              {t('homepage.mainNewsForward')}
            </span>
            <div className={styles.arrow}>
              <Arrow className={styles.arrowIcon} />
            </div>
          </Link>
        </div>
      </div>
      <div className={styles.imgWrapper} id={'main-image'}>
        <ImageLoader
          image={article?.attributes.preview_image?.data}
          className={styles.img}
        />
      </div>
    </div>
  );
}

export default MainNews;
