import { ReactElement } from 'react';
import { PlayerSeasonStats, Season } from '../../../types/Types';
import styles from './Goalkeeper.module.scss';
import { getYears } from 'helpers/helpers';

type GoalkeeperTableProps = {
  seasonParam: string | null;
  season: Season | undefined;
  playerStats: PlayerSeasonStats | undefined;
};

function GoalkeeperTable({
  seasonParam,
  season,
  playerStats,
}: GoalkeeperTableProps): ReactElement {
  return (
    <tr className={styles.tableTr}>
      <td className={styles.firstTd}>
        <div className={styles.firstTd}>
          <span>{season?.attributes.years_interval}</span>
          <span className={styles.season}>
            {
              // TODO: add season name to getYears(seasonParam || '') + 3rd fallback if both absent
              season?.attributes.name || getYears(seasonParam || '')
            }
          </span>
          <span className={styles.season}>{playerStats?.teamname}</span>
        </div>
      </td>
      <td>{playerStats?.gamesPlayed}</td>
      <td>{playerStats?.wins}</td>
      <td>{playerStats?.lose}</td>
      <td>{playerStats?.shootoutSeries}</td>
      <td>{playerStats?.shotsOnGoal}</td>
      <td>{playerStats?.goalsAllowed}</td>
      <td>{playerStats?.saves}</td>
      <td>{playerStats?.savesPercent}</td>
      <td>{playerStats?.goalAgainstAverage}</td>
      <td>{playerStats?.goals}</td>
      <td>{playerStats?.assists}</td>
      <td>{playerStats?.shutouts}</td>
      <td>{playerStats?.penaltyInMinutes}</td>
      <td>{playerStats?.timeOnIce}</td>
    </tr>
  );
}

export default GoalkeeperTable;
