import { ReactElement, useEffect, useState } from 'react';
import { MenuElementDto } from '../../types/Types';
import { useStore } from '../../store/store';
import ShowOn from '../core/adaptivity/ShowOn';
import { observer } from 'mobx-react-lite';
import DesktopHeader from './DesktopHeader/DesktopHeader';
import styles from './Header.module.scss';
import MobileHeader from './MobileHeader/MobileHeader';
import ErrorWidget from 'components/common/ErrorWidget/ErrorWidget';

function Header(): ReactElement {
  const { menuStore } = useStore();
  const allMenu = menuStore.menu;

  return allMenu ? (
    <header className={styles.header}>
      <nav>
        <ShowOn largeDesktop smallDesktop>
          <DesktopHeader allMenu={allMenu} />
        </ShowOn>
        <ShowOn largeTablet smallTablet largeMobile smallMobile>
          <MobileHeader allMenu={allMenu} />
        </ShowOn>
      </nav>
    </header>
  ) : (
    <ErrorWidget.Error />
  );
}

export default observer(Header);
