import classNames from "classnames";
import background from "../../assets/logo.png";
import styles from "./Spinner.module.scss";

type SpinnerProps = {
  className?: string;
};

function Spinner({ className }: SpinnerProps) {
  return (
    <div className={classNames(styles.center, className)}>
      <div
        className={styles.loader}
        style={{ backgroundImage: `url(${background})` }}
      />
    </div>
  );
}

export default Spinner;
