import React, { PropsWithChildren, useEffect, useState } from 'react';

interface ScalableDivProps {
  onScaleUpdate?: (scale: number) => void;
}

const ScalableDiv = ({
  children,
  onScaleUpdate,
}: PropsWithChildren<ScalableDivProps>) => {
  const [scale, setScale] = useState(1);
  const [isDragging, setIsDragging] = useState(false);
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [startDistance, setStartDistance] = useState<number | null>(null);
  const [startPosition, setStartPosition] = useState({ x: 0, y: 0 });
  const [origin, setOrigin] = useState({ x: 0, y: 0 });
  const maxScale = 5; // Максимальное увеличение

  useEffect(() => {
    if (onScaleUpdate) onScaleUpdate(scale);
  }, [onScaleUpdate, scale]);

  const getDistance = (touch1: React.Touch, touch2: React.Touch) => {
    return Math.sqrt(
      Math.pow(touch2.clientX - touch1.clientX, 2) +
        Math.pow(touch2.clientY - touch1.clientY, 2)
    );
  };

  const getMidpoint = (touch1: React.Touch, touch2: React.Touch) => {
    return {
      x: (touch1.clientX + touch2.clientX) / 2,
      y: (touch1.clientY + touch2.clientY) / 2,
    };
  };

  const handleTouchStart = (e: React.TouchEvent<HTMLDivElement>) => {
    if (e.touches.length === 1) {
      // Для однопальцевого касания начинаем перемещение
      const touch = e.touches[0];
      setStartPosition({
        x: touch.clientX - position.x,
        y: touch.clientY - position.y,
      });
      setIsDragging(true);
    } else if (e.touches.length === 2) {
      // Для многопальцевого касания начинаем масштабирование
      e.preventDefault();
      setStartDistance(getDistance(e.touches[0], e.touches[1]));
      const midpoint = getMidpoint(e.touches[0], e.touches[1]);
      setOrigin(midpoint);
    }
  };

  const handleTouchMove = (e: React.TouchEvent<HTMLDivElement>) => {
    if (isDragging) {
      if (e.touches.length === 1) {
        // Обработка перемещения одним пальцем
        const touch = e.touches[0];
        setPosition({
          x: touch.clientX - startPosition.x,
          y: touch.clientY - startPosition.y,
        });
      } else if (e.touches.length === 2) {
        // Обработка масштабирования двумя пальцами
        e.preventDefault();
        const currentDistance = getDistance(e.touches[0], e.touches[1]);
        if (startDistance) {
          const scaleChange = currentDistance / startDistance;
          const newScale = Math.min(maxScale, Math.max(1, scale * scaleChange));
          setScale(newScale);

          const midpoint = getMidpoint(e.touches[0], e.touches[1]);
          const translateX =
            position.x + (midpoint.x - origin.x) * (1 - scaleChange);
          const translateY =
            position.y + (midpoint.y - origin.y) * (1 - scaleChange);
          setPosition({ x: translateX, y: translateY });

          setStartDistance(currentDistance);
        }
      }
    }
  };

  const handleTouchEnd = () => {
    setIsDragging(false);
    setStartDistance(null);
  };

  const handleWheel = (e: React.WheelEvent<HTMLDivElement>) => {
    e.preventDefault();
    const newScale = scale + e.deltaY * -0.01;
    setScale(Math.min(maxScale, Math.max(1, newScale)));
  };

  return (
    <div
      style={{
        width: '100%',
        overflow: 'hidden',
        touchAction: 'none', // Отключить стандартное поведение мультитача
      }}
      onWheel={handleWheel}
      onTouchStart={handleTouchStart}
      onTouchMove={handleTouchMove}
      onTouchEnd={handleTouchEnd}
    >
      <div
        style={{
          transform: `scale(${scale}) translate(${position.x}px, ${position.y}px)`,
          transformOrigin: `${origin.x}px ${origin.y}px`,
          width: '100%',
          height: '100%',
          position: 'relative',
        }}
      >
        {children}
      </div>
    </div>
  );
};

export default ScalableDiv;
