import { ReactElement, useCallback, useEffect, useState } from 'react';
import styles from './PaginationBlock.module.scss';
import classNames from 'classnames';
import { PaginationBreakpoints } from 'constants/adaptivity';

type PaginationBlockProps = {
  countPages: number;
  currentPage: number;
  onPageChange: (pageNumber: number) => void;
};

type PaginationElement = {
  key: number;
  value: string;
};

const PaginationBlock = ({
  countPages,
  currentPage,
  onPageChange,
}: PaginationBlockProps): ReactElement => {
  const [pages, setPages] = useState<PaginationElement[]>([]);

  const generateArray = useCallback(() => {
    const map = new Map();
    const divider =
      window.innerWidth < PaginationBreakpoints.smallTablet
        ? window.innerWidth < PaginationBreakpoints.largeMobile
          ? 0
          : 1
        : 2;

    Array.from(Array(countPages), (_, i) => {
      if (
        Math.abs(i + 1 - currentPage) <= divider ||
        i === 0 ||
        i === countPages - 1
      ) {
        map.set(i + 1, i + 1);
      }
      if (Math.abs(i + 1 - currentPage) === divider + 1) {
        map.set(i + 1, '...');
      }
    });
    setPages(Array.from(map, ([key, value]) => ({ key, value })));
  }, [countPages, currentPage]);

  useEffect(() => {
    generateArray();

    window.addEventListener('resize', generateArray);

    return () => {
      window.removeEventListener('resize', generateArray);
    };
  }, [generateArray]);

  const onClick = (pageNumber: number) => {
    window.scrollTo({
      top: 0,
    });

    onPageChange(pageNumber);
  };

  return (
    <div className={styles.pagination}>
      {pages.map((page) => (
        <div
          key={page.key}
          onClick={() => onClick(page.key)}
          className={classNames(styles.pageNumber, {
            [styles.active]: page.key === currentPage,
          })}
        >
          {page.value}
        </div>
      ))}
    </div>
  );
};

export default PaginationBlock;
