import { useEffect, useState, useRef } from 'react';
import { observer } from 'mobx-react-lite';
import {
  PlayerSeasonStatsDtoReadable,
  SortElement,
  StandingsTypes,
} from 'types/Types';
import TableTh from '../../../../../StatsLokomotivPage/TableTh/TableTh';
import styles from './PlayerStatsTable.module.scss';
import {
  defaultForwardSortArray,
  defaultGoalKeeperSortArray,
} from 'constants/constants';
import {
  customSort,
  getNameSurname,
  getNameSurnameForProtocol,
} from 'helpers/helpers';
import classNames from 'classnames';

type PlayerStatsTableProps = {
  playerStats: Array<PlayerSeasonStatsDtoReadable>;
  role: string;
};

const PlayerStatsTable = observer(
  ({ playerStats, role }: PlayerStatsTableProps) => {
    const [players, setPlayers] =
      useState<PlayerSeasonStatsDtoReadable[]>(playerStats);
    const [userSorting, setUserSorting] = useState<Array<SortElement>>([]);
    const tableBodyRef = useRef<HTMLTableSectionElement>(null);

    useEffect(() => {
      const defaultSortArray =
        role === 'Вратари'
          ? defaultGoalKeeperSortArray
          : defaultForwardSortArray;

      setPlayers(customSort(playerStats, defaultSortArray));
    }, [playerStats]);

    const updateSorting = (
      key: string,
      isGoalKeeper: boolean,
      type: StandingsTypes
    ) => {
      let newUserSorting = userSorting;
      const sortParam = newUserSorting.find(
        (sortParam) => sortParam.key === key
      );
      if (!sortParam) {
        newUserSorting.push({
          key: key,
          desc: true,
          type: type,
        });
      } else {
        if (sortParam.desc === true) {
          sortParam.desc = false;
        } else {
          newUserSorting = newUserSorting.filter(
            (newSortParam) => newSortParam.key !== sortParam.key
          );
        }
      }

      const defaultSortArray = isGoalKeeper
        ? defaultGoalKeeperSortArray
        : defaultForwardSortArray;
      const updatedValue = customSort(players, [
        ...newUserSorting,
        ...defaultSortArray,
      ]);
      setUserSorting(newUserSorting);
      setPlayers([...updatedValue]);
    };

    const redirectToPlayer = (id?: number): void => {
      if (id) {
        window.open(`/player/${id}`);
      }
    };

    const renderPlayersTable = () => {
      return (
        <table>
          <tbody ref={tableBodyRef}>
            <tr className={styles.tableHeader}>
              <TableTh
                text='#'
                title='Игровой номер'
                tableBodyRef={tableBodyRef}
                updateSorting={updateSorting}
                updateSortingParam='number'
                index={1}
                type={StandingsTypes.number}
              />
              <TableTh
                text={role}
                title='Роль'
                tableBodyRef={tableBodyRef}
                updateSorting={updateSorting}
                updateSortingParam='namelocal'
                textAlign='left'
                index={2}
                isFirstTh={true}
                isWhite={false}
                isProtocolPage={true}
                type={StandingsTypes.string}
              />
              <TableTh
                text='Ш'
                title='Заброшенные шайбы'
                tableBodyRef={tableBodyRef}
                updateSorting={updateSorting}
                updateSortingParam='goals'
                index={3}
                isProtocolPage={true}
                type={StandingsTypes.number}
              />
              <TableTh
                text='П'
                title='Передачи'
                tableBodyRef={tableBodyRef}
                updateSorting={updateSorting}
                updateSortingParam='assists'
                index={4}
                isProtocolPage={true}
                type={StandingsTypes.number}
              />
              <TableTh
                text='О'
                title='Очки'
                tableBodyRef={tableBodyRef}
                updateSorting={updateSorting}
                updateSortingParam='points'
                index={5}
                type={StandingsTypes.number}
                isProtocolPage={true}
              />
              <TableTh
                text='+/-'
                title='Плюс/Минус'
                tableBodyRef={tableBodyRef}
                updateSorting={updateSorting}
                updateSortingParam='plus_minus'
                index={6}
                type={StandingsTypes.number}
                isProtocolPage={true}
              />
              <TableTh
                text='Штр'
                title='Штрафное время'
                tableBodyRef={tableBodyRef}
                updateSorting={updateSorting}
                updateSortingParam='penalty_in_minutes'
                index={7}
                type={StandingsTypes.number}
                isProtocolPage={true}
              />
              <TableTh
                text='ШБ'
                title='Шайбы в большинстве'
                tableBodyRef={tableBodyRef}
                updateSorting={updateSorting}
                updateSortingParam='power_play_goals'
                index={8}
                type={StandingsTypes.number}
                isProtocolPage={true}
              />
              <TableTh
                text='ШМ'
                title='Шайбы в меньшинстве'
                tableBodyRef={tableBodyRef}
                updateSorting={updateSorting}
                updateSortingParam='shorthanded_goals'
                index={9}
                type={StandingsTypes.number}
                isProtocolPage={true}
              />
              <TableTh
                text='ШО'
                title='Шайбы в овертайме'
                tableBodyRef={tableBodyRef}
                updateSorting={updateSorting}
                updateSortingParam='overtime_goals'
                index={10}
                type={StandingsTypes.number}
                isProtocolPage={true}
              />
              <TableTh
                text='БВ'
                title='Броски по воротам'
                tableBodyRef={tableBodyRef}
                updateSorting={updateSorting}
                updateSortingParam='shots_on_goal'
                index={11}
                type={StandingsTypes.number}
                isProtocolPage={true}
              />
              <TableTh
                text='%БВ'
                title='Процент реализованных бросков'
                tableBodyRef={tableBodyRef}
                updateSorting={updateSorting}
                updateSortingParam='shots_on_goal_percent'
                index={12}
                type={StandingsTypes.number}
                isProtocolPage={true}
              />
              <TableTh
                text='%Вбр'
                title='Процент выигранных вбрасываний'
                tableBodyRef={tableBodyRef}
                updateSorting={updateSorting}
                updateSortingParam='faceoffs_wons_percent'
                index={13}
                type={StandingsTypes.number}
                isProtocolPage={true}
              />
              <TableTh
                text='ВП/И'
                title='Среднее время на площадке за игру'
                tableBodyRef={tableBodyRef}
                updateSorting={updateSorting}
                updateSortingParam='time_on_ice_average'
                index={14}
                type={StandingsTypes.time}
                isProtocolPage={true}
              />
            </tr>
            {players.map((rosterDtoWithStats) => (
              <tr className={styles.tableTr} key={rosterDtoWithStats.id}>
                <td>
                  <span className={styles.number}>
                    {rosterDtoWithStats.number}
                  </span>
                </td>
                <td className={styles.club}>
                  <div
                    className={classNames(styles.playerInfo, {
                      [styles.pointer]: !!rosterDtoWithStats.idlocal,
                    })}
                    onClick={() => redirectToPlayer(rosterDtoWithStats.idlocal)}
                  >
                    <div>
                      <span className={styles.name}>
                        {getNameSurnameForProtocol(
                          getNameSurname(rosterDtoWithStats)
                        )}
                      </span>
                    </div>
                  </div>
                </td>
                <td>{rosterDtoWithStats.goals}</td>
                <td>{rosterDtoWithStats.assists}</td>
                <td>{rosterDtoWithStats.points}</td>
                <td>{rosterDtoWithStats.plus_minus}</td>
                <td>{rosterDtoWithStats.penalty_in_minutes}</td>
                <td>{rosterDtoWithStats.power_play_goals}</td>
                <td>{rosterDtoWithStats.shorthanded_goals}</td>
                <td>{rosterDtoWithStats.overtime_goals}</td>
                <td>{rosterDtoWithStats.shots_on_goal}</td>
                <td>{rosterDtoWithStats.shots_on_goal_percent}</td>
                <td>{rosterDtoWithStats.faceoffs_wons_percent}</td>
                <td>{rosterDtoWithStats.time_on_ice_average}</td>
              </tr>
            ))}
          </tbody>
        </table>
      );
    };

    const renderGoalkeepersTable = () => {
      return (
        <table>
          <tbody ref={tableBodyRef}>
            <tr className={styles.tableHeader}>
              <TableTh
                text='#'
                title='Игровой номер'
                tableBodyRef={tableBodyRef}
                updateSorting={updateSorting}
                updateSortingParam='number'
                index={1}
                type={StandingsTypes.number}
                isProtocolPage={true}
              />
              <TableTh
                text={role}
                title='Роль'
                tableBodyRef={tableBodyRef}
                updateSorting={updateSorting}
                updateSortingParam='namelocal'
                textAlign='left'
                index={2}
                additionalClasses={styles.role}
                isFirstTh={true}
                isWhite={false}
                type={StandingsTypes.string}
                isProtocolPage={true}
              />
              <TableTh
                text='КН'
                title='Коэффициент надёжности = 60мин*ПШ/ВП'
                tableBodyRef={tableBodyRef}
                updateSorting={updateSorting}
                updateSortingParam='goals_against_average'
                index={3}
                type={StandingsTypes.number}
                isProtocolPage={true}
              />
              <TableTh
                text='Бр'
                title='Броски'
                tableBodyRef={tableBodyRef}
                updateSorting={updateSorting}
                updateSortingParam='shots_on_goal'
                index={4}
                type={StandingsTypes.number}
                isProtocolPage={true}
              />
              <TableTh
                text='ПШ'
                title='Пропущено шайб'
                tableBodyRef={tableBodyRef}
                updateSorting={updateSorting}
                updateSortingParam='goals_against'
                index={5}
                type={StandingsTypes.number}
                isProtocolPage={true}
              />
              <TableTh
                text='ОБ'
                title='Отражённые броски'
                tableBodyRef={tableBodyRef}
                updateSorting={updateSorting}
                updateSortingParam='savings'
                index={6}
                type={StandingsTypes.number}
                isProtocolPage={true}
              />
              <TableTh
                text='%ОБ'
                title='Процент отражённых бросков'
                tableBodyRef={tableBodyRef}
                updateSorting={updateSorting}
                updateSortingParam='savings_percent'
                index={7}
                type={StandingsTypes.number}
                isProtocolPage={true}
              />
              <TableTh
                text='Ш'
                title='Заброшенные шайбы'
                tableBodyRef={tableBodyRef}
                updateSorting={updateSorting}
                updateSortingParam='goals'
                index={8}
                type={StandingsTypes.number}
                isProtocolPage={true}
              />
              <TableTh
                text='A'
                title='Голевые передачи'
                tableBodyRef={tableBodyRef}
                updateSorting={updateSorting}
                updateSortingParam='assists'
                index={9}
                type={StandingsTypes.number}
                isProtocolPage={true}
              />
              <TableTh
                text="И'0'"
                title='Сухие игры'
                tableBodyRef={tableBodyRef}
                updateSorting={updateSorting}
                updateSortingParam='shootouts'
                index={10}
                type={StandingsTypes.number}
                isProtocolPage={true}
              />
              <TableTh
                text='ШТР'
                title='Штрафное время'
                tableBodyRef={tableBodyRef}
                updateSorting={updateSorting}
                updateSortingParam='penalty_in_minutes'
                index={11}
                type={StandingsTypes.number}
                isProtocolPage={true}
              />
            </tr>
            {players.map((rosterDtoWithStats) => (
              <tr className={styles.tableTr} key={rosterDtoWithStats.id}>
                <td>
                  <span className={styles.number}>
                    {rosterDtoWithStats.number}
                  </span>
                </td>
                <td className={styles.club}>
                  <div className={styles.playerInfo}>
                    <div>
                      <span className={styles.name}>
                        {getNameSurnameForProtocol(
                          getNameSurname(rosterDtoWithStats)
                        )}
                      </span>
                    </div>
                  </div>
                </td>
                <td>{rosterDtoWithStats.goals_against_average || '-'}</td>
                <td>{rosterDtoWithStats.shots_on_goal}</td>
                <td>{rosterDtoWithStats.goals_against}</td>
                <td>{rosterDtoWithStats.savings}</td>
                <td>{rosterDtoWithStats.savings_percent}</td>
                <td>{rosterDtoWithStats.goals}</td>
                <td>{rosterDtoWithStats.assists}</td>
                <td>{rosterDtoWithStats.shootouts}</td>
                <td>{rosterDtoWithStats.penalty_in_minutes}</td>
              </tr>
            ))}
          </tbody>
        </table>
      );
    };

    return role === 'Вратари' ? renderGoalkeepersTable() : renderPlayersTable();
  }
);

export default PlayerStatsTable;
