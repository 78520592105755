import { ReactElement } from "react";
import styles from "./LokoTable.module.scss";

interface LokoTableProps {
  head: React.ReactNode;
  body: React.ReactNode;
}

export default function LokoTable({
  head,
  body,
}: LokoTableProps): ReactElement {
  return (
    <div className={styles.tableWrapper}>
      <table className={styles.table}>
        <thead>{head}</thead>
        <tbody>{body}</tbody>
      </table>
    </div>
  );
}
