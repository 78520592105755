import { useState } from 'react';
import { Modal, notification } from 'antd';
import styles from './RegistrationForm.module.scss';
import { sendAwayGameForm } from 'api/api';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

type RegistrationFormProps = {
  isVisible: boolean;
  close: () => void;
};

const enum RegistrationFormWay {
  onYourOwn = 'Своим ходом',
  byBus = 'На автобусе с Локомотивом',
}

type RegistrationFormData = {
  name: string;
  phone: string;
  date: string;
  ticketAmount: number;
  city: string;
  rival: string;
  email: string;
  way: RegistrationFormWay;
};

const RegistrationForm = ({ isVisible, close }: RegistrationFormProps) => {
  const { t } = useTranslation();
  const [way, setWay] = useState(RegistrationFormWay.onYourOwn);
  const [formData, setFormData] = useState<RegistrationFormData>({
    name: '',
    phone: '',
    date: '',
    ticketAmount: 0,
    city: '',
    rival: '',
    email: '',
    way: way,
  });

  const handleRadioButtonClick = (way: RegistrationFormWay) => {
    setWay(way);
    setFormData({
      ...formData,
      way: way,
    });
  };

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    try {
      await sendAwayGameForm({ formData });
      close();
      notification.success({
        message: t('awayMatchesPage.registrationForm.successMessage'),
        placement: 'bottomLeft',
        duration: 15,
      });
    } catch (error: any) {
      notification.error({
        message: error.message,
        placement: 'bottomLeft',
        duration: 15,
      });
    }
  };

  return (
    <Modal
      open={isVisible}
      onCancel={close}
      closable={false}
      className={styles.modal}
      footer={null}
      centered
    >
      <div className={styles.modalContent}>
        <h2 className={styles.title}>
          {t('awayMatchesPage.registrationForm.title')}
        </h2>
        <form onSubmit={handleSubmit}>
          <label className={styles.label}>
            {t('awayMatchesPage.registrationForm.formFields.name')}
          </label>
          <input
            className={styles.input}
            type='text'
            name='name'
            value={formData.name}
            onChange={handleChange}
            required
          />
          <label className={styles.label}>
            {t('awayMatchesPage.registrationForm.formFields.email')}
          </label>
          <input
            className={styles.input}
            type='email'
            name='email'
            value={formData.email}
            onChange={handleChange}
            required
          />
          <label className={styles.label}>
            {t('awayMatchesPage.registrationForm.formFields.phone')}
          </label>
          <input
            className={styles.input}
            type='tel'
            name='phone'
            value={formData.phone}
            onChange={handleChange}
            required
          />

          <div className={styles.row}>
            <div>
              <label className={styles.label}>
                {t('awayMatchesPage.registrationForm.formFields.date')}
              </label>
              <input
                className={styles.input}
                type='date'
                name='date'
                value={formData.date}
                onChange={handleChange}
                required
              />
            </div>
            <div>
              <label className={styles.label}>
                {t('awayMatchesPage.registrationForm.formFields.ticketsCount')}
              </label>
              <input
                className={styles.input}
                type='number'
                name='ticketAmount'
                value={formData.ticketAmount}
                onChange={handleChange}
                required
              />
            </div>
          </div>
          <label className={styles.label}>
            {t('awayMatchesPage.registrationForm.formFields.city')}
          </label>
          <input
            className={styles.input}
            type='text'
            name='city'
            value={formData.city}
            onChange={handleChange}
            required
          />
          <label className={styles.label}>
            {t(
              'awayMatchesPage.registrationForm.formFields.howDoYouPreferToGetThere'
            )}
          </label>
          <div className={styles.checkboxes}>
            <div
              className={styles.checkboxContainer}
              onClick={() =>
                handleRadioButtonClick(RegistrationFormWay.onYourOwn)
              }
            >
              <div
                className={classNames(styles.checkbox, {
                  [styles.checked]: way === RegistrationFormWay.onYourOwn,
                })}
              />
              <span className={styles.checkboxLabel}>
                {t('awayMatchesPage.registrationForm.formFields.onYourOwn')}
              </span>
            </div>
            <div
              className={styles.checkboxContainer}
              onClick={() => handleRadioButtonClick(RegistrationFormWay.byBus)}
            >
              <div
                className={classNames(styles.checkbox, {
                  [styles.checked]: way === RegistrationFormWay.byBus,
                })}
              />
              <span className={styles.checkboxLabel}>
                {t(
                  'awayMatchesPage.registrationForm.formFields.byBusWithLocomotive'
                )}
              </span>
            </div>
          </div>
          <label className={styles.label}>
            {t('awayMatchesPage.registrationForm.formFields.enemy')}
          </label>
          <input
            className={styles.input}
            type='text'
            name='rival'
            value={formData.rival}
            onChange={handleChange}
            required
          />
          <button className={styles.button} type='submit'>
            {t('awayMatchesPage.registrationForm.submitButton')}
          </button>
          <p className={styles.policy}>
            {t('awayMatchesPage.registrationForm.warning1')}{' '}
            <span className={styles.bold}>
              {t('awayMatchesPage.registrationForm.warning2')}
            </span>
          </p>
        </form>
      </div>
    </Modal>
  );
};

export default RegistrationForm;
