import Arena from '../../../assets/images/about_arena.jpg';
import { Popover } from 'antd';
import { arenaPoints } from '../../../constants/arenaPoints';
import PointInfoTooltip from '../PointInfoTooltip/PointInfoTooltip';
import { getDataForPoint } from 'helpers/helpers';
import { ComplexInformationResponse } from 'types/Types';
import { useEffect, useState } from 'react';
import styles from './InfrastructureMap.module.scss';

const _getWindowSize = () => {
  const { innerWidth, innerHeight } = window;
  return { innerWidth, innerHeight };
};

interface InfrastructureMapProps {
  aboutArena: ComplexInformationResponse;
  scale: number;
  isBackground: boolean;
}

const InfrastructureMap = ({
  aboutArena,
  scale,
  isBackground,
}: InfrastructureMapProps) => {
  const [windowSize, setWindowSize] = useState(_getWindowSize());

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowSize(_getWindowSize());
    };

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  // ширина и высота исходных картинок
  const srcWidth = 5685;
  const srcHeight = 3790;

  const imageWidth = document.documentElement.clientWidth;
  const imageHeight = (imageWidth / srcWidth) * srcHeight;

  // FullHD - 1980
  const calculatedScale =
    (((1 / scale) * (imageWidth / 1980) * srcWidth) / windowSize.innerWidth) *
    0.4;
  const iconHeight = 45 * calculatedScale;
  const width2height = 0.66;
  const iconOffsetY = -iconHeight;
  const iconOffsetX = -iconHeight * width2height * 0.5;

  return (
    <svg
      width={imageWidth}
      height={imageHeight}
      xmlns='http://www.w3.org/2000/svg'
      className={isBackground ? styles.svgIsBackground : undefined}
    >
      <image href={Arena} width={imageWidth} height={imageHeight} />
      <rect width={imageWidth} height={imageHeight} fill='url(#radialGrad)' />
      <defs>
        <radialGradient
          id='radialGrad'
          cx='0'
          cy='0'
          r='1'
          gradientUnits='userSpaceOnUse'
          gradientTransform={`translate(${imageWidth / 2} ${
            imageHeight / 2
          }) scale(${imageWidth / 2} ${imageHeight / 2})`}
        >
          <stop stop-color='#052148' stop-opacity='0' />
          <stop offset='1' stop-color='#042046' stop-opacity='0.78' />
        </radialGradient>
      </defs>
      {arenaPoints.map((point) => (
        <Popover
          key={point.alt}
          placement='left'
          trigger='click'
          color='#16223A'
          overlayInnerStyle={{ padding: 0 }}
          content={
            <PointInfoTooltip
              title={
                getDataForPoint(point, aboutArena?.objects?.data)?.title ?? ''
              }
              images={getDataForPoint(
                point,
                aboutArena?.objects?.data
              ).images.map((image) => image.attributes.url)}
              description={
                getDataForPoint(point, aboutArena?.objects?.data).description ??
                ''
              }
            />
          }
        >
          <image
            href={point.icon}
            height={iconHeight}
            x={imageWidth * (1 - point.right * 0.01) + iconOffsetX}
            y={imageHeight * (1 - point.bottom * 0.01) + iconOffsetY}
          />
        </Popover>
      ))}
    </svg>
  );
};

export default InfrastructureMap;
