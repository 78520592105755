import styles from './Tooltip.module.scss';
import Slider, { Settings } from 'react-slick';
import ArrowLeftLine from '../../assets/icons/ArrowLeftLine';
import ArrowRightLine from '../../assets/icons/ArrowRightLIne';
import classNames from 'classnames';
import { useEffect, useRef, useState } from 'react';
import ReactImageGallery from 'react-image-gallery';
import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/scss/image-gallery.scss';
import '../Album/CustomImageGallery.scss';

type TooltipProps = {
  title: string;
  images?: Array<string>;
  topPosition?: number;
  leftPosition?: number;
  description?: string;
  caption?: string;
  className?: string;
  isNeedStopPropagation?: boolean;
};

export default function Tooltip({
  title,
  description,
  images,
  caption,
  topPosition,
  leftPosition,
  className,
  isNeedStopPropagation,
}: TooltipProps) {
  const [currentSlideIndex, setCurrentSlideIndex] = useState<number | null>(
    null
  );
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const galleryRef = useRef<ReactImageGallery | null>(null);

  const handleSlideChange = (currentSlide: number) => {
    setCurrentSlideIndex(currentSlide);
  };

  const openGallery = () => {
    setIsModalOpen(true);
  };

  useEffect(() => {
    handleSlideChange(0);
  }, []);

  useEffect(() => {
    if (isModalOpen && galleryRef.current) {
      galleryRef.current?.fullScreen();
    }
  }, [galleryRef, isModalOpen]);

  const sliderSettings: Settings = {
    dots: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    prevArrow: <ArrowLeftLine fill='white' />,
    nextArrow: <ArrowRightLine fill='white' />,
    afterChange: handleSlideChange,
  };

  return (
    <>
      <div
        style={{ top: `${topPosition}px`, left: `${leftPosition}px` }}
        className={classNames(styles.tooltip, className)}
        onClick={(e) => {
          isNeedStopPropagation && e.stopPropagation();
        }}
      >
        {images?.length && (
          <div
            className={styles.tooltipImgContainer}
            style={{ cursor: 'pointer' }}
          >
            <Slider className={styles.slider} {...sliderSettings}>
              {images.map((img, index) => (
                <img
                  src={img}
                  alt='slider-img'
                  onClick={openGallery}
                  key={index}
                />
              ))}
            </Slider>
          </div>
        )}
        {caption && (
          <div className={styles.captionContainer}>
            <div className={styles.caption}>
              <p>{caption}&nbsp;</p>
            </div>
          </div>
        )}
        <div className={styles.infoContainer}>
          <div className={styles.titleContainer}>
            <h3 className={styles.title}>{title}</h3>
          </div>
          {description && (
            <div className={styles.descriptionContainer}>
              <p className={styles.description}>{description}</p>
            </div>
          )}
        </div>
      </div>
      <div
        className={styles.galleryWrapper}
        onClick={(e) => e.stopPropagation()}
      >
        {isModalOpen && (
          <ImageGallery
            items={
              images
                ? images.map((url) => {
                    return {
                      original: url,
                      thumbnail: url,
                      originalClass: !isModalOpen ? 'customImage' : '',
                      thumbnailClass: 'customThumbnail',
                    };
                  })
                : []
            }
            infinite={false}
            showPlayButton={false}
            additionalClass='gallery'
            onScreenChange={(value) => {
              setIsModalOpen(value);
            }}
            startIndex={currentSlideIndex || 0}
            ref={galleryRef}
          />
        )}
      </div>
    </>
  );
}
