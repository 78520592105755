import cn from 'classnames';
import { ReactElement } from 'react';
import TeamTile from './TeamTile/TeamTile';
import { GamesResultProps } from '../Tournament';
import { StandingsReadableDto } from 'types/Types';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import FinalPrize from '../../../../../assets/playoffs_prize.png';
import Stages from './Stages/Stages';
import styles from './TournamentTree.module.scss';

type TournamentTreeProps = {
  round1West: Array<GamesResultProps>;
  round2West: Array<GamesResultProps>;
  round3West: Array<GamesResultProps>;
  round1East: Array<GamesResultProps>;
  round2East: Array<GamesResultProps>;
  round3East: Array<GamesResultProps>;
  finalResults: Array<GamesResultProps>;
  generalStandings: Array<StandingsReadableDto>;
  isCrossPlayoff?: boolean;
  crossRound?: number;
};

function TournamentTree({
  round1West,
  round2West,
  round3West,
  round1East,
  round2East,
  round3East,
  finalResults,
  generalStandings,
  isCrossPlayoff = false,
  crossRound,
}: TournamentTreeProps): ReactElement {
  const navigate = useNavigate();
  const { teamCode } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const navigateToDetails = (pair: GamesResultProps) => {
    const seasonParam = searchParams.get('season');
    navigate(
      `/playoffs/${teamCode}?teama=${pair?.teama}&teamb=${pair?.teamb}${
        seasonParam ? `&season=${seasonParam}` : ''
      }`
    );
  };

  const getFinalConferenceType = (teamId: number) => {
    return generalStandings.find((item) => item.club_id === teamId?.toString())
      ?.conference === '1'
      ? 'a'
      : 'b';
  };

  return (
    <>
      <div className={styles.conferences}>
        <div className={styles.conference}>Конференция «Запад»</div>
        <div className={styles.conference}>Конференция «Восток»</div>
      </div>
      <Stages />
      <div className={styles.tournament}>
        <div
          className={cn(styles.column, styles.left, {
            [styles.lineLeft1]: crossRound === 2,
          })}
        >
          <div
            className={cn(styles.matches, {
              [styles.hidden]: crossRound === 2,
            })}
            onClick={() => navigateToDetails(round1West[0])}
          >
            <TeamTile
              data={round1West[0]}
              generalStandings={generalStandings}
              type='a'
              position='left'
            />
            <TeamTile
              data={round1West[0]}
              generalStandings={generalStandings}
              type='b'
              position='left'
            />
          </div>
          <div
            className={cn(styles.matches, styles.lower, {
              [styles.hidden]: crossRound === 2,
            })}
            onClick={() => navigateToDetails(round1West[1])}
          >
            <TeamTile
              data={round1West[1]}
              generalStandings={generalStandings}
              type='a'
              position='left'
            />
            <TeamTile
              data={round1West[1]}
              generalStandings={generalStandings}
              type='b'
              position='left'
            />
          </div>
          <div
            className={cn(styles.matches, {
              [styles.hidden]: crossRound === 2,
            })}
            onClick={() => navigateToDetails(round1West[2])}
          >
            <TeamTile
              data={round1West[2]}
              generalStandings={generalStandings}
              type='a'
              position='left'
            />
            <TeamTile
              data={round1West[2]}
              generalStandings={generalStandings}
              type='b'
              position='left'
            />
          </div>
          <div
            className={cn(styles.matches, styles.lower, {
              [styles.hidden]: crossRound === 2,
            })}
            onClick={() => navigateToDetails(round1West[3])}
          >
            <TeamTile
              data={round1West[3]}
              generalStandings={generalStandings}
              type='a'
              position='left'
            />
            <TeamTile
              data={round1West[3]}
              generalStandings={generalStandings}
              type='b'
              position='left'
            />
          </div>
        </div>
        <div
          className={cn(styles.column, styles.left, {
            [styles.lineLeft2]: crossRound === 3,
          })}
        >
          <div
            className={cn(styles.matches, styles.long, {
              [styles.hidden]: crossRound === 3,
              [styles.semihidden]: crossRound === 2,
            })}
            onClick={() => navigateToDetails(round2West[0])}
          >
            <TeamTile
              data={round2West[0]}
              generalStandings={generalStandings}
              type='a'
              position='left'
            />
            <TeamTile
              data={round2West[0]}
              generalStandings={generalStandings}
              type='b'
              position='left'
            />
          </div>
          <div
            className={cn(styles.matches, styles.lower, styles.long, {
              [styles.hidden]: crossRound === 3,
              [styles.semihidden]: crossRound === 2,
            })}
            onClick={() => navigateToDetails(round2West[1])}
          >
            <TeamTile
              data={round2West[1]}
              generalStandings={generalStandings}
              type='a'
              position='left'
            />
            <TeamTile
              data={round2West[1]}
              generalStandings={generalStandings}
              type='b'
              position='left'
            />
          </div>
        </div>
        <div
          className={cn(styles.column, styles.left)}
          onClick={() => navigateToDetails(round3West[0])}
        >
          <div
            className={cn(styles.matches, {
              [styles.semihidden]: crossRound === 3,
            })}
          >
            <TeamTile
              data={round3West[0]}
              generalStandings={generalStandings}
              type='a'
              position='left'
            />
            <TeamTile
              data={round3West[0]}
              generalStandings={generalStandings}
              type='b'
              position='left'
            />
          </div>
        </div>
        <div className={cn(styles.column, styles.center)}>
          <div
            className={styles.matches}
            onClick={() => navigateToDetails(finalResults[0])}
          >
            <div className={styles.winnerInfo}>
              <img className={styles.image} src={FinalPrize} alt='final' />
              <TeamTile
                data={finalResults[0]}
                generalStandings={generalStandings}
                type='final'
                position='center'
              />
            </div>
            <TeamTile
              data={finalResults[0]}
              generalStandings={generalStandings}
              type={
                isCrossPlayoff
                  ? 'a'
                  : getFinalConferenceType(finalResults[0]?.teama)
              }
              position='left'
            />
            <TeamTile
              data={finalResults[0]}
              generalStandings={generalStandings}
              type={
                isCrossPlayoff
                  ? 'b'
                  : getFinalConferenceType(finalResults[0]?.teamb)
              }
              position='right'
            />
          </div>
        </div>
        <div className={cn(styles.column, styles.right)}>
          <div
            className={cn(styles.matches, {
              [styles.semihidden]: crossRound === 3,
            })}
            onClick={() => navigateToDetails(round3East[0])}
          >
            <TeamTile
              data={round3East[0]}
              generalStandings={generalStandings}
              type='a'
              position='right'
            />
            <TeamTile
              data={round3East[0]}
              generalStandings={generalStandings}
              type='b'
              position='right'
            />
          </div>
        </div>
        <div
          className={cn(styles.column, styles.right, {
            [styles.lineRight2]: crossRound === 3,
          })}
        >
          <div
            className={cn(styles.matches, styles.long, {
              [styles.hidden]: crossRound === 3,
              [styles.semihidden]: crossRound === 2,
            })}
            onClick={() => navigateToDetails(round2East[0])}
          >
            <TeamTile
              data={round2East[0]}
              generalStandings={generalStandings}
              type='a'
              position='right'
            />
            <TeamTile
              data={round2East[0]}
              generalStandings={generalStandings}
              type='b'
              position='right'
            />
          </div>
          <div
            className={cn(styles.matches, styles.lower, styles.long, {
              [styles.hidden]: crossRound === 3,
              [styles.semihidden]: crossRound === 2,
            })}
            onClick={() => navigateToDetails(round2East[1])}
          >
            <TeamTile
              data={round2East[1]}
              generalStandings={generalStandings}
              type='a'
              position='right'
            />
            <TeamTile
              data={round2East[1]}
              generalStandings={generalStandings}
              type='b'
              position='right'
            />
          </div>
        </div>
        <div
          className={cn(styles.column, styles.right, {
            [styles.lineRight1]: crossRound === 2,
          })}
        >
          <div
            className={cn(styles.matches, {
              [styles.hidden]: crossRound === 2,
            })}
            onClick={() => navigateToDetails(round1East[0])}
          >
            <TeamTile
              data={round1East[0]}
              generalStandings={generalStandings}
              type='a'
              position='right'
            />
            <TeamTile
              data={round1East[0]}
              generalStandings={generalStandings}
              type='b'
              position='right'
            />
          </div>
          <div
            className={cn(styles.matches, styles.lower, {
              [styles.hidden]: crossRound === 2,
            })}
            onClick={() => navigateToDetails(round1East[1])}
          >
            <TeamTile
              data={round1East[1]}
              generalStandings={generalStandings}
              type='a'
              position='right'
            />
            <TeamTile
              data={round1East[1]}
              generalStandings={generalStandings}
              type='b'
              position='right'
            />
          </div>
          <div
            className={cn(styles.matches, {
              [styles.hidden]: crossRound === 2,
            })}
            onClick={() => navigateToDetails(round1East[2])}
          >
            <TeamTile
              data={round1East[2]}
              generalStandings={generalStandings}
              type='a'
              position='right'
            />
            <TeamTile
              data={round1East[2]}
              generalStandings={generalStandings}
              type='b'
              position='right'
            />
          </div>
          <div
            className={cn(styles.matches, styles.lower, {
              [styles.hidden]: crossRound === 2,
            })}
            onClick={() => navigateToDetails(round1East[3])}
          >
            <TeamTile
              data={round1East[3]}
              generalStandings={generalStandings}
              type='a'
              position='right'
            />
            <TeamTile
              data={round1East[3]}
              generalStandings={generalStandings}
              type='b'
              position='right'
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default TournamentTree;
