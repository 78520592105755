import { ReactElement, PropsWithChildren } from 'react';
import styles from './Team.module.scss';
import classNames from 'classnames';

type TeamProps = {
    name: string;
    city: string;
    isCurrent: boolean;
}

function Team({ name, city, children, isCurrent }: PropsWithChildren<TeamProps>): ReactElement {
    return (
        <div className={styles.teamWrapper}>
            {children}
            {isCurrent ? <div className={classNames(styles.teamName, styles.white)}>{name}</div> : <div className={styles.teamName}>{name}</div>}
            {isCurrent ? <div className={classNames(styles.teamCity, styles.white)}>{city}</div> : <div className={styles.teamCity}>{city}</div>}
        </div>
    )
}

export default Team;
