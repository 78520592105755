import { observer } from 'mobx-react-lite';
import { useStore } from '../../../store/store';
import ImageBgPageWrapper from '../../common/ImageBgPageWrapper/ImageBgPageWrapper';
import InfoWrapper from '../../Wrappers/InfoWrapper/InfoWrapper';
import Breadcrumbs from '../../common/Breadcrumbs/Breadcrumbs';
import styles from './CalendarPage.module.scss';
import { useEffect, useState } from 'react';
import {
  getGamesInSeason,
  getSeasonByCode,
  getSeasonsByTeamId,
} from '../../../api/api';
import {
  GameWithLogo,
  ScheduleDay,
  ScheduleGame,
  Season,
} from '../../../types/Types';
import {
  getCountDaysInMonth,
  getMonthName,
  sortSeasonOptions,
} from '../../../helpers/helpers';
import { mergeGameWithLogoAndDate } from '../../../mapping/mapping';
import classNames from 'classnames';
import { useParams } from 'react-router-dom';
import Calendar from './Calendar/Calendar';
import Select, { SelectOption } from 'components/common/Select/Select';
import { useTranslation } from 'react-i18next';
import ErrorPage from '../ErrorPage/ErrorPage';
import ErrorWidget from 'components/common/ErrorWidget/ErrorWidget';

// TODO: Refactor state management
const CalendarPage = observer(() => {
  const { t } = useTranslation();
  const { currentSeasonStore, lokomotivTeamsStore } = useStore();
  const [schedule, setSchedule] = useState<Array<ScheduleDay>>([]);
  const [schedulePreviuous, setSchedulePreviuous] = useState<
    Array<ScheduleDay>
  >([]);
  const [scheduleNext, setScheduleNext] = useState<Array<ScheduleDay>>([]);
  const [games, setGames] = useState<Array<GameWithLogo>>([]);
  const [timeFilter, setTimeFilter] = useState<string>(
    JSON.stringify({
      month: new Date().getMonth(),
      year: new Date().getFullYear(),
    })
  );
  const [seasonFilter, setSeasonFilter] = useState<string>('');
  const [seasonsOptions, setSeasonsOptions] =
    useState<Array<{ key: string; value: string }>>();
  const [monthOptions, setMonthOptions] =
    useState<Array<{ key: string; value: string }>>();
  const [season, setSeason] = useState<string>('');
  const [isSeasonError, setIsSeasonError] = useState<boolean>(false);
  const { teamCode } = useParams();

  function filterGamesInMonth(
    games: Array<GameWithLogo>,
    month: number,
    year: number
  ): Array<GameWithLogo> {
    return games?.filter((game) =>
      month === 12
        ? new Date(game.attributes.date).getMonth() === new Date().getMonth() &&
          new Date(game.attributes.date).getFullYear() ===
            new Date().getFullYear()
        : new Date(game.attributes.date).getMonth() === month &&
          new Date(game.attributes.date).getFullYear() === year
    );
  }

  function filterGamesInSeason(
    games: Array<ScheduleGame>
  ): Array<ScheduleGame> {
    return games?.filter((game) => game.attributes.date);
  }

  useEffect(() => {
    const seasonCode = currentSeasonStore.getSeasonByCode(teamCode);
    if (seasonCode) {
      setSeason(seasonCode);
    }
  }, [teamCode, currentSeasonStore.currentSeason]);

  useEffect(() => {
    const getData = async () => {
      if (
        season &&
        lokomotivTeamsStore.lokomotivTeams?.men.data.attributes.id_league_web
      ) {
        setIsSeasonError(false);
        const seasonData = await getSeasonByCode(season);
        const seasonList = await getSeasonsByTeamId(
          seasonData?.data[0]?.attributes.team
        );

        const gamesTmp = await getGamesInSeason(
          season,
          undefined,
          lokomotivTeamsStore.lokomotivTeams?.men.data.attributes.id_league_web,
          lokomotivTeamsStore.lokomotivTeamsIds
        );

        const seasonOptionsTmp = getSeasonsFilterOptions(seasonList?.data);
        const monthOptionsTmp = getMonthFilterOptions(gamesTmp?.data);
        setGames(gamesTmp?.data);
        setSeasonsOptions(
          seasonOptionsTmp.sort((a, b) => sortSeasonOptions(a?.key, b?.key))
        );

        setMonthOptions(monthOptionsTmp);
        setSeasonFilter(season);
      } else {
        setIsSeasonError(true);
      }
    };
    getData();
  }, [
    season,
    teamCode,
    lokomotivTeamsStore.lokomotivTeams,
    lokomotivTeamsStore.lokomotivTeamsIds,
  ]);

  useEffect(() => {
    if (
      timeFilter ===
      JSON.stringify({
        month: new Date().getMonth(),
        year: new Date().getFullYear(),
      })
    ) {
      updateData(false);
    }
    updateData(true);
  }, [seasonFilter]);

  useEffect(() => {
    updateData(false);
  }, [timeFilter, lokomotivTeamsStore.lokomotivTeams]);

  const updateData = async (isSeasonChanged: boolean) => {
    let gamesInSeason;
    let monthOptionsTmp;
    let month;
    let year;

    if (isSeasonChanged && lokomotivTeamsStore.lokomotivTeams?.men) {
      const seasonGames = await getGamesInSeason(
        seasonFilter,
        undefined,
        lokomotivTeamsStore.lokomotivTeams?.men.data.attributes.id_league_web,
        lokomotivTeamsStore.lokomotivTeamsIds
      );
      gamesInSeason = seasonGames?.data;
      monthOptionsTmp = getMonthFilterOptions(gamesInSeason);
      if (
        timeFilter ===
          JSON.stringify({
            month: new Date().getMonth(),
            year: new Date().getFullYear(),
          }) &&
        monthOptions?.find((option) => option?.key === timeFilter)
      ) {
        const key = JSON.parse(timeFilter);
        month = Number(key.month);
        year = Number(key.year);
      } else {
        month = new Date().getMonth();
        year = new Date().getFullYear();
        const mOption = JSON.stringify({
          month: month,
          year: year,
        });

        const currentMOption = {
          key: mOption,
          value: `${getMonthName(month)} ${year}`,
        };

        const gamesInMonth = filterGamesInMonth(gamesInSeason, month, year);
        let currentIndex = null;
        if (
          !monthOptionsTmp.includes(currentMOption) &&
          season == currentSeasonStore.getSeasonByCode(teamCode) &&
          gamesInMonth.length == 0
        ) {
          monthOptionsTmp.push(currentMOption);
          monthOptionsTmp = monthOptionsTmp.sort(function (a, b) {
            if (
              JSON.parse(a?.key)?.year > JSON.parse(b?.key)?.year &&
              JSON.parse(a?.key)?.month > JSON.parse(b?.key)?.month
            ) {
              return 1;
            }

            if (
              JSON.parse(a?.key)?.year < JSON.parse(b?.key)?.year &&
              JSON.parse(a?.key)?.month < JSON.parse(b?.key)?.month
            ) {
              return -1;
            }

            if (JSON.parse(a?.key)?.year == JSON.parse(b?.key)?.year) {
              if (JSON.parse(a?.key)?.month > JSON.parse(b?.key)?.month) {
                return 1;
              }
              if (JSON.parse(a?.key)?.month < JSON.parse(b?.key)?.month) {
                return -1;
              }
            }

            return 0;
          });

          currentIndex = monthOptionsTmp.indexOf(currentMOption);
        }
      }
    } else {
      gamesInSeason = games;
      const key = JSON.parse(timeFilter);
      month = Number(key.month);
      year = Number(key.year);
    }

    const gamesInMonth = filterGamesInMonth(gamesInSeason, month, year);
    const calendar = getScheduleWithoutGames(
      getCountDaysInMonth(month, year),
      month,
      year
    );

    const prev = getPreviousMonth(month);
    const gamesInPreviousMonth = filterGamesInMonth(gamesInSeason, prev, year);
    const prevDays = getCountDaysInMonth(prev, year);
    const calendarPrevious = getScheduleWithoutGames(
      prevDays,
      prev,
      prev === 11 ? year - 1 : year
    );

    const next = getNextMonth(month);
    const nextDays = getCountDaysInMonth(next, year);
    const gamesInNextMonth = filterGamesInMonth(gamesInSeason, next, year);
    const calendarNext = getScheduleWithoutGames(nextDays, next, year);

    setSchedule(mergeGameWithLogoAndDate(calendar, gamesInMonth));

    if (isSeasonChanged) {
      if (monthOptionsTmp) {
        setMonthOptions(monthOptionsTmp);
        setTimeFilter(JSON.stringify({ month, year }));
      }

      setGames(gamesInSeason);
    }

    if (getDayOfWeek(calendar[0].getDay()) === 0) {
      setSchedulePreviuous([]);
    } else
      setSchedulePreviuous(
        mergeGameWithLogoAndDate(
          calendarPrevious?.slice(-getDayOfWeek(calendar[0].getDay())),
          gamesInPreviousMonth?.slice(-getDayOfWeek(calendar[0].getDay()))
        )
      );
    setScheduleNext(
      mergeGameWithLogoAndDate(
        calendarNext?.slice(
          0,
          6 - getDayOfWeek(calendar[calendar.length - 1].getDay())
        ),
        gamesInNextMonth?.slice(
          0,
          6 - getDayOfWeek(calendar[calendar.length - 1].getDay())
        )
      )
    );
  };

  function getPreviousMonth(month: number) {
    const currentMonth = new Date().getMonth();
    if (month === 12) {
      return currentMonth === 0 ? 11 : currentMonth - 1;
    }
    return month === 0 ? 11 : month - 1;
  }

  function getNextMonth(month: number) {
    const currentMonth = new Date().getMonth();
    if (month === 12) {
      return currentMonth === 11 ? 0 : currentMonth + 1;
    }
    return month === 11 ? 0 : month + 1;
  }

  function getDayOfWeek(day: number) {
    return day === 0 ? 6 : day - 1;
  }

  function getScheduleWithoutGames(
    countDays: number,
    month: number = 0,
    year: number = new Date().getFullYear()
  ): Array<Date> {
    const currentDate = new Date();
    if (month === 12) {
      month = currentDate.getMonth();
      year = currentDate.getFullYear();
    }
    return Array.from(Array(countDays), (_, i) => new Date(year, month, i + 1));
  }

  function getMonthFilterOptions(
    games: ScheduleGame[]
  ): Array<{ key: string; value: string }> {
    const monthOptionsTmp = new Map();
    filterGamesInSeason(games)?.forEach((game) => {
      const date = new Date(game.attributes.date);
      const month = date.getMonth();
      const year = date.getFullYear();
      if (!monthOptionsTmp.has(JSON.stringify({ month, year }))) {
        monthOptionsTmp.set(
          JSON.stringify({ month, year }),
          `${getMonthName(month)} ${year}`
        );
      }
    });

    return Array.from(monthOptionsTmp, ([key, value]) => ({ key, value }));
  }

  function getSeasonsFilterOptions(
    seasonsList: Season[]
  ): Array<{ key: string; value: string }> {
    const seasonsOptions = new Map();
    seasonsList &&
      seasonsList?.forEach((season) => {
        seasonsOptions.set(
          season.attributes.code,
          `${season.attributes.name} ${season.attributes.years_interval}`
        );
      });
    return Array.from(seasonsOptions, ([key, value]) => ({ key, value }));
  }

  async function updateSeason(e: any) {
    setSeasonFilter(e);
    setSeason(e);
    const seasonGames = await getGamesInSeason(
      e,
      undefined,
      lokomotivTeamsStore.lokomotivTeams?.men.data.attributes.id_league_web ||
        1,
      lokomotivTeamsStore.lokomotivTeamsIds
    );
    const gamesInSeason = seasonGames?.data;
    const monthOptionsTmp = getMonthFilterOptions(gamesInSeason);
    setMonthOptions(monthOptionsTmp);
  }

  function getDefault(time: any) {
    return JSON.stringify({
      month: JSON.parse(time).month,
      year: JSON.parse(time).year,
    });
  }

  function getSeasonSelectOptions(
    options: { key: string; value: string }[]
  ): SelectOption[] {
    return options.map((option) => {
      return {
        value: option.key,
        displayValue: option.value,
      };
    });
  }

  function getMonthSelectOptions(
    monthOptions: Array<{ key: string; value: string }>
  ): SelectOption[] {
    return monthOptions.map((option) => {
      return {
        value: JSON.stringify({
          month: JSON.parse(option.key).month,
          year: JSON.parse(option.key).year,
        }),
        displayValue: option.value,
      };
    });
  }

  function getMonthSelectPlaceholder() {
    const month = new Date().getMonth();
    const year = new Date().getFullYear();

    return `${getMonthName(month)} ${year}`;
  }

  return (
    <ImageBgPageWrapper>
      <InfoWrapper
        isWhiteBackground={false}
        innerPaddingTop='10px'
        borderRadius='0px'
      >
        <div className={styles.breadcrumbs}>
          <Breadcrumbs
            breadcrumbs={[
              {
                name: t('calendarPage.breadcrumb'),
                url: `/schedule/${teamCode}`,
              },
            ]}
            isWhiteColor={false}
          />
        </div>

        <div className={styles.calendar}>
          <div className={styles.calendarWrapper}>
            <h1 className={styles.header}>{t('calendarPage.header')}</h1>
            <div className={styles.selectWrapper}>
              {seasonsOptions && seasonsOptions.length > 0 && (
                <Select
                  values={getSeasonSelectOptions(seasonsOptions)}
                  onChange={updateSeason}
                  value={season}
                />
              )}
              <Select
                placeholder={
                  getMonthSelectPlaceholder() ||
                  t('calendarPage.monthPlaceholder')
                }
                value={getDefault(timeFilter)}
                values={getMonthSelectOptions(
                  monthOptions && monthOptions.length > 0 ? monthOptions : []
                )}
                onChange={setTimeFilter}
              />
            </div>
            {isSeasonError ? (
              <ErrorWidget.NotFound />
            ) : (
              <Calendar
                schedulePreviuous={schedulePreviuous}
                schedule={schedule}
                scheduleNext={scheduleNext}
              />
            )}
            {isSeasonError ? (
              ''
            ) : (
              <div className={styles.legendaWrapper}>
                <div className={styles.legenda}>
                  <div
                    className={classNames(
                      styles.legendaColor,
                      styles.legendaAway
                    )}
                  ></div>
                  <span className={styles.legendaText}>
                    {t('calendarPage.legend.away')}
                  </span>
                </div>
                <div className={styles.legenda}>
                  <div className={styles.legendaColor}></div>
                  <span className={styles.legendaText}>
                    {t('calendarPage.legend.home')}
                  </span>
                </div>
              </div>
            )}
          </div>
        </div>
      </InfoWrapper>
    </ImageBgPageWrapper>
  );
});

export default CalendarPage;
