import { ReactElement, PropsWithChildren } from "react";
import styles from "./Team.module.scss";

type TeamProps = {
  name: string;
  city: string;
};

function Team({
  name,
  city,
  children,
}: PropsWithChildren<TeamProps>): ReactElement {
  return (
    <div className={styles.teamWrapper}>
      {children}
      <div className={styles.teamName}>{name}</div>
      <div className={styles.teamCity}>{city}</div>
    </div>
  );
}

export default Team;
