import { useEffect, useRef } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Footer from './components/Footer/Footer';
import Header from './components/Header/Header';
import { store, StoreContext, useStore } from './store/store';
import './styles.scss';
import './hotfix.scss';
import './customStyles.scss';
import { observer } from 'mobx-react-lite';
import { ExecutiveType } from './types/Types';
import ScrollToTop from 'components/common/ScrollToTop/ScrollToTop';

import ArticlePage from 'components/ArticlePage/ArticlePage';
import StaticPage from 'components/StaticPage/StaticPage';
import CalendarPage from 'components/pages/CalendarPage/CalendarPage';
import RosterPage from 'components/pages/RosterPage/RosterPage';
import ExecutivePage from 'components/pages/ExecutivePage/ExecutivePage';
import StaffBioPage from 'components/pages/StaffBioPage/StaffBioPage';
import CoachPage from 'components/pages/CoachPage/CoachPage';
import ManagementPage from 'components/pages/ManagementPage/ManagementPage';
import PressBioPage from 'components/pages/PressBioPage/PressBioPage';
import PlayerPage from 'components/PlayerPage/PlayerPage';
import StandingPage from 'components/StandingPage/StandingPage';
import PlayoffsPage from 'components/pages/PlayoffsPage/PlayoffsPage';
import StatsLokomotivPage from 'components/StatsLokomotivPage/StatsLokomotivPage';
import AlbumPage from './components/pages/AlbumPage/AlbumPage';
import RecoveryPasswordPage from './components/pages/RecoveryPasswordPage/RecoveryPasswordPage';
import GameProtocolPage from './components/pages/GameProtocolPage/GameProtocolPage';
import HomePage from './components/HomePage/HomePage';
import ErrorPage from './components/pages/ErrorPage/ErrorPage';
import Photogallery from './components/Photogallery/Photogallery';
import MemoryBookPage from './components/pages/MemoryBookPage/MemoryBookPage';
import TicketingProgramPage from './components/pages/tickets/TicketingProgramPage/TicketingProgramPage';
import LokoTvPage from './components/LokoTVPage/LokoTVPage';
import SubscriptionPage from './components/pages/tickets/SubscriptionPage/SubscriptionPage';
import NewsPage from './components/NewsPage/NewsPage';
import BookletsPage from './components/BookletsPage/BookletsPage';
import ExecutivesPage from './components/pages/ExecutivesPage/ExecutivesPage';
import SponsorsPage from './components/pages/SponsorsPage/SponsorsPage';
import InfrastructurePage from './components/pages/InfrastructurePage/InfrastructurePage';
import SubscriptionPrivilegesPage from './components/pages/SubscriptionPrivilegesPage/SubscriptionPrivilegesPage';
import AwayMatchesPage from 'components/pages/AwayMatchesPage/AwayMatchesPage';

const App = observer(() => {
  const {
    productsStore,
    userStore,
    currentSeasonStore,
    backgroundStore,
    sideMenuStore,
    lokomotivTeamsStore,
    documentsStore,
    menuStore,
  } = useStore();
  const footerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    productsStore.loadProducts();
  }, []);

  useEffect(() => {
    menuStore.loadMenu();
  }, []);

  useEffect(() => {
    userStore.loadUserInfo();
  }, []);

  useEffect(() => {
    currentSeasonStore.loadCurrentSeason();
  }, []);

  useEffect(() => {
    backgroundStore.loadBackgrounds();
  }, []);

  useEffect(() => {
    sideMenuStore.loadSideMenu();
  }, []);

  useEffect(() => {
    lokomotivTeamsStore.loadLokomotivTeams();
  }, []);

  useEffect(() => {
    documentsStore.loadDocuments();
  }, []);

  useEffect(() => {
    // Скролл в 1 пиксель нужен, чтобы при первой загрузке страницы плашка KHL не была видна.
    // При скролле в 1 пиксель, элемент вставится "за видимость экрана".
    // такой подход позволяет избежать дёрганий и settimeout
    window.scrollTo(0, 1);
  }, []);

  return (
    <StoreContext.Provider value={store}>
      <Router>
        <ScrollToTop>
          <Header />
          <Routes>
            <Route path='/' element={<HomePage footerRef={footerRef} />} />
            <Route path='/static-page/:staticPageId' element={<StaticPage />} />
            <Route path='/article/:articleId' element={<ArticlePage />} />
            <Route path='/away-matches' element={<AwayMatchesPage />} />
            <Route path='/sponsors' element={<SponsorsPage />} />
            <Route path='/news' element={<NewsPage />} />
            <Route path='/booklets' element={<BookletsPage />} />
            <Route path='/booklet/:bookletId' element={<ArticlePage />} />
            <Route path='/schedule/:teamCode' element={<CalendarPage />} />
            <Route path='/roster/:teamCode' element={<RosterPage />} />
            <Route
              path='/executives/'
              element={
                <ExecutivesPage
                  type={ExecutiveType.EXECUTIVE}
                  isCatastrophe={false}
                />
              }
            />
            <Route
              path='/coaches/'
              element={
                <ExecutivesPage
                  type={ExecutiveType.COACH}
                  isCatastrophe={false}
                />
              }
            />
            <Route
              path='/staff/'
              element={
                <ExecutivesPage
                  type={ExecutiveType.STAFF}
                  isCatastrophe={false}
                />
              }
            />
            <Route
              path='/management/'
              element={
                <ExecutivesPage
                  type={ExecutiveType.MANAGER}
                  isCatastrophe={false}
                />
              }
            />
            <Route
              path='/press/'
              element={
                <ExecutivesPage
                  type={ExecutiveType.PRESS}
                  isCatastrophe={false}
                />
              }
            />
            <Route
              path='/staff/:staffId'
              element={<StaffBioPage is_catastrophe={false} />}
            />
            <Route path='/executive/:executiveId' element={<ExecutivePage />} />
            <Route
              path='/coach/:coachId'
              element={<CoachPage is_catastrophe={false} />}
            />
            <Route path='/manager/:managementId' element={<ManagementPage />} />
            <Route path='/press/:pressId' element={<PressBioPage />} />
            <Route
              path='/catastrophe/coach/'
              element={
                <ExecutivesPage
                  type={ExecutiveType.COACH}
                  isCatastrophe={true}
                />
              }
            />
            <Route
              path='/catastrophe/staff/'
              element={
                <ExecutivesPage
                  type={ExecutiveType.STAFF}
                  isCatastrophe={true}
                />
              }
            />
            <Route
              path='/catastrophe/coach/:coachId'
              element={<CoachPage is_catastrophe={true} />}
            />
            <Route
              path='/catastrophe/staff/:staffId'
              element={<StaffBioPage is_catastrophe={true} />}
            />
            <Route path='/player/:playerId' element={<PlayerPage />} />
            <Route path='/standings/:teamCode' element={<StandingPage />} />
            <Route path='/playoffs/:teamCode' element={<PlayoffsPage />} />
            <Route path='/stats/:teamCode' element={<StatsLokomotivPage />} />
            <Route path='/photogallery' element={<Photogallery />} />
            <Route path='/photogallery/:albumId' element={<AlbumPage />} />
            <Route path='/memorybook' element={<MemoryBookPage />} />
            <Route
              path='/password-recovery'
              element={<RecoveryPasswordPage />}
            />
            <Route
              path='/tickets/ticketing-program'
              element={<TicketingProgramPage />}
            />
            <Route path='/tickets/subscription' element={<SubscriptionPage />} />
            <Route
              path='/subscription-privileges'
              element={<SubscriptionPrivilegesPage />}
            />
            <Route path='/lokotv' element={<LokoTvPage />} />
            <Route path='/protocol' element={<GameProtocolPage />} />
            <Route
              path='/arena/about'
              element={<InfrastructurePage pathname='about' />}
            />
            <Route
              path='/arena/construction-progress'
              element={<InfrastructurePage pathname='construction-progress' />}
            />
            <Route
              path='/arena/today'
              element={<InfrastructurePage pathname='today' />}
            />
            <Route
              path='/mfsk/about'
              element={<InfrastructurePage pathname='about' />}
            />
            <Route
              path='/mfsk/construction-progress'
              element={<InfrastructurePage pathname='construction-progress' />}
            />
            <Route
              path='/mfsk/today'
              element={<InfrastructurePage pathname='today' />}
            />
            <Route path='/*' element={<ErrorPage />} />
          </Routes>
          <Footer footerRef={footerRef} />
        </ScrollToTop>
      </Router>
    </StoreContext.Provider>
  );
});

export default App;
