import { ReactElement, useEffect, useState } from 'react';
import { getArticlesUsingFilters, getProgramAppeals } from '../../api/api';
import { BookletAppealDto } from '../../types/Types';
import PageHeader from 'components/common/PageHeader/PageHeader';
import BreadcrumbsWrapper from 'components/common/BreadcrumbsWrapper/BreadcrumbsWrapper';
import Container from '../common/Container/Container';
import ImageBgPageWrapper from '../common/ImageBgPageWrapper/ImageBgPageWrapper';
import styles from './BookletsPage.module.scss';
import ViewMoreButton from '../ViewMoreButton/ViewMoreButton';
import { getLatestBooklet } from '../../helpers/helpers';
import BookletItem from './BookletItem/BookletItem';
import ScrollToTopButton from 'components/ScrollToTopButton/ScrollToTopButton';
import Spinner from 'components/Spinner/Spinner';
import ErrorWidget from 'components/common/ErrorWidget/ErrorWidget';
import Article from 'model/entity/loko/type/Article';
import { useTranslation } from 'react-i18next';

const BookletsPage = (): ReactElement => {
  const { t } = useTranslation();
  const [bookletsList, setBookletsList] = useState<Array<Article>>([]);
  const [programAppeal, setProgramAppeal] = useState<BookletAppealDto>();
  const [topBooklet, setTopBooklet] = useState<Article | null>();
  const [pageParam, setPageParam] = useState<number>(1);
  const [isBookletsListLoading, setIsBookletsListLoading] =
    useState<boolean>(true);
  const [isBookletsListError, setIsBookletsListError] =
    useState<boolean>(false);
  const [isProgramAppealsError, setIsProgramAppealsError] =
    useState<boolean>(false);
  const categoryParam = '90';

  useEffect(() => {
    const getData = async () => {
      try {
        const resp = await getArticlesUsingFilters(
          '',
          categoryParam,
          '',
          '',
          pageParam,
          pageParam === 1 ? 16 : 15
        );
        if (pageParam === 1) {
          setBookletsList(resp?.data);
        } else {
          setBookletsList((prevState) => [...prevState, ...resp?.data]);
        }
      } catch (error) {
        setIsBookletsListError(true);
      } finally {
        setIsBookletsListLoading(false);
      }
    };
    getData();
  }, [pageParam]);

  useEffect(() => {
    const getAppealData = async () => {
      try {
        const resp = await getProgramAppeals();
        setProgramAppeal(resp?.data);
      } catch (e: any) {
        setIsProgramAppealsError(true);
      }
    };
    getAppealData();
  }, []);

  useEffect(() => {
    const latestBooklet = getLatestBooklet(bookletsList);
    setTopBooklet(latestBooklet);
  }, [bookletsList]);

  const showMoreData = () => {
    setPageParam((prevState) => prevState + 1);
  };

  return (
    <ImageBgPageWrapper>
      <Container paddingTop='50px'>
        <div className={styles.wrapper}>
          <BreadcrumbsWrapper
            breadcrumbs={[
              {
                name: t('bookletsPage.breadcrumb'),
                url: '/booklets',
              },
            ]}
          />
          <div className={styles.pageHeaderWrapper}>
            <PageHeader text={t('bookletsPage.header')} />
          </div>
          <div className={styles.appeals}>
            <h3 className={styles.appealsTitle}>
              {t('bookletsPage.appealsTitle')}
            </h3>
            {isProgramAppealsError ? (
              <ErrorWidget.Error isOnDarkBackground />
            ) : (
              programAppeal && (
                <div
                  className={styles.appealsDescription}
                  dangerouslySetInnerHTML={{
                    __html: programAppeal.attributes.text,
                  }}
                />
              )
            )}
          </div>
          <div className={styles.content}>
            {topBooklet && <BookletItem booklet={topBooklet} />}
            <div className={styles.row}>
              <div className={styles.colSm12}>
                <div className={styles.row}>
                  {isBookletsListLoading ? (
                    <Spinner />
                  ) : isBookletsListError && isProgramAppealsError ? (
                    <></>
                  ) : isBookletsListError ? (
                    <ErrorWidget.Error isOnDarkBackground />
                  ) : (
                    bookletsList
                      .filter((booklet) => booklet.id !== topBooklet?.id)
                      .sort(
                        (bookletA, bookletB) =>
                          new Date(bookletB.attributes.date).getTime() -
                          new Date(bookletA.attributes.date).getTime()
                      )
                      .map((booklet) => (
                        <div key={booklet.id} className={styles.colMd4}>
                          <BookletItem
                            isSmall
                            key={booklet.id}
                            booklet={booklet}
                          />
                        </div>
                      ))
                  )}
                </div>
                <br />
                <ViewMoreButton onClick={showMoreData} />
                <div className={styles.ScrollToTopButtonWrapper}>
                  <ScrollToTopButton color='#00256D' textColor='#fff' />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </ImageBgPageWrapper>
  );
};

export default BookletsPage;
