import { useEffect, useState } from 'react';
import useScrollPosition from '../../../hooks/UseScrollPosition';
import styles from './MobileHeader.module.scss';
import Row from 'components/core/layout/Row';
import ShowOn from 'components/core/adaptivity/ShowOn';
import MobileHeaderHamburger from './MobileHeaderHamburger/MobileHeaderHamburger';
import Flex from 'components/core/layout/Flex';
import { MenuElementDto } from 'types/Types';
import MobileHeaderTickets from './MobileHeaderTickets/MobileHeaderTickets';
import MobileHeaderShop from './MobileHeaderShop/MobileHeaderShop';
import MobileHeaderLogo from './MobileHeaderLogo/MobileHeaderLogo';
import MobileHeaderSocials from './MobileHeaderSocials/MobileHeaderSocials';
import { Drawer } from 'antd';
import MobileHeaderDrawer from './MobileHeaderDrawer/MobileHeaderDrawer';
import MobileHeaderDrawerFooter from './MobileHeaderDrawerFooter/MobileHeaderDrawerFooter';

interface MobileHeaderProps {
  allMenu: Array<MenuElementDto>;
}

const MobileHeader = ({ allMenu }: MobileHeaderProps) => {
  const countPx = 55;
  const scrollPosition = useScrollPosition();
  const [isNavOpen, setIsNavOpen] = useState(false);
  const [isScroll, setIsScroll] = useState(false);

  useEffect(() => {
    const root = document.getElementById('root');
    if (scrollPosition >= countPx) {
      if (!root?.classList.contains(styles.scrolled))
        root?.classList.add(styles.scrolled);
      setIsScroll(true);
    } else {
      if (root?.classList.contains(styles.scrolled))
        root?.classList.remove(styles.scrolled);
      setIsScroll(false);
    }
  }, [scrollPosition]);

  const renderContent = () => (
    <div className={styles.header}>
      <Row alignItems='center' height='55px'>
        <MobileHeaderHamburger
          isOpen={isNavOpen}
          onTap={() => setIsNavOpen(!isNavOpen)}
        />

        <Flex weight={1}>
          <MobileHeaderLogo />
        </Flex>

        <ShowOn largeTablet smallTablet largeMobile>
          <MobileHeaderTickets allMenu={allMenu} />
          <div className={styles.divider}>
            <div className={styles.dividerLine}></div>
          </div>
          <MobileHeaderShop />
        </ShowOn>
        <ShowOn largeTablet>
          <MobileHeaderSocials />
        </ShowOn>
      </Row>
    </div>
  );

  return (
    <>
      <div className={styles.navbar}>
        <div className={styles.nav}>{renderContent()}</div>
      </div>
      <Drawer
        placement='left'
        width={400}
        open={isNavOpen}
        zIndex={5}
        footer={
          <ShowOn smallMobile>
            <MobileHeaderDrawerFooter allMenu={allMenu} />
          </ShowOn>
        }
        footerStyle={{
          padding: '0px',
        }}
        bodyStyle={{ padding: '0px' }}
      >
        <MobileHeaderDrawer allMenu={allMenu} isScroll={isScroll} />
      </Drawer>
    </>
  );
};

export default MobileHeader;
