import { createContext, useContext } from 'react';
import MenuStore from './MenuStore/MenuStore';
import ProductsStore from './ProductsStore/ProductsStore';
import UserStore from './UserStore/UserStore';
import CurrentSeasonStore from './CurrentSeasonStore/CurrentSeasonStore';
import BackgroundStore from './BackgroundStore/BackgroundStore';
import SideMenuStore from './SideMenuStore/SideMenuStore';
import LokomotivTeamsStore from './LokomotivTeamsStore/LokomotivTeamsStore';
import DocumentsStore from './DocumentsStore/DocumentsStore';

type Store = {
  menuStore: MenuStore;
  userStore: UserStore;
  productsStore: ProductsStore;
  currentSeasonStore: CurrentSeasonStore;
  backgroundStore: BackgroundStore;
  sideMenuStore: SideMenuStore;
  lokomotivTeamsStore: LokomotivTeamsStore;
  documentsStore: DocumentsStore;
};

const menuStore = new MenuStore();
const userStore = new UserStore();
const productsStore = new ProductsStore();
const currentSeasonStore = new CurrentSeasonStore();
const backgroundStore = new BackgroundStore();
const sideMenuStore = new SideMenuStore();
const lokomotivTeamsStore = new LokomotivTeamsStore();
const documentsStore = new DocumentsStore();

export const store: Store = {
  menuStore: menuStore,
  productsStore: productsStore,
  userStore: userStore,
  currentSeasonStore: currentSeasonStore,
  backgroundStore: backgroundStore,
  sideMenuStore: sideMenuStore,
  lokomotivTeamsStore: lokomotivTeamsStore,
  documentsStore: documentsStore,
};

export const StoreContext = createContext(store);

export function useStore() {
  return useContext(StoreContext);
}
