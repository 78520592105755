import { ReactElement, useState } from 'react';
import { Link } from 'react-router-dom';
import UpperRightArrow from '../../assets/UpperRightArrow';
import styles from './LinkButton.module.scss';

type LinkButtonProps = {
  link: string;
  title: string;
};
function LinkButton({ link, title }: LinkButtonProps): ReactElement {
  const [isHover, setIsHover] = useState(false);
  return (
    <Link
      className={styles.button}
      to={link}
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
    >
      <p className={styles.title}>{title}</p>
      <UpperRightArrow fillColor='#C8102E' rotateDegrees={isHover ? 45 : 0} />
    </Link>
  );
}

export default LinkButton;
