import { ReactElement, useEffect, useState } from 'react';
import styles from './PostSection.module.scss';
import { ForumCategory, ForumPost, SmileDto } from 'types/Types';
import { getForumCategories, getForumPostsPage, getSmiles } from 'api/api';
import Spinner from 'components/Spinner/Spinner';
import ErrorWidget from 'components/common/ErrorWidget/ErrorWidget';
import PaginationBlock from './PaginationBlock/PaginationBlock';
import PostItem from './PostItem/PostItem';
import { observer } from 'mobx-react-lite';
import { useStore } from 'store/store';
import PostWritingForm from './PostWritingForm/PostWritingForm';
import { useTranslation } from 'react-i18next';

const PostSection = observer((): ReactElement => {
  const [activeCategory, setActiveCategory] = useState<ForumCategory>();
  const [pageSize] = useState<number>(20);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [postsToShow, setPostsToShow] = useState<Array<ForumPost>>([]);
  const [smiles, setSmiles] = useState<Array<SmileDto>>([]);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isError, setIsError] = useState<boolean>(false);
  const [showWritingForm, setShowWritingForm] = useState<boolean>(false);
  const { userStore } = useStore();
  const { t } = useTranslation();

  useEffect(() => {
    const getData = async () => {
      try {
        const [categories, smileItems] = await Promise.all([
          getForumCategories(),
          getSmiles(),
        ]);

        if (categories.data.length === 1) {
          setActiveCategory(categories.data[0]);
        } else {
          const memoryBookCategory = categories.data.find(
            (item: ForumCategory) => item.id === 2
          );

          setActiveCategory(
            !!memoryBookCategory ? memoryBookCategory : categories.data[0]
          );
        }

        setSmiles(smileItems?.data);
      } catch (error: any) {
        console.error(error?.response?.data?.error || error?.message);
      }
    };

    getData();
  }, []);

  useEffect(() => {
    const getData = async () => {
      setIsLoading(true);
      setIsError(false);

      try {
        if (activeCategory) {
          const posts = await getForumPostsPage(
            activeCategory.id.toString(),
            pageSize.toString(),
            (pageSize * (pageNumber - 1 || 0)).toString() || '0'
          );
          setPostsToShow(posts?.data);

          if (pageSize != 0) {
            setTotalPages(Math.ceil(posts?.meta?.pagination?.total / pageSize));
          }
        }
      } catch (error: any) {
        setIsError(true);
      } finally {
        setIsLoading(false);
      }
    };

    getData();
  }, [activeCategory, pageNumber, pageSize]);

  const openPage = (pageNumber: number) => {
    setPageNumber(pageNumber);
  };

  return (
    <div className={styles.wrapper}>
      <h1 className={styles.title}>{t('memoryBookPage.postSection.title')}</h1>
      {userStore.isLogged && (
        <>
          <div>
            {showWritingForm ? (
              <PostWritingForm
                smiles={smiles}
                category={activeCategory}
                onCancel={() => setShowWritingForm(false)}
              />
            ) : (
              <button
                className={styles.actionButton}
                onClick={() => setShowWritingForm(true)}
              >
                {t('memoryBookPage.postSection.writePost')}
              </button>
            )}
          </div>
        </>
      )}
      <div>
        {isLoading ? (
          <Spinner />
        ) : isError ? (
          <ErrorWidget.Error />
        ) : (
          postsToShow && (
            <div className={styles.listWrapper}>
              <div className={styles.postList}>
                {postsToShow.map((post) => (
                  <PostItem post={post} smiles={smiles} key={post.id} />
                ))}
              </div>
              {totalPages !== 0 && (
                <PaginationBlock
                  countPages={totalPages}
                  currentPage={Number(pageNumber) || 1}
                  onPageChange={openPage}
                />
              )}
            </div>
          )
        )}
      </div>
    </div>
  );
});

export default PostSection;
