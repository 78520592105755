import { makeAutoObservable } from 'mobx';
import { whoAmI } from '../../api/api';
import { CurrentUser, User } from '../../types/Types';

class UserStore {
  constructor() {
    makeAutoObservable(this);

    this.currentUser = null;
  }

  jwt = '';
  currentUser: User | null;
  isLogged = !!window.localStorage.getItem('jwt');

  getJWT() {
    return this.jwt
      ? this.jwt
      : window.localStorage.getItem('jwt') || undefined;
  }

  async loadUserInfo() {
    const token = this.getJWT();
    if (!token) {
      return null;
    }

    try {
      const response = await whoAmI(token);
      this.currentUser = {
        ...response.data,
        country: response.data?.country?.id,
        delivery_country: response.data?.delivery_country?.id,
      };
    } catch (e: any) {
      console.log(e?.message);
    }

    return this.currentUser;
  }

  saveJwt(jwt: string) {
    this.jwt = jwt;
    window.localStorage.setItem('jwt', jwt);
  }

  async login(jwt: string) {
    window.localStorage.setItem('jwt', jwt);
    this.jwt = jwt;
    this.isLogged = true;

    this.loadUserInfo();
  }

  logout() {
    window.localStorage.removeItem('jwt');
    this.jwt = '';
    this.currentUser = null;
    this.isLogged = false;
  }
}

export default UserStore;
