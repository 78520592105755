import { ReactElement } from 'react';
import { PlayerSeasonStats, Season } from '../../../types/Types';
import styles from './NotGoalkeeperTable.module.scss';
import { getYears } from 'helpers/helpers';

type GoalkeeperTableProps = {
  seasonParam: string | null;
  season: Season | undefined;
  playerStats: PlayerSeasonStats | undefined;
};

function NotGoalkeeperTable({
  seasonParam,
  season,
  playerStats,
}: GoalkeeperTableProps): ReactElement {
  const getSeasonName = () => {
    const name = season?.attributes.name || getYears(seasonParam || '');
    if (name) {
      return ' ' + name;
    }
    return null;
  };

  return (
    <tr className={styles.tableTr}>
      <td className={styles.firstTd}>
        <div className={styles.firstTd}>
          <span>{season?.attributes.years_interval}</span>
          {
            // TODO: add season name to getYears(seasonParam || '') + 3rd fallback if both absent
            getSeasonName()
          }
          <span className={styles.season}>{playerStats?.teamname}</span>
        </div>
      </td>
      <td>{playerStats?.gamesPlayed}</td>
      <td>{playerStats?.goals}</td>
      <td>{playerStats?.assists}</td>
      <td>{playerStats?.points}</td>
      <td>{playerStats?.plusMinus}</td>
      <td>{playerStats?.penaltyInMinutes}</td>
      <td>{playerStats?.powerPlayGoals}</td>
      <td>{playerStats?.shorthandedGoals}</td>
      <td>{playerStats?.overtimeGoals}</td>
      <td>{playerStats?.shotsOnGoal}</td>
      <td>{playerStats?.shotsOnGoalPercent}</td>
      <td>{playerStats?.faceOffPercent}</td>
      <td>{playerStats?.timeOnIceAverage}</td>
    </tr>
  );
}

export default NotGoalkeeperTable;
