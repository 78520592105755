import Row from 'components/core/layout/Row';
import styles from './MobileHeaderShop.module.scss';
import tShirtIcon from 'assets/tshirt.svg';
import tShirtWhiteIcon from 'assets/icons/tshirt_red_on_white.svg';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import Url from 'constants/urls';

interface MobileHeaderShopProps {
  isDrawer?: boolean;
}

const MobileHeaderShop = ({ isDrawer }: MobileHeaderShopProps) => {
  const { t } = useTranslation();
  const buttonClassName = isDrawer
    ? styles.shopButtonDrawer
    : styles.shopButtonHeader;
  const textClassName = isDrawer
    ? styles.shopButtonTextDrawer
    : styles.shopButtonTextHeader;
  const iconSrc = isDrawer ? tShirtWhiteIcon : tShirtIcon;

  return (
    <a href={Url.loko.SHOP} target='_blank' rel='noreferrer'>
      <div className={classNames(styles.shopButton, buttonClassName)}>
        <Row
          alignItems='center'
          justifyContent='center'
          height='55px'
          gap='8px'
          width='150px'
        >
          <img src={iconSrc} alt='' />
          <span className={classNames(styles.shopButtonText, textClassName)}>
            {t('header.shop')}
          </span>
        </Row>
      </div>
    </a>
  );
};

export default MobileHeaderShop;
