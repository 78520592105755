import Colors from 'constants/colors';
import { ReactElement } from 'react';

interface ProtocolBannerRightBlockProps {
  fillColor?: string;
  width?: number;
  height?: number;
}

export default function ProtocolBannerRightBlock({
  fillColor,
  width = 150,
  height = 110,
}: ProtocolBannerRightBlockProps): ReactElement {
  const viewBoxY = 110 - height;

  return (
    <svg
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      width={`${width}px`}
      height={`${height}px`}
      viewBox={`0 ${viewBoxY} ${width} ${height}`}
    >
      <path
        fill={fillColor || Colors.DARK_GREY}
        d={`M58.65576,0.08935 ${width},0.08935 ${width},110 0.61922,110 Z`}
      />
    </svg>
  );
}
