import { ReactElement, useEffect, useState } from 'react';
import { PlayoffStandingsReadableDto, StandingsReadableDto } from 'types/Types';
import ShowOn from 'components/core/adaptivity/ShowOn';
import TournamentTree from './TournamentTree/TournamentTree';
import TournamentTable from './TournamentTable/TournamentTable';

const TournamentType = {
  west: '1',
  east: '2',
  final: '3',
};

type TournamentCrossProps = {
  generalStandings: Array<StandingsReadableDto>;
  playoffStandings: Array<PlayoffStandingsReadableDto>;
  crossRound: number;
};

type PlayoffGameProps = {
  [key: string]: Array<PlayoffStandingsReadableDto>;
};

export type GamesResultProps = {
  teama: number;
  teamb: number;
  teamaWins: number;
  teambWins: number;
  teamaRank: number;
  teambRank: number;
};

function TournamentCross({
  generalStandings,
  playoffStandings,
  crossRound,
}: TournamentCrossProps): ReactElement {
  const [round1West, setRound1West] = useState<Array<GamesResultProps>>([]);
  const [round2West, setRound2West] = useState<Array<GamesResultProps>>([]);
  const [round3West, setRound3West] = useState<Array<GamesResultProps>>([]);
  const [round1East, setRound1East] = useState<Array<GamesResultProps>>([]);
  const [round2East, setRound2East] = useState<Array<GamesResultProps>>([]);
  const [round3East, setRound3East] = useState<Array<GamesResultProps>>([]);
  const [finalResults, setFinalResults] = useState<GamesResultProps[]>([]);

  const sortMatches = (result: Array<GamesResultProps>) => {
    return result.sort((a, b) => {
      const lowestARank = Math.min(Number(a.teamaRank), Number(a.teambRank));
      const lowestBRank = Math.min(Number(b.teamaRank), Number(b.teambRank));

      return lowestARank - lowestBRank;
    });
  };

  const sortFirstRound = (
    round1: Array<GamesResultProps>,
    round2: Array<GamesResultProps>,
    setRound1: (value: Array<GamesResultProps>) => void
  ) => {
    if (round2?.length === 2) {
      const round1ResultSorted = [] as Array<GamesResultProps>;
      round2.forEach((pair2) =>
        round1.forEach((pair1) => {
          if (
            pair2.teamaRank === pair1.teamaRank ||
            pair2.teamaRank === pair1.teambRank ||
            pair2.teambRank === pair1.teamaRank ||
            pair2.teambRank === pair1.teambRank
          ) {
            round1ResultSorted.push(pair1);
          }
        })
      );
      setRound1(round1ResultSorted);
    } else {
      setRound1(round1);
    }
  };

  const getGamesResult = (teams: any) => {
    const result: Array<GamesResultProps> = [];
    Object.keys(teams).forEach((pair: string) => {
      const [team1, team2] = pair.split(':');
      let teamaWins = 0;
      let teambWins = 0;

      const matches = teams[pair];
      matches.forEach((match: PlayoffStandingsReadableDto) => {
        if (!match.score) return;
        const [teamaScore, teambScore] = match.score.split(':').map(Number);

        if (match.teama === team1) {
          if (teamaScore > teambScore) {
            teamaWins++;
          } else {
            teambWins++;
          }
        } else {
          if (teamaScore > teambScore) {
            teambWins++;
          } else {
            teamaWins++;
          }
        }
      });

      result.push({
        teamaWins,
        teambWins,
        teama: Number(team1),
        teamb: Number(team2),
        teamaRank:
          generalStandings?.find((team) => team.club_id === team1)
            ?.conference_rank ?? 0,
        teambRank:
          generalStandings?.find((team) => team.club_id === team2)
            ?.conference_rank ?? 0,
      });
    }, {});

    return sortMatches(result);
  };

  const groupByConferenceAndRound = (
    type: string,
    round: string,
    previousRound?: PlayoffGameProps
  ) => {
    let teamsInGroup: string[] = [];

    if (round === crossRound.toString()) {
      const gameInRound = playoffStandings.filter(
        (game) => game.round === round
      );

      const teamsInRound = Array.from(
        new Set(
          gameInRound.reduce((result: string[], game) => {
            result.push(game.teama);
            result.push(game.teamb);

            return result;
          }, [])
        )
      );

      teamsInGroup = generalStandings
        .filter(
          (team) =>
            team.conference === type && teamsInRound.includes(team.club_id)
        )
        .sort((a, b) => a.conference_rank - b.conference_rank)
        .reduce((result: StandingsReadableDto[], team, index) => {
          if (index % 2 === 0) {
            result.push(team);
          }

          return result;
        }, [])
        .map((item) => item.club_id);
    }

    if (parseInt(round) > crossRound && previousRound) {
      teamsInGroup = Object.keys(previousRound).reduce(
        (result: string[], item: string) => {
          const pair = item.split(':');
          result.push(...pair);

          return result;
        },
        []
      );
    }

    return playoffStandings
      .filter((game) => game.round === round)
      .reduce((result: PlayoffGameProps, game) => {
        const { teama, teamb } = game;

        const gamePair = `${teama}:${teamb}`;
        const gamePairReversed = `${teamb}:${teama}`;

        if (
          type !== TournamentType.final &&
          parseInt(round) < crossRound &&
          generalStandings.find((item) => item.club_id === teama)
            ?.conference !== type
        ) {
          return result;
        }

        if (
          round === crossRound.toString() &&
          !(teamsInGroup.includes(teama) || teamsInGroup.includes(teamb))
        ) {
          return result;
        }

        if (parseInt(round) > crossRound && teamsInGroup) {
          if (!(teamsInGroup.includes(teama) && teamsInGroup.includes(teamb))) {
            return result;
          }
        }

        if (!result[gamePair] && !result[gamePairReversed]) {
          result[gamePair] = [];
        }

        if (result[gamePairReversed]) {
          result[gamePairReversed].push(game);
        }

        if (result[gamePair]) {
          result[gamePair].push(game);
        }

        return result;
      }, {});
  };

  const groupFinalRound = (
    groupWest: PlayoffGameProps,
    groupEast: PlayoffGameProps
  ) => {
    const teamsInWestGroup = Object.keys(groupWest).reduce(
      (result: string[], item: string) => {
        const pair = item.split(':');
        result.push(...pair);

        return result;
      },
      []
    );
    return playoffStandings
      .filter((game) => game.round === '4')
      .reduce((result: PlayoffGameProps, game) => {
        const { teama, teamb } = game;
        let gamePair = '';
        let gamePairReversed = '';

        if (teamsInWestGroup.includes(teama)) {
          gamePair = `${teama}:${teamb}`;
          gamePairReversed = `${teamb}:${teama}`;
        } else {
          gamePair = `${teamb}:${teama}`;
          gamePairReversed = `${teama}:${teamb}`;
        }

        if (!result[gamePair] && !result[gamePairReversed]) {
          result[gamePair] = [];
        }

        if (result[gamePair]) {
          result[gamePair].push(game);
        }

        return result;
      }, {});
  };

  useEffect(() => {
    try {
      const round1GamesGroupedWest = groupByConferenceAndRound(
        TournamentType.west,
        '1'
      );
      const round2GamesGroupedWest = groupByConferenceAndRound(
        TournamentType.west,
        '2',
        round1GamesGroupedWest
      );
      const round3GamesGroupedWest = groupByConferenceAndRound(
        TournamentType.west,
        '3',
        round2GamesGroupedWest
      );
      const round1GamesGroupedEast = groupByConferenceAndRound(
        TournamentType.east,
        '1'
      );
      const round2GamesGroupedEast = groupByConferenceAndRound(
        TournamentType.east,
        '2',
        round1GamesGroupedEast
      );
      const round3GamesGroupedEast = groupByConferenceAndRound(
        TournamentType.east,
        '3',
        round2GamesGroupedEast
      );
      const roundGamesGroupedFinal = groupFinalRound(
        round3GamesGroupedWest,
        round3GamesGroupedEast
      );

      setRound2West(getGamesResult(round2GamesGroupedWest));
      setRound3West(getGamesResult(round3GamesGroupedWest));

      if (crossRound === 2) {
        setRound1West(getGamesResult(round1GamesGroupedWest));
        setRound1East(getGamesResult(round1GamesGroupedEast));
      } else {
        sortFirstRound(
          getGamesResult(round1GamesGroupedWest),
          getGamesResult(round2GamesGroupedWest),
          setRound1West
        );
        sortFirstRound(
          getGamesResult(round1GamesGroupedEast),
          getGamesResult(round2GamesGroupedEast),
          setRound1East
        );
      }

      setRound2East(getGamesResult(round2GamesGroupedEast));
      setRound3East(getGamesResult(round3GamesGroupedEast));

      setFinalResults(getGamesResult(roundGamesGroupedFinal));
    } catch (e: any) {
      console.error(e);
    }
  }, [generalStandings, playoffStandings]);

  return (
    <>
      <ShowOn largeDesktop smallDesktop>
        <TournamentTree
          round1West={round1West}
          round2West={round2West}
          round3West={round3West}
          round1East={round1East}
          round2East={round2East}
          round3East={round3East}
          finalResults={finalResults}
          generalStandings={generalStandings}
          isCrossPlayoff
          crossRound={crossRound}
        />
      </ShowOn>
      <ShowOn largeTablet smallTablet largeMobile smallMobile>
        <TournamentTable
          round1West={round1West}
          round2West={round2West}
          round3West={round3West}
          round1East={round1East}
          round2East={round2East}
          round3East={round3East}
          finalResults={finalResults}
          generalStandings={generalStandings}
          crossRound={crossRound}
        />
      </ShowOn>
    </>
  );
}

export default TournamentCross;
