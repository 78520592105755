import { ReactElement } from 'react';

type ArrowRightLineProps = {
  fill?: string;
  className?: string;
  onClick?: () => void;
};

function ArrowRightLine({
  fill = 'white',
  className,
  onClick,
}: ArrowRightLineProps): ReactElement {
  return (
    <svg
      className={className}
      onClick={onClick}
      width='48'
      height='48'
      viewBox='0 0 48 48'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g
        id='remix-icons/line/system/arrow-right-s-line'
        clipPath='url(#clip0_2129_76928)'
      >
        <g id='Group'>
          <path
            id='Vector'
            d='M26.0211 25.0712C26.4116 24.6807 26.4116 24.0475 26.0211 23.657L14.7071 12.343C14.3166 11.9525 14.3166 11.3193 14.7071 10.9288L16.9288 8.70711C17.3193 8.31658 17.9525 8.31658 18.343 8.70711L33.2929 23.657C33.6834 24.0475 33.6834 24.6807 33.2929 25.0712L18.343 40.0211C17.9525 40.4116 17.3193 40.4116 16.9288 40.0211L14.7071 37.7994C14.3166 37.4089 14.3166 36.7757 14.7071 36.3852L26.0211 25.0712Z'
            fill={fill}
          />
        </g>
      </g>
      <defs>
        <clipPath id='clip0_2129_76928'>
          <rect width='48' height='48' fill={fill} />
        </clipPath>
      </defs>
    </svg>
  );
}

export default ArrowRightLine;
