import styles from './DesktopAdvertisingWidget.module.scss';
import { AdvertisingWidgetDto } from 'types/Types';
import ImageInSideTypeWidget from '../ImageInSideTypeWidget/ImageInSideTypeWidget';
import ImageInCenterTypeWidget from '../ImageInCenterTypeWidget/ImageInCenterTypeWidget';
import GameTypeWidget from '../GameTypeWidget/GameTypeWidget';
import { ReactComponent as Arrow } from 'assets/arrow_upper_white2.svg';
import { useState } from 'react';

type DesktopAdvertisingWidgetType = {
  advertising: AdvertisingWidgetDto;
};

function DesktopAdvertisingWidget({
  advertising,
}: DesktopAdvertisingWidgetType) {
  const [isFull, setIsFull] = useState<boolean>(true);

  function getWidget() {
    if (advertising.attributes.game?.data) {
      return <GameTypeWidget advertising={advertising} />;
    }
    if (advertising.attributes.isImageInCenter) {
      return <ImageInCenterTypeWidget advertising={advertising} />;
    }
    return <ImageInSideTypeWidget advertising={advertising} />;
  }

  return isFull ? (
    <div className={styles.bottomRightBlock}>
      <div className={styles.insideBlock}>
        <button
          onClick={() => {
            setIsFull(false);
          }}
          className={styles.fullVersionBtn}
        >
          <Arrow className={styles.fullVersionArrow} />
        </button>
        {getWidget()}
      </div>
    </div>
  ) : (
    <div className={styles.smallBottomRightBlock}>
      <div className={styles.insideBigSmallBlock}>
        <div className={styles.insideSmallBlock}>
          <button
            onClick={() => {
              setIsFull(true);
            }}
            className={styles.smallVersionBtn}
          >
            <Arrow className={styles.smallVersionArrow} />
          </button>
        </div>
      </div>
    </div>
  );
}

export default DesktopAdvertisingWidget;
