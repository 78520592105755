import { ReactElement, useRef, useState } from 'react';
import { compareOrder } from '../../../helpers/helpers';
import { MenuElementDto } from '../../../types/Types';
import classNames from 'classnames';
import styles from './MenuElement.module.scss';

type MenuElementProps = {
  menuElement: MenuElementDto;
  closeMenu: Function;
  openMenuMouseEnter: Function;
  isScroll: boolean;
};

function MenuElement({
  menuElement,
  closeMenu,
  openMenuMouseEnter,
  isScroll,
}: MenuElementProps): ReactElement {
  const liRef = useRef<HTMLLIElement>(null);
  const [activeTab, setActiveTab] = useState(false);

  return (
    <li
      ref={liRef}
      onMouseEnter={() => {
        openMenuMouseEnter(liRef);
        setActiveTab(true);
      }}
      onMouseLeave={() => {
        closeMenu(liRef);
        setActiveTab(false);
      }}
      className={
        isScroll
          ? classNames(styles.navItem, styles.dropdown, styles.navItemScroll)
          : classNames(styles.navItem, styles.dropdown)
      }
    >
      <div
        className={activeTab ? styles.linkWrapperActive : styles.linkWrapper}
      >
        <a
          className={classNames(styles.navLink, styles.dropdownToggle)}
          data-toggle__='dropdown'
        >
          <span
            className={
              activeTab
                ? classNames(styles.linkTextActive, styles.linkText)
                : styles.linkText
            }
          >
            {menuElement.attributes.display_name}
          </span>
        </a>
      </div>

      <div className={'dropdown-menu'}>
        <div className={styles.dropdownMenuContainer}>
          {menuElement &&
            menuElement.attributes.main_menu_item.data
              .sort(compareOrder)
              .map((submenu) =>
                submenu.attributes.is_external ? (
                  <a
                    className={styles.dropdownItem}
                    key={submenu.id}
                    href={submenu.attributes.url}
                    target='_blank'
                    rel='noreferrer'
                  >
                    {submenu.attributes.display_name}
                  </a>
                ) : (
                  <a
                    className={styles.dropdownItem}
                    key={submenu.id}
                    href={submenu.attributes.url}
                    target=''
                    rel='noreferrer'
                  >
                    {submenu.attributes.display_name}
                  </a>
                )
              )}
        </div>
      </div>
    </li>
  );
}

export default MenuElement;
