import ArticleCategory from 'model/entity/loko/type/ArticleCategory';
import { compareOrder } from '../helpers/helpers';
import {
  GameProtocolDto,
  GameWithLogo,
  MenuElementDto,
  PlayerSeasonStats,
  PlayerSeasonStatsDto,
  RosterDto,
  RosterDtoWithStats,
  RosterSeason,
  ScheduleDay,
  Season,
  SideMenuPartnerDto,
  SideMenuPartners,
  SubMenuElementDto,
} from '../types/Types';

export function mapMenuElementDtoToAsideNavMenuType(
  menuElement: MenuElementDto
) {
  return {
    header: menuElement.attributes.display_name,
    elements: menuElement.attributes.main_menu_item.data
      .sort(compareOrder)
      .map((element) => {
        return {
          name: element.attributes.display_name.toLocaleUpperCase(),
          url: element.attributes.url,
          isExternal: element.attributes?.url?.includes('http'),
          isActive: false,
        };
      }),
  };
}

export function mapSeasonsListToAsideNavMenuType(
  seasonsList: Array<Season>,
  seasonCode: string,
  baseUrl: string
) {
  return {
    header: 'Сезон',
    elements: seasonsList.map((season) => {
      return {
        name: `${season.attributes.name} ${season.attributes.years_interval}`,
        url: `${baseUrl}?season=${season.attributes.code}`,
        isExternal: false,
        isActive: season.attributes.code === seasonCode,
      };
    }),
  };
}

export function mapRosterSeasonsToYearsInterval(
  rostersSeasons: Array<RosterSeason>
): Array<{ key: string; value: string }> {
  const map = new Map();

  rostersSeasons.forEach((season) => {
    if (
      !map.has(season.attributes.years_interval) &&
      season.attributes.display
    ) {
      const yearCode = season.attributes.name.slice(0, 4);
      map.set(season.attributes.years_interval, yearCode);
    }
  });

  return Array.from(map, ([key, value]) => ({ key, value }));
}

export function mergeGameWithLogoAndDate(
  calendar: Array<Date>,
  games: Array<GameWithLogo>
): Array<ScheduleDay> {
  return calendar.map((day) => {
    const gameInDay = games?.find(
      (game) => new Date(game.attributes.date).getDate() === day.getDate()
    );
    return {
      day: day,
      game: gameInDay,
    };
  });
}

export function mapArticleCategoryDtoToAsideNavMenuType(
  catogories: Array<ArticleCategory>,
  currentCategory: number
) {
  return {
    header: 'НОВОСТИ',
    elements: catogories.map((catogory) => {
      return {
        name: `${catogory.attributes.name}`,
        url: `/archive?category=${catogory.id}`,
        isExternal: false,
        isActive: catogory.id === currentCategory,
      };
    }),
  };
}

export function mapRostersSeasonsToAsideNavMenuType(
  rostersSeasons: Array<RosterSeason>
) {
  return {
    header: 'СЕЗОН',
    elements: rostersSeasons.map((season) => {
      return {
        name: `Команда ${season.attributes.years_interval}`,
        url: `/roster?season=${season.attributes.name}`,
        isExternal: false,
        isActive: false,
      };
    }),
  };
}

export function mapRosterDtoToAsideNavMenuType(
  rosters: Array<RosterDto>,
  header: string,
  currentRosterId: number
) {
  return {
    header: header,
    elements: rosters.map((roster) => {
      return {
        name: `${roster?.attributes?.player?.data?.attributes?.name} ${roster?.attributes?.player?.data?.attributes?.surname}`,
        url: `/player/${roster?.attributes?.player?.data?.id}?season=${roster?.attributes?.season?.data?.attributes?.name}`,
        isExternal: false,
        isActive: roster.id === currentRosterId,
      };
    }),
  };
}

export function mapMatchSeasonsToAsideNavMenuType(
  seasons: Array<Season>,
  season: string | null
) {
  const seasonsFiltered =
    season?.endsWith('MEN') || season?.endsWith('MEN-2')
      ? // '*prip' shouldn't be there for 'MEN' seasons
        seasons.filter(
          (x) =>
            x.attributes?.code?.endsWith('MEN') ||
            x.attributes?.code?.endsWith('MEN-2')
        )
      : season?.substring(4, 7) === 'LKO'
      ? seasons.filter((x) => x.attributes?.code?.substring(4, 7) === 'LKO')
      : // LUN/L76 part cannot be reached from menu now but we still could use it with minimal effort
      season?.substring(4, 7) === 'L76' || season?.substring(4, 7) === 'LUN'
      ? seasons.filter(
          (x) =>
            x.attributes?.code?.substring(4, 7) === 'LUN' ||
            x.attributes?.code?.substring(4, 7) === 'L76'
        )
      : [];
  return {
    header: 'ТУРНИРЫ',
    elements: seasonsFiltered.map((season) => {
      return {
        name: `${season.attributes.name} ${season.attributes.years_interval}`,
        url: `/standings?season=${season.attributes.code}`,
        isExternal: false,
        isActive: false,
      };
    }),
  };
}

export function mapStatsMenuToAsideNavMenuType(
  items: Array<SubMenuElementDto>
) {
  return {
    header: 'СТАТИСТИКА',
    elements: items.map((item) => {
      return {
        name: `${item.attributes.display_name.toLocaleUpperCase()}`,
        // TODO: for URLs that are not assigned yet set 404 URL
        url: `${item.attributes.url}`,
        isExternal: false,
        isActive: false,
      };
    }),
  };
}

export function mapPlayerSeasonStatsDto(
  playerStats: PlayerSeasonStatsDto
): PlayerSeasonStats {
  return {
    id: playerStats['@_id'] ? Number(playerStats['@_id']) : undefined,
    gamesPlayed: playerStats['@_gp'] ? playerStats['@_gp'] : undefined,
    goals: playerStats['@_g'] ? playerStats['@_g'] : undefined,
    assists: playerStats['@_a'] ? playerStats['@_a'] : undefined,
    points: playerStats['@_pts'] ? playerStats['@_pts'] : undefined,
    plusMinus: playerStats['@_pm'] ? playerStats['@_pm'] : undefined,
    plus: playerStats['@_plus'] ? playerStats['@_plus'] : undefined,
    minus: playerStats['@_minus'] ? playerStats['@_minus'] : undefined,
    penaltyInMinutes: playerStats['@_pim'] ? playerStats['@_pim'] : undefined,
    gameWinningGoals: playerStats['@_gwg'] ? playerStats['@_gwg'] : undefined,
    faceOffs: playerStats['@_fo'] ? playerStats['@_fo'] : undefined,
    faceOffsWin: playerStats['@_fow'] ? playerStats['@_fow'] : undefined,
    faceOffPercent: playerStats['@_fo_pct']
      ? playerStats['@_fo_pct']
      : undefined,
    goalsAllowed: playerStats['@_ga'] ? playerStats['@_ga'] : undefined,
    goalAgainstAverage: playerStats['@_gaa'] ? playerStats['@_gaa'] : undefined,
    timeOnIce: playerStats['@_toi'] ? playerStats['@_toi'] : undefined,
    saves: playerStats['@_sv'] ? playerStats['@_sv'] : undefined,
    savesPercent: playerStats['@_sv_pct'] ? playerStats['@_sv_pct'] : undefined,
    shutouts: playerStats['@_so'] ? playerStats['@_so'] : undefined,
    powerPlayGoals: playerStats['@_ppg'] ? playerStats['@_ppg'] : undefined,
    shorthandedGoals: playerStats['@_shg'] ? playerStats['@_shg'] : undefined,
    shootoutsDecidingShots: playerStats['@_sds']
      ? playerStats['@_sds']
      : undefined,
    shotsOnGoal: playerStats['@_sog'] ? playerStats['@_sog'] : undefined,
    shotsOnGoalPercent: playerStats['@_sog_pct']
      ? playerStats['@_sog_pct']
      : undefined,
    hits: playerStats['@_hits'] ? playerStats['@_hits'] : undefined,
    timeOnIceAverage: playerStats['@_toi_avg']
      ? playerStats['@_toi_avg']
      : undefined,
    shiftsOnGameAverage: playerStats['@_sft_avg']
      ? playerStats['@_sft_avg']
      : undefined,
    shotsOnGameAverage: playerStats['@_sog_avg']
      ? playerStats['@_sog_avg']
      : undefined,
    equalityGoals: playerStats['@_eqg'] ? playerStats['@_eqg'] : undefined,
    overtimeGoals: playerStats['@_otg'] ? playerStats['@_otg'] : undefined,
    foulsAgainst: playerStats['@_foa'] ? playerStats['@_foa'] : undefined,
    blockedThrows: playerStats['@_bls'] ? playerStats['@_bls'] : undefined,
    powerTechniques: playerStats['@_se'] ? playerStats['@_se'] : undefined,
    wins: playerStats['@_w'] ? playerStats['@_w'] : undefined,
    lose: playerStats['@_l'] ? playerStats['@_l'] : undefined,
    shootoutSeries: playerStats['@_sop'] ? playerStats['@_sop'] : undefined,
    timeInEqualAverage: playerStats['@_tie_avg']
      ? playerStats['@_tie_avg']
      : undefined,
    shiftsEqual: playerStats['@_sfte_avg']
      ? playerStats['@_sfte_avg']
      : undefined,
    timeInMostAverage: playerStats['@_tipp_avg']
      ? playerStats['@_tipp_avg']
      : undefined,
    shiftsMost: playerStats['@_sftpp_avg']
      ? playerStats['@_sftpp_avg']
      : undefined,
    timeInSmallerAverage: playerStats['@_tish_avg']
      ? playerStats['@_tish_avg']
      : undefined,
    shiftsSmaller: playerStats['@_sftsh_avg']
      ? playerStats['@_sftsh_avg']
      : undefined,
    tka: playerStats['@_tka'] ? playerStats['@_tka'] : undefined,
    position: playerStats['@_pos'] ? playerStats['@_pos'] : undefined,
    number: playerStats['@_jn'] ? playerStats['@_jn'] : undefined,
    teamname: playerStats['@_teamname'] ? playerStats['@_teamname'] : undefined,
  };
}

export function mergePlayerSeasonStatsAndRosterDto(
  rosters: Array<RosterDto>,
  stats: Array<PlayerSeasonStats>
): Array<RosterDtoWithStats> {
  return rosters.map((player) => {
    const playerStats = stats.find(
      (s) => player.attributes.player.data.attributes.id_league_web === s.id
    );

    return {
      player: player,
      stats: playerStats,
    };
  });
}

export function mapSideMenuPartner(
  sideMenuPartners: SideMenuPartnerDto[]
): SideMenuPartners[] {
  return sideMenuPartners.map((partner) => {
    return {
      id: partner.id,
      priority: partner.attributes.priority,
      partner: {
        name: partner.attributes?.partner.data?.attributes.name,
        url: partner.attributes?.partner.data?.attributes.url,
        enabled: partner.attributes?.partner.data?.attributes.enabled,
        logo: {
          url: partner.attributes?.partner.data?.attributes.logo?.data
            ?.attributes?.url,
        },
        partner_category: {
          data: {
            id: partner.attributes?.partner?.data?.attributes?.partner_category
              ?.data?.id,
            attributes: {
              name: partner.attributes?.partner?.data?.attributes
                ?.partner_category?.data?.attributes.name,
              priority:
                partner.attributes?.partner?.data?.attributes?.partner_category
                  ?.data?.attributes.priority,
              enabled:
                partner.attributes?.partner?.data?.attributes?.partner_category
                  ?.data?.attributes.enabled,
            },
          },
        },
      },
    } as SideMenuPartners;
  });
}

export function mergeGameWithLogoAndDateScheduleBlock(
  calendar: Array<Date>,
  games: Array<GameWithLogo>
): Array<ScheduleDay> {
  return calendar.map((day) => {
    const gameInDay = games?.find(
      (game) =>
        new Date(game.attributes.date).getFullYear() === day.getFullYear() &&
        new Date(game.attributes.date).getMonth() === day.getMonth() &&
        new Date(game.attributes.date).getDate() === day.getDate()
    );
    return {
      day: day,
      game: gameInDay,
    };
  });
}
