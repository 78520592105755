import { ReactElement } from 'react';

interface VKIconTransparentProps {
  fillColor: string;
  width?: number;
  height?: number;
}

export default function VKIconTransparent({
  fillColor,
  width = 24,
  height = 15,
}: VKIconTransparentProps): ReactElement {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 24 15'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        id='Vector'
        d='M13.0677 15.486C4.87043 15.486 0.194817 9.86622 0 0.5H4.10615C4.24102 7.36357 7.26818 10.2409 9.66593 10.8703V0.514989H13.5323V6.43444C15.9001 6.17968 18.3878 3.48221 19.227 0.514989H23.0859C22.7752 2.05799 22.1489 3.5201 21.2463 4.80955C20.3437 6.09899 19.1842 7.18791 17.8408 8.00796C19.3385 8.75084 20.6619 9.80272 21.7235 11.0943C22.7852 12.3858 23.5611 13.8877 24 15.5009H19.7365C19.3446 14.0971 18.5467 12.8403 17.4429 11.8884C16.339 10.9366 14.9786 10.3321 13.5323 10.151V15.5009L13.0677 15.486Z'
        fill={fillColor}
      />
    </svg>
  );
}
