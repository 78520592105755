import { ExecutiveType, SortElement, StandingsTypes } from '../types/Types';

import { Breakpoints } from './adaptivity';

export const FACEBOOK_PLUGIN = 'https://www.facebook.com/plugins/like.php';

export const DAYS_OF_WEEK = new Map()
  .set(0, 'Воскресенье')
  .set(1, 'Понедельник')
  .set(2, 'Вторник')
  .set(3, 'Среда')
  .set(4, 'Четверг')
  .set(5, 'Пятница')
  .set(6, 'Суббота');

export const DAYS_SHORT_OF_WEEK = new Map()
  .set(0, 'Вс')
  .set(1, 'Пн')
  .set(2, 'Вт')
  .set(3, 'Ср')
  .set(4, 'Чт')
  .set(5, 'Пт')
  .set(6, 'Сб');

export const POSITION_MAP = new Map()
  .set(1, 'Вратарь')
  .set(2, 'Защитник')
  .set(3, 'Нападающий');

export const POSITION_MAP_LETTER = new Map()
  .set('в', 'Вратарь')
  .set('з', 'Защитник')
  .set('н', 'Нападающий');

export const GRIP = new Map().set(1, 'Левый').set(2, 'Правый');

export const DEFAULT_IMAGE_NAME = 'ЛокомотивБольшой';

export const DEFAULT_PHOTOGALLERY_NAME = 'Фотогалерея';

export const DEFAULT_PLAYER_IMAGE_NAME = 'ФотоИгрока';

export const HEADER_FOR_CATEGORY = new Map()
  .set('ОЧКОВ', 'Бомбардиры')
  .set('ГОЛОВ', 'Снайперы')
  .set('ПЕРЕДАЧ', 'Ассистенты')
  .set('КН', 'Вратари');

export const VK_GROUP_ID = 14160737;
export const TG_IFRAME_WIDGET_URL =
  'https://tgwidget.com/channel/v2.0/?id=651e9d5d37797e1f4f07ff55';

export const TG_ID = 'tgw_651e9d5d37797e1f4f07ff55';

// We can not get this information from youtube by api
export const LOKOMOTIV_YOUTUBE_OLDEST_VIDEO_YEAR = 2013;

export const LOKOMOTIV_CODE = 'LOK';

export const CATEGORY_NOUNS = new Map()
  .set('ОЧКОВ', ['ОЧКО', 'ОЧКА', 'ОЧКОВ'])
  .set('ГОЛОВ', ['ШАЙБА', 'ШАЙБЫ', 'ШАЙБ'])
  .set('ПЕРЕДАЧ', ['ПЕРЕДАЧА', 'ПЕРЕДАЧИ', 'ПЕРЕДАЧ']);

export const starts_hcl_year = 2009;
export const starts_mhl_year = 2010;

export const SORT_KEYS_FOR_STANDINGS = [
  'points',
  'wins',
  'overtime_wins',
  'shootouts_wins',
  'shootouts_losses',
  'overtime_losses',
  'losses',
  'goals_for',
  'goals_against',
];

export const MONTH = new Map()
  .set(0, 'Январь')
  .set(1, 'Февраль')
  .set(2, 'Март')
  .set(3, 'Апрель')
  .set(4, 'Май')
  .set(5, 'Июнь')
  .set(6, 'Июль')
  .set(7, 'Август')
  .set(8, 'Сентябрь')
  .set(9, 'Октябрь')
  .set(10, 'Ноябрь')
  .set(11, 'Декабрь');

export const CONTINUATION = new Map()
  .set(' Овертайм', 'ОТ')
  .set('Буллиты', 'Б');

export const EXECUTIVESNAMES = new Map()
  .set(ExecutiveType.COACH, 'Тренерский состав ХК «Локомотив»')
  .set(ExecutiveType.EXECUTIVE, 'Руководство')
  .set(ExecutiveType.MANAGER, 'Менеджмент')
  .set(ExecutiveType.STAFF, 'Персонал команды')
  .set(ExecutiveType.PRESS, 'Пресс-служба');

export const EXECUTIVESNAMES_CATASTROPHE = new Map()
  .set(ExecutiveType.COACH, 'Жертвы катастрофы - тренеры')
  .set(ExecutiveType.STAFF, 'Жертвы катастрофы - персонал')
  .set('coach_TITLE', 'Тренеры')
  .set('staff_TITLE', 'Персонал');

export const SEASON_BOUND_EXECUTIVES: Array<string> = [
  ExecutiveType.COACH,
  ExecutiveType.STAFF,
];

export const EXECUTIVESURLS = new Map()
  .set(ExecutiveType.COACH, '/coaches')
  .set(ExecutiveType.EXECUTIVE, '/executives')
  .set(ExecutiveType.MANAGER, '/management')
  .set(ExecutiveType.STAFF, '/staff')
  .set(ExecutiveType.PRESS, '/press');

export const HEIGHT = new Map()
  .set(Breakpoints.smallDesktop, 584)
  .set(Breakpoints.largeTablet, 470)
  .set(Breakpoints.smallTablet, 450)
  .set(Breakpoints.largeMobile, 400);

export const BreakpointsForCalendar = {
  tv: 4000,
  largeDesktop: 3000,
  desktop: 1920,
  tablet: 1024,
  mobile: 464,
};

export const calendarSlidesToShow = {
  tv: 15,
  largeDesktop: 12,
  desktop: 10,
  tablet: 8,
  mobile: 3,
};

export const calendarSlidesToScroll = {
  tv: 5,
  largeDesktop: 4,
  desktop: 3,
  tablet: 3,
  mobile: 1,
};

export const defaultForwardSortArray: Array<SortElement> = [
  { key: 'points', desc: true, type: StandingsTypes.number },
  { key: 'goals', desc: true, type: StandingsTypes.number },
  { key: 'assists', desc: true, type: StandingsTypes.number },
  { key: 'games_played', desc: false, type: StandingsTypes.number },
  { key: 'plus_minus', desc: true, type: StandingsTypes.number },
  { key: 'penalty_in_minutes', desc: true, type: StandingsTypes.number },
];
export const defaultGoalKeeperSortArray: Array<SortElement> = [
  { key: 'goals_against_average', desc: false, type: StandingsTypes.number },
  { key: 'games_played', desc: true, type: StandingsTypes.number },
  { key: 'savings_percent', desc: true, type: StandingsTypes.number },
];

export const defaultStandingSortArray: Array<SortElement> = [
  { key: 'points', desc: true, type: StandingsTypes.number },
  { key: 'wins', desc: true, type: StandingsTypes.number },
  { key: 'overtime_wins', desc: true, type: StandingsTypes.number },
  { key: 'shootouts_wins', desc: true, type: StandingsTypes.number },
  { key: 'shootouts_losses', desc: true, type: StandingsTypes.number },
  { key: 'overtime_losses', desc: true, type: StandingsTypes.number },
  { key: 'losses', desc: true, type: StandingsTypes.number },
  { key: 'games_played', desc: false, type: StandingsTypes.number },
];

export const MONTHS = new Map()
  .set(0, 'января')
  .set(1, 'февраля')
  .set(2, 'марта')
  .set(3, 'апреля')
  .set(4, 'мая')
  .set(5, 'июня')
  .set(6, 'июля')
  .set(7, 'августа')
  .set(8, 'сентября')
  .set(9, 'октября')
  .set(10, 'ноября')
  .set(11, 'декабря');

export const TICKET_PRICE_CATEGORIES = new Map<string, string>()
  .set('category_a_plus', 'A+')
  .set('category_a', 'A')
  .set('category_b', 'B')
  .set('category_c', 'C');

export const TICKET_AGE_CATEGORIES = ['adult', 'child'];

export const RESPONSE_MESSAGE_UNIQUE = 'This attribute must be unique';

export const ALBUM_OPEN_FAILED = 'Невозможно открыть альбом';
export const VOTE_FAILED = 'Голос не учтён';

export enum DOCUMENTS_SYS_NAMES {
  termsOfUse = 'terms_of_use',
  userAgreement = 'user_agreement',
  privacyStatement = 'privacy_statement',
  laborProtection = 'labor_protection',
}

export const lokomotivStatsPDFname = 'lokomotive_stats.pdf';
export const conferenceWest = 'Конференция "Запад"';
export const conferenceEast = 'Конференция "Восток"';
