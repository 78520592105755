import { ReactElement } from 'react';
import styles from './Info.module.scss';
import { useStore } from '../../../store/store';
import InfoBlock from './InfoBlock/InfoBlock';
import { ReactComponent as Logo } from '../../../assets/logo_svg.svg';
import ShowOn from 'components/core/adaptivity/ShowOn';
import Row from 'components/core/layout/Row';
import Column from 'components/core/layout/Column';
import ErrorWidget from 'components/common/ErrorWidget/ErrorWidget';
import { observer } from 'mobx-react-lite';
import { useMediaQuery } from 'react-responsive';

const Info = observer((): ReactElement => {
  const { menuStore } = useStore();
  const isTablet = useMediaQuery({
    maxWidth: '820px',
  });

  const renderMenu = () =>
    menuStore.menu.length > 0 &&
    menuStore.menu.map(
      (element) =>
        element.attributes.display_name !== 'Билеты'.toLocaleUpperCase() && (
          <InfoBlock
            key={element.id}
            menuElementName={element.attributes.name}
            submenuElements={element.attributes.main_menu_item.data}
            menuElementTitle={element.attributes.display_name}
          />
        )
    );

  const renderContent = () => (
    <>
      <ShowOn largeDesktop smallDesktop largeTablet smallTablet>
        <a className={styles.logo} href='/'>
          <Logo />
        </a>
      </ShowOn>
      <ShowOn largeMobile smallMobile>
        <a className={styles.mobileLogo} href='/'>
          <Logo className={styles.mobileLogo} />
        </a>
      </ShowOn>

      <ShowOn largeDesktop smallDesktop largeTablet smallTablet>
        <div className={styles.wrapperGrid}>{renderMenu()}</div>
      </ShowOn>
      <ShowOn largeMobile smallMobile>
        <div className={styles.wrapperSmallWidth}>
          <Column gap='20px' width='100%'>
            {renderMenu()}
          </Column>
        </div>
      </ShowOn>
    </>
  );

  return menuStore.menu.length > 0 ? (
    <>
      <ShowOn largeDesktop smallDesktop largeTablet smallTablet>
        <div className={styles.wrapperFullWidth}>
          <Row gap={isTablet ? '0' : '40px'}>{renderContent()}</Row>
        </div>
      </ShowOn>
      <ShowOn largeMobile smallMobile>
        <Column gap='20px' alignItems='center'>
          {renderContent()}
        </Column>
      </ShowOn>
    </>
  ) : (
    <ErrorWidget.Error />
  );
});

export default Info;
